import React, { Component } from 'react';

export default class Footer extends Component<{}, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    render() {
        return (
            <footer className="main-footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <p>&copy; Simple Mart All rights reserved 2020 - {(new Date().getFullYear())}</p>
                        </div>
                        <div className="col-sm-6 text-right">
                            <p>
                                Powered By{" "}
                                <a style={{ textDecoration: "none" }} className="external" href="/" target="/blank">
                                    Simple Mart
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer >
        );
    }
};