import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { logoutUser, blockunblockEmp, employeeList } from '../../redux'
import { EmpProps } from '../../interfaces/employees';
class ActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleBlockUnblock = (id: any, is_active: any) => {
        this.props.data.blockunblock(id, is_active, this.props.row.email_address);
    };
    render() {
        const { row } = this.props
        let coupons = [];
        for (let i = 0; i < (row.emp_coupon_id.length); i++) {
            coupons.push(row.emp_coupon_id[i].coupon_id)
        }
        return (
            <div>
                <button title={row.is_active === 0 ? "UnBlock" : "Block"} data-toggle="modal" data-target={`#blockunblock${row.id}`} className={row.is_active === 0 ? "btn btn-outline-success" : "btn btn-outline-danger"}><i className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}></i></button>
                <Link title="Edit Employee" className="btn btn-outline-primary ml-2" to={`/edit-emp/${row.id}`}><i className="fa fa-edit"></i></Link>
                <button title="View Vouchers" data-toggle="modal" data-target={`#viewVouchers${row.id}`} className="btn btn-outline-info ml-2"><i className="fa fa-list"></i></button>
                {/* <!-- Block/Unblock Modal--> */}
                <div id={`blockunblock${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 0 ? "UnBlock" : "Block"} Employee</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to {row.is_active === 0 ? "UnBlock" : "Block"} this employee?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleBlockUnblock(row.id, row.is_active)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Modal--> */}
                <div id={`viewVouchers${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Vouchers</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            <BootstrapTable version='4' data={coupons} hover>
                                                <TableHeaderColumn dataField='coupon_code' columnTitle isKey>Voucher Code</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}

class Employees extends Component<EmpProps, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.employeeList()
        document.title = "Crust Culture | Employees"
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-emp" /> },
            noDataText: 'Employees Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Employees Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} insertRow csvFileName='employees.csv' hover>
                                                    <TableHeaderColumn dataField='id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='name' csvHeader='Name' width='100' columnTitle>Name</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='email_address' csvHeader='Email' width='100' columnTitle>Email</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='phone_number' csvHeader='Phone' width='100' columnTitle>Mobile</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='redemption_limit' csvHeader='Redemption Limit' width='100' columnTitle>Limit</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='total_redeemed' csvHeader='Redeemed' width='100' columnTitle>Redeemed</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='vip' width='100' dataFormat={employeeFormatter} export={false}>VIP</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='employee_type_id' dataFormat={empTypeFormatter} csvHeader='Type' width='100'>Type</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='action' width='200' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
function employeeFormatter(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" })}>{cell == 1 && <i className="fa fa-check-square-o"></i>}</span>
        </div>
    )
}
function empTypeFormatter(cell: any) {
    return (
        <div className="text-capitalize">
            <span title={cell && cell.name}>{cell && cell.name}</span>
        </div>
    )
}
const mapStateToProps = (state: any) => {
    return {
        data: state.employee.employees,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        employeeList: function () {
            dispatch(employeeList())
        },
        blockunblock: function (id: any, is_active: any, email_address: any) {
            dispatch(blockunblockEmp(id, is_active, email_address))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Employees);