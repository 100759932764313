import React, { Component } from 'react';
import { connect } from 'react-redux'
import { addNotification, logoutUser } from '../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
import { AddPushProps, AddPushState } from '../../interfaces/notifications';
import moment from 'moment';
class AddNotification extends Component<AddPushProps, AddPushState> {
    constructor(props: any) {
        super(props);
        this.state = {
            title: "",
            description: "",
            expiryDate: "",
            error: "",
            is_schedule: false,
            scheduleDate: '',
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        document.title = "Crust Culture | Push Notifications"
    }
    handleSchedule = (event: { target: { name: any; value: any; }; }) => {
        const { is_schedule } = this.state;
        this.setState({ is_schedule: !is_schedule })
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.name == "description" && event.target.value.length > 150) {
            this.setState({
                error: "Description must be 150 characters"
            });
        } else {
            this.setState({
                error: ""
            });
        }
    }
    isNotificationReady = () => {
        const { title, description, expiryDate, is_schedule, scheduleDate } = this.state
        if (is_schedule) {
            return (title !== "" && (description !== "" && description.length <= 150) && expiryDate !== "" && scheduleDate !== "");
        } else {
            return (title !== "" && (description !== "" && description.length <= 150) && expiryDate !== "");
        }
    }
    handleSaveBtnClick = (event: any) => {
        let { title, description, expiryDate, scheduleDate, is_schedule } = this.state;
        let data: any = {
            title: title,
            body: description,
            expire_date: expiryDate
        }
        if (is_schedule) {
            data.is_schedule = 1;
            data.pn_start_time = scheduleDate;
        }
        this.props.addNotification(data);
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let today = moment().format("YYYY-MM-DDThh:mm");
        return (
            <div className="page">
                <CheckChanges path="/add-promo" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Compaigns Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/marketing/2" className="text-primary">Compaigns</Link></li>
                                <li className="breadcrumb-item active">Add Push Notification</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Title <span className="text-danger">*</span></label>
                                                            <input id="title" type="text" name="title" required data-msg="Please enter Title" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description <span className="text-danger">*</span></label>
                                                            <input id="description" type="text" name="description" required data-msg="Please enter Description" className="input-material" onChange={this.handleInputChange} />
                                                            <small className="form-text text-danger">{this.state.error}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Valid till <span className="text-danger">*</span></label>
                                                            <input id="expiryDate" type="date" name="expiryDate" required data-msg="Please enter Expire Date" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-6 my-2">
                                                        <div>
                                                            <input id="checkboxCustom3" type="checkbox" name="is_schedule" checked={this.state.is_schedule} onChange={this.handleSchedule} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom3">Schedule Notifications</label>
                                                        </div>
                                                    </div>
                                                    {this.state.is_schedule &&
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label mb-3">Schedule Notification <span className="text-danger">*</span></label>
                                                                <input type="datetime-local" name="scheduleDate" min={today} data-msg="Please enter Date" placeholder="DD/MM/YYYY" className="form-control" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isNotificationReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addNotification: function (data: any) {
            dispatch(addNotification(data));
        }
    }
}
export default connect(null, mapDispatchToProps)(AddNotification);