import React, { Component } from 'react';
import { AddModProps, AddModState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import { modGroupsList, addModifier, logoutUser, groupsList } from '../../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import Topbar from '../../../components/topbar';
import Sidebar from '../../../components/sidebar';
import Footer from '../../../components/footer/main';
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';
import { getItemsByGroup, getVariantsByItem, variantsList } from '../../../redux/actions/menuAction';
class AddMod extends Component<AddModProps, AddModState> {
    constructor(props: any) {
        super(props);
        this.state = {
            modGroupJson: [],
            modifiername: "",
            erpid: null,
            poscode: null,
            variant_id: [],
            priority: "",
            modifiertype: "",
            modifiercost: "",
            modifiersale: "",
            images: null,
            for_specific_variations: '0',
            min_quantity: "",
            max_quantity: "",
            nutritional_info:"",
            recipe: ""
            // sizejson: [
            //     {
            //         nutritional_info: "",
            //         variant_id: "",
            //     }
            // ]
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleModGroupsInputChange = this.handleModGroupsInputChange.bind(this);
    }
    componentDidMount() {
        this.props.groupsList();
        this.props.modGroupsList()
        this.props.variantsList()
        document.title = "Crust Culture | Modifiers"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        console.log(event.target.value, "event.target.value");
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleModGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ modGroupJson: e });
        } else {
            this.setState({ modGroupJson: [] });
        }
    };
    // handleVariantInputChange = (e: any, index: any) => {
    //     const { name, value } = e.target;
    //     const list: any = this.state.sizejson;
    //     list[index][name] = value;
    //     this.setState({ sizejson: list });
    // };
    // handleRemoveClick = (index: any) => {
    //     const list = this.state.sizejson;
    //     list.splice(index, 1);
    //     this.setState({ sizejson: list });
    // };
    // handleAddClick = (i: any) => {
    //     const list: any = this.state.sizejson;
    //     if (list[i]["variant_id"] == "" || list[i]["nutritional_info"] == "") {
    //         toast.error("Please fill all mandatory nutrition fields", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: false, autoClose: 3000 });
          
    //     } else {
    //         const newlist: any = { variant_id: "", nutritional_info: "" }

    //         const nutrition: any = this.state.sizejson.concat(newlist)
    //         this.setState({ sizejson: nutrition });
    //     }

    // };
    fileSelectedHandler = (e: any) => {
        this.setState({ images: e.target.files })
    }
    handleVariantChange = async (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ variant_id: e });
        } else {
            this.setState({ variant_id: [] });
        }
    }
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    isModifierReady = () => {
        const { modifiername, modGroupJson, modifiercost, modifiersale,
            images,priority
         } = this.state
        return (modifiername !== "" && priority!=="" && (modGroupJson && modGroupJson.length > 0) && modifiercost !== "" && modifiersale !== "" && images && images.length>0 );
    }
    handleSaveBtnClick = (event: any) => {
        let { for_specific_variations, modGroupJson, erpid, poscode, priority, modifiername, modifiertype, modifiercost, modifiersale, variant_id, nutritional_info,recipe } = this.state;
        // const SizeJsonString = JSON.stringify(sizejson)
        const data: any = new FormData()
        if (this.state.images) {
            data.append('file', this.state.images[0])
        }
        data.append('modifier_name', modifiername)
        if (erpid !== null) {
            data.append('erp_id', erpid)
        }
        if (poscode !== null) {
            data.append('pos_code', poscode)
        }
        data.append('modifier_type', modifiertype)
        data.append('total_nutrition', nutritional_info)
        data.append('modifier_cost_price', modifiercost)
        data.append('modifier_sale_price', modifiersale)
        data.append('priority', priority)
        data.append('recipe', recipe)
        //  if (sizejson[sizejson.length - 1]["variant_id"] !== "" && sizejson[sizejson.length - 1]["nutritional_info"] !== "") {
        //     data.append('itemSizes', SizeJsonString)
        // }
        // data.append('min_quantity', min_quantity)
        // data.append('max_quantity', max_quantity)
        data.append('mod_groups_json', JSON.stringify(modGroupJson))
        if (for_specific_variations == '0') {
            data.append('for_specific_variations', 0)
            this.props.addModifier(data);
        } else if (for_specific_variations == '1') {
            data.append('for_specific_variations', 1)
            data.append('variants', JSON.stringify(variant_id))
            if (variant_id.length > 0) {
                this.props.addModifier(data);
            }
           
            //  else {
                
            //     toast.error("Please fill all mandatory variant fields", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            //  }
        }
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let variants_arr: any = []
        const { modGroupJson, for_specific_variations,
            //  sizejson
             } = this.state;
        const { modgroups, groups, items, variants } = this.props;
        variants.map((varr: any) => {
            variants_arr.push({ label: varr.name, value: varr.id })
        })
        return (
            <div className="page">
                <CheckChanges path="/add-modifier" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Modifier Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/modifiers" className="text-primary">Modifiers</Link></li>
                                <li className="breadcrumb-item active">Add Modifier</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifier name <span className="text-danger">*</span></label>
                                                            <input id="modifiername" type="text" name="modifiername" required data-msg="Please enter Modifier Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">ERP Id</label>
                                                            <input id="erpid" type="text" name="erpid" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">POS Code</label>
                                                            <input id="poscode" type="text" name="poscode" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-8 col-sm-8 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifier Group <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="groups"
                                                                value={modGroupJson}
                                                                options={modgroups}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleModGroupsInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority<span className="text-danger">*</span></label>
                                                            <input id="priority" type="text" name="priority" required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-4 col-sm-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Recipe</label>
                                                            <input id="recipe" type="text" name="recipe" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-4 col-sm-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Modifier Image</label>
                                                            <input id="file" type="file" name="file" className="form-control-file" onChange={this.fileSelectedHandler} />
                                                            <small className="form-text">Image size should be less than 1 mb</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Nutritional Info</label>
                                                            <input id="nutritional_info" type="text" name="nutritional_info" className="input-material" onChange={ this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Cost Price <span className="text-danger">*</span></label>
                                                            <input id="modifiercost" type="number" onWheel={(e:any) => e.target.blur()} name="modifiercost" required data-msg="Please enter Cost Price" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Sale Price <span className="text-danger">*</span></label>
                                                            <input id="modifiersale" type="number" onWheel={(e:any) => e.target.blur()} name="modifiersale" required data-msg="Please enter Sale Price" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Min Quantity <span className="text-danger">*</span></label>
                                                            <input id="min_quantity" type="number" onWheel={(e:any) => e.target.blur()} name="min_quantity" min={0} required data-msg="Please enter Min Quantity" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Max Quantity <span className="text-danger">*</span></label>
                                                            <input id="max_quantity" type="number" onWheel={(e:any) => e.target.blur()} name="max_quantity" min={1} required data-msg="Please enter Max Quantity" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                        <div >
                                                            <input id="radioCustom3" type="radio" name="for_specific_variations" value="0" checked={for_specific_variations == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Available for all variants</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                        <div >
                                                            <input id="radioCustom4" type="radio" name="for_specific_variations" value="1" checked={for_specific_variations == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Available for specific variants</label>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-4 col-sm-4 col-6 mt-5" >
                                                        <div onClick={() => this.setState({ for_multi_nutrition: !this.state.for_multi_nutrition })} >
                                                            <input id="checkboxCustom6" type="checkbox" name="for_multi_nutrition" value="2" onChange={this.handleChangeRad} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom6">Nutritional info</label>
                                                        </div>
                                                    </div> */}


                                                </div>

                                                {for_specific_variations == "1" &&
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <div className="row">

                                                                    <div className="col-lg-6 col-sm-6 col-12 pt-3">
                                                                        <div className="form-group">
                                                                            <label className="form-control-label">Select Variant <span className="text-danger">*</span></label>
                                                                            <Select
                                                                                isMulti
                                                                                className="basic-multi-select text-capitalize py-2"
                                                                                options={variants_arr}
                                                                                onChange={(e, i) => this.handleVariantChange(e, i)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {/* {sizejson.map((x: any, ind: any) => {
                                                    return (
                                                        <div key={ind} className="row">


                                                            <div className="col-lg-4 col-sm-4 col-6 mt-5">
                                                                <div className="form-group">
                                                                    <label className="form-control-label mb-3">Nutritional Info Variant  <span className="text-danger">*</span></label>

                                                                    <select
                                                                        onChange={(e: any) => this.handleVariantInputChange(e, ind)}
                                                                        name="variant_id" className="form-control text-capitalize mt-2">
                                                                        <option value="Select variant" selected disabled>Select variant</option>
                                                                        {variants &&
                                                                            variants.map((variant: any, index: any) => (
                                                                                <option value={variant.id}>{variant.name}</option>
                                                                            ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-sm-4 col-6 mt-5">
                                                                <div className="form-group">
                                                                    <label className="form-control-label mb-3">Nutritional Info <span className="text-danger">*</span></label>
                                                                    <input id="nutritional_info" value={x.nutritional_info} type="number" onWheel={(e:any) => e.target.blur()} name="nutritional_info" className="input-material" onChange={(e) => this.handleVariantInputChange(e, ind)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-sm-3 col-6 d-flex align-items-center">
                                                                <div className="form-group">
                                                                    <label className="form-control-label"> </label>
                                                                    {sizejson.length !== 1 &&
                                                                        <button className="btn btn-sm btn-outline-danger" onClick={() => this.handleRemoveClick(ind)}><i className="fa fa-trash"></i></button>}
                                                                    {sizejson.length - 1 === ind && <button className="btn btn-sm btn-primary ml-2 float-right" onClick={() => this.handleAddClick(ind)}><i className="fa fa-plus"></i></button>}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )
                                                })} */}
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isModifierReady()
                                                        //  || (sizejson[sizejson.length - 1]["variant_id"] == "" || sizejson[sizejson.length - 1]["nutritional_info"] == "") 
                                                         } onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        groups: state.menu.groups,
        modgroups: state.menu.activeModgroups,
        items: state.menu.itemsByGroup,
        variants: state.menu.variants
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addModifier: function (data: any) {
            dispatch(addModifier(data));
        },
        groupsList: function () {
            dispatch(groupsList())
        },
        getItemsByGroup: (groupid: number) => {
            dispatch(getItemsByGroup(groupid))
        },
        getVariantsByItem: (itemid: number) => {
            dispatch(getVariantsByItem(itemid))
        },
        variantsList: function () {
            dispatch(variantsList())
        },
        modGroupsList: function () {
            dispatch(modGroupsList())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddMod);