import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { getSuggCombos, itemsListForBanners, logoutUser, updateSuggestiveDeals } from '../../../redux'
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import { EditSuggestiveComboProps, EditSuggestiveComboState } from '../../../interfaces/menu';
import { storesList } from '../../../redux/actions/reportAction';
import Select from 'react-select'
import CheckChanges from '../../../components/confirmOnLeave'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { editSuggestive, getSuggestive } from '../../../redux/actions/menuAction';
toast.configure();

class EditSuggestiveDeals extends Component<EditSuggestiveComboProps, EditSuggestiveComboState> {
    constructor(props: any) {
        super(props);
        this.state = {
            store_value: "",
            store_label: "",

            selectedCombo: null,
            selectedSuggComboJson: [],
            is_lsm: '0',
            storesJson: [],
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id
        this.props.storesList();
        this.props.getSuggestive(id);
        this.props.getOnlyCombos();
        this.props.getSuggCombos()
        document.title = "Crust Culture | Edit Suggistive Deals"

    }
    UNSAFE_componentWillReceiveProps(nextProps: any, nextState: any) {
        if (nextProps.suggestiveData.store_id) {
            {
                let data: any = []
                let abc = {
                    value: nextProps.suggestiveData.store_id && nextProps.suggestiveData.store_id.store_id,
                    label: nextProps.suggestiveData.store_id && nextProps.suggestiveData.store_id.store_name,
                }
                data.push(abc)
                this.setState({
                    store_label: nextProps.suggestiveData.store_id && nextProps.suggestiveData.store_id.store_name,
                    store_value: nextProps.suggestiveData.store_id && nextProps.suggestiveData.store_id.store_id,
                })
            }
        }
        if (nextProps.suggestiveData.suggestive_child_products) {
            let data: any = []
            nextProps.suggestiveData.suggestive_child_products.forEach((obj: any, index: any) => {
                let abc = {
                    value: obj.suggestive_combo_id.combo_id,
                    label: obj.suggestive_combo_id.combo_name
                }
                data.push(abc)
            })
            this.setState({
                selectedSuggComboJson: (data)
            })
        }
        if (nextProps.suggestiveData.combo_id) {
            {
                let data: any = []
                let abc = {
                    value: nextProps.suggestiveData.combo_id.combo_id,
                    label: nextProps.suggestiveData.combo_id.combo_name
                }
                data.push(abc)
                this.setState({
                    selectedCombo: data, 
                    is_lsm: nextProps.suggestiveData.is_lsm,
                    storesJson: nextProps.suggestiveData.stores_json ? JSON.parse(nextProps.suggestiveData.stores_json) : this.state.storesJson,
                })
            }
        }

    }
    isAttachSuggistiveDealReady = () => {
        const { storesJson,is_lsm, selectedCombo, selectedSuggComboJson } = this.state;
        if (is_lsm == "0") {
            return (selectedCombo !== null && selectedSuggComboJson.length > 0);
        } else if (is_lsm == "1") {
            return (selectedCombo !== null && selectedSuggComboJson.length > 0&& storesJson.length > 0);
        } else if (is_lsm == "2") {
            return (selectedCombo !== null && selectedSuggComboJson.length > 0&& storesJson.length > 0);
        }
    }
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ storesJson: e });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    handleSubmit = () => {
        let id = this.props.match.params.id;
        let { is_lsm,storesJson, selectedCombo, selectedSuggComboJson } = this.state;
        let data:any = {
            combo_id: selectedCombo.value,
            selectedSuggComboJson: JSON.stringify(selectedSuggComboJson)
        }
        if (is_lsm == '0') {
            data.is_lsm= 0;
        } else if (is_lsm == '1') {
            data.is_lsm= 1;
            data.stores_json= JSON.stringify(storesJson)
        }
        else if (is_lsm == '2') {
            data.is_lsm= 2
            data.stores_json= JSON.stringify(storesJson)
        }
        this.props.editSuggestive(id, data)
    }
    handleCombosInputChange = async (e: any, index: any) => {
        if (!this.state.selectedCombo) {
            this.props.getSuggCombos()
        }
        await this.setState({ selectedCombo: e })
    }
    handleSuggCombosInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            if (this.state.selectedSuggComboJson.length == 2) {
                toast.error("You can select only two suggestive combos", { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: false, autoClose: 3000 })
            } else {
                this.setState({ selectedSuggComboJson: e });
            }
        } else {
            this.setState({ selectedSuggComboJson: [] });
        }
    };
    blockInvalidChar = (e: any) => {
        var regex = new RegExp("[a-zA-Z]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let { is_lsm , storesJson }= this.state;
        return (
            <div className="page">
                <CheckChanges path="/editSuggestiveCombos" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Edit Suggestive Combos</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/suggestiveCombos" className="text-primary">Combos</Link></li>
                                <li className="breadcrumb-item active">Edit Suggestive Combos</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Combo</label>
                                                            <Select
                                                                name="combos"
                                                                options={this.props.combos}
                                                                value={this.state.selectedCombo}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleCombosInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Suggestive Deal</label>
                                                            <Select
                                                                isMulti
                                                                name="combos"
                                                                options={this.props.suggestiveCombos}
                                                                value={this.state.selectedSuggComboJson}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleSuggCombosInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-3 col-sm-3 col-6 mt-5" >
                                                        <div >
                                                            <input id="radioCustom3" type="radio" name="is_lsm" value="0" checked={is_lsm == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Available for all stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-3 col-6 mt-5" >
                                                        <div >
                                                            <input id="radioCustom4" type="radio" name="is_lsm" value="1" checked={is_lsm == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Available for specific stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-3 col-6 mt-5" >
                                                        <div >
                                                            <input id="radioCustom5" type="radio" name="is_lsm" value="2" checked={is_lsm == '2'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom5">Available for stores except</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        is_lsm == '1' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={storesJson}
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    {
                                                        is_lsm == '2' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={storesJson}
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button onClick={this.handleSubmit} disabled={!this.isAttachSuggistiveDealReady()} className="btn btn-primary">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>

                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {

    console.log(state, "affsdfdfdsf");

    return {

        stores: state.report.stores,
        combos: state.menu.combosForBanners,
        suggestiveCombos: state.menu.suggestiveCombos,
        suggestiveData: state.menu.suggestiveData,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: () => { dispatch(logoutUser()); },
        storesList: () => {
            dispatch(storesList())
        },
        getOnlyCombos: function () {
            dispatch(itemsListForBanners())
        },
        getSuggCombos: function () {
            dispatch(getSuggCombos())
        },
        // updateSuggestiveDeals: (data: any) => { dispatch(updateSuggestiveDeals(data)) },

        editSuggestive: function (id: any, data: any) {
            dispatch(editSuggestive(id, data));
        },
        getSuggestive: function (id: number) {
            dispatch(getSuggestive(id));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditSuggestiveDeals);