import { TIMER_STATUS, ORDERS_LIST, ORDER_ITEMS_LIST, CANCEL_ORDER_REASONS, ORDER_STATUS_LIST, DELIVERY_BOYS, UPDATE_ORDER, OUTBOUND_CONTACTS, FILTERED_ORDERS, UPDATE_TIMER, MANUAL_SYNC_ORDER, ORDERS_BY_STORE, FILTERED_CARD_ORDERS, CARD_ORDERS_LIST, CARD_ORDER_ITEMS_LIST, UPDATE_SELECTED_ROW, UPDATE_LOADER, XML_BY_ORDER_ID } from './orderType'
import Api from '../../components/Api';
import { toast } from "react-toastify";
import jwt from 'jsonwebtoken'
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';
import jwtDecode from 'jwt-decode';
toast.configure();
let timer: any;
export const filterOrdersList = (obj: any, updateLoader?: any, closeTimer?: any, startTimer?: any) => {
    return function (dispatch: any) {
        if (updateLoader) {
            updateLoader(true)
        }
        closeTimer()
        dispatch({
            type: "CLEAR_ORDERS",
            payload: []
        })
        let token: any = localStorage.getItem('token');
        Api.post(`/admin/status_filter_orders`, obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    if (updateLoader) {
                        updateLoader(false)
                    }
                    response.data.successResponse.forEach((element: any) => {
                        element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
                    })
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: response.data.successResponse,
                        emptyFilterOrder: response.data.successResponse.length > 0 ? false : true,
                        currentPage: 0,
                        pageCount: 0,
                        numOfRows: 0
                    })
                    if (startTimer) startTimer()
                }
            }).catch(err => {
                if (err.response) {
                    if (updateLoader) {
                        updateLoader(false)
                    }
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    if (startTimer) startTimer()
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const filterFPOrdersList = (obj: any, updateLoader?: any, closeTimer?: any, startTimer?: any) => {
    return function (dispatch: any) {
        if (updateLoader) {
            updateLoader(true)
        }
        // closeTimer()
        dispatch({
            type: "CLEAR_ORDERS",
            payload: []
        })
        let token: any = localStorage.getItem('token');
        Api.post(`/admin/status_filter_orders_fp`, obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let ordersData: any = []
                    response.data.successResponse.map((obj: any) => {
                        // if (obj.order_status_code !=1) {
                            ordersData.push(obj)
                        // }
                    })
                    if (updateLoader) {
                        updateLoader(false)
                    }
                    response.data.successResponse.forEach((element: any) => {
                        element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
                    })
                    dispatch({
                        type: "AGG_ORDERS_LIST",
                        payload: ordersData,
                        emptyFilterOrder: ordersData.length > 0 ? false : true,
                        currentPage: 0,
                        pageCount: 0,
                        numOfRows: 0,
                        failedIntegOrderCount:ordersData.length
                    })
                    dispatch({
                        type: "TOTAL_ORDERS_COUNT",
                        // payload: response.data.total_orders_count
                        payload: ordersData.length
                    })
                    
                    
                    if (startTimer) startTimer()
                }
            }).catch(err => {
                if (err.response) {
                    if (updateLoader) {
                        updateLoader(false)
                    }
                    dispatch({
                        type: "AGG_ORDERS_LIST",
                        payload: [],
                        emptyFilterOrder: false,
                        failedIntegOrderCount:0
                    })
                    if (startTimer) startTimer()
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const searchOrder = (orderId: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/search_order/${orderId}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let recieved = 0;
                    let prep = 0;
                    let readyCount = 0;
                    let dispatchCount = 0;
                    let completed = 0;
                    let failedIntg = 0;
                    for (let i = 0; i < response.data.successResponse.length; i++) {
                        response.data.successResponse.forEach((element: any) => {
                            element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
                        })
                        const obj = response.data.successResponse[i];
                        if (obj.order_status_code === 1) {
                            recieved += 1;
                        } if (obj.order_status_code === 2) {
                            prep += 1;
                        } if (obj.order_status_code === 3 || obj.order_status_code === 4) {
                            readyCount += 1;
                        } if (obj.order_status_code === 5) {
                            dispatchCount += 1;
                        } if (obj.order_status_code === 6 || obj.order_status_code === 7) {
                            completed += 1;
                        } if (obj.check_number == '') {
                            failedIntg += 1;
                        }
                    }
                    response.data.successResponse.map((obj: any) => {
                        let mintDiff: any = "";
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })
                    let avg = response.data.successResponse[0].avg_delivery_time;
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: ORDERS_LIST,
                        payload: response.data.successResponse,
                        avg: avg,
                        prepCount: prep,
                        dayVal: "",
                        recievedOrderCount: recieved,
                        readyCount: readyCount,
                        dispatchCount: dispatchCount,
                        completedOrderCount: completed,
                        failedIntegOrderCount: failedIntg,
                    })
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    dispatch({
                        type: ORDERS_LIST,
                        payload: [],
                        avg: 0,
                        prepCount: 0,
                        dayVal: "",
                        recievedOrderCount: 0,
                        readyCount: 0,
                        dispatchCount: 0,
                        completedOrderCount: 0,
                        failedIntegOrderCount: 0
                    })
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const ordersList = (obj: any, datetime?: any, page?: any, updateLoader?: any, closeTimer?: any, startTimer?: any, manualCloseTimer?: any) => {
    return function (dispatch: any) {
        if (page && page > 0) {
            dispatch({
                type: ORDERS_LIST,
                payload: []
            })
            if (updateLoader) {
                updateLoader(true)
            }
        }
        if (closeTimer) { closeTimer() }
        if (datetime) {
            dispatch({
                type: ORDERS_LIST,
                payload: []
            })
            dispatch({
                type: UPDATE_LOADER,
                isLoading: true
            })
        }
        let token: any = localStorage.getItem('token');
        Api.post(`/admin/orders/${page}`, obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    if (updateLoader) {
                        updateLoader(false)
                    }
                    response.data.successResponse.forEach((element: any) => {
                        element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
                    })
                    response.data.successResponse.map((obj: any) => {
                        let mintDiff: any = "";
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })
                    let avg = response.data.avg_delivery_time;
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: ORDERS_LIST,
                        payload: response.data.successResponse,
                        avg: avg,
                        dayVal: obj,
                        recievedOrderCount: response.data.counterData ? response.data.counterData.receivedOrders : 0,
                        prepCount: response.data.counterData ? response.data.counterData.prepOrders : 0,
                        readyCount: response.data.counterData ? response.data.counterData.readyOrders : 0,
                        dispatchCount: response.data.counterData ? response.data.counterData.dispatchOrders : 0,
                        completedOrderCount: response.data.counterData ? response.data.counterData.completeOrders : 0,
                        failedIntegOrderCount: response.data.counterData ? response.data.counterData.failedIntgOrders : 0,
                        isLoading: false,
                        isTimerUpdate: false,
                        currentPage: response.data.currentPage,
                        pageCount: response.data.pageCount,
                        numOfRows: response.data.numOfRows,
                    })
                    if (!manualCloseTimer && startTimer) {
                        startTimer()
                    }
                    // dispatch(deliveryBoysList(response.data.successResponse))
                }
            }).catch(err => {
                if (err.response) {
                    if (updateLoader) {
                        updateLoader(false)
                    }
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: ORDERS_LIST,
                        payload: [],
                        recievedOrderCount: 0,
                        dayVal: obj,
                        prepCount: 0,
                        readyCount: 0,
                        dispatchCount: 0,
                        completedOrderCount: 0,
                        failedIntegOrderCount: 0,
                        isLoading: false,
                        isTimerUpdate: false,
                        currentPage: 0,
                        pageCount: 0,
                        numOfRows: 0
                    })
                    if (startTimer) {
                        startTimer()
                    }
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const cancelOrdersReasons = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post('/reasons/reasonByType', {
            type: 'cancel order'
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: CANCEL_ORDER_REASONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const showorderItems = (order_id: any, orders: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        if (order_id === "") {
            dispatch({
                type: ORDER_ITEMS_LIST,
                payload: []
            })
        } else {
            Api.get(`/admin/order_items/${order_id}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        let order = orders.find((obj: any) => {
                            return obj.order_id == order_id;
                        })
                        dispatch({
                            type: ORDER_ITEMS_LIST,
                            payload: response.data.successResponse,
                            orderDetail: order
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        dispatch({
                            type: ORDER_ITEMS_LIST,
                            payload: []
                        })
                        console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}
export const getOrderXML = (order_id: any,) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
            Api.get(`/admin/xml_by_order_id/${order_id}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                   
                        dispatch({
                            type: XML_BY_ORDER_ID,
                            payload: response.data.successResponse,
                        })
                        dispatch(updateSelectedRow(response.data.successResponse))
                    }
                }).catch(err => {
                    if (err.response) {
                        dispatch({
                            type: XML_BY_ORDER_ID,
                            payload: []
                        })
                        console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
    
    }
}
export const showCardOrderItems = (order_id: any, orders: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        if (order_id === "") {
            dispatch({
                type: ORDER_ITEMS_LIST,
                payload: []
            })
        } else {
            Api.get(`/admin/temp_order_items/${order_id}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        let order = orders.find((obj: any) => {
                            return obj.order_id == order_id;
                        })
                        dispatch({
                            type: CARD_ORDER_ITEMS_LIST,
                            payload: response.data.successResponse,
                            orderDetail: order
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        dispatch({
                            type: CARD_ORDER_ITEMS_LIST,
                            payload: []
                        })
                        console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}
export const orderStatusList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/order_status', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ORDER_STATUS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const deliveryBoysList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/Drivers/getActiveDrivers', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((driversData) => {
                if (driversData.data.success) {
                    dispatch({
                        type: DELIVERY_BOYS,
                        payload: driversData.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const updateOrder = (id: any, data: any, history: any) => {
    return function (dispatch: any) {
        dispatch({
            type: UPDATE_ORDER,
            payload: true
        })
        if (data.orderData) {
            let orderTime = moment(data.orderData.date_created).utc(false).format();
            data.orderData.date_created = orderTime;
        }
        let token: any = localStorage.getItem('token');
        data.user_info = jwt.decode(token);
        Api.put(`/admin/update_order/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    if (data.delivery_boy) {
                        toast.info("Driver Saved Succesfully!", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                        dispatch({
                            type: UPDATE_ORDER,
                            payload: false
                        })
                    } else {
                        if (response.data.error == false) {
                            toast.error(`${response.data.successResponse}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 10000 });
                        } else {
                            toast.info(`${response.data.successResponse}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 });
                        }
                        dispatch({
                            type: UPDATE_ORDER,
                            payload: false
                        })
                    }
                    dispatch(ordersList({ days: 0 }, false, 0))
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please select the driver";
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch({
                        type: UPDATE_ORDER,
                        payload: false
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}
export const updatefutureOrder = (id: any, data: any, history: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.put(`/admin/update_future_order/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    history.push("/orders");
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    dispatch({
                        type: UPDATE_ORDER,
                        isUpdated: false,
                        payload: error
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}

//outbound contacts
export const outboundContacts = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/outbound/contacts', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: OUTBOUND_CONTACTS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const updateContactStatus = (id: any, data: any, history: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.put(`/outbound/update_contact_status/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    history.push("/outbound_contacts");
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    console.log(error)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const newmanualSyncOrder = (id: any, data: any, closeModal: any, updateLoaderState?: any) => {
    return function (dispatch: any) {
        dispatch({
            type: MANUAL_SYNC_ORDER,
            payload: true
        })
        if (updateLoaderState) updateLoaderState(true)
        let token: any = localStorage.getItem('token');
        data.user_info = jwtDecode(token);
        Api.post(`/admin/dumpIntegOrdersManually/${id}`, { pos_xml: data.pos_xml }, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                console.log("response.data", response.data)
                if (response.data.success) {
                    toast.info(`${response.data.message}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 });
                    dispatch(ordersList({ days: 0 }))
                    closeModal.click();
                    dispatch({
                        type: MANUAL_SYNC_ORDER,
                        payload: false
                    })
                } else {
                    toast.error(`${response.data.message}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 10000 });
                    dispatch({
                        type: MANUAL_SYNC_ORDER,
                        payload: false
                    })
                    if (updateLoaderState) updateLoaderState(false)
                }
            }).catch(err => {
                dispatch({
                    type: MANUAL_SYNC_ORDER,
                    payload: false
                })
                if (updateLoaderState) updateLoaderState(false)
            });
    }
}
export const getOrdersByStore = (id: any, store: any) => {
    return function (dispatch: any) {
        dispatch({
            type: ORDERS_BY_STORE,
            heatMapUpdate: false
        })
        let token: any = localStorage.getItem('token');
        Api.get('/admin/orders_by_store/' + id, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ORDERS_BY_STORE,
                        payload: response.data.successResponse,
                        heatmapLat: store.lat,
                        heatmapLng: store.lng,
                        store_name: store,
                        store_id: id,
                        heatMapUpdate: true
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
                dispatch({
                    type: ORDERS_BY_STORE,
                    payload: [],
                    store_name: store,
                    store_id: id,
                    heatMapUpdate: true
                })
            });
    }
}

export const cardOrdersList = (obj: any, datetime?: any, updateLoader?: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post('/admin/card_orders', obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    if (updateLoader) {
                        updateLoader()
                    }
                    response.data.successResponse.forEach((element: any) => {
                        element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
                    })
                    let verified = 0;
                    let unverified = 0;

                    for (let i = 0; i < response.data.successResponse.length; i++) {
                        const obj = response.data.successResponse[i];
                        if (obj.is_verify === 0) {
                            unverified += 1;
                        } if (obj.is_verify === 1) {
                            verified += 1;
                        }
                    }

                    response.data.successResponse.map((obj: any) => {
                        let mintDiff: any = "";
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })
                    dispatch({
                        type: FILTERED_CARD_ORDERS,
                        payload: [],
                        emptyFilterCardOrder: false
                    })
                    dispatch({
                        type: CARD_ORDERS_LIST,
                        payload: response.data.successResponse,
                        verifyCount: verified,
                        unverifyCount: unverified,
                        isLoading: false
                    })
                }
            }).catch(err => {
                if (err.response) {
                    if (updateLoader) {
                        updateLoader()
                    }
                    dispatch({
                        type: FILTERED_CARD_ORDERS,
                        payload: [],
                        emptyFilterCardOrder: false
                    })
                    dispatch({
                        type: CARD_ORDERS_LIST,
                        payload: [],
                        verifyCount: 0,
                        unverifyCount: 0,
                        isLoading: false
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const filterCardOrdersList = (filteredArr: any) => {
    return function (dispatch: any) {
        dispatch({
            type: FILTERED_CARD_ORDERS,
            payload: filteredArr,
            emptyFilterCardOrder: filteredArr.length > 0 ? false : true
        })
    }
}

export const updateSelectedRow = (row: any) => {
    return function (dispatch: any) {
        dispatch({
            type: UPDATE_SELECTED_ROW,
            payload: row
        })
    }
};

export const aggregatorOrdersList = (obj: any, datetime?: any) => {
    return function (dispatch: any) {

        if (datetime) {

            dispatch({ type: "SET_PAGINATION", payload: { pageNum: 1, pSize: 50 } })
            dispatch({
                type: UPDATE_LOADER,
                isLoading: true
            })
        }
        dispatch({
            type: UPDATE_TIMER,
            isTimerUpdate: true
        })
        let failedOrders:any=[]

        let token: any = localStorage.getItem('token');
        Api.post('/call-portal/agg-orders', obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let ordersData: any = []
                    response.data.successResponse.map((obj: any) => {
                        // if (obj.order_status_code !=1) {
                            ordersData.push(obj)
                        // }
                    })
                    
                    ordersData && ordersData.length > 0 && ordersData.map((obj: any) => {
                        if(obj.check_number==""){
                            failedOrders.push(obj)
                        }
                        console.log(failedOrders,"dsadsadd");
                        let mintDiff: any = "";
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })

                    dispatch({
                        type: "AGG_ORDERS_LIST",
                        payload: ordersData,
                        failedIntegOrderCount:failedOrders.length
                    })
                    dispatch({
                        type: "TOTAL_ORDERS_COUNT",
                        // payload: response.data.total_orders_count
                        payload: ordersData.length
                    })
                    localStorage.setItem('prevLength', ordersData.length)
                }
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: "AGG_ORDERS_LIST",
                        payload: []
                    })
                    dispatch({
                        type: "TOTAL_ORDERS_COUNT",
                        payload: 0
                    })

                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const searchOrderByfoodPandaId = (orderId: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/search_order_fp_id/${orderId}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
        .then((response) => {
            if (response.data.success) {
                let ordersData: any = []
                response.data.successResponse.map((obj: any) => {
                    // if (obj.order_status_code !=1) {
                        ordersData.push(obj)
                    // }
                })
                console.log(ordersData,"dsadsadd");
                
                ordersData && ordersData.length > 0 && ordersData.map((obj: any) => {
                    let mintDiff: any = "";
                    if (obj.future_status == 1) {
                        let futureDay = moment(obj.future_date).local().startOf('day');
                        let currentDay = moment(new Date()).startOf('day');
                        const currentTime = moment().format('HH:mm:ss');
                        const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                        if (futureDay.isSame(currentDay)) {
                            mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                            if (mintDiff >= 0 && mintDiff <= 60) {
                                obj.orderStatus = "now";
                            } else if (mintDiff > 60) {
                                obj.orderStatus = "future";
                            } else {
                                obj.orderStatus = "now";
                            }
                        } else {
                            let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                            let currentDate = moment().format('YYYY-MM-DD');
                            if (moment(currentDate).isAfter(futureDate, 'day')) {
                                obj.orderStatus = "now";
                            } else {
                                obj.orderStatus = "future";
                            }
                        }
                    } else {
                        obj.orderStatus = "now";
                    }
                })

                dispatch({
                    type: "AGG_ORDERS_LIST",
                    payload: ordersData
                })
                dispatch({
                    type: "TOTAL_ORDERS_COUNT",
                    payload: response.data.total_orders_count
                })
                localStorage.setItem('prevLength', response.data.successResponse.length)
            }
        }).catch(err => {
            if (err.response) {
                dispatch({
                    type: "AGG_ORDERS_LIST",
                    payload: []
                })
                dispatch({
                    type: "TOTAL_ORDERS_COUNT",
                    payload: 0
                })

                console.log(err.response.data.message)
            } else {
                alert(err.message)
            }
        });
    }
}
export const searchFPordersByOrderID = (orderId: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/search_fp_order_order_id/${orderId}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
        .then((response) => {
            if (response.data.success) {
                let ordersData: any = []
                response.data.successResponse.map((obj: any) => {
                    // if (obj.order_status_code !=1) {
                        ordersData.push(obj)
                    // }
                })
                console.log(ordersData,"dsadsadd");
                
                ordersData && ordersData.length > 0 && ordersData.map((obj: any) => {
                    let mintDiff: any = "";
                    if (obj.future_status == 1) {
                        let futureDay = moment(obj.future_date).local().startOf('day');
                        let currentDay = moment(new Date()).startOf('day');
                        const currentTime = moment().format('HH:mm:ss');
                        const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                        if (futureDay.isSame(currentDay)) {
                            mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                            if (mintDiff >= 0 && mintDiff <= 60) {
                                obj.orderStatus = "now";
                            } else if (mintDiff > 60) {
                                obj.orderStatus = "future";
                            } else {
                                obj.orderStatus = "now";
                            }
                        } else {
                            let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                            let currentDate = moment().format('YYYY-MM-DD');
                            if (moment(currentDate).isAfter(futureDate, 'day')) {
                                obj.orderStatus = "now";
                            } else {
                                obj.orderStatus = "future";
                            }
                        }
                    } else {
                        obj.orderStatus = "now";
                    }
                })

                dispatch({
                    type: "AGG_ORDERS_LIST",
                    payload: ordersData
                })
                dispatch({
                    type: "TOTAL_ORDERS_COUNT",
                    payload: response.data.total_orders_count
                })
                localStorage.setItem('prevLength', response.data.successResponse.length)
            }
        }).catch(err => {
            if (err.response) {
                dispatch({
                    type: "AGG_ORDERS_LIST",
                    payload: []
                })
                dispatch({
                    type: "TOTAL_ORDERS_COUNT",
                    payload: 0
                })

                console.log(err.response.data.message)
            } else {
                alert(err.message)
            }
        });
    }
}

export const orderTransfer = (id: any, data: any, history: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        data.user_info = jwt.decode(token);
        Api.put(`/admin/order_transfer/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info("Order Transfered Succesfully!", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    let obj = {
                        days: 0
                    }
                    dispatch(ordersList(obj))
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const orderTransferReasons = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post('/reasons/reasonByType', {
            type: 'transfer order'
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: "ORDER_TRANSFER_REASONS",
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const searchOrdersByQuery = (query: any,data:any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post(`admin/search_query_orders/${query}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let newOrder = 0;
                    let recieved = 0;
                    let readyOrder = 0;
                    let dispatched = 0;
                    let completed = 0;
                    let failedIntg = 0;

                    for (let i = 0; i < response.data.successResponse.length; i++) {
                        const obj = response.data.successResponse[i];
                        if (obj.order_status_code === 1) {
                            newOrder += 1;
                        } if (obj.order_status_code === 2) {
                            recieved += 1;
                        } if (obj.order_status_code === 12) {
                            readyOrder += 1;
                        } if (obj.order_status_code === 3 || obj.order_status_code === 5) {
                            dispatched += 1;
                        } if (obj.order_status_code === 4 || obj.order_status_code === 11) {
                            completed += 1;
                        } if (obj.check_number === '') {
                            failedIntg += 1;
                        }
                    }
                    response.data.successResponse.forEach((element: any) => {
                        element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
                    })
                    response.data.successResponse.map((obj: any) => {
                        let mintDiff: any = "";
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })
                    let avg = response.data.successResponse[0].avg_delivery_time;
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: ORDERS_LIST,
                        payload: response.data.successResponse,
                        avg: avg,
                        newOrderCount: newOrder,
                        dayVal: "",
                        recievedOrderCount: recieved,
                        readyOrderCount: readyOrder,
                        dispatchOrderCount: dispatched,
                        completedOrderCount: completed,
                        failedIntegOrderCount: failedIntg,
                    })
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    dispatch({
                        type: ORDERS_LIST,
                        payload: [],
                        avg: 0,
                        dayVal: "",
                        newOrderCount: 0,
                        recievedOrderCount: 0,
                        readyOrderCount: 0,
                        dispatchedCount: 0,
                        completedOrderCount: 0,
                        failedIntegOrderCount: 0
                    })
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const searchOrdersByQueryFP = (query: any,data:any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post(`admin/search_query_fp_orders/${query}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let newOrder = 0;
                    let recieved = 0;
                    let readyOrder = 0;
                    let dispatched = 0;
                    let completed = 0;
                    let failedIntg = 0;
                    let ordersData: any = []
                    response.data.successResponse.map((obj: any) => {
                        // if (obj.order_status_code !=1) {
                            ordersData.push(obj)
                        // }
                    })

                    for (let i = 0; i <ordersData.length; i++) {
                        const obj =ordersData[i];
                        if (obj.order_status_code === 1) {
                            newOrder += 1;
                        } if (obj.order_status_code === 2) {
                            recieved += 1;
                        } if (obj.order_status_code === 12) {
                            readyOrder += 1;
                        } if (obj.order_status_code === 3 || obj.order_status_code === 5) {
                            dispatched += 1;
                        } if (obj.order_status_code === 4 || obj.order_status_code === 11) {
                            completed += 1;
                        } if (obj.check_number === '') {
                            failedIntg += 1;
                        }
                    }
                   ordersData.forEach((element: any) => {
                        element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
                    })
                   ordersData.map((obj: any) => {
                        let mintDiff: any = "";
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })
                    let avg =ordersData[0].avg_delivery_time;
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: "AGG_ORDERS_LIST",
                        payload:ordersData,
                        avg: avg,
                        newOrderCount: newOrder,
                        dayVal: "",
                        recievedOrderCount: recieved,
                        readyOrderCount: readyOrder,
                        dispatchOrderCount: dispatched,
                        completedOrderCount: completed,
                        failedIntegOrderCount: failedIntg,
                    })
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    dispatch({
                        type: "AGG_ORDERS_LIST",
                        payload: [],
                        avg: 0,
                        dayVal: "",
                        newOrderCount: 0,
                        recievedOrderCount: 0,
                        readyOrderCount: 0,
                        dispatchedCount: 0,
                        completedOrderCount: 0,
                        failedIntegOrderCount: 0
                    })
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
