import { CHANNEL_MIX_REPORTS, RIDER_DETAILS_REPORT, ORDER_DETAIL_REPORT, STORES_REPORTS, RIDERS_REPORTS, SOS_REPORTS, STORES_LIST, COUPON_REDEMPTION_REPORTS, CUSTOMERS_REPORTS, DAILY_SALES_REPORTS, DISCOUNT_REPORTS, PMIX_REPORTS, PROFIT_REPORTS, SALES_MIX_REPORTS, CANCEL_SMRY_REPORTS, CANCEL_DETAIL_REPORT, ACTIVITY_LOGS_REPORT, REPORTS_LOAD, SYSTEM_INTEGRATED_ORDERS, ORDER_ITEMS_DETAIL_REPORT, VOUCHER_ORDER_DETAIL_REPORT, PAYMENT_LOGS, DEVICE_MIX_REPORTS, AGGREGATOR_DATA_EXPORT, AGGREGATOR_FAILED_ORDERS } from '../actions/reportType';
const initialState: any = {
    dailyReports: [] || "",
    sosReports: [] || "",
    stores: [] || "",
    customerReports: [] || "",
    pmixReports: [] || "",
    smixReports: [] || "",
    channelmixReports: [] || "",
    deviceInfoReports: [] || "",
    couponredemptionReports: [] || "",
    profitReports: [] || "",
    discountReports: [] || "",
    ridersReports: [] || "",
    storesReports: [] || "",
    activity_logs: [] || "",
    cancel_smryReports: [] || "",
    cancel_detail_report: [] || "",
    order_details_report: [] || "",
    order_item_details_report: [] || "",
    rider_details_report: [] || "",
    voucher_logs_report: [] || "",
    system_intrgrated_orders: [] || "",
    // currentPage: 0,
    // pageCount: 0,
    // numOfRows: 0,
    isLoad: false,
    payment_logs: [] || '',
    aggregator_failed_orders: [] || "",
    aggr_data_export: [] || "",
    menuItems_extract: 0,
    combos_extract: 0,
    total_data_extract: 0,
    msg: ""
}

const reportReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case DAILY_SALES_REPORTS: return {
            ...state,
            dailyReports: action.payload
        }
        case PAYMENT_LOGS: return {
            ...state,
            payment_logs: action.payload
        }
        case SOS_REPORTS: return {
            ...state,
            sosReports: action.payload,
            // currentPage: action.currentPage,
            // pageCount: action.pageCount,
            // numOfRows: action.numOfRows
        }
        case ACTIVITY_LOGS_REPORT: return {
            ...state,
            activity_logs: action.payload
        }
        case VOUCHER_ORDER_DETAIL_REPORT: return {
            ...state,
            voucher_logs_report: action.payload
        }
        case STORES_REPORTS: return {
            ...state,
            storesReports: action.payload
        }
        case CANCEL_SMRY_REPORTS: return {
            ...state,
            cancel_smryReports: action.payload
        }
        case CANCEL_DETAIL_REPORT: return {
            ...state,
            cancel_detail_report: action.payload
        }
        case ORDER_DETAIL_REPORT: return {
            ...state,
            order_details_report: action.payload
        }
        case RIDERS_REPORTS: return {
            ...state,
            ridersReports: action.payload
        }
        case RIDER_DETAILS_REPORT: return {
            ...state,
            rider_details_report: action.payload
        }
        case STORES_LIST: return {
            ...state,
            stores: action.payload
        }
        case CUSTOMERS_REPORTS: return {
            ...state,
            customerReports: action.payload
        }
        case PMIX_REPORTS: return {
            ...state,
            pmixReports: action.payload
        }
        case SALES_MIX_REPORTS: return {
            ...state,
            smixReports: action.payload
        }
        case CHANNEL_MIX_REPORTS: return {
            ...state,
            channelmixReports: action.payload
        }
        case DEVICE_MIX_REPORTS: return {
            ...state,
            deviceInfoReports: action.payload
        }
        case COUPON_REDEMPTION_REPORTS: return {
            ...state,
            couponredemptionReports: action.payload
        }
        case PROFIT_REPORTS: return {
            ...state,
            profitReports: action.payload
        }
        case DISCOUNT_REPORTS: return {
            ...state,
            discountReports: action.payload
        }
        case SYSTEM_INTEGRATED_ORDERS: return {
            ...state,
            system_intrgrated_orders: action.payload
        }
        case ORDER_ITEMS_DETAIL_REPORT: return {
            ...state,
            order_item_details_report: action.payload
        }
        case REPORTS_LOAD: return {
            ...state,
            isLoad: action.isLoad
        }
        case AGGREGATOR_FAILED_ORDERS: return {
            ...state,
            aggregator_failed_orders: action.payload
        }
        case AGGREGATOR_DATA_EXPORT: return {
            ...state,
            aggr_data_export: action.payload,
            menuItems_extract: action.menuItems_extract,
            combos_extract: action.combos_extract,
            total_data_extract: action.total_data_extract,
            msg: action.msg
        }
        default: return state;
    }
}
export default reportReducer;