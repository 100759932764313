import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../../../components/topbar'
import Sidebar from '../../../../components/sidebar'
import Footer from '../../../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../../secret'
import { connect } from 'react-redux'
import { blockunblockNutItem, logoutUser, nutItemList } from '../../../../redux'
import { NutItemProps } from '../../../../interfaces/menu';
class ActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleBlockUnblock = (id: any, is_active: any) => {
        this.props.data.blockunblockNutItem(id, is_active, this.props.row.item_name);
    };
    render() {
        const { row } = this.props
        return (
            <div>
                <button title={row.is_active === 0 ? "UnBlock" : "Block"} data-toggle="modal" data-target={`#blockunblock${row.id}`} className={row.is_active === 0 ? "btn btn-outline-success mr-2" : "btn btn-outline-danger mr-2"}><i className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}></i></button>
                <Link title="Edit Nutritional Item" className="btn btn-outline-primary ml-2" to={`/edit-nut-items/${row.id}`}><i className="fa fa-edit"></i></Link>
                {/* <!-- Block/Unblock Modal--> */}
                <div id={`blockunblock${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 0 ? "UnBlock" : "Block"} Nutritional Item</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to {row.is_active === 0 ? "UnBlock" : "Block"} this nutritional item?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleBlockUnblock(row.id, row.is_active)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}
class NutritionalInforFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                {(row.nutritional_variation_id && row.nutritional_variation_id.length > 0) &&
                    <button title="View Nutritional Info" data-toggle="modal" data-target={`#nutinfo${row.id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button>
                }
                {/* <!-- Modal--> */}
                <div id={`nutinfo${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog nut-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Nutritional Info</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            <BootstrapTable version='4' data={row.nutritional_variation_id && row.nutritional_variation_id.length > 0 ? row.nutritional_variation_id : ""} hover>
                                                <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='variation' width='100' columnTitle isKey>Variant</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='total_piece' width='100' columnTitle>Total Piece</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='calories_per_piece' width='100' columnTitle>Calories/piece</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='total_calories' width='100' columnTitle>Total Calories</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='total_fat' width='100' columnTitle>Total Fat</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='sat_fat' width='100' columnTitle>Saturated Fat</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='trans_fatty_acid' width='100' columnTitle>Trans Fatty Acid</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='cholesterol' width='100' columnTitle>Cholesterol</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='sodium' width='100' columnTitle>Sodium</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='sugars' width='100' columnTitle>Sugars</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='dietary_fibre' width='100' columnTitle>Dietary Fibre</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='protein' width='100' columnTitle>Protein</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
function NutInfoFormatter(cell: any, row: any, props: any) {
    return (
        <NutritionalInforFormatter row={row} data={props} />
    );
}
class NutItems extends Component<NutItemProps, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.nutItemList()
        document.title = "Crust Culture | Nutrtional Items"
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-nut-items" /> },
            noDataText: 'Nutrtional Items Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Nutrtional Items Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='meals.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='id' csvHeader='#' width='50' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='item_name' csvHeader='ItemName' width='100' columnTitle>Item name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='category_name' csvHeader='NutritionalCatName' width='150' columnTitle>Nutritional Category name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField="variants" width="120" dataFormat={NutInfoFormatter} export={false}>Nutritional Info</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='total_piece' csvHeader='Total Piece' width='100' columnTitle>Total Piece</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='calories_per_piece' csvHeader='Calories/piece' width='100' columnTitle>Calories/piece</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='total_calories' csvHeader='Total Calories' width='100' columnTitle>Total Calories</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='total_fat' csvHeader='Fat' width='100' columnTitle>Fat</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='sat_fat' csvHeader='Saturated Fat' width='100' columnTitle>Saturated Fat</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='trans_fatty_acid' csvHeader='Trans Fatty Acid' width='100' columnTitle>Trans Fatty Acid</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='cholesterol' csvHeader='Cholesterol' width='100' columnTitle>Cholesterol</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='sodium' csvHeader='Sodium' width='100' columnTitle>Sodium</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='total_carbs' csvHeader='Carbs' width='100' columnTitle>Carbs</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='sugars' csvHeader='Sugar' width='100' columnTitle>Sugar</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='dietary_fibre' csvHeader='Dietary Fibre' width='100' columnTitle>Dietary Fibre</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='protein' csvHeader='Protein' width='100' columnTitle>Protein</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='150' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        data: state.menu.nutItems
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        nutItemList: function () {
            dispatch(nutItemList())
        },
        blockunblockNutItem: function (id: any, is_active: any, item_name: any) {
            dispatch(blockunblockNutItem(id, is_active, item_name))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NutItems);