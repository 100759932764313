import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import Skelton from '../../pages/skeltonLoader'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import Invoice from './FbrInvoice/fbrInvoice';
import { logoutUser, cardOrdersList, statesList, storesList } from '../../redux'
import { OrderProps } from '../../interfaces/order';
import moment from 'moment';
import { filterCardOrdersList, showCardOrderItems } from '../../redux/actions/orderAction';
import { toast } from "react-toastify";
import { currency } from '../../client-config'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
import './orders.css'
toast.configure();

class ActionFormatter extends Component<{ row: any }, { selectedOrderId: any, checkTimer: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            selectedOrderId: "",
            checkTimer: false
        }
    }
    showCardOrderItems = (id: any) => {
        let storeId: any = "";
        let stateId: any = "";
        this.props.data.showCardOrderItems(id, this.props.data.orders);
        storeId = this.props.data.orders.find((obj: any) => {
            return obj.order_id == id;
        }).store_id;
        if (storeId && storeId !== "") {
            stateId = this.props.data.stores && this.props.data.stores.find((obj: any) => {
                return obj.store_id == storeId;
            }).state_id;
        }
        // if (stateId !== "") {
        //     tax = this.props.data.states.find((obj: any) => {
        //         return obj.state_id == stateId;
        //     }).tax_percent;
        // }
        this.setState({ selectedOrderId: id });
    }
    render() {
        const { row, data } = this.props;
        return (
            <div>
                <button title="View Invoice" data-toggle="modal" data-target={`#viewinvoice${row.order_id}`} className="btn btn-outline-info ml-2" onClick={() => this.showCardOrderItems(row.order_id)}><i className="fa fa-file-pdf-o"></i></button>
                {/*Invoice Modal */}
                <div id={`viewinvoice${row.order_id}`} data-backdrop='static' role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog modal-lg" style={{ width: '500px' }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Invoice</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <Invoice data={data} orderId={this.state.selectedOrderId} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}
function skeltonFormatter() {
    return (
        <Skelton></Skelton>
    );
}
class Orders extends Component<OrderProps, { startDate: any, endDate: any, validTimeFlag: any, [x: number]: any, days: any, orderId: any, loader: any }> {
    closeModal: any;
    constructor(readonly props: any) {
        super(props);
        this.state = {
            days: 0,
            startDate: "",
            endDate: "",
            validTimeFlag: "",
            orderId: "",
            loader: true
        }
        this.rowClassNameFormat = this.rowClassNameFormat.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidTime = this.handleValidTime.bind(this);
    }
    rowClassNameFormat(row: any, rowIdx: any) {
        // row is whole row object
        // rowIdx is index of row
        if (row.is_verify == 0) {
            return 'tr-pending-orders'
        } else {
            return ''
        }
    }
    componentDidMount() {
        let obj = { days: this.state.days };
        this.props.ordersList(obj, false, this.updateLoader);
        this.props.statesList();
        this.props.storesList();
        document.title = "Crust Culture | Orders"
    }
    updateLoader = () => {
        this.setState({ loader: false })
    }
    FilterbyStatus(verify_status: any) {
        let { orders } = this.props;
        let filteredArr: any = [];
        filteredArr = orders.filter((order: any) => order.is_verify == verify_status)
        this.props.filterCardOrdersList(filteredArr)
    }
    handleValidTime(event: { target: { name: any; value: any; }; }) {
        let futureDatetime = new Date(event.target.value)
        let validMinDateTime = new Date();
        if (futureDatetime <= validMinDateTime) {
            this.setState({ [event.target.name]: event.target.value, validTimeFlag: true });
        } else {
            this.setState({ validTimeFlag: false })
            toast.error("Invalid date and time selected");
        }
    }
    isOrderDateReady = () => {
        let { startDate, endDate, validTimeFlag } = this.state;
        return (startDate !== "" && endDate !== "" && validTimeFlag == true);
    }
    handleSubmit() {
        this.setState({ loader: true })
        let { startDate, endDate } = this.state;
        let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
        let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
        let data: any = {
            start: startDateUtc,
            end: endDateUtc
        }
        // this.setState({ startDate: "", endDate: "" })
        this.props.ordersList(data, true, this.updateLoader);
    }
    render() {
        const dataa = [
            { id: 1, name: 'John Smith', age: 32, email: 'john.smith@example.com' },
            { id: 2, name: 'Jane Doe', age: 27, email: 'jane.doe@example.com' },
            { id: 3, name: 'Bob Johnson', age: 41, email: 'bob.johnson@example.com' },
            { id: 4, name: 'Alice Williams', age: 25, email: 'alice.williams@example.com' },
            { id: 5, name: 'Jack Brown', age: 38, email: 'jack.brown@example.com' },
            { id: 6, name: 'Linda Davis', age: 29, email: 'linda.davis@example.com' },
            { id: 7, name: 'Mike Garcia', age: 45, email: 'mike.garcia@example.com' },
            { id: 8, name: 'Samantha Lee', age: 31, email: 'samantha.lee@example.com' },
            { id: 9, name: 'David Nguyen', age: 36, email: 'david.nguyen@example.com' },
            { id: 10, name: 'Emily Taylor', age: 24, email: 'emily.taylor@example.com' }
        ];
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let maxDate = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS')
        let { orders, filteredOrders, emptyFilteredOrder, unverifyCount, verifyCount } = this.props;
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // wher to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            noDataText: 'Orders Not Found',
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="row py-2">
                                    <div className="col-lg-6 col-md-6 col-12" style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                        {/* <h4 className="mt-2">Orders Management<button title={this.props.timerStatus == false ? "Enable Refresh" : "Disable Refresh"} data-toggle="modal" data-target={`#enableTimer`} className={this.props.timerStatus == false ? "btn btn-outline-success ml-2" : "btn btn-outline-danger ml-2"}><i className={"fa fa-refresh"}></i></button></h4>
                                        <div className="ml-4 ">
                                            {
                                                this.props.timerStatus == true &&
                                                !this.props.updateTimer == true &&
                                                <ReactTimer />
                                            }
                                        </div> */}
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="form-group">
                                                    <input
                                                        id="start"
                                                        type="datetime-local"
                                                        name="startDate"
                                                        max={maxDate}
                                                        value={this.state.startDate}
                                                        className="input-material"
                                                        onChange={this.handleValidTime}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input
                                                        id="end"
                                                        type="datetime-local"
                                                        name="endDate"
                                                        max={maxDate}
                                                        value={this.state.endDate}
                                                        className="input-material"
                                                        onChange={this.handleValidTime}
                                                    />
                                                </div>
                                                <button disabled={!this.isOrderDateReady()} className="badge badge-pill btn-info text-capitalize" style={{ cursor: 'pointer', float: 'right' }} onClick={() => { this.handleSubmit() }}>get</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {/* Counters Section */}
                        <section className="dashboard-counts py-2">
                            <div className="container-fluid">
                                <div className="row bg-white has-shadow p-0">
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-6 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(1) }} className="item d-flex align-items-center p-0" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-green"><i className="fa fa-money"></i></div>
                                            <div className="title"><span>Verified</span><br />
                                                <strong className="text-bold text-dark">{verifyCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${verifyCount / 100}%`, height: "4px" }} className="progress-bar bg-green"></div>  {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!-- Item --> */}
                                    <div className="col-xl-6 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(0) }} className="item d-flex align-items-center p-0" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-blue"><i className="fa fa-cutlery"></i></div>
                                            <div className="title"><span>Unverified</span> <br />
                                                <strong className="text-bold text-dark">{unverifyCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${unverifyCount / 100}%`, height: "4px" }} className="progress-bar bg-blue"></div>  {/*ariaValuenow="25" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* End Counters Section */}
                        <section className="tables py-2">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                {(this.props.applyLoaderonDate || (orders && orders.length == 0 && this.state.loader)) ?
                                                    <div >
                                                        {/* <Loader
                                                            type="TailSpin"
                                                            color="#007bff"
                                                            height={50}
                                                            width={50}
                                                        /> */}
                                                        {/* here */}
                                                        <BootstrapTable version='4' data={dataa} search={true} options={options} csvFileName='orders.csv'>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold' }} width='80' tdStyle={{ fontSize: 10 }} dataField='1' dataFormat={skeltonFormatter} csvHeader='Order Id' dataSort={true} isKey>Order Id</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold' }} width='80' tdStyle={{ fontSize: 10 }} dataFormat={skeltonFormatter} >Details</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Parmanent Order Id</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Card Order Id</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Verify</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Order Recieved</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Store</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Order Type</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal', textTransform: 'capitalize' }} dataFormat={skeltonFormatter} columnTitle>Order Channel</TableHeaderColumn>
                                                            {/* <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Pulse Status' columnTitle>Pulse Status</TableHeaderColumn> */}
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Customer Name' columnTitle>Name</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Phone' columnTitle>Phone</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Address' columnTitle>Address</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Price'>Order Price</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Discount'>Discount</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Payment Method</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Cancel Reason</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Cancel Reason Description' columnTitle>Cancel Reason Description</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Caller For Order Transfer</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Order Transfer</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Order Transfer Reason</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Unverify Reason</TableHeaderColumn>
                                                        </BootstrapTable>
                                                    </div> :
                                                    // here
                                                    <BootstrapTable version='4' data={filteredOrders.length > 0 || emptyFilteredOrder ? filteredOrders : orders} search={true} trClassName={this.rowClassNameFormat} pagination={orders.length > 10 && true} options={options} exportCSV={true} csvFileName='orders.csv'>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold' }} width='80' tdStyle={{ fontSize: 10 }} dataField='order_id' csvHeader='Order Id' dataSort={true} isKey>Order Id</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold' }} width='80' tdStyle={{ fontSize: 10 }} dataField='action' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Details</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='simplex_order_id' csvHeader='Parmanent Order Id' columnTitle>Parmanent Order Id</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='cardOrderId' csvHeader='Card Order Id' columnTitle>Card Order Id</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='is_verify' dataFormat={verifyStatus} csvHeader='Verify' columnTitle>Verify</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='date_created' csvHeader='Order Recieved' columnTitle>Order Recieved</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='store_name' csvHeader='Store' columnTitle>Store</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='delivery_status' csvHeader='Order Type' columnTitle>Order Type</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal', textTransform: 'capitalize' }} dataField='order_channel' columnTitle>Order Channel</TableHeaderColumn>
                                                        {/* <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='failure_reason' csvHeader='Pulse Status' columnTitle>Pulse Status</TableHeaderColumn> */}
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='login_name' csvHeader='Customer Name' columnTitle>Name</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='phone_number' csvHeader='Phone' columnTitle>Phone</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='delivery_address' csvHeader='Address' columnTitle>Address</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='order_grossprice' dataFormat={priceFormatter} csvHeader='Price'>Order Price</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='discount' dataFormat={priceFormatter} csvHeader='Discount'>Discount</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='payment_method' csvHeader='Payment Method' columnTitle>Payment Method</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='reason' csvHeader='Cancel Reason' columnTitle>Cancel Reason</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='cancel_reason_description' csvHeader='Cancel Reason Description' columnTitle>Cancel Reason Description</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='order_transfer_caller' csvHeader='Caller For Order Transfer' columnTitle>Caller For Order Transfer</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='is_order_transfer' csvHeader='Order Transfer' dataFormat={isOrderTransferFormatter} columnTitle>Order Transfer</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField="order_transfer_reason" csvHeader="Order Transfer Reason" columnTitle>Order Transfer Reason</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='unverified_reason' csvHeader='Unverify Reason' columnTitle>Unverify Reason</TableHeaderColumn>
                                                    </BootstrapTable>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div >
            </div >
        );
    }
};
function isOrderTransferFormatter(cell: any, row: any) {
    if (row.is_order_transfer == 0) {
        return <p title="No">No</p>;
    } else {
        return <p title="Yes">Yes</p>;
    }
}
function priceFormatter(cell: any, row: any) {
    if (row.aggregator_orderId) {
        return `${currency} ${cell}`;
    } else {
        return `${currency} ${Math.round(cell)}`;
    }
}

function verifyStatus(cell: any, row: any) {
    let obj1 = {
        backgroundColor: "green",
        color: "white",
        padding: "4px"
    }
    let obj2 = {
        backgroundColor: "red",
        color: "white",
        padding: "4px"
    }
    return (
        <div>
            <span style={row.is_verify ? obj1 : obj2}>{row.is_verify ? "Verified" : "Unverified"}</span>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        orders: state.order.cardOrders,
        filteredOrders: state.order.filteredCardOrders,
        emptyFilteredOrder: state.order.emptyFilterCardOrder,
        Items: state.order.cardOrderItems,
        orderDetail: state.order.order,
        states: state.setting.states,
        stores: state.store.data,
        verifyCount: state.order.verifyCount,
        unverifyCount: state.order.unverifyCount,
        applyLoaderonDate: state.order.isLoading,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        ordersList: (obj: any, datetime: any, updateLoader?: any) => {
            dispatch(cardOrdersList(obj, datetime,updateLoader))
        },
        showCardOrderItems: function (id: any, orders: any) {
            dispatch(showCardOrderItems(id, orders))
        },
        statesList: function () {
            dispatch(statesList())
        },
        storesList: function () {
            dispatch(storesList())
        },
        filterCardOrdersList: (filteredArr: any) => {
            dispatch(filterCardOrdersList(filteredArr))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Orders);