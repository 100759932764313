import React, { Component } from 'react';

export default class Footer extends Component<{}, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    render() {
        return (
            <div className="copyrights text-center">
                <p>
                    Powered By{" "}
                    <a style={{ textDecoration: "none" }} className="external" href="/" target="/blank">
                        Simple Mart
                    </a>
                </p>
            </div>
        );
    }
};