import { useEffect } from "react";
import { Pagination } from "antd";
import { connect, useDispatch } from "react-redux";
import React from "react";

type PaginationCompProps = {
  handlePagination: any;
  total_orders_count?:any;
  pageNumber?:any;
  pageSize?:any
};

function PaginationComp(props:PaginationCompProps) {
  const dispatch=useDispatch();

  const handlePagination = (pageNum:any,pSize:any) => {
    dispatch({ type:"SET_PAGINATION", payload:{ pageNum, pSize } })

    setTimeout(()=>{   
      props.handlePagination() // call after redux var set
    },100)

  };

  useEffect(()=>{
    props.handlePagination()
    console.log(props.pageNumber,props.pageSize,"dsadsadsadssadsad");
    
    return ()=>{
      dispatch({ type:"SET_PAGINATION", payload:{ pageNum: 1, pSize: 50 } })
    }
  },[])

  return <>
        {
        props.total_orders_count > 0 ? 
          <Pagination
            onChange={(pageNumber:any, pageSize:any) => {
              handlePagination(pageNumber,pageSize);
            }}
            current={props.pageNumber}
            total={props.total_orders_count}
            pageSize={props.pageSize}
            // showSizeChanger={true}
            // showQuickJumper={true}
            showLessItems
            showTotal={(total:any) => `Total ${total} orders`}
          />
        : ""
        }
      
        </>
}

const mapStateToProps = (state: any) => {
  return {
    pageSize: state.order.pageSize,
    pageNumber: state.order.pageNumber,
    total_orders_count: state.order.total_orders_count,
  };
};

export default connect(mapStateToProps, null)(PaginationComp);
