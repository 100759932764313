import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { logoutUser, getTaxValue, saveDiscountValue, countryList, saveDeliveryFee, discountsList, statesList, saveTaxByState } from '../../redux'
import { SettingsProps, SettingsState } from '../../interfaces/settings';
import { savePOSFee, getDeliveryFee, storeList, clearPropsData, SettingOrderingModes, saveDeliveryToogleFee } from '../../redux/actions/settingsAction';
import { OrderingModes } from '../../redux/actions/menuAction';
import Toggle from 'react-toggle';

class Settings extends Component<SettingsProps, SettingsState> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            country: "",
            deliveryCountry: "",
            deliveryState: "",
            deliveryStore: "",
            posFeeCountry:"",
            state: "",            
            posFeeState:"",
            tax_value: "",
            delivery_fee: "",
            pos_service_fee:"",
            deliveryChecks: {
                'Online': false,
                'Mobile': false,
                'Call Center': false
            }
        }
        this.handleTaxSubmit = this.handleTaxSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handlePOSFeeStateChange = this.handlePOSFeeStateChange.bind(this);
        this.handleDeliveryFeeChange = this.handleDeliveryFeeChange.bind(this);
        this.handleDeliveryCheck = this.handleDeliveryCheck.bind(this);
    }
    componentDidMount() {
        // this.props.getTaxValue();
        this.props.countryList();
        this.props.statesList();
        this.props.discountsList();
        this.props.OrderingModes()
        document.title = "Crust Culture | Global Settings"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any, nextContext: any): void {
        if(nextProps.storeDeliveryFee) {
            this.setState({ delivery_fee: nextProps.storeDeliveryFee.delivery_fee })
        }
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.name == 'country') {
            if (event.target.value == "") {
                this.setState({ state: "", tax_value: "" });
            } else {
                if (event.target.value !== this.state.country) {
                    this.setState({ state: "", tax_value: "" });
                }
            }
        } else if (event.target.name == 'deliveryCountry') {
            if (event.target.value == "") {
                this.setState({ deliveryState: "", delivery_fee: "" });
            } else {
                if (event.target.value !== this.state.deliveryCountry) {
                    this.setState({ deliveryState: "", delivery_fee: "" });
                }
            }
        } else if (event.target.name == 'posFeeCountry') {
            if (event.target.value == "") {
                this.setState({ posFeeState: "", pos_service_fee: "" });
            } else {
                if (event.target.value !== this.state.posFeeCountry) {
                    this.setState({ posFeeState: "", pos_service_fee: "" });
                }
            }
        }
    }
    handleStateChange(event: { target: { name: any; value: any; }; }) {
        let { states } = this.props;
        if (event.target.value !== "") {
            let data: any = states.find((state: any) => state.state_id == event.target.value);
            this.setState({ state: data.state_id, tax_value: data.tax_percent ? data.tax_percent : "" });
        } else {
            this.setState({ state: "", tax_value: "" });
        }
    }

    handleDeliveryCheck(event: { target: { name: any; checked: any; }; }) {
        let { deliveryChecks } = this.state;
        let { name, checked } = event.target;
        deliveryChecks[name] = checked;
        this.setState({ deliveryChecks });
    }

    handleDeliveryFeeChange(event: { target: { name: any; value: any; }; }) {
        let { deliveryState, deliveryCountry } = this.state
        this.setState({
            [event.target.name]: event.target.value
        });
        let { name } = event.target;
        if(name == 'deliveryCountry') {
            this.setState({ deliveryState: "", deliveryStore: "", delivery_fee: "" });
            this.props.statesList()
        }
        else if(name == 'deliveryState') {
            this.setState({ deliveryStore: "", delivery_fee: "",  });
            this.props.stores(deliveryCountry, event.target.value)
        }
        else if(name == 'deliveryStore') {
            this.setState({ delivery_fee: "" });
            this.props.deliveryFee(deliveryCountry, deliveryState, event.target.value)
        }
    }
    handlePOSFeeStateChange(event: { target: { name: any; value: any; }; }) {
        let { states } = this.props;
        if (event.target.value !== "") {
            let data: any = states.find((state: any) => state.state_name == event.target.value);
            this.setState({ posFeeState: data.state_name, pos_service_fee: data.pos_service_fee ? data.pos_service_fee : "" });
        } else {
            this.setState({ posFeeState: "", pos_service_fee: "" });
        }
    }
    isTaxSubmitReady = () => {
        let { country, state, tax_value } = this.state;
        return (country !== "" && state !== "" && tax_value !== "");
    }
    isCountrySubmitReady = () => {
        let { deliveryCountry, deliveryState, delivery_fee } = this.state;
        return (deliveryCountry !== "" && deliveryState !== "" && delivery_fee !== "");
    }
    isPosSubmitReady = () => {
        let { posFeeCountry, posFeeState, pos_service_fee } = this.state;
        return (posFeeCountry !== "" && posFeeState !== "" && pos_service_fee !== "");
    }
    handleTaxSubmit = (event: any) => {
        let { tax_value, state } = this.state;
        let data = {
            state_id: state,
            tax_percent: tax_value
        }
        this.props.saveTaxByState(data);
        event.preventDefault()
    }
    handleToggleChange(e: any, element: any) {

        let { orderingModes } = this.props;
        let data = {
            mode_id: element.value,
            free_delivery: e.target.checked
        }

        this.props.saveDeliveryToogleFee(data);

    }
    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    handleDeliveryFeeSubmit = (event: any) => {
        let { delivery_fee, deliveryStore } = this.state;
        let data = {
            store_id: deliveryStore,
            delivery_fee: delivery_fee,
        }
        this.props.saveDeliveryFee(data);
        this.setState({
            deliveryCountry: "",
            deliveryState: "",
            deliveryStore: "",
            delivery_fee: ""
        })
        this.props.clearDeliveryProps()
        event.preventDefault()
    }
    handleDeliveryFeeModes = (event: any) => {
        let { deliveryChecks } = this.state;
        let data = {
            online: deliveryChecks['Online'],
            mobile: deliveryChecks['Mobile'],
            call_center: deliveryChecks['Call Center']
        }
        console.log(data)
        event.preventDefault()
    }
    handlePOSFeeSubmit = (event: any) => {
        let { pos_service_fee, posFeeState } = this.state;
        let data = {
            state_name: posFeeState,
            pos_service_fee: pos_service_fee
        }
        this.props.savePOSFee(data);
        event.preventDefault()
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg, discmsg;
        let taxcolor, disccolor;
        let deliverymsg, deliverycolor;
        let { country, tax_value, delivery_fee, deliveryCountry, deliveryState, deliveryStore, posFeeCountry,posFeeState, pos_service_fee } = this.state;
        const { isSaved, message, states, countries, deliveryMsg, saveDelivery, storesList } = this.props;
        if (!isSaved) { msg = message; taxcolor = 'red' } else { msg = message; taxcolor = 'green' }
        if (!saveDelivery) { deliverymsg = deliveryMsg; deliverycolor = 'red' }
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Global Settings</h4>
                                </div>
                            </div>
                        </header>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="card shadow rounded">
                                    <div className="row p-3">
                                        <div className="col-lg-6">
                                            <div className="card shadow rounded">
                                                <div className="card-content">
                                                    <div className="card-header border-bottom-grey">
                                                        <strong className="">Global Tax</strong>
                                                    </div>
                                                    <div className="card-body">
                                                        <strong><p className="text-center font-weight-light mb-4" style={{ color: taxcolor }}>
                                                            {msg}
                                                        </p></strong>
                                                        <form className="form-validate">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Country <span className="text-danger">*</span></label>
                                                                        <select name="country" className="form-control mt-2" required data-msg="Please enter Country" onChange={this.handleInputChange}>
                                                                            <option value="">Select Country</option>
                                                                            {countries &&
                                                                                countries.map((country: any, index: any) => (
                                                                                    <option key={index} value={country.country_id}>{country.country_name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">States <span className="text-danger">*</span></label>
                                                                        <select name="state" className="form-control mt-2" required data-msg="Please select state" onChange={this.handleStateChange}>
                                                                            <option value="">Select State</option>
                                                                            {country !== "" &&
                                                                                states &&
                                                                                states.map((state: any, index: any) => (
                                                                                    state.country_id == country &&
                                                                                    <option key={index} value={state.state_id}>{state.state_name}-({state.payment_method})</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Tax Value <span className="text-danger">*</span></label>
                                                                        <input id="taxValue" type="number" onWheel={(e:any) => e.target.blur()} name="tax_value" min={0} onKeyDown={this.blockInvalidChar} value={tax_value} required data-msg="Please enter Tax value" className="input-material" onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="form-group d-flex justify-content-end">
                                                                <button onClick={this.handleTaxSubmit} disabled={!this.isTaxSubmitReady()} className="btn btn-primary">Save</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card shadow rounded">
                                                <div className="card-content">
                                                    <div className="card-header border-bottom-grey">
                                                        <strong className="">Delivery Fee</strong>
                                                    </div>
                                                    <div className="card-body">
                                                        <strong><p className="text-center font-weight-light mb-4" style={{ color: deliverycolor }}>
                                                            {deliverymsg}
                                                        </p></strong>
                                                        <form className="form-validate">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Country <span className="text-danger">*</span></label>
                                                                        <select name="deliveryCountry" className="form-control mt-2" required data-msg="Please enter Country" onChange={this.handleDeliveryFeeChange}>
                                                                            <option>Select Country</option>
                                                                            {
                                                                                countries &&
                                                                                countries.map((country: any, index: any) => (
                                                                                    <option key={index} value={country.country_id}>{country.country_name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">States <span className="text-danger">*</span></label>
                                                                        <select name="deliveryState" disabled={deliveryCountry == ''} className="form-control mt-2" required data-msg="Please select state" onChange={this.handleDeliveryFeeChange}>
                                                                            <option value="">Select State</option>
                                                                            {
                                                                                states &&
                                                                                states.map((state: any, index: any) => (
                                                                                    (state.country_id == deliveryCountry && state.payment_method == "cash") &&
                                                                                    <option key={index} value={state.state_id}>{state.state_name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Branch <span className="text-danger">*</span></label>
                                                                        <select name="deliveryStore" disabled={(deliveryCountry == '' || deliveryState == '')} className="form-control mt-2" required data-msg="Please select branch" onChange={this.handleDeliveryFeeChange}>
                                                                            <option value="">Select Branch</option>
                                                                            {
                                                                                storesList &&
                                                                                storesList.map((store: any, index: any) => (
                                                                                    <option key={index} value={store.store_id}>{store.store_name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Delivery Fee <span className="text-danger">*</span></label>
                                                                        <input disabled={deliveryCountry == '' || deliveryState == '' || deliveryStore == ''} id="deliveryFee" type="number" onWheel={(e:any) => e.target.blur()} name="delivery_fee" min={0}
                                                                            onKeyDown={this.blockInvalidChar} value={delivery_fee} required data-msg="Please enter Delivery Fee" className="input-material" onChange={this.handleDeliveryFeeChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="form-group d-flex justify-content-end">
                                                                <button onClick={this.handleDeliveryFeeSubmit} disabled={!this.isCountrySubmitReady()} className="btn btn-primary">Save</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row p-3">
                                        <div className="col-lg-6">
                                            <div className="card shadow rounded">
                                                <div className="card-content">
                                                    <div className="card-header border-bottom-grey">
                                                        <strong className="">POS Service Fee</strong>
                                                    </div>
                                                    <div className="card-body">
                                                        <form className="form-validate">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Country <span className="text-danger">*</span></label>
                                                                        <select name="posFeeCountry" className="form-control mt-2" required data-msg="Please enter Country" onChange={this.handleInputChange}>
                                                                            <option>Select Country</option>
                                                                            {countries &&
                                                                                countries.map((country: any, index: any) => (
                                                                                    <option key={index} value={country.country_id}>{country.country_name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">States <span className="text-danger">*</span></label>
                                                                        <select name="posFeeState" className="form-control mt-2" required data-msg="Please select state" onChange={this.handlePOSFeeStateChange}>
                                                                            <option value="">Select State</option>
                                                                            {posFeeCountry !== "" &&
                                                                                states &&
                                                                                states.map((state: any, index: any) => (
                                                                                    (state.country_id == posFeeCountry && state.payment_method == "cash") &&
                                                                                    <option key={index} value={state.state_name}>{state.state_name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Pos Service Fee <span className="text-danger">*</span></label>
                                                                        <input id="posfee" type="number" onWheel={(e:any) => e.target.blur()} name="pos_service_fee" min={0}
                                                                            onKeyDown={this.blockInvalidChar} value={pos_service_fee} required data-msg="Please enter POS Service Fee" className="input-material" onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="form-group d-flex justify-content-end">
                                                                <button onClick={this.handlePOSFeeSubmit} disabled={!this.isPosSubmitReady()} className="btn btn-primary">Save</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card shadow rounded">
                                                <div className="card-content">
                                                    <div className="card-header border-bottom-grey">
                                                        <strong className="">Delivery Fee (Mode Specific)</strong>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            {
                                                                this.props.orderingModes && this.props.orderingModes.length > 0 ?
                                                                    this.props.orderingModes.map((element:any, index:any) => {
                                                                        return (
                                                                            <div className="col-lg-12 my-2" key={index}>
                                                                                <div className="d-flex justify-content-between">
                                                                                    <div>{element.label}</div>
                                                                                    <div>
                                                                                        <Toggle
                                                                                            className='custom-classname'
                                                                                            checked={element.free_delivery == false ? false : true}
                                                                                            icons={true}
                                                                                            onChange={(e) => this.handleToggleChange(e, element)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                :
                                                                <div className="col-12 text-center">
                                                                    <h5 className='m-auto'>Modes mode not found</h5>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div >
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        message: state.setting.message,
        discounts: state.setting.discounts,
        discountMsg: state.setting.discMsg,
        states: state.setting.states,
        countries: state.store.countries,
        isSaved: state.setting.isSaved,
        isInserted: state.setting.isInserted,
        tax: state.setting.taxData,
        deliveryMsg: state.setting.deliveryMsg,
        saveDelivery: state.setting.saveDelivery,
        storesList: state.setting.storesList,
        storeDeliveryFee: state.setting.storeDeliveryFee,
        orderingModes: state.setting.settingOrderModes,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        OrderingModes: () => {
            dispatch(SettingOrderingModes())
        },
        saveDeliveryToogleFee: function (data: any) {
            dispatch(saveDeliveryToogleFee(data))
        },
        stores: function (state: any, country: any) {
            dispatch(storeList(state, country))
        },
        deliveryFee: function (country: any, state: any, store: any) {
            dispatch(getDeliveryFee(country, state, store))
        },
        countryList: function () {
            dispatch(countryList())
        },
        discountsList: function () {
            dispatch(discountsList())
        },
        statesList: function () {
            dispatch(statesList())
        },
        saveTaxByState: function (data: any) {
            dispatch(saveTaxByState(data))
        },
        getTaxValue: function () {
            dispatch(getTaxValue());
        },
        saveDiscountValue: function (data: any) {
            dispatch(saveDiscountValue(data))
        },
        saveDeliveryFee: function (data: any) {
            dispatch(saveDeliveryFee(data))
        },
        savePOSFee: function (data: any) {
            dispatch(savePOSFee(data))
        },
        clearDeliveryProps: function() {
            dispatch(clearPropsData())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Settings);