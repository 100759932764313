import React, { Component } from 'react';
import { connect } from 'react-redux'
import { logoutUser, addEmp, empCoupons, empTypeList } from '../../redux'
import { AddEmpProps, AddEmpState } from '../../interfaces/employees';
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import Select from 'react-select';
import CheckChanges from '../../components/confirmOnLeave'
class AddEmp extends Component<AddEmpProps, AddEmpState> {
    constructor(props: any) {
        super(props);
        this.state = {
            name: "",
            email_address: "",
            phone_number: "",
            limit: "",
            vip: false,
            empType: "",
            voucher_codes: []
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    }
    componentDidMount() {
        document.title = "Crust Culture | Employees"
        this.props.empCoupons()
        this.props.empTypeList()
    }
    handleInputChange = (event: { target: { name: any; value: any; }; }) => {
        if (event.target.value == ' ') {
            event.target.value = event.target.value.replace(/\s/g, "");
        }
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

      validatePhone= (event: { target: { name: any; value: any; };})=> {
        if(event.target.value && event.target.value.length<12){
            this.setState({ [event.target.name]: event.target.value})
          }
          else if (event.target.value.length < this.state.phone_number.length) {
            this.setState({ [event.target.name]: event.target.value})
          }
       
    }
    isEmpReady = () => {
        const { name, email_address, phone_number, limit, empType, voucher_codes } = this.state;
        return (name !== "" && email_address !== "" && phone_number !== "" && limit !== "" && empType !== "" && voucher_codes.length > 0);
    }
    handleCouponsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ voucher_codes: e });
        } else {
            this.setState({ voucher_codes: [] });
        }
    };
    blockInvalidChar = (e: any) => {
        ["@", "+", "-", "=", "/", "!", ">", "<", "%", "&", "*", "(", ")", "_", "{", "}", "[", "]", "|", ":", ";", "?", "#", "$", "^",].includes(e.key) && e.preventDefault();
    }
    handleVip = (event: { target: { name: any; value: any; }; }) => {
        const { vip } = this.state;
        this.setState({ vip: !vip })
    }
    handleEmpType = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ empType: e.value });
        } else {
            this.setState({ empType: "" });
        }
    };
    handleSaveBtnClick = () => {
        let { name, email_address, phone_number, limit, vip, empType, voucher_codes } = this.state;
        let data: any = {
            name: name,
            email_address: email_address,
            phone_number: phone_number,
            redemption_limit: limit,
            employee_type_id: empType,
            voucher_codes: JSON.stringify(voucher_codes)
        }
        if (vip) {
            data.is_vip = 1;
        }
        this.props.addEmp(data);
    }
    render() {
        const { coupons, empTypes } = this.props;
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        return (
            <div className="page">
                <CheckChanges path="/add-brand" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Employees Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/employees" className="text-primary">Employees</Link></li>
                                <li className="breadcrumb-item active">Add Employee</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Name <span className="text-danger">*</span></label>
                                                            <input id="name" type="text" name="name" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Email Address <span className="text-danger">*</span></label>
                                                            <input id="email_address" type="text" name="email_address" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className='col'>
                                                        <div className="form-group">
                                                            <label className="form-control-label">Employee Type <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="EmpType"
                                                                isClearable
                                                                options={empTypes}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleEmpType(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Phone Number <span className="text-danger">*</span></label>
                                                            <input id="phone_number" type="number" onWheel={(e:any) => e.target.blur()} maxLength={11} name="phone_number" value={this.state.phone_number}  className="input-material" onChange={this.validatePhone} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Redemption Limit <span className="text-danger">*</span></label>
                                                            <input id="limit" type="number" onWheel={(e:any) => e.target.blur()} name="limit" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col my-2">
                                                        <div>
                                                            <input id="checkboxCustom3" type="checkbox" name="vip" checked={this.state.vip} onChange={this.handleVip} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom3">For Vip</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Select Vouchers <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="coupons"
                                                                options={coupons}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleCouponsInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isEmpReady()} onClick={this.handleSaveBtnClick} >Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        coupons: state.employee.empCoupons,
        empTypes: state.employee.empTypes,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: () => { dispatch(logoutUser()); },
        addEmp: (data: any) => { dispatch(addEmp(data)); },
        empCoupons: function () {
            dispatch(empCoupons())
        },
        empTypeList: function () {
            dispatch(empTypeList())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEmp);