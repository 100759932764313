import { ALLIANCE_LIST, GET_ALLIANCE } from '../actions/allianceType'
const initialState: any = {
    alliances: [] || "",
    allianceData: {}
}

const allianceReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ALLIANCE_LIST: return {
            ...state,
            alliances: action.payload
        }
        case GET_ALLIANCE: return {
            ...state,
            allianceData: action.payload
        }
        default: return state;
    }
}
export default allianceReducer;