import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import Select from 'react-select';
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { menusList, groupsListByMenuId, logoutUser, publishMenu, publishLater, storesListForMenuStoreRelation, syncMenuWithFP } from '../../redux'
import { MenuProps } from '../../interfaces/menu';
import { syncMenu } from '../../redux/actions/menuAction'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
class ActionFormatter extends Component<{ row: any, data: any }, { [x: number]: any, activeTab: any, date: any, time: any, syncFPClick: any, storesJson: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            activeTab: 0,
            date: "",
            time: "",
            syncFPClick: false,
            storesJson: [],
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    showgroups = (id: any) => {
        this.props.data.groupsListByMenuId(id)
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    ispublishReady = () => {
        const { date } = this.state
        return (date !== "");
    }
    handleUnPublish = (id: any) => {
        let data = { is_publish: 0 }
        this.props.data.publishMenu(id, data, this.props.row.menu_name)
    }
    handlePublish = (id: any) => {
        let { activeTab, date, time } = this.state;
        let { row } = this.props
        if (activeTab === 0) {
            let data = {}
            if (row.cron_job_id) {
                data = {
                    is_publish: 1,
                    cron_job_id: row.cron_job_id
                }
            } else {
                data = { is_publish: 1 }
            }
            this.props.data.publishMenu(id, data, row.menu_name)
        } else {
            let data = {
                date: new Date(date),
                time: time,
                is_publish: 1
            }
            this.props.data.publishLater(id, data, row.menu_name)
        }
    }
    enableSyncButton = () => {
        this.setState({ syncFPClick: false })
    }
    handleFPMenuSync = (id: any) => {
        this.setState({ syncFPClick: true })
        let store_ids: any = [];
        this.state.storesJson.forEach((obj: any) => {
            store_ids.push(obj.value)
        })
        this.props.data.syncMenuWithFP(id, store_ids, this.enableSyncButton)
    }
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            let newArr = e.map((item: any) => {
                return {
                    label: item.label,
                    value: item.value
                }
            });
            this.setState({ storesJson: newArr });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    render() {
        const { row, data } = this.props;
        const today = new Date().toISOString().split('T')[0];
        return (
            <div>
                <button title="View Groups" data-toggle="modal" data-target={`#viewgroups${row.menu_id}`} onClick={() => this.showgroups(row.menu_id)} className="btn btn-outline-info mt-2"><i className="fa fa-list"></i></button>
                <Link title="Edit Menu" className="btn btn-outline-primary ml-2 mt-2" to={`/edit-menu/${row.menu_id}`}><i className="fa fa-edit"></i></Link>
                <button title="Publish Menu" data-toggle="modal" data-target={`#publish${row.menu_id}`} className="btn btn-outline-info ml-2 mt-2"><img title="Publish Menu" alt="publish" className="rounded-circle" src={process.env.PUBLIC_URL + '/assets/img/publishform.png'} width="20px" height="20px" /></button>
                <button onClick={() => { this.setState({ storesJson: row.stores }) }} title="Sync FoodPanda Menu" data-toggle="modal" data-target={`#syncaggregator${row.menu_id}`} className="btn btn-outline-fp ml-2  mt-2"><i className="fa fa-refresh"></i></button>
                {
                    (row.is_publish === 1 && !row.cron_job_id) &&
                    <button title="UnPublish Menu" data-toggle="modal" data-target={`#unpublish${row.menu_id}`} className="btn btn-outline-danger float-right ml-2 mt-2"><img title="UnPublish Menu" alt="unpublish" className="rounded-circle" src={process.env.PUBLIC_URL + '/assets/img/unpublish.png'} width="20px" height="20px" /></button>
                }
                {/* <!-- Modal--> */}
                <div id={`viewgroups${row.menu_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Groups</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            {(data.groups.length > 0) ?
                                                <BootstrapTable version='4' data={data.groups} hover>
                                                    <TableHeaderColumn dataField="group_id" columnTitle isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='group_name' columnTitle>Group Name</TableHeaderColumn>
                                                </BootstrapTable> :
                                                <p className="text-center">Groups not found</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Publish Modal--> */}
                <div id={`publish${row.menu_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Publish Menu</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <Tabs onSelect={index => this.setState({ activeTab: index, date: "", time: "" })}>
                                    <TabList>
                                        <Tab>Publish Now</Tab>
                                        <Tab {...row.cron_job_id && { disabled: true }}>Publish Later</Tab>
                                    </TabList>

                                    <TabPanel style={{ marginTop: 15 }}>
                                        <p>Are you sure,you want to Publish this menu?</p>
                                    </TabPanel>
                                    <TabPanel style={{ marginTop: 15 }}>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Date</label>
                                                    <input type="datetime-local" name="date" min={today} required data-msg="Please enter Date" placeholder="DD/MM/YYYY" className="form-control" onChange={this.handleInputChange} />
                                                </div>
                                            </div>
                                            {/* <div className="col">
                                                <div className="form-group">
                                                    <label>Time</label>
                                                    <input type="time" name="time" required data-msg="Please enter Time" className="form-control" onChange={this.handleInputChange} />
                                                </div>
                                            </div> */}
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button {...this.state.activeTab == 1 && { disabled: !this.ispublishReady() }} onClick={() => this.handlePublish(row.menu_id)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Sync FP Menu Modal--> */}
                <div id={`syncaggregator${row.menu_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left" data-backdrop='static'>
                    <div role="document" className="modal-dialog" style={{ maxWidth: '100%' }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Sync Menu with FoodPanda</h4>
                                {!this.state.syncFPClick && <button onClick={() => { this.setState({ syncFPClick: false }) }} type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>}
                            </div>
                            <div className="modal-body">
                                <Tabs onSelect={index => this.setState({ activeTab: index, date: "", time: "" })}>
                                    <TabList>
                                        <Tab>Publish Now</Tab>
                                    </TabList>
                                    <TabPanel style={{ marginTop: 15 }}>
                                        <p>Are you sure,you want to Sync with FoodPanda?</p>
                                        <div className="row">
                                            <div className="col" >
                                                <div className="form-group">
                                                    <label className="form-control-label">Select Store </label>
                                                    <Select
                                                        isMulti
                                                        name="stores"
                                                        options={data.stores}
                                                        value={this.state.storesJson}
                                                        className="text-capitalize basic-multi-select mt-2"
                                                        classNamePrefix="select"
                                                        onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                            <div className="modal-footer">
                                {!this.state.syncFPClick && <button onClick={() => { this.setState({ syncFPClick: false }) }} type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>}
                                {(this.state.syncFPClick) ?
                                    <button className='btn btn-primary' disabled={this.state.syncFPClick}><Loader type="TailSpin" color="white" height={30} width={30} /></button> :
                                    <button disabled={this.state.syncFPClick || this.state.storesJson.length == 0} onClick={() => this.handleFPMenuSync(row.menu_id)} className="btn btn-primary">Confirm</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- UnPublish Modal--> */}
                <div id={`unpublish${row.menu_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">UnPublish Menu</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to UnPublish this menu?</p></div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleUnPublish(row.menu_id)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}

class Menu extends Component<MenuProps, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.menusList();
        this.props.storesList();
        document.title = "Crust Culture | Menus"
    }
    syncMenu = () => {
        this.props.syncMenu()
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-menu" /> },
            noDataText: 'Menus Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Menus Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            {/* {<button onClick={() => this.syncMenu()} type="button" data-dismiss="modal" className="btn btn-primary">Sync Menu with Sync</button>} */}
                                            <div className="card-body">
                                                <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='menus.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='menu_id' csvHeader='#' width='100' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='menu_name' csvHeader='Menu Name' width='100' columnTitle>Menu name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='brand_name' csvHeader='Brand Name' width='100' columnTitle>Brand name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='store_type_name' csvHeader='Store Type' width='100' columnTitle>Store type</TableHeaderColumn>
                                                    {/* <TableHeaderColumn dataField='state_name' csvHeader='State Name' width='100' columnTitle>State name</TableHeaderColumn> */}
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='is_publish' width='100' dataFormat={statusFormatter} export={false}>Status</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='stores' width='100' dataFormat={storesFormatter} export={false}>Stores</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='230' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
function statusFormatter(cell: any) {
    return (
        <div>
            <span {...(cell == 1 && { className: "badge badge-success p-2" }) || (cell == 0 && { className: "badge badge-danger p-2" })}>{cell == 1 ? "published" : "Un published"}</span>
        </div>
    )
}
function storesFormatter(cell: any, row: any) {
    let stores = row.stores
    return (
        <div>
            <button title="View Stores" data-toggle="modal" data-target={`#viewStores${row.menu_id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button>

            {/* <!-- Modal--> */}
            <div id={`viewStores${row.menu_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                <div role="document" className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 id="exampleModalLabel" className="modal-title">Stores</h4>
                            <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group text-capitalize">
                                        {(stores.length > 0) ?
                                            <BootstrapTable version='4' data={stores} hover>
                                                <TableHeaderColumn dataField="value" columnTitle isKey>#</TableHeaderColumn>
                                                <TableHeaderColumn dataField='label' columnTitle>Store Name</TableHeaderColumn>
                                            </BootstrapTable> :
                                            <p className="text-center">Stores not found</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
const mapStateToProps = (state: any) => {
    return {
        data: state.menu.menus,
        groups: state.menu.groups,
        stores: state.menu.menuStoresOptions,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        menusList: function () {
            dispatch(menusList())
        },
        groupsListByMenuId: function (id: any) {
            dispatch(groupsListByMenuId(id))
        },
        publishMenu: function (id: any, data: any,menu_name:any) {
            dispatch(publishMenu(id, data,menu_name))
        },
        publishLater: function (id: any, data: any,menu_name:any) {
            dispatch(publishLater(id, data,menu_name))
        },
        syncMenu:(data:any)=>{
            dispatch(syncMenu())
        },
        syncMenuWithFP: function (id: any, store_ids: any, enableSyncButton: any) {
            dispatch(syncMenuWithFP(id, store_ids, enableSyncButton))
        },
        storesList: function () {
            dispatch(storesListForMenuStoreRelation())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Menu);