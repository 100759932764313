import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { connect } from 'react-redux'
import { logoutUser, storesList, storesListForMultiSelect } from '../../../redux'
import { storeTableList, tablesList } from '../../../redux/actions/dineInAction';
var QRCode = require('qrcode')

function LinkFormatter(cell: any, row: any, props: any) {

    let QRValue = `https://cc-web.simplexts.com.au/${row.value}/${props.store_id}`

    const generateQR = (QRValue:any) => {
        QRCode.toDataURL(QRValue, function (err: any, url: any) {
            if (err) {
                console.log('error occurred')
            } else {
                var a = document.createElement('a');
                a.href = url;
                a.download = 'QRCode.png';
                a.click();
            }
        })
    }

    return (
        <div className='d-flex justify-content-center'>
            <button title="Download QR Code" style={{ width: '100%' }} className="btn btn-outline-info" onClick={() => generateQR(QRValue)}>
                <i className="fa fa-download"></i>
            </button>
        </div>
    )
}

class ActionFormatter extends Component<{ row: any }, {}> {
    
    constructor(readonly props: any) {
        super(props);
        this.state = {
            store_id: ''
        }
    }

    showTables = (id: any) => {
        this.props.data.getStoreTable(id)
        this.setState({ store_id: id })
    }

    render() {
        const { row, data } = this.props
        let tablesList: any = data.storeTableList
        return (
            <div>
                <button title="View Assigned Store" data-toggle="modal" data-target={`#viewStoreTable${row.value}`} className="btn btn-outline-info" onClick={() => this.showTables(row.value)}>
                    <i className="fa fa-list"></i>
                </button>
                <Link title="Edit Assignment" className="btn btn-outline-primary ml-2" to={`/edit-store-table/${row.value}`}>
                    <i className="fa fa-edit"></i>
                </Link>
                <div id={`viewStoreTable${row.value}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Tables List</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            {
                                                (tablesList?.length > 0) ?
                                                    <BootstrapTable version='4' data={tablesList} hover >
                                                        <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} isKey dataField='value' columnTitle>Tables</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' dataFormat={LinkFormatter} formatExtraData={this.state} export={false}>QR Code</TableHeaderColumn>
                                                    </BootstrapTable> 
                                                :
                                                <p className="text-center">No table assigned to this store</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}



class StoreTable extends Component<{}, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.storesList()
        document.title = "SimplexCMS | Assignment"
    }
    jwtVerify = async () => {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
    }
    render() {
        this.jwtVerify();
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            // insertBtn: () => { return <CreateCustomInsertButton route={"/add-assignment"} /> },
            noDataText: 'Assignment Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Store Table Assignment</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable version='4' data={this.props.stores} search={true} pagination={this.props.stores.length > 10 && true} options={options} exportCSV={true} csvFileName='StoreTables.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='value' csvHeader='#' width='100' dataSort={true} isKey>ID</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='label' csvHeader='Store Name' width='100' columnTitle>Store Name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='200' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        stores: state.menu.storesoptions,
        storeTableList: state.dineIn.storeTableList
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        storesList: () => {
            dispatch(storesListForMultiSelect())
        },
        getStoreTable: (id: any) => {
            dispatch(storeTableList(id))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(StoreTable);