import { ACTIVE_EMP_COUPONS, EMP_LIST, GET_EMP,EMP_TYPE_LIST } from '../actions/empTypes'
const initialState: any = {
    employees: [] || "",
    empData: {},
    empCoupons: [] || "",
    empTypes: [] || ""

}

const empReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case EMP_LIST: return {
            ...state,
            employees: action.payload
        }
        case GET_EMP: return {
            ...state,
            empData: action.payload
        }
        case ACTIVE_EMP_COUPONS: return {
            ...state,
            empCoupons: action.payload
        }
        case EMP_TYPE_LIST: return {
            ...state,
            empTypes: action.payload
        }
        default: return state;
    }
}
export default empReducer;