import { COUPONS_LIST, PAGINATED_COUPONS_LIST, SEARCH_COUPONS_LIST, GET_COUPON } from './couponType'
import Api from '../../components/Api';
import { saveLogs } from './storeAction';
import jwt from 'jsonwebtoken'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
export const couponsList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/coupons', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: COUPONS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getCoupon = (id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/coupon/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_COUPON,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addCoupon = (data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        data.user_info = jwt.decode(token);
        Api.post('/admin/add_coupon', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/marketing/0";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editCoupon = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        data.user_info = jwt.decode(token);
        Api.put(`/admin/edit_coupon/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/marketing/0";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const deleteCoupon = (id: number, history: any, coupon_code: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        let data = {
            coupon_code: coupon_code,
            user_info: jwt.decode(token)
        }
        Api.post(`/admin/del_coupon/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    history.push("/marketing/0")
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const activeInactiveCoupon = (id: any, isArchive: any, history: any, coupon_code: any) => {
    return function (dispatch: any) {
        let is_archive: any;
        if (isArchive === 0) {
            is_archive = 1    //is_archive =1 it's mean that coupon is inactive
        } else if (isArchive === 1) {
            is_archive = 0      //is_archive =0 it's mean that coupon is active
        }
        let token: any = localStorage.getItem('token');
        let data = {
            is_archive: is_archive,
            coupon_code: coupon_code,
            user_info: jwt.decode(token)
        }
        Api.put(`/admin/coupon_active_inactive/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    history.push("/marketing/0")
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const paginatedCouponsList = (page: any, updateLoader: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');

        Api.get(`/admin/paginated-coupons/${page}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    if (updateLoader) {
                        updateLoader()
                        const options: ScrollToOptions = {
                            top: 300,
                            left: 0,
                            behavior: 'smooth' // or 'auto', 'instant', or omit the property
                        };
                        window.scrollTo(options);
                    }
                    dispatch({
                        type: COUPONS_LIST,
                        payload: response.data.successResponse,
                        currentPage: response.data.currentPage,
                        pageCount: response.data.pageCount,
                        numOfRows: response.data.numOfRows,
                    })
                }
            })
            .catch(err => {
                if (err.response) {
                    if (updateLoader) {
                        updateLoader()
                    }
                    dispatch({
                        type: COUPONS_LIST,
                        payload: [],
                        currentPage: 0,
                        pageCount: [],
                        numOfRows: [],
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}

export const searchCouponsList = (value: any, page: any, updateLoader: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        let obj = {
            search_value: value
        }
        Api.post(`/admin/search_coupons/${page}`, obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    if (updateLoader) {
                        updateLoader()
                        const options: ScrollToOptions = {
                            top: 300,
                            left: 0,
                            behavior: 'smooth' // or 'auto', 'instant', or omit the property
                        };
                        window.scrollTo(options);
                    }
                    dispatch({
                        type: SEARCH_COUPONS_LIST,
                        payload: response.data.successResponse,
                        currentPage: response.data.currentPage,
                        pageCount: response.data.pageCount,
                        numOfRows: response.data.numOfRows,
                    })
                }
            })
            .catch(err => {
                if (err.response) {
                    if (updateLoader) {
                        updateLoader()
                    }
                    dispatch({
                        type: SEARCH_COUPONS_LIST,
                        payload: [],
                        currentPage: 0,
                        pageCount: 0,
                        numOfRows: 0
                    })
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}