import React, { Component } from 'react';
import { AddStoreProps, AddStoreState } from '../../interfaces/store'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { addStore, brandsList, channelList, storeTypeList, areasList, countryList, statesList, addStates, storesList, cityList, activeKmlZoneList, activeZoneList } from '../../redux'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import Map from './map';
import CheckChanges from '../../components/confirmOnLeave'
import { Link, Redirect } from 'react-router-dom';
import { businessTypeList } from '../../redux/actions/settingsAction';
import Select from 'react-select';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class AddStore extends Component<AddStoreProps, AddStoreState> {
    salesChannelDropDown: any;
    constructor(props: any) {
        super(props);
        this.state = {
            storename: "",
            storeemail: "",
            pulse_ip: "",
            escalationemail: "",
            contact1: "",
            contact2: "",
            rgmContact: "",
            posno: "",
            brand: "",
            saleschannel: "",
            storetype: "",
            area: "",
            state: "",
            state_id: "",
            city_id: "",
            city: "",
            country: "",
            country_code: "",
            country_id: "",
            address: "",
            storeopen: "",
            storeclose: "",
            ntn: "",
            expected_delivery_time: 0,
            kml: "",
            lat: 0,
            lng: 0,
            newlat: 0,
            newlng: 0,
            radius: 50,
            businessType: "",
            franchise_name: "",
            timeByDays: false,
            specialDaysTiming: false,
            cloudKitchen: "Disabled",
            branchCode: "",
            fp_branch_code: "",
            agg_restaurantId:"",
            aloha_branch_code: "",
            backup_trade_zone_id: "",
            trade_zone_id: "",
            backupStoreEnable: false,
            backupStore: [
                {
                    storeId: "",
                    backUpStoreId: "",
                    priority: ""
                }
            ],
            tradeAreas: [
                {
                    tradeArea: "",
                    tradeZone: "",
                    expected_delivery_time: 0,
                    backup_store_id: ""
                }
            ],
            mondayTime: {
                day: 'Monday',
                opening: '',
                closing: ''
            },
            tuesdayTime: {
                day: 'Tuesday',
                opening: '',
                closing: ''
            },
            wednesdayTime: {
                day: 'Wednesday',
                opening: '',
                closing: ''
            },
            thursdayTime: {
                day: 'Thursday',
                opening: '',
                closing: ''
            },
            fridayTime: {
                day: 'Friday',
                opening: '',
                closing: ''
            },
            saturdayTime: {
                day: 'Saturday',
                opening: '',
                closing: ''
            },
            sundayTime: {
                day: 'Sunday',
                opening: '',
                closing: ''
            },
            //Special Days
            specialMondayTime: {
                day: 'Monday',
                opening: '',
                closing: ''
            },
            specialTuesdayTime: {
                day: 'Tuesday',
                opening: '',
                closing: ''
            },
            specialWednesdayTime: {
                day: 'Wednesday',
                opening: '',
                closing: ''
            },
            specialThursdayTime: {
                day: 'Thursday',
                opening: '',
                closing: ''
            },
            specialFridayTime: {
                day: 'Friday',
                opening: '',
                closing: ''
            },
            specialSaturdayTime: {
                day: 'Saturday',
                opening: '',
                closing: ''
            },
            specialSundayTime: {
                day: 'Sunday',
                opening: '',
                closing: ''
            }
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        document.title = "Crust Culture | Stores"
        this.props.brandsList();
        this.props.channelsList();
        this.props.storeTypeList();
        this.props.areasList();
        this.props.countryList();
        this.props.statesList();
        this.props.businessTypeList();
        this.props.storesList();
        this.props.activekmlZoneList();
        this.props.activeZoneList();
        this.props.citiesList();
    }

    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    dayOpeningTime = (e: any) => {
        const { name, value } = e.target;
        if (name == "mondayTime") {
            const list: any = this.state.mondayTime;
            list.opening = value;
            this.setState({ mondayTime: list });

        } else if (name == "tuesdayTime") {
            const list: any = this.state.tuesdayTime;
            list.opening = value;
            this.setState({ tuesdayTime: list });

        } else if (name == "wednesdayTime") {
            const list: any = this.state.wednesdayTime;
            list.opening = value;
            this.setState({ wednesdayTime: list });

        } else if (name == "thursdayTime") {
            const list: any = this.state.thursdayTime;
            list.opening = value;
            this.setState({ thursdayTime: list });

        } else if (name == "fridayTime") {
            const list: any = this.state.fridayTime;
            list.opening = value;
            this.setState({ fridayTime: list });

        } else if (name == "saturdayTime") {
            const list: any = this.state.saturdayTime;
            list.opening = value;
            this.setState({ saturdayTime: list });

        } else if (name == "sundayTime") {
            const list: any = this.state.sundayTime;
            list.opening = value;
            this.setState({ sundayTime: list });
        }
    };

    dayClosingTime = (e: any) => {
        const { name, value } = e.target;
        if (name == "mondayTime") {
            const list: any = this.state.mondayTime;
            list.closing = value;
            this.setState({ mondayTime: list });

        } else if (name == "tuesdayTime") {
            const list: any = this.state.tuesdayTime;
            list.closing = value;
            this.setState({ tuesdayTime: list });

        } else if (name == "wednesdayTime") {
            const list: any = this.state.wednesdayTime;
            list.closing = value;
            this.setState({ wednesdayTime: list });

        } else if (name == "thursdayTime") {
            const list: any = this.state.thursdayTime;
            list.closing = value;
            this.setState({ thursdayTime: list });

        } else if (name == "fridayTime") {
            const list: any = this.state.fridayTime;
            list.closing = value;
            this.setState({ fridayTime: list });

        } else if (name == "saturdayTime") {
            const list: any = this.state.saturdayTime;
            list.closing = value;
            this.setState({ saturdayTime: list });

        } else if (name == "sundayTime") {
            const list: any = this.state.sundayTime;
            list.closing = value;
            this.setState({ sundayTime: list });
        }
    };

    specialDayOpeningTime = (e: any) => {
        const { name, value } = e.target;
        if (name == "specialmondayTime") {
            const list: any = this.state.specialMondayTime;
            list.opening = value;
            this.setState({ specialMondayTime: list });

        } else if (name == "specialtuesdayTime") {
            const list: any = this.state.specialTuesdayTime;
            list.opening = value;
            this.setState({ specialTuesdayTime: list });

        } else if (name == "specialwednesdayTime") {
            const list: any = this.state.specialWednesdayTime;
            list.opening = value;
            this.setState({ specialWednesdayTime: list });

        } else if (name == "specialthursdayTime") {
            const list: any = this.state.specialThursdayTime;
            list.opening = value;
            this.setState({ specialThursdayTime: list });

        } else if (name == "specialfridayTime") {
            const list: any = this.state.specialFridayTime;
            list.opening = value;
            this.setState({ specialFridayTime: list });

        } else if (name == "specialsaturdayTime") {
            const list: any = this.state.specialSaturdayTime;
            list.opening = value;
            this.setState({ specialSaturdayTime: list });

        } else if (name == "specialsundayTime") {
            const list: any = this.state.specialSundayTime;
            list.opening = value;
            this.setState({ specialSundayTime: list });
        }
    };

    specialDayClosingTime = (e: any) => {
        const { name, value } = e.target;
        if (name == "specialmondayTime") {
            const list: any = this.state.specialMondayTime;
            list.closing = value;
            this.setState({ specialMondayTime: list });

        } else if (name == "specialtuesdayTime") {
            const list: any = this.state.specialTuesdayTime;
            list.closing = value;
            this.setState({ specialTuesdayTime: list });

        } else if (name == "specialwednesdayTime") {
            const list: any = this.state.specialWednesdayTime;
            list.closing = value;
            this.setState({ specialWednesdayTime: list });

        } else if (name == "specialthursdayTime") {
            const list: any = this.state.specialThursdayTime;
            list.closing = value;
            this.setState({ specialThursdayTime: list });

        } else if (name == "specialfridayTime") {
            const list: any = this.state.specialFridayTime;
            list.closing = value;
            this.setState({ specialFridayTime: list });

        } else if (name == "specialsaturdayTime") {
            const list: any = this.state.specialSaturdayTime;
            list.closing = value;
            this.setState({ specialSaturdayTime: list });

        } else if (name == "specialsundayTime") {
            const list: any = this.state.specialSundayTime;
            list.closing = value;
            this.setState({ specialSundayTime: list });
        }
    };
    isStoreReady = () => {
        const { trade_zone_id, pulse_ip, storename, storeemail, city_id, kml, brand, branchCode, fp_branch_code, aloha_branch_code, saleschannel, storetype, storeopen, expected_delivery_time,
            storeclose, cloudKitchen, businessType, timeByDays, mondayTime, tuesdayTime, wednesdayTime, thursdayTime, fridayTime, saturdayTime, sundayTime, state_id, tradeAreas, franchise_name, specialDaysTiming, specialMondayTime, specialTuesdayTime, specialWednesdayTime, specialThursdayTime, specialFridayTime, specialSaturdayTime, specialSundayTime } = this.state

        if (timeByDays) {
            if (specialDaysTiming) {
                return (trade_zone_id !== "" && storename !== "" && city_id !== "" && storeemail !== "" && state_id !== "" && branchCode !== "" && businessType !== "" && (businessType == 1 ? franchise_name !== "" : true) && kml !== "" && brand !== "" && saleschannel !== "" && storetype !== "" &&
                    cloudKitchen !== "" && mondayTime.opening !== "" && mondayTime.closing !== "" && tuesdayTime.opening !== "" && tuesdayTime.closing !== "" && expected_delivery_time > 0
                    && wednesdayTime.opening !== "" && wednesdayTime.closing !== "" && thursdayTime.opening !== "" && thursdayTime.closing !== ""
                    && fridayTime.opening !== "" && fridayTime.closing !== "" && saturdayTime.opening !== "" && saturdayTime.closing !== ""
                    && sundayTime.opening !== "" && sundayTime.closing !== ""
                    && specialMondayTime.opening !== "" && specialMondayTime.closing !== "" && specialTuesdayTime.opening !== "" && specialTuesdayTime.closing !== ""
                    && specialWednesdayTime.opening !== "" && specialWednesdayTime.closing !== "" && specialThursdayTime.opening !== "" && specialThursdayTime.closing !== ""
                    && specialFridayTime.opening !== "" && specialFridayTime.closing !== "" && specialSaturdayTime.opening !== "" && specialSaturdayTime.closing !== ""
                    && specialSundayTime.opening !== "" && specialSundayTime.closing !== "" && tradeAreas[tradeAreas.length - 1].tradeArea !== "" && tradeAreas[tradeAreas.length - 1].tradeZone !== "");
            } else {
                return (trade_zone_id !== "" && storename !== "" && city_id !== "" && storeemail !== "" && state_id !== "" && branchCode !== "" && businessType !== "" && (businessType == 1 ? franchise_name !== "" : true) && kml !== "" && brand !== "" && saleschannel !== "" && storetype !== "" &&
                    cloudKitchen !== "" && mondayTime.opening !== "" && mondayTime.closing !== "" && tuesdayTime.opening !== "" && tuesdayTime.closing !== "" && expected_delivery_time > 0
                    && wednesdayTime.opening !== "" && wednesdayTime.closing !== "" && thursdayTime.opening !== "" && thursdayTime.closing !== ""
                    && fridayTime.opening !== "" && fridayTime.closing !== "" && saturdayTime.opening !== "" && saturdayTime.closing !== ""
                    && sundayTime.opening !== "" && sundayTime.closing !== "" && tradeAreas[tradeAreas.length - 1].tradeArea !== "" && tradeAreas[tradeAreas.length - 1].tradeZone !== "");
            }
        } else if (specialDaysTiming) {
            return (trade_zone_id !== "" && storename !== "" && city_id !== "" && storeemail !== "" && state_id !== "" && branchCode !== "" && businessType !== "" && (businessType == 1 ? franchise_name !== "" : true) && kml !== "" && brand !== "" && saleschannel !== "" && storetype !== "" &&
                cloudKitchen !== ""
                && specialMondayTime.opening !== "" && specialMondayTime.closing !== "" && specialTuesdayTime.opening !== "" && specialTuesdayTime.closing !== ""
                && specialWednesdayTime.opening !== "" && specialWednesdayTime.closing !== "" && specialThursdayTime.opening !== "" && specialThursdayTime.closing !== ""
                && specialFridayTime.opening !== "" && specialFridayTime.closing !== "" && specialSaturdayTime.opening !== "" && specialSaturdayTime.closing !== ""
                && specialSundayTime.opening !== "" && specialSundayTime.closing !== "" && tradeAreas[tradeAreas.length - 1].tradeArea !== "" && tradeAreas[tradeAreas.length - 1].tradeZone !== "");
        } else {
            return (trade_zone_id !== "" && storename !== "" && city_id !== "" && businessType !== "" && (businessType == 1 ? franchise_name !== "" : true) && state_id !== "" && branchCode !== "" && expected_delivery_time > 0 && storeemail !== "" && kml !== "" && brand !== "" && saleschannel !== "" && storetype !== "" && storeopen !== "" && storeclose !== "" && cloudKitchen !== "" && tradeAreas[tradeAreas.length - 1].tradeArea !== "" && tradeAreas[tradeAreas.length - 1].tradeZone !== "");
        }

    }
    handleMapData = (obj: any) => {    //to Get the values from the map component and set in state
        this.setState({
            address: obj.address,
            // state: obj.state,
            city: obj.city,
            country: obj.country,
            country_code: obj.country_code,
            lat: obj.lat,
            lng: obj.lng
        })
        let { countries, states } = this.props;
        let stateExist = false;
        // if (obj.state !== this.state.state) {
        countries.forEach((country) => {
            if (country.country_code === obj.country_code) {
                for (let i = 0; i < states.length; i++) {
                    if (country.country_id == states[i].country_id) {
                        if (country.country_name == states[i].state_name) {
                            stateExist = true;
                            this.setState({ country_id: country.country_id })
                            break;
                        } else if (obj.state == states[i].state_name) {
                            stateExist = true;
                            this.setState({ country_id: country.country_id })
                            break;
                        }
                    }
                }
                // if (stateExist === false) {
                //     this.setState({ state_id: "" })
                //     let stateObj = {
                //         state_name: obj.state,
                //         country_id: country.country_id,
                //         tax_percent: 0
                //     }
                //     this.props.addStates(stateObj, "store");
                // }
            }
        })
        // }
    }
    handleRadius = (radius: any) => {    //to Get the Radius from the map component and set in state
        this.setState({
            radius: radius
        })
    }
    checkAreaNameDuplication = () => {
        const { tradeAreas } = this.state;
        var result: any = [];
        // To Remove all Duplicate objects
        var array: any = tradeAreas,
            seen = Object.create(null),
            result = array.filter((o: any) => {
                var key = ['tradeZone', 'tradeArea'].map(k => o[k]).join('|');
                if (!seen[key]) {
                    seen[key] = true;
                    return true;
                }
            });
        if (array.length !== result.length) {
            //check original array length after remove duplicates
            return true;
        } else {
            return false;
        }
    }
    handleSaveBtnClick = async (event: any) => {
        let daysTiming: any = [];
        let special_daysTiming: any = [];
        let { trade_zone_id,backupStoreEnable, backup_trade_zone_id, storename, pulse_ip, storeemail, escalationemail, ntn, contact1, contact2, rgmContact, posno, brand, saleschannel, storetype, storeopen, storeclose, state_id, city, city_id,
            expected_delivery_time, address, lat, lng, kml, cloudKitchen, businessType, timeByDays, backupStore, mondayTime, tuesdayTime, wednesdayTime, thursdayTime,
            fridayTime, saturdayTime, sundayTime, branchCode, fp_branch_code, agg_restaurantId, country_id, tradeAreas, franchise_name, specialDaysTiming, specialFridayTime, specialMondayTime, specialSaturdayTime, specialSundayTime, specialThursdayTime, specialTuesdayTime, specialWednesdayTime } = this.state;
        let { countries } = this.props;
        let DuplicationFlag: any = await this.checkAreaNameDuplication();
        if (DuplicationFlag) {
            toast.error("Duplicate areas found for one tradezone", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
        } else {
            let data: any = {
                store_name: storename,
                store_email: storeemail,
                pulse_ip: pulse_ip,
                escalation_email: escalationemail,
                contact1: contact1,
                contact2: contact2,
                contact3: rgmContact,
                trade_zone_id: trade_zone_id,
                branch_code: branchCode,
                pos_no: posno,
                brand_id: brand,
                expected_delivery_time: expected_delivery_time,
                ntn_number: ntn,
                city: city,
                sales_channel_id: saleschannel,
                store_type_id: storetype,
                address: address,
                lat: lat,
                lng: lng,
                business_type_id: businessType,
                cloudKitchen: cloudKitchen,
                backupStore: JSON.stringify(backupStore),
                tradeAreas: JSON.stringify(tradeAreas),
                is_active: 1
            }
            if (backup_trade_zone_id && backup_trade_zone_id!=="") {
                data.backup_zone_id = backup_trade_zone_id;
            }
            if (backupStoreEnable) {
                data.is_backup_store = 1;
            } else {
                data.is_backup_store = 0;
            }
            if (timeByDays) {
                daysTiming.push(mondayTime);
                daysTiming.push(tuesdayTime);
                daysTiming.push(wednesdayTime);
                daysTiming.push(thursdayTime);
                daysTiming.push(fridayTime);
                daysTiming.push(saturdayTime);
                daysTiming.push(sundayTime);
                data.daysTiming = JSON.stringify(daysTiming)
            }

            if (timeByDays === true) {
                data.timeByDays = 1;
            } else {
                data.timeByDays = 0;
            }
            if (country_id !== "") {
                data.country_id = country_id;
            }
            if (state_id !== "") {
                data.state_id = state_id;
            }
            if (city_id !== "") {
                data.city_id = city_id;
            }
            if (fp_branch_code !== "") {
                data.fp_branch_code = fp_branch_code;
            }
            if (agg_restaurantId !== "") {
                data.fpRestaurantId = agg_restaurantId;
            }
            if (businessType == 1) {
                data.franchise_name = franchise_name;
            }
            if (specialDaysTiming === true) {
                data.specialDaysTiming = 1;
            } else {
                data.specialDaysTiming = 0;
            }
            if (specialDaysTiming) {
                special_daysTiming.push(specialMondayTime);
                special_daysTiming.push(specialTuesdayTime);
                special_daysTiming.push(specialWednesdayTime);
                special_daysTiming.push(specialThursdayTime);
                special_daysTiming.push(specialFridayTime);
                special_daysTiming.push(specialSaturdayTime);
                special_daysTiming.push(specialSundayTime);
                data.special_daysTiming = JSON.stringify(special_daysTiming)
            }
            if (!timeByDays && !specialDaysTiming) {
                data.store_open_time = storeopen;
                data.store_close_time = storeclose;
                data.daysTiming = '';
                data.special_daysTiming = '';
            }
            this.props.addStore(data);
        }
    }
    handleTimeByDays = (event: { target: { name: any; value: any; }; }) => {
        const { timeByDays, storeclose, storeopen, } = this.state;
        this.setState({ timeByDays: !timeByDays })
        if (storeclose && storeclose !== "") {
            const mondaytimeObj = { 'day': 'Monday', 'opening': storeopen, 'closing': storeclose };
            this.setState({ mondayTime: mondaytimeObj })
            const tuesdaytimeObj = { 'day': 'Tuesday', 'opening': storeopen, 'closing': storeclose };
            this.setState({ tuesdayTime: tuesdaytimeObj })
            const wedtimeObj = { 'day': 'Wednesday', 'opening': storeopen, 'closing': storeclose };
            this.setState({ wednesdayTime: wedtimeObj })
            const thursdaytimeObj = { 'day': 'Thursday', 'opening': storeopen, 'closing': storeclose };
            this.setState({ thursdayTime: thursdaytimeObj })
            const fridaytimeObj = { 'day': 'Friday', 'opening': storeopen, 'closing': storeclose };
            this.setState({ fridayTime: fridaytimeObj })
            const sattimeObj = { 'day': 'Saturday', 'opening': storeopen, 'closing': storeclose };
            this.setState({ saturdayTime: sattimeObj })
            const sundaytimeObj = { 'day': 'Sunday', 'opening': storeopen, 'closing': storeclose };
            this.setState({ sundayTime: sundaytimeObj })
        }
    }
    handleSpecialDayTimings = (event: { target: { name: any; value: any; }; }) => {
        const { specialDaysTiming, storeclose, storeopen } = this.state;
        this.setState({ specialDaysTiming: !specialDaysTiming })
        const mondaytimeObj = { 'day': 'Monday', 'opening': storeopen, 'closing': storeclose };
        this.setState({ specialMondayTime: mondaytimeObj })
        const tuesdaytimeObj = { 'day': 'Tuesday', 'opening': storeopen, 'closing': storeclose };
        this.setState({ specialTuesdayTime: tuesdaytimeObj })
        const wedtimeObj = { 'day': 'Wednesday', 'opening': storeopen, 'closing': storeclose };
        this.setState({ specialWednesdayTime: wedtimeObj })
        const thursdaytimeObj = { 'day': 'Thursday', 'opening': storeopen, 'closing': storeclose };
        this.setState({ specialThursdayTime: thursdaytimeObj })
        const fridaytimeObj = { 'day': 'Friday', 'opening': storeopen, 'closing': storeclose };
        this.setState({ specialFridayTime: fridaytimeObj })
        const sattimeObj = { 'day': 'Saturday', 'opening': storeopen, 'closing': storeclose };
        this.setState({ specialSaturdayTime: sattimeObj })
        const sundaytimeObj = { 'day': 'Sunday', 'opening': storeopen, 'closing': storeclose };
        this.setState({ specialSundayTime: sundaytimeObj })
    }
    handleBackupStoreInputChange = (e: any, index: any) => {
        if (e && e.target) {
            const { name, value } = e.target;
            const list: any = this.state.backupStore;
            list[index][name] = value;
            this.setState({ backupStore: list });
        } else {
            const list: any = this.state.backupStore;
            if (e && e.value > 0) {
                list[index]["backUpStoreId"] = e.value;
            } else {
                list[index]["backUpStoreId"] = "";
            }
            this.setState({ backupStore: list });
        }
    };
    handleTradeAreaInputChange = (e: any, index: any, type?: any) => {
        if (e && e.target) {
            const { name, value } = e.target;
            const list: any = this.state.tradeAreas;
            list[index][name] = value;
            this.setState({ tradeAreas: list });
        } else {
            const list: any = this.state.tradeAreas;
            if (type == "backup") {
                if (e && e.value > 0) {
                    list[index]["backup_store_id"] = e.value;
                } else {
                    list[index]["backup_store_id"] = "";
                }
            } else if (type == "tradezone") {
                if (e && e.value > 0) {
                    list[index]["tradeZone"] = e.value;
                } else {
                    list[index]["tradeZone"] = "";
                }
            }
            this.setState({ tradeAreas: list });
        }
    };

    handleAddBackupStore = (i: any) => {
        const list: any = this.state.backupStore;
        if (list[i]["backUpStoreId"] == "" || list[i]["priority"] == "") {
            alert("Please fill in current item")
        } else if (i == '1') {
            alert("Sorry you can select only 2 Backup Stores")
        } else {
            const newlist: any = { backUpStoreId: "", priority: "", storeId: "" }
            const backupStr: any = this.state.backupStore.concat(newlist)
            this.setState({ backupStore: backupStr });
        }
    };

    handleAddTradeArea = (i: any) => {
        const list: any = this.state.tradeAreas;
        if (list[i]["tradeZone"] == "" || list[i]["tradeArea"] == "") {
            alert("Please fill in current item")
        } else {
            const newlist: any = { tradeZone: "", tradeArea: "", expected_delivery_time: 0, backup_store_id: "" }
            const tradeArea: any = this.state.tradeAreas.concat(newlist)
            this.setState({ tradeAreas: tradeArea });
        }
    };
    handleRemoveBackupStore = (index: any) => {
        const list = this.state.backupStore;
        list.splice(index, 1);
        this.setState({ backupStore: list });
    };
    handleRemoveTradeArea = (index: any) => {
        const list = this.state.tradeAreas;
        list.splice(index, 1);
        this.setState({ tradeAreas: list });
    };

    handleBrands = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ brand: e.value });
        } else {
            this.setState({ brand: "" });
        }
    };
    handleBusinessType = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ businessType: e.value });
        } else {
            this.setState({ businessType: "" });
        }
    };
    handleCloudKitchen = (e: any, index: any) => {
        if (e && e.value !== "") {
            this.setState({ cloudKitchen: e.value });
        } else {
            this.setState({ cloudKitchen: "" });
        }
        this.salesChannelDropDown.select.clearValue();
    };
    handleSalesChannel = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ saleschannel: e.value });
        } else {
            this.setState({ saleschannel: "" });
        }
    };
    handleStoreTypes = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ storetype: e.value });
        } else {
            this.setState({ storetype: "" });
        }
    };
    handleStates = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ state_id: e.value });
        } else {
            this.setState({ state_id: "" });
        }
    };
    handleCity = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ city_id: e.value });
        } else {
            this.setState({ city_id: "" });
        }
    };
    handleTradeZones = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ trade_zone_id: e.value, kml: e.trade_zone_shape });
        } else {
            this.setState({ trade_zone_id: "", kml: "" });
        }
    };
    blockInvalidNumberData = (e: any) => {
        var regex = new RegExp("[0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    handleBackupTradeZones = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ backup_trade_zone_id: e.value });
        } else {
            this.setState({ backup_trade_zone_id: "" });
        }
    };
    handleCheckBoxes = (event: { target: { name: any; value: any; }; }) => {
        const { backupStoreEnable } = this.state;
        if (event.target.name == "backupStoreEnable") {
            this.setState({ backupStoreEnable: !backupStoreEnable })
            let newlist: any = [{ backUpStoreId: "", priority: "", storeId: "" }]
            let upd_obj:any = this.state.tradeAreas.map((obj: any) => {
                obj.backup_store_id = '';
                return obj;
            })
            this.setState({ backupStore: newlist, tradeAreas: upd_obj });
        }
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const { brands, channels, types, businessType, stores, cities, states, activeKmlZone, activeZones } = this.props;
        const { timeByDays, cloudKitchen, backupStore, tradeAreas, franchise_name, backupStoreEnable, specialDaysTiming, specialMondayTime, specialTuesdayTime, specialWednesdayTime, specialThursdayTime, specialFridayTime, specialSaturdayTime, specialSundayTime, storeclose, storeopen } = this.state;
        // Sales Channel data
        let salesChannelData;
        (cloudKitchen == 'Enabled') ?
            salesChannelData = channels.filter(function (el: any) {
                return el.sales_channel_id == 1
            }) :
            salesChannelData = channels;
        // Store Type data
        return (
            <div className="page">
                <CheckChanges path="/add-store" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Stores Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/stores" className="text-primary">Stores</Link></li>
                                <li className="breadcrumb-item active">Add Store</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Store Name <span className="text-danger">*</span></label>
                                                            <input id="storename" type="text" name="storename" required data-msg="Please enter Store Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="brand"
                                                                isClearable
                                                                options={brands}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleBrands(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div {...(this.state.businessType == "1" ? { className: "col-md-6" } : { className: "col-12" })}>
                                                        <div className="form-group">
                                                            <label className="form-control-label">Business Type <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="businessType"
                                                                isClearable
                                                                options={businessType}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleBusinessType(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {this.state.businessType == "1" &&
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Franchise Name <span className="text-danger">*</span></label>
                                                                <input id="franchisename" type="text" name="franchise_name" defaultValue={franchise_name} data-msg="Please enter franchise name" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">POS Branch Code<span className="text-danger">*</span></label>
                                                            <input id="branchCode" type="text" name="branchCode" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">POS IP <span className="text-danger">*</span></label>
                                                            <input id="pulse_ip" type="text" name="pulse_ip" required data-msg="Please enter ip address" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Aggregator Branch Code</label>
                                                            <input id="fp_branch_code" type="text" name="fp_branch_code" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">POS Branch Code <span className="text-danger">*</span></label>
                                                            <input id="aloha_branch_code" type="text" name="aloha_branch_code" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Aggregator Restaurant ID</label>
                                                            <input id="agg_restaurantId" type="text" name="agg_restaurantId" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Email Contact <span className="text-danger">*</span></label>
                                                            <input id="storeemail" type="email" name="storeemail" required data-msg="Please enter Store Email" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Email Escalation</label>
                                                            <input id="escalationemail" type="email" name="escalationemail" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Contact 1</label>
                                                            <input id="contact1" type="text" onKeyDown={this.blockInvalidNumberData} name="contact1" required data-msg="Please enter 1st Contact" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Contact 2</label>
                                                            <input id="contact2" type="text" onKeyDown={this.blockInvalidNumberData} name="contact2" required data-msg="Please enter 2nd Contact" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">RGM Contact</label>
                                                            <input id="rgmContact" type="text" onKeyDown={this.blockInvalidNumberData} name="rgmContact" required data-msg="Please enter RGM Contact" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {/* <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Cloud Kitchen <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="cloudKitchen"
                                                                isClearable
                                                                options={[{ label: 'Disabled', value: 'Disabled' }, { label: 'Enabled', value: 'Enabled' }]}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                defaultValue={{ label: "Disabled", value: 'Disabled' }}
                                                                onChange={(e, i) => this.handleCloudKitchen(e, i)}
                                                            />
                                                        </div>
                                                    </div> */}
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Sale Channel <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="saleschannle"
                                                                isClearable
                                                                ref={el => this.salesChannelDropDown = el}
                                                                options={salesChannelData}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleSalesChannel(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Pos no</label>
                                                            <input id="posno" type="text" name="posno" required data-msg="Please enter POS no" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">NTN Number</label>
                                                            <input id="ntn" type="text" name="ntn" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Store Type <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="storetype"
                                                                isClearable
                                                                options={types}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleStoreTypes(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Expected Time <span className="text-danger">* (In minutes)</span></label>
                                                            <input id="expected_delivery_time" type="number" onWheel={(e: any) => e.target.blur()} name="expected_delivery_time" required data-msg="Please enter expected time" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">States <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="state_id"
                                                                isClearable
                                                                options={states}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleStates(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ width: '100%' }}>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label htmlFor="">Latitude</label>
                                                                <input type="text" name="newlat" className="form-control" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label htmlFor="">Longitude</label>
                                                                <input type="text" name="newlng" className="form-control" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">City <span className="text-danger">*</span></label>
                                                                <Select
                                                                    name="city_id"
                                                                    isClearable
                                                                    options={cities}
                                                                    className="text-capitalize select"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleCity(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Map
                                                        data=""
                                                        center={{ lat: this.state.newlat, lng: this.state.newlng }}
                                                        radius={parseInt(this.state.radius)}
                                                        kml={this.state.kml}
                                                        onSetRadius={this.handleRadius}
                                                        onSetData={this.handleMapData}
                                                        google={this.props.google}
                                                        height='400px'
                                                        zoom={13}
                                                    />
                                                </div>
                                                <div className='row'>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">KML Zones <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="tradeZone"
                                                                isClearable
                                                                options={activeKmlZone}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleTradeZones(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">KML Backup Zone</label>
                                                            <Select
                                                                name="tradeZone"
                                                                isClearable
                                                                options={activeKmlZone}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleBackupTradeZones(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {(!timeByDays && !specialDaysTiming) &&
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                <input id="storeopen" type="time" name="storeopen" required data-msg="Please enter Opening Time" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                <input id="storeclose" type="time" name="storeclose" required className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col my-2">
                                                        <div>
                                                            <input id="checkboxCustom1" type="checkbox" name="timeByDays" checked={timeByDays} onChange={this.handleTimeByDays} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom1">Add Time by Week Days</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {timeByDays &&
                                                    <div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Monday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="mondayTime" type="time" name="mondayTime" defaultValue={storeopen && storeopen} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="mondayTime" type="time" name="mondayTime" defaultValue={storeclose && storeclose} required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Tuesday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="tuesdayTime" type="time" name="tuesdayTime" defaultValue={storeopen && storeopen} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="tuesdayTime" type="time" name="tuesdayTime" defaultValue={storeclose && storeclose} required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Wednesday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="wednesdayTime" type="time" name="wednesdayTime" defaultValue={storeopen && storeopen} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="wednesdayTime" type="time" name="wednesdayTime" defaultValue={storeclose && storeclose} required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Thursday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="thursdayTime" type="time" name="thursdayTime" defaultValue={storeopen && storeopen} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="thursdayTime" type="time" name="thursdayTime" defaultValue={storeclose && storeclose} required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Friday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="fridayTime" type="time" name="fridayTime" defaultValue={storeopen && storeopen} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="fridayTime" type="time" name="fridayTime" defaultValue={storeclose && storeclose} required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Saturday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="saturdayTime" type="time" name="saturdayTime" defaultValue={storeopen && storeopen} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="saturdayTime" type="time" name="saturdayTime" defaultValue={storeclose && storeclose} required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Sunday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="sundayTime" type="time" name="sundayTime" defaultValue={storeopen && storeopen} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="sundayTime" type="time" name="sundayTime" defaultValue={storeclose && storeclose} required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className='row'>
                                                    <div className="col my-2">
                                                        <div>
                                                            <input id="checkboxCustom2" type="checkbox" name="specialDaysTiming" checked={specialDaysTiming == true && true} onChange={this.handleSpecialDayTimings} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom2">Add Time for Special Days</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {specialDaysTiming &&
                                                    <div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Monday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="specialmondayTime" type="time" name="specialmondayTime" defaultValue={storeopen && storeopen} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.specialDayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="specialmondayTime" type="time" name="specialmondayTime" defaultValue={storeclose && storeclose} required className="input-material" onChange={(e) => this.specialDayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Tuesday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="specialtuesdayTime" type="time" name="specialtuesdayTime" defaultValue={storeopen && storeopen} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.specialDayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="specialtuesdayTime" type="time" name="specialtuesdayTime" defaultValue={storeclose && storeclose} required className="input-material" onChange={(e) => this.specialDayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Wednesday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="specialwednesdayTime" type="time" name="specialwednesdayTime" defaultValue={storeopen && storeopen} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.specialDayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="specialwednesdayTime" type="time" name="specialwednesdayTime" defaultValue={storeclose && storeclose} required className="input-material" onChange={(e) => this.specialDayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Thursday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="specialthursdayTime" type="time" name="specialthursdayTime" defaultValue={storeopen && storeopen} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.specialDayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="specialthursdayTime" type="time" name="specialthursdayTime" defaultValue={storeclose && storeclose} required className="input-material" onChange={(e) => this.specialDayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Friday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="specialfridayTime" type="time" name="specialfridayTime" defaultValue={storeopen && storeopen} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.specialDayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="specialfridayTime" type="time" name="specialfridayTime" defaultValue={storeclose && storeclose} required className="input-material" onChange={(e) => this.specialDayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Saturday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="specialsaturdayTime" type="time" name="specialsaturdayTime" defaultValue={storeopen && storeopen} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.specialDayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="specialsaturdayTime" type="time" name="specialsaturdayTime" defaultValue={storeclose && storeclose} required className="input-material" onChange={(e) => this.specialDayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Sunday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="specialsundayTime" type="time" name="specialsundayTime" defaultValue={storeopen && storeopen} required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.specialDayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="specialsundayTime" type="time" name="specialsundayTime" defaultValue={storeclose && storeclose} required className="input-material" onChange={(e) => this.specialDayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className='col-12 my-2'>
                                                        <div>
                                                            <input id="checkboxCustom3" type="checkbox" name="backupStoreEnable" checked={backupStoreEnable} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom3">Backup Store</label>
                                                        </div>
                                                    </div>
                                                    {!backupStoreEnable &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                {backupStore.map((x: any, i: any) => {
                                                                    return (
                                                                        <div key={i} className="row">
                                                                            <div className="col">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label">Backup Store</label>
                                                                                    <Select
                                                                                        name="backUpStoreId"
                                                                                        isClearable
                                                                                        options={stores.filter(o1 => !backupStore.some((o2: any) => o1.store_id === o2.backUpStoreId))}
                                                                                        className="text-capitalize select mt-2"
                                                                                        classNamePrefix="select"
                                                                                        onChange={e => this.handleBackupStoreInputChange(e, i)} />
                                                                                    {/* <select name="backUpStoreId" className="form-control text-capitalize mt-2" required data-msg="Please select Channel" onChange={e => this.handleBackupStoreInputChange(e, i)}>
                                                                                    <option value="">Backup Store</option>
                                                                                    {stores.length > 0 &&
                                                                                        stores.map((store: any, index: any) => (
                                                                                            <option key={index} value={store.store_id}>{store.store_name}</option>
                                                                                        ))
                                                                                    }
                                                                                </select> */}
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="col">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label">Priority</label>
                                                                                    <input
                                                                                        name="priority"
                                                                                        type="number" onWheel={(e: any) => e.target.blur()}
                                                                                        value={x.priority}
                                                                                        min={0}
                                                                                        disabled={x.backUpStoreId == ""}
                                                                                        onKeyDown={this.blockInvalidChar}
                                                                                        data-msg="Please enter qunatity"
                                                                                        className="input-material"
                                                                                        onChange={e => this.handleBackupStoreInputChange(e, i)}
                                                                                    />
                                                                                </div>
                                                                            </div> */}
                                                                            {/* <div className="col py-4">
                                                                                {backupStore.length !== 1 &&
                                                                                    <button className="btn btn-sm btn-outline-danger"
                                                                                        onClick={() => this.handleRemoveBackupStore(i)}><i className="fa fa-trash"></i></button>}
                                                                                {backupStore.length - 1 === i && <button className="btn btn-sm btn-primary ml-2" onClick={() => this.handleAddBackupStore(i)}><i className="fa fa-plus"></i></button>}
                                                                            </div> */}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <hr />
                                                <div className="row" style={{ paddingTop: '1em' }}>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            {tradeAreas.map((x: any, i: any) => {
                                                                return (
                                                                    <div key={i} className="row">
                                                                        <div className="col">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">CallCenter Zones <span className="text-danger">*</span></label>
                                                                                <Select
                                                                                    name="tradeZone"
                                                                                    isClearable
                                                                                    options={activeZones}
                                                                                    className="text-capitalize select mt-2"
                                                                                    classNamePrefix="select"
                                                                                    onChange={e => this.handleTradeAreaInputChange(e, i, "tradezone")} />
                                                                                {/* <select name="tradeZone" className="form-control mt-2" required data-msg="Please Select TradeZone" onChange={e => this.handleTradeAreaInputChange(e, i)}>
                                                                                    <option value="">Select TradeZone</option>
                                                                                    {tradezones &&
                                                                                        tradezones.map((zone, index) => (
                                                                                            <option key={index} value={zone.id}>{zone.delivery_zone_name} ({zone.city})</option>
                                                                                        ))
                                                                                    }
                                                                                </select> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">TradeArea name <span className="text-danger">*</span></label>
                                                                                <input id="tradeArea" type="text" name="tradeArea" value={x.tradeArea} required data-msg="Please enter TradeAreaName" className="input-material" onChange={e => this.handleTradeAreaInputChange(e, i)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Expected Time <span className="text-danger" style={{ fontSize: 10 }}>(In minutes)</span></label>
                                                                                <input id="expected_delivery_time" type="number" onWheel={(e: any) => e.target.blur()} name="expected_delivery_time" required data-msg="Please enter expected time" className="input-material" onChange={e => this.handleTradeAreaInputChange(e, i)} />
                                                                            </div>
                                                                        </div>
                                                                        {!backupStoreEnable &&
                                                                            <div className="col">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label">Backup Store</label>
                                                                                    <Select
                                                                                        name="backup_store_id"
                                                                                        isClearable
                                                                                        options={stores.filter(o1 => !tradeAreas.some((o2: any) => o1.store_id === o2.backup_store_id))}
                                                                                        className="text-capitalize select mt-2"
                                                                                        classNamePrefix="select"
                                                                                        value={stores.map((element: any) => {
                                                                                            if (element.store_id == x.backup_store_id) {
                                                                                                return { label: element.store_name, value: element.store_id }
                                                                                            }
                                                                                        })}
                                                                                        onChange={e => this.handleTradeAreaInputChange(e, i, "backup")} />
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        <div className="col py-4">
                                                                            {tradeAreas.length !== 1 &&
                                                                                <button className="btn btn-sm btn-outline-danger"
                                                                                    onClick={() => this.handleRemoveTradeArea(i)}><i className="fa fa-trash"></i></button>}
                                                                            {tradeAreas.length - 1 === i && <button className="btn btn-sm btn-primary ml-2" onClick={() => this.handleAddTradeArea(i)}><i className="fa fa-plus"></i></button>}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group float-right mt-3">
                                                    <button onClick={() => { this.props.history.push("/stores") }} className="btn btn-danger mr-2">Cancel</button>
                                                    <button className='btn btn-primary' disabled={!this.isStoreReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        brands: state.store.brands,
        channels: state.store.channels,
        states: state.setting.states,
        types: state.store.types,
        areas: state.store.areas,
        countries: state.store.countries,
        businessType: state.store.businessType,
        stores: state.store.data,
        activeZones: state.tradezone.activeZones,
        activeKmlZone: state.tradezone.activeKmlZone,
        cities: state.city.cities
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        addStore: function (data: any) {
            dispatch(addStore(data));
        },
        addStates: function (data: any, type: any) {
            dispatch(addStates(data, type));
        },
        brandsList: function () {
            dispatch(brandsList())
        },
        channelsList: function () {
            dispatch(channelList())
        },
        storeTypeList: function () {
            dispatch(storeTypeList())
        },
        areasList: function () {
            dispatch(areasList())
        },
        countryList: function () {
            dispatch(countryList())
        },
        statesList: function () {
            dispatch(statesList("store"))
        },
        businessTypeList: function () {
            dispatch(businessTypeList())
        },
        storesList: function () {
            dispatch(storesList())
        },
        activekmlZoneList: () => { dispatch(activeKmlZoneList()) },
        activeZoneList: () => { dispatch(activeZoneList()) },
        citiesList: () => {
            dispatch(cityList())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddStore);