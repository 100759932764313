import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
// import CustomInsertModal from './add-role'
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { connect } from 'react-redux'
import { logoutUser } from '../../../redux'
import { StatesProps } from '../../../interfaces/settings';
import { tablesList } from '../../../redux/actions/dineInAction';

class Tables extends Component<StatesProps, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.tablesList();
        document.title = "Crust Culture | Tables"
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let tax: any = []
        const options: any = {
            sizePerPage: 10, 
            page: 1,
            pageStartIndex: 1,
            paginationSize: 3,
            hideSizePerPage: true,
            insertModal: () => { return <Redirect to="/add-table" /> },
            noDataText: 'Tables Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Tables Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable data={this.props.tables} search={true} pagination={tax.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='table.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='id' csvHeader='#' width='50' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='value' csvHeader='Table' width='100' columnTitle>Table</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section >
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        tables: state.dineIn.tablesList
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        tablesList: function () {
            dispatch(tablesList())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Tables);