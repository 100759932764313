import React, { Component } from 'react';
import { AddComboProps, AddComboState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import Footer from '../../../components/footer/main';
import Sidebar from '../../../components/sidebar';
import Topbar from '../../../components/topbar';
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { addCombo, channelList, logoutUser, groupedItemsList, menusList, groupsList, storesListForMultiSelect } from '../../../redux'
import Select from "react-select";
import { Link, Redirect } from 'react-router-dom';
import CheckChanges from '../../../components/confirmOnLeave'
import moment from 'moment';
import { toast } from "react-toastify";
import { comboChoiceItemsByGroupId, subGroupListByGroupId, combosList, OrderingModes, modGroupsList, variantsList, getBSLimit } from '../../../redux/actions/menuAction';
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
toast.configure();
class AddCombo extends Component<AddComboProps, AddComboState> {
    weeklyDays: { value: string; label: string; }[];
    constructor(props: any) {
        super(props);
        this.weeklyDays = [
            { value: 'MONDAY', label: 'Mon' },
            { value: 'TUESDAY', label: 'Tue' },
            { value: 'WEDNESDAY', label: 'Wed' },
            { value: 'THURSDAY', label: 'Thu' },
            { value: 'FRIDAY', label: 'Fri' },
            { value: 'SATURDAY', label: 'Sat' },
            { value: 'SUNDAY', label: 'Sun' },
        ];
        this.state = {
            menu: "",
            group: "",
            subgroup: "",
            state: "",
            comboname: "",
            erpid: null,
            poscode: null,
            combodesc: "",
            is_featured: false,
            featured_image: null,
            channel: "",
            priority: "",
            combocost: "",
            combosale: "",
            combomrp: "",
            images: null,
            // thumbnail_images:null,
            flag: false,
            hero_image: null,
            hero_mobile_image: null,
            taxstatus: false,
            settime: 'alltime',
            combostart: "",
            comboclose: "",
            errtaxdef: false,
            tax_percent: "",
            hero_item: false,
            topDeal: false,
            is_hide: false,
            is_voucher: false,
            is_foodpanda:false,
            is_blurry: false,
            is_lsm: '0',
            mode: [],
            storesJson: [],
            comboUnique: "",
            metaTitle: "",
            metaDesc: "",
            altTag: "",
            is_suggestive: false,
            specific_days: false,
            daysTiming: [
                {
                    day: "",
                    label: "",
                    timePeriods: [{
                        open: "",
                        close: ""
                    }]
                }
            ],
            comboChoices: [
                {
                    mod_groups: [],
                    crusts: [],
                    flavours: [],
                    topings: [],
                    condiments: [],
                    group_name: "",
                    priority: "",
                    size_pos_code: null,
                    recipe: "",
                    variant_id: "",
                    is_half_n_half: false,
                    if_full_pizza: false,
                }
            ],
            order_modes_price: []
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleItemsByMenuId = this.handleItemsByMenuId.bind(this);
    }
    componentDidMount() {
        this.props.combosList();
        this.props.groupsList();
        this.props.modGroupsList();
        this.props.getBSLimit();
        this.props.menusList();
        this.props.variantsList();
        this.props.channelList();
        this.props.storesList();
        this.props.OrderingModes();
        document.title = "Crust Culture | Combos"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        // let { menu } = this.state;
        if (event.target.name == 'group') {
            this.setState({
                group: event.target.value
            });
            this.props.subGroupListByGroupId(event.target.value)
        } else {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    }
    handleSpecificDaysCheck = (event: { target: { name: any; value: any; }; }) => {
        const { specific_days } = this.state;
        this.setState({ specific_days: !specific_days })
    }
    fileSelectedHandler = (e: any) => {
        // if (e.target.files.length > 0) {
        //     this.setState({ images: e.target.files })
        // }
        if (e.target.files.length > 0) {
            var size = 153600;

            if (e.target.files[0].size > size) {
                toast.error(" Combo image size too large. Upload image less then 150kb", { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: false, autoClose: 3000 })

                this.setState({
                    flag: false
                })
            }
            else {
                this.setState({ images: e.target.files, flag: true })
            }
        }
    }
    // thumbnailFileSelectedHandler = (e: any) => {

    //     if (e.target.files.length > 0) {
    //         var size = 51200;

    //         if (e.target.files[0].size > size) {

    //           toast.error(" Thumbnail image size too large. Upload image less then 50kb", { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: false, autoClose: 3000 })

    //           this.setState({
    //             flag:false
    //           })
    //         }
    //         else{
    //             this.setState({ thumbnail_images: e.target.files,flag:true })
    //         }
    //     }

    // }
    handleTopDeal = (event: { target: { name: any; value: any; }; }) => {
        const { topDeal } = this.state;
        const { combos } = this.props;
        let topDealsCount: any = 0;
        combos.forEach((element: any) => {
            if (element.topDeal == "1") {
                topDealsCount += 1;
            }
        });
        if (topDealsCount == 4) {
            if (topDeal == false) {
                alert("You have already 4 Top Deals!")
            } else {
                this.setState({ topDeal: !topDeal })
            }
        } else {
            this.setState({ topDeal: !topDeal })
        }
    }
    heroItemImageSelectedHandler = (e: any) => {
        if (e.target.files.length > 0) {
            this.setState({ hero_image: e.target.files })
        }
    }
    heroItemMobileImageSelectedHandler = (e: any) => {
        if (e.target.files.length > 0) {
            this.setState({ hero_mobile_image: e.target.files })
        }
    }
    handleItemsByMenuId(event: { target: { name: any; value: any; }; }) {
        let select: any = document.getElementById('menu');
        if (this.state.menu !== "") {
            if (window.confirm("You cannot change the menu. If you clicks OK, then menu items corresponding to present combo will lost.")) {
                this.setState({
                    menu: event.target.value,
                    combocost: "",
                    combomrp: "",
                })
                this.props.groupedItemsList(event.target.value);
            } else {
                select.value = this.state.menu
            }
        } else {
            this.setState({
                "menu": event.target.value,
                // errtaxdef: false,
                // state: stateData ? `${stateData.state_name} , ${stateData.country_name}` : "",
                // tax_percent: stateData ? stateData.tax_percent : ""
            });
            this.props.groupedItemsList(event.target.value);
        }
    }
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    handleFeatureImage = (e:any) => {
        let { files } = e.target
        if (files.length > 0) {

            let size = 1024000;

            if (files[0].size > size) {
                toast.error(" Item image size too large. Upload image less then 1mb", { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: false, autoClose: 3000 })
            }
            else {
                this.setState({ featured_image: files[0] });
            }
        }
    }
    handleCheckBoxes = (event: { target: { name: any; value: any; }; }) => {
        const { hero_item, is_featured, topDeal, is_hide, is_voucher, is_foodpanda, is_blurry, is_suggestive } = this.state;
        const { combos } = this.props;
        if (event.target.name == "hero_item") {
            this.setState({ hero_item: !hero_item })
        } else if (event.target.name == "topDeal") {
            let topDealsCount: any = 0;
            combos.forEach((element: any) => {
                if (element.topDeal == "1") {
                    topDealsCount += 1;
                }
            });
            if (topDealsCount == 4) {
                if (topDeal == false) {
                    alert("You have already 4 Top Deals!")
                } else {
                    this.setState({ topDeal: !topDeal })
                }
            } else {
                this.setState({ topDeal: !topDeal })
            }
        } else if (event.target.name == "is_featured") {
            this.setState({ is_featured: !is_featured })
        } else if (event.target.name == "is_hide") {
            this.setState({ is_hide: !is_hide })
        } else if (event.target.name == "is_voucher") {
            this.setState({ is_voucher: !is_voucher })
        } else if (event.target.name == "is_blurry") {
            this.setState({ is_blurry: !is_blurry })
        } else if (event.target.name == "is_suggestive") {
            this.setState({ is_suggestive: !is_suggestive })
        }
        else if (event.target.name == "is_foodpanda") {
            this.setState({ is_foodpanda: !is_foodpanda })
        }
    }
    checkMRPForAllModes = () => {
        let { order_modes_price } = this.state;
        var filtered = order_modes_price && order_modes_price.filter(function (el) {
            // return (el.mrp == 0 || el.mrp == "");  //With 0 check
            return (el.mrp == "");
        });
        if (filtered.length > 0) {
            return false;
        } else {
            return true
        }
    }
    isComboReady = () => {
        const { comboname, menu, combocost, group, subgroup, comboUnique, mode, hero_item, images, hero_image, combomrp, settime, combostart, comboclose, is_lsm, storesJson ,priority} = this.state;
        if (settime == 'customtime') {
            if (hero_item == false) {
                if (is_lsm == "0") {
                    return (comboname !== "" && priority!=="" && menu !== "" && images !== null && group !== "" && subgroup !== "" && combocost !== "" && combostart !== "" && comboclose !== "" && mode.length > 0 && this.checkMRPForAllModes());
                } else if (is_lsm == "1") {
                    return (comboname !== "" && priority!=="" && menu !== "" && images !== null && group !== "" && subgroup !== "" && combocost !== "" && combostart !== "" && comboclose !== "" && storesJson.length > 0 && mode.length > 0 && this.checkMRPForAllModes());
                }
                else if (is_lsm == "2") {
                    return (comboname !== "" && priority!=="" && menu !== "" && images !== null && group !== "" && subgroup !== "" && combocost !== "" && combostart !== "" && comboclose !== "" && storesJson.length > 0 && mode.length > 0 && this.checkMRPForAllModes());
                }
            } else {
                if (is_lsm == "0") {
                    return (comboname !== "" && priority!=="" && menu !== "" && images !== null && hero_image !== null && group !== "" && subgroup !== "" && combocost !== "" && combostart !== "" && comboclose !== "" && mode.length > 0 && this.checkMRPForAllModes());
                } else if (is_lsm == "1") {
                    return (comboname !== "" && priority!=="" && menu !== "" && images !== null && hero_image !== null && group !== "" && subgroup !== "" && combocost !== "" && combostart !== "" && comboclose !== "" && storesJson.length > 0 && mode.length > 0 && this.checkMRPForAllModes());
                }
                else if (is_lsm == "2") {
                    return (comboname !== "" && priority!=="" && menu !== "" && images !== null && hero_image !== null && group !== "" && subgroup !== "" && combocost !== "" && combostart !== "" && comboclose !== "" && storesJson.length > 0 && mode.length > 0 && this.checkMRPForAllModes());
                }
            }
        } else {
            if (hero_item == false) {
                if (is_lsm == "0") {
                    return (comboname !== "" && priority!=="" && menu !== "" && images !== null && group !== "" && subgroup !== "" && combocost !== "" && mode.length > 0 && this.checkMRPForAllModes());
                } else if (is_lsm == "1") {
                    return (comboname !== "" && priority!=="" && menu !== "" && images !== null && group !== "" && subgroup !== "" && combocost !== "" && storesJson.length > 0 && mode.length > 0 && this.checkMRPForAllModes());
                }
                else if (is_lsm == "2") {
                    return (comboname !== "" && priority!=="" && menu !== "" && images !== null && group !== "" && subgroup !== "" && combocost !== "" && storesJson.length > 0 && mode.length > 0 && this.checkMRPForAllModes());
                }
            } else {
                if (is_lsm == "0") {
                    return (comboname !== "" && priority!=="" && menu !== "" && images !== null && hero_image !== null && group !== "" && subgroup !== "" && combocost !== "" && mode.length > 0 && this.checkMRPForAllModes());
                } else if (is_lsm == "1") {
                    return (comboname !== "" && priority!=="" && menu !== "" && images !== null && hero_image !== null && group !== "" && subgroup !== "" && combocost !== "" && storesJson.length > 0 && mode.length > 0 && this.checkMRPForAllModes());
                }
                else if (is_lsm == "2") {
                    return (comboname !== "" && priority!=="" && menu !== "" && images !== null && hero_image !== null && group !== "" && subgroup !== "" && combocost !== "" && storesJson.length > 0 && mode.length > 0 && this.checkMRPForAllModes());
                }
            }
        }
    }
    blockInvalidChar = (e: any) => {
        var regex = new RegExp("^[a-zA-Z0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }

    blockSpecialCharacters = () => {
        var checkString = this.state.comboname;
        if (checkString != "") {
            if (/[%,_]/.test(checkString)) {
                alert("Your combo name has '%' or '_' sign. \nIt is not allowed.\nPlease remove it and try again.");
                return (false);
            } else {
                return true
            }
        }
    }
    handleOrderModesInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            if (e.length < this.state.mode.length) {
                let tempArr = this.state.order_modes_price;
                let selectedItems = tempArr.filter(obj => e.find((s: any) => s.value === obj.value));
                this.setState({ order_modes_price: selectedItems })
            } else {
                let mode_id = e[this.state.order_modes_price.length].value;
                let mode_label = e[this.state.order_modes_price.length].label;
                let tempArr = this.state.order_modes_price;
                let obj = {
                    combo_id: "",
                    order_mode_id: mode_id,
                    order_mode: mode_label,
                    mrp: "0",
                    label: mode_label,
                    value: mode_id
                }
                tempArr.push(obj);
                this.setState({ order_modes_price: tempArr })
            }
            this.setState({ mode: e });
        } else {
            this.setState({ order_modes_price: [] });
            this.setState({ mode: [] });
        }
    };
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ storesJson: e });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    handleComboChoiceInputChange = (e: any, index: any, choiceModGType?: any) => {
        if (e.target) {
            const { name, value } = e.target;
            if (name == "group_id") {
                this.props.comboChoiceItemsByGroupId(value, index)
            }
            if (name == "is_half_n_half") {
                const list: any = this.state.comboChoices;
                list[index]["is_full_pizza"] = false;
                list[index]["is_half_n_half"] = !list[index]["is_half_n_half"];
                list[index]["size_pos_code"] = null;
                list[index]["recipe"] = "";
                list[index]["variant_id"] = "";
                list[index]["crusts"] = [];
                list[index]["flavours"] = [];
                list[index]["topings"] = [];
                list[index]["condiments"] = [];
                list[index]["mod_groups"] = [];
                this.setState({ comboChoices: list });
            } else if (name == "is_full_pizza") {
                const list: any = this.state.comboChoices;
                list[index]["is_full_pizza"] = !list[index]["is_full_pizza"];
                list[index]["is_half_n_half"] = false;
                list[index]["size_pos_code"] = null;
                list[index]["recipe"] = "";
                list[index]["variant_id"] = "";
                list[index]["crusts"] = [];
                list[index]["flavours"] = [];
                list[index]["topings"] = [];
                list[index]["condiments"] = [];
                list[index]["mod_groups"] = [];
                this.setState({ comboChoices: list });
            } else {
                const list: any = this.state.comboChoices;
                list[index][name] = value;
                this.setState({ comboChoices: list });
            }
        } else {
            if (Array.isArray(e) && e.length > 0) {
                const list: any = this.state.comboChoices;
                if (list[index]["is_half_n_half"]) {
                    if (choiceModGType == "crusts") {
                        list[index]["crusts"] = e;
                    } else if (choiceModGType == "flavours") {
                        list[index]["flavours"] = e;
                    } else if (choiceModGType == "topings") {
                        list[index]["topings"] = e;
                    } else if (choiceModGType == "condiments") {
                        list[index]["condiments"] = e;
                    }
                } else if (list[index]["is_full_pizza"]) {
                    if (choiceModGType == "crusts") {
                        list[index]["crusts"] = e;
                    } else if (choiceModGType == "topings") {
                        list[index]["topings"] = e;
                    } else if (choiceModGType == "condiments") {
                        list[index]["condiments"] = e;
                    }
                } else {
                    list[index]["mod_groups"] = e;
                }
                this.setState({ comboChoices: list });
            } else {
                if (e.value) {
                    const list: any = this.state.comboChoices;
                    list[index]["menu_item_id"] = e.value;
                    list[index]["itemsize"] = JSON.parse(e.item_size);
                    list[index]["size"] = "";
                    this.setState({ comboChoices: list });
                } else {
                    const list: any = this.state.comboChoices;
                    if (choiceModGType == "crusts") {
                        list[index]["crusts"] = [];
                    } else if (choiceModGType == "flavours") {
                        list[index]["flavours"] = [];
                    } else if (choiceModGType == "topings") {
                        list[index]["topings"] = [];
                    } else if (choiceModGType == "condiments") {
                        list[index]["condiments"] = [];
                    } else if (choiceModGType == "modGroups") {
                        list[index]["mod_groups"] = [];
                    }
                }

            }
        }
    };
    handleAddComboChoice = (i: any) => {
        const list: any = this.state.comboChoices;
        if (list[i]["is_half_n_half"]) {
            if (list[i]["group_name"] == "" || list[i]["crusts"].length == 0 || list[i]["flavours"].length == 0 || list[i]["topings"].length == 0) {
                alert("Please fill in current item")
            } else {
                const newlist: any = {
                    mod_groups: [],
                    crusts: [],
                    flavours: [],
                    topings: [],
                    condiments: [],
                    group_name: "",
                    priority: "",
                    size_pos_code: null,
                    recipe: "",
                    variant_id: "",
                    is_half_n_half: false,
                    is_full_pizza: false,
                }
                const comboChoice: any = this.state.comboChoices.concat(newlist)
                this.setState({ comboChoices: comboChoice });
            }
        } else if (list[i]["is_full_pizza"]) {
            if (list[i]["group_name"] == "" || list[i]["crusts"].length == 0 || list[i]["topings"].length == 0) {
                alert("Please fill in current item")
            } else {
                const newlist: any = {
                    mod_groups: [],
                    crusts: [],
                    flavours: [],
                    topings: [],
                    condiments: [],
                    group_name: "",
                    priority: "",
                    size_pos_code: null,
                    recipe: "",
                    variant_id: "",
                    is_half_n_half: false,
                    is_full_pizza: false,
                }
                const comboChoice: any = this.state.comboChoices.concat(newlist)
                this.setState({ comboChoices: comboChoice });
            }
        } else {
            if (list[i]["group_name"] == "" || list[i]["mod_groups"].length == 0) {
                alert("Please fill in current item")
            } else {
                const newlist: any = {
                    mod_groups: [],
                    crusts: [],
                    flavours: [],
                    topings: [],
                    condiments: [],
                    group_name: "",
                    priority: "",
                    size_pos_code: null,
                    recipe: "",
                    is_half_n_half: false,
                    is_full_pizza: false,
                }
                const comboChoice: any = this.state.comboChoices.concat(newlist)
                this.setState({ comboChoices: comboChoice });
            }
        }
    };
    handleRemoveComboChoice = (index: any) => {
        const list = this.state.comboChoices;
        list.splice(index, 1);
        this.setState({ comboChoices: list });
    };
    handlePricingOrderModesInputChange = (e: any, indexing: any) => {
        if (e && e.length > 0) {
            if (e.length === this.state.order_modes_price.length + 1) {
                if (this.state.order_modes_price.length == 0) {
                    let mode_id = e[this.state.order_modes_price.length].value;
                    let mode_label = e[this.state.order_modes_price.length].label;
                    let tempArr = this.state.order_modes_price;
                    let obj = {
                        combo_id: "",
                        order_mode_id: mode_id,
                        order_mode: mode_label,
                        mrp: "0",
                        label: mode_label,
                        value: mode_id
                    }
                    tempArr.push(obj);
                    this.setState({ order_modes_price: tempArr })
                } else {
                    if (this.state.order_modes_price[this.state.order_modes_price.length - 1].mrp > 0) {
                        let mode_id = e[this.state.order_modes_price.length].value;
                        let mode_label = e[this.state.order_modes_price.length].label;
                        let tempArr = this.state.order_modes_price;
                        let obj = {
                            combo_id: "",
                            order_mode_id: mode_id,
                            order_mode: mode_label,
                            mrp: "0",
                            label: mode_label,
                            value: mode_id
                        }
                        tempArr.push(obj);
                        this.setState({ order_modes_price: tempArr })
                    } else {
                        toast.error("Max retail price should not be 0,Please update", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    }
                }
            }
            else {
                let tempArr = this.state.order_modes_price;
                tempArr = tempArr.filter((item: any) => (item.order_mode_id != indexing.removedValue.value))
                this.setState({ order_modes_price: tempArr })
            }
        } else {
            this.setState({ order_modes_price: [] })
        }
    };

    handleVariantPriceInputChange = (e: any, index: any) => {
        const { name, value } = e.target;
        const list: any = this.state.order_modes_price;
        list[index][name] = value;
        this.setState({ order_modes_price: list });
        // if (name == "pos_code") {
        //     this.props.checkDuplicatePosCode(value)
        // }
        // if (name == "erp_id") {
        //     this.props.checkDuplicateErpId(value)
        // }
    };
    handleWeeklyDaysInputChange = (e: any, index: any, pindex?: any) => {
        if (e.target) {
            const { name, value } = e.target;
            const list: any = this.state.daysTiming;
            let timeP = list[index].timePeriods;
            timeP[pindex][name] = value;
            list[index].timePeriods = timeP;
            this.setState({ daysTiming: list });
        } else {
            const list: any = this.state.daysTiming;
            list[index]["day"] = e.value;
            list[index]["label"] = e.label;
            this.setState({ daysTiming: list });
        }
    };
    handleWeeklyDaysRemoveClick = (index: any, pindex?: any) => {
        const list = this.state.daysTiming;
        if (pindex >= 0) {
            let timePer = list[index].timePeriods;
            timePer.splice(pindex, 1);
            list[index].timePeriods = timePer;
            this.setState({ daysTiming: list });
        } else {
            list.splice(index, 1);
            this.setState({ daysTiming: list });
        }
    };
    handleweeklyDaysAddClick = (e: any, i: any, pindex?: any) => {
        const list: any = this.state.daysTiming;
        if (pindex >= 0) {
            if (list[i].timePeriods[list[i].timePeriods.length - 1].open == "" || list[i].timePeriods[list[i].timePeriods.length - 1].close == "") {
                toast.error("Please fill in selected day hours", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            } else {
                let timePeriods = {
                    open: "",
                    close: ""
                }
                list[i].timePeriods.push(timePeriods)
                this.setState({ daysTiming: list });
            }
        } else {
            if (list[i]["day"] == "" || list[i].timePeriods[list[i].timePeriods.length - 1].open == "" || list[i].timePeriods[list[i].timePeriods.length - 1].close == "") {
                toast.error("Please fill in selected day timing", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            } else {
                let timePeriods = {
                    open: "",
                    close: ""
                }
                const newlist: any = {
                    day: "",
                    timePeriods: []
                }
                newlist.timePeriods.push(timePeriods)
                const days: any = this.state.daysTiming.concat(newlist)
                this.setState({ daysTiming: days });
            }
        }
        e.preventDefault()
    };

    handleSaveBtnClick = (event: any) => {
        let { comboname, erpid, poscode, combodesc, is_featured, featured_image, priority, specific_days, daysTiming, is_hide, is_voucher, is_foodpanda ,is_blurry, mode, channel, combocost, topDeal, hero_item, hero_image, hero_mobile_image, combomrp, group, subgroup, settime, combostart, comboclose, comboChoices, menu, is_lsm, storesJson, metaTitle, metaDesc, altTag, is_suggestive } = this.state;
        const data: any = new FormData()
        const Days = JSON.stringify(daysTiming);
        let comboNamevalid = this.blockSpecialCharacters();
        if (comboNamevalid) {
            if (this.state.images) {
                for (var x = 0; x < this.state.images.length; x++) {
                    data.append('files', this.state.images[x])
                }
            }
            // if (this.state.thumbnail_images) {
            //     for (var x = 0; x < this.state.thumbnail_images.length; x++) {
            //         var file = this.state.thumbnail_images[0];
            //         var newFileName = file.name.split(".")[0] + `_thumbnail_${x}.` + file.name.split(".")[1];

            //         data.append('files', file, newFileName)

            //     }
            // }
            if (hero_image) {
                var file = hero_image[0];
                var newFileName = file.name.split(".")[0] + "_hero_image." + file.name.split(".")[1];
                data.append('files', file, newFileName);
            }
            if (hero_mobile_image) {
                var file = hero_mobile_image[0];
                var newFileName = file.name.split(".")[0] + "_hero_mobile_image." + file.name.split(".")[1];
                data.append('files', file, newFileName);
            }
            if (featured_image) {
                var file = featured_image;
                var newFileName = file.name.split(".")[0] + "_featured_image." + file.name.split(".")[1];
                data.append('files', file, newFileName);
            }
            data.append('combo_name', comboname)
            if (erpid !== null) {
                data.append('erp_id', erpid)
            }
            if (poscode !== null) {
                data.append('pos_code', poscode)
            }
            if (hero_item === true) {
                data.append('hero_item', 1)
            }
            if (topDeal === true) {
                data.append('topDeal', 1)
            }
            if (is_hide === true) {
                data.append('is_hide', 1)
            }
            if (is_voucher === true) {
                data.append('is_voucher', 1)
            }
            if (is_foodpanda === true) {
                data.append('is_foodpanda', 1)
            }
            if (is_blurry === true) {
                data.append('is_blurry', 1)
            }
            if (is_featured === true) {
                data.append('is_featured', 1)
            }
            if (is_lsm == '0') {
                data.append('is_lsm', 0)
            } else if (is_lsm == '1') {
                data.append('is_lsm', 1)
                data.append('stores_json', JSON.stringify(storesJson))
            }
            else if (is_lsm == '2') {
                data.append('is_lsm', 2)
                data.append('stores_json', JSON.stringify(storesJson))
            }
            data.append('combo_description', combodesc)
            data.append('combo_channel_id', channel)
            data.append('combo_cost_price', combocost)
            // data.append('comboUnique', comboUnique)
            data.append('item_mode', JSON.stringify(mode))
            data.append('priority', priority)
            // data.append('combo_sales_price', combosale)
            // data.append('combo_tax_configuration', taxstatus == false ? '0' : '1')
            data.append("is_suggestive_deal", is_suggestive ? 1 : 0)
            // data.append('combo_mrp_price', combomrp)
            data.append('comboModePrice', JSON.stringify(this.state.order_modes_price))
            data.append('menu_id', menu)
            data.append('group_id', group)
            data.append('subgroup_id', subgroup)
            data.append('meta_title', metaTitle)
            data.append('meta_description', metaDesc)
            data.append('alt_text', altTag)
            if (settime === 'customtime') {
                data.append('combo_start_time', moment(combostart).utc(false))
                data.append('combo_close_time', moment(comboclose).utc(false))
            }
            // for (var i = 0, len = combojson.length; i < len; i++) {
            //     delete combojson[i].itemsize;   //delete is used to remove itemSize Array from combo_ingredients json 
            // }   
            //But this is not used because itemSize array is required to display mutiple items in Edit section

            // if (!combojson[1]) {
            //     alert("Please select at least 2 items")
            // } else
            data.append('combo_choices', JSON.stringify(comboChoices))
            if (specific_days) {
                if (daysTiming[daysTiming.length - 1]["day"] !== "" && daysTiming[daysTiming.length - 1].timePeriods[daysTiming[daysTiming.length - 1].timePeriods.length - 1].open !== "" && daysTiming[daysTiming.length - 1].timePeriods[daysTiming[daysTiming.length - 1].timePeriods.length - 1].close !== "") {
                    data.append('serving_hours', Days)
                    this.props.addCombo(data);
                } else {
                    alert("Please fill in selected day timing")
                }
            } else {
                this.props.addCombo(data);
            }
        }
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const { menus, channels, combooptions, groups, subGroups, addons, crusts, topings, flavours, condiments, variants } = this.props;
        const { comboChoices, hero_item, topDeal, settime, combomrp, daysTiming, combocost, is_lsm, storesJson, is_featured, is_hide, is_voucher, is_foodpanda, is_blurry, specific_days } = this.state
        const groupedOptions: any = [];
        let obj: any = ""
        for (const property in combooptions) {
            obj = {
                label: property,
                options: combooptions[property]
            }
            groupedOptions.push(obj)
        }
        let groupsByMenuID: any[] = groups.filter((obj: any) => {
            return obj.menu_id == this.state.menu;
        })
        const formatGroupLabel = (data: any) => (
            <div className="d-flex justify-content-between">
                <span>{data.label}</span>
                <span className="badge badge-secondary p-1">{data.options.length}</span>
            </div>
        );
        return (
            <div className="page">
                <CheckChanges path="/add-combo" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Combo Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/combos" className="text-primary">Combos</Link></li>
                                <li className="breadcrumb-item active">Add Combo</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-4 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Combo Name <span className="text-danger">*</span></label>
                                                            <input id="comboname" type="text" name="comboname" required data-msg="Please enter Combo Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">ERP Id</label>
                                                            <input id="erpid" type="text" name="erpid" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">POS Code</label>
                                                            <input id="poscode" type="text" name="poscode" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Meta title </label>
                                                            <input id="metaTitle" type="text" name="metaTitle" required data-msg="Please enter Item Title" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Meta description</label>
                                                            <input id="metaDesc" type="text" name="metaDesc" required data-msg="Please enter Item Description" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-4" >
                                                        <div>
                                                            <input id="checkboxCustom6" type="checkbox" name="is_suggestive" checked={this.state.is_suggestive} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom6">Suggestive Deal</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {/* <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Combo Unique Id <span className="text-danger">*</span></label>
                                                            <input id="comboUnique" type="text" name="comboUnique" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className='row'>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Menu <span className="text-danger">*</span></label>
                                                            <select id="menu" name="menu" className="form-control text-capitalize mt-2" required data-msg="Please select Menu" onChange={this.handleItemsByMenuId}>
                                                                <option value="">Select Menu</option>
                                                                {menus &&
                                                                    menus.map((menu, index) => (
                                                                        <option key={index} value={menu.menu_id}>{menu.brand_name} - {menu.store_type_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Group <span className="text-danger">*</span></label>
                                                            <select name="group" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange}>
                                                                <option value="">Select Group</option>
                                                                {groupsByMenuID &&
                                                                    groupsByMenuID.map((group, index) => (
                                                                        group &&
                                                                        <option key={index} value={group.group_id}>{group.group_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">SubGroups <span className="text-danger">*</span></label>
                                                            <select name="subgroup" className="form-control text-capitalize mt-2" required data-msg="Please select SubGroup" onChange={this.handleInputChange}>
                                                                <option value="">Select SubGroup</option>
                                                                {subGroups &&
                                                                    subGroups.map((group, index) => (
                                                                        group &&
                                                                        <option key={index} value={group.id}>{group.sub_group_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <h4>Select Items For Combo Options</h4>
                                                        <div className="form-group">
                                                            {comboChoices.map((x: any, i: any) => {
                                                                return (
                                                                    <>
                                                                        <div className='row justify-content-center'>
                                                                            <div className="col-6 mt-4" >
                                                                                <div className="form-group" >
                                                                                    <input id={`checkboxFull${i}`} type="checkbox" name="is_full_pizza" checked={x.is_full_pizza} onChange={e => this.handleComboChoiceInputChange(e, i)} className="checkbox-template" />
                                                                                    <label htmlFor={`checkboxFull${i}`}>Full Pizza</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6 mt-4" >
                                                                                <div className="form-group" >
                                                                                    <input id={`checkboxHalf${i}`} type="checkbox" name="is_half_n_half" checked={x.is_half_n_half} onChange={e => this.handleComboChoiceInputChange(e, i)} className="checkbox-template" />
                                                                                    <label htmlFor={`checkboxHalf${i}`}>HALF n HALF</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div key={i} className="row">
                                                                            <div className="col">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label">Priority <span className="text-danger">*</span></label>
                                                                                    <input id="priority" type="number" onWheel={(e: any) => e.target.blur()} name="priority" defaultValue={x.priority} onKeyDown={this.blockInvalidChar} required data-msg="Please enter Priority" className="input-material" onChange={e => this.handleComboChoiceInputChange(e, i)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col'>
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label">Pos Code</label>
                                                                                    <input
                                                                                        name="size_pos_code"
                                                                                        type="text"
                                                                                        value={x.size_pos_code}
                                                                                        data-msg="Please enter pos code"
                                                                                        className="input-material"
                                                                                        onChange={e => this.handleComboChoiceInputChange(e, i)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {(x.is_half_n_half || x.is_full_pizza) &&
                                                                                <>{x.is_full_pizza && <div className='col-lg-3 col-sm-3 col-6'>
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Recipe</label>
                                                                                        <input
                                                                                            name="recipe"
                                                                                            type="text"
                                                                                            value={x.recipe}
                                                                                            data-msg="Please enter recipe"
                                                                                            className="input-material"
                                                                                            onChange={e => this.handleComboChoiceInputChange(e, i)} />
                                                                                    </div>
                                                                                </div>}
                                                                                    <div className="col-lg-3 col-sm-3 col-6">
                                                                                        <div className="form-group">
                                                                                            <label className="form-control-label mb-3">Variant</label>
                                                                                            <select onChange={(e: any) => this.handleComboChoiceInputChange(e, i)} name="variant_id" className="form-control text-capitalize mt-2" >
                                                                                                <option value="Select variant" selected disabled>Select variant</option>
                                                                                                {variants &&
                                                                                                    variants.map((variant: any, index: any) => (
                                                                                                        <option value={variant.id}>{variant.name}</option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label">Group Name <span className="text-danger">*</span></label>
                                                                                    <input
                                                                                        name="group_name"
                                                                                        type="text"
                                                                                        value={x.group_name}
                                                                                        data-msg="Please enter group name"
                                                                                        className="input-material"
                                                                                        onChange={e => this.handleComboChoiceInputChange(e, i)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            {x.is_half_n_half ? <>
                                                                                <div className="col">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Crusts <span className="text-danger">*</span></label>
                                                                                        <Select
                                                                                            isMulti
                                                                                            name="crusts"
                                                                                            options={crusts}
                                                                                            value={x.crusts}
                                                                                            className="text-capitalize basic-multi-select mt-2"
                                                                                            classNamePrefix="select"
                                                                                            onChange={(e) => this.handleComboChoiceInputChange(e, i, "crusts")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Flavours <span className="text-danger">*</span></label>
                                                                                        <Select
                                                                                            isMulti
                                                                                            name="flavours"
                                                                                            options={flavours}
                                                                                            value={x.flavours}
                                                                                            className="text-capitalize basic-multi-select mt-2"
                                                                                            classNamePrefix="select"
                                                                                            onChange={(e) => this.handleComboChoiceInputChange(e, i, "flavours")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Toppings <span className="text-danger">*</span></label>
                                                                                        <Select
                                                                                            isMulti
                                                                                            name="topings"
                                                                                            options={topings}
                                                                                            value={x.topings}
                                                                                            className="text-capitalize basic-multi-select mt-2"
                                                                                            classNamePrefix="select"
                                                                                            onChange={(e) => this.handleComboChoiceInputChange(e, i, "topings")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Condiments</label>
                                                                                        <Select
                                                                                            isMulti
                                                                                            name="condiments"
                                                                                            options={condiments}
                                                                                            defaultValue={x.condiments}
                                                                                            className="text-capitalize basic-multi-select mt-2"
                                                                                            classNamePrefix="select"
                                                                                            onChange={(e) => this.handleComboChoiceInputChange(e, i, "condiments")}
                                                                                        />
                                                                                    </div>
                                                                                </div></> : x.is_full_pizza ?
                                                                                <>
                                                                                    <div className="col">
                                                                                        <div className="form-group">
                                                                                            <label className="form-control-label">Crusts <span className="text-danger">*</span></label>
                                                                                            <Select
                                                                                                isMulti
                                                                                                name="crusts"
                                                                                                options={crusts}
                                                                                                value={x.crusts}
                                                                                                className="text-capitalize basic-multi-select mt-2"
                                                                                                classNamePrefix="select"
                                                                                                onChange={(e) => this.handleComboChoiceInputChange(e, i, "crusts")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col">
                                                                                        <div className="form-group">
                                                                                            <label className="form-control-label">Topings <span className="text-danger">*</span></label>
                                                                                            <Select
                                                                                                isMulti
                                                                                                name="topings"
                                                                                                options={topings}
                                                                                                value={x.topings}
                                                                                                className="text-capitalize basic-multi-select mt-2"
                                                                                                classNamePrefix="select"
                                                                                                onChange={(e) => this.handleComboChoiceInputChange(e, i, "topings")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col">
                                                                                        <div className="form-group">
                                                                                            <label className="form-control-label">Condiments</label>
                                                                                            <Select
                                                                                                isMulti
                                                                                                name="condiments"
                                                                                                options={condiments}
                                                                                                defaultValue={x.condiments}
                                                                                                className="text-capitalize basic-multi-select mt-2"
                                                                                                classNamePrefix="select"
                                                                                                onChange={(e) => this.handleComboChoiceInputChange(e, i, "condiments")}
                                                                                            />
                                                                                        </div>
                                                                                    </div></> :
                                                                                <div className="col">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Modifier Group <span className="text-danger">*</span></label>
                                                                                        <Select
                                                                                            isMulti
                                                                                            name="groups"
                                                                                            options={addons}
                                                                                            value={x.mod_groups}
                                                                                            className="text-capitalize basic-multi-select mt-2"
                                                                                            classNamePrefix="select"
                                                                                            onChange={(e) => this.handleComboChoiceInputChange(e, i, "modGroups")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        {/* <div className="col-lg-3 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Menu Items <span className="text-danger">*</span></label>
                                                                                <Select
                                                                                    className="basic-single text-capitalize py-2"
                                                                                    options={x.choicesItemOptions}
                                                                                    onChange={e => this.handleComboChoiceInputChange(e, i)} />
                                                                            </div>
                                                                        </div> */}
                                                                        {/* <div className="col-lg-3 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Size <span className="text-danger">*</span></label>
                                                                                <select name="size" className="form-control text-capitalize mt-2" required data-msg="Please select Size" onChange={e => this.handleComboChoiceInputChange(e, i)}>
                                                                                    <option value="">Select Size</option>
                                                                                    {x.itemsize.length > 0 &&
                                                                                        x.itemsize.map((item: any, index: any) => (
                                                                                            <option key={index} value={item.id}>{item.size}</option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="col-12 text-right">
                                                                            {comboChoices.length !== 1 &&
                                                                                <button className="btn btn-sm btn-outline-danger"
                                                                                    onClick={() => this.handleRemoveComboChoice(i)}><i className="fa fa-trash"></i></button>}
                                                                            {comboChoices.length - 1 === i && <button className="btn btn-sm btn-primary ml-2" onClick={() => this.handleAddComboChoice(i)}><i className="fa fa-plus"></i></button>}
                                                                        </div>
                                                                    </>
                                                                );
                                                            })}
                                                        </div >
                                                    </div >
                                                </div >
                                                <div className="row">
                                                    <div className="col-lg-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description</label>
                                                            <input id="combodesc" type="text" name="combodesc" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Alt Tag</label>
                                                            <input id="altTag" type="text" name="altTag" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Channel <span className="text-danger">*</span></label>
                                                            <select name="channel" className="form-control text-capitalize mt-2" required data-msg="Please select Channel" onChange={this.handleInputChange}>
                                                                <option>Select Channel</option>
                                                                {channels &&
                                                                    channels.map((channel, index) => (
                                                                        <option key={index} value={channel.sales_channel_id}>{channel.channel_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Combo Image <span className="text-danger">*</span></label>
                                                            <input id="files" type="file" name="files" className="form-control-file" onChange={this.fileSelectedHandler} multiple />
                                                            <p style={{ color: "red", fontSize: "12px" }}>Combo image size should be less than 150 kb</p>
                                                            {/* <small className="form-text">You can also choose multiple images.</small> */}
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-3 col-sm-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Thumbnail Image <span className="text-danger">*</span></label>
                                                            <input id="files" type="file" name="files" className="form-control-file" onChange={this.thumbnailFileSelectedHandler} multiple />
                                                            <p style={{ color: "red", fontSize: "12px" }}>Thumbnail image size should be less than 50 kb</p>
                                                            <small className="form-text">You can also choose multiple images.</small>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="row">
                                                    {/* <div className="col-lg-8" >
                                                        <div className="form-group">
                                                            <label className="form-control-label">Pricing By Ordering Modes <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="mode"
                                                                options={this.state.mode}
                                                                value={this.state.order_modes_price}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handlePricingOrderModesInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-6 col-12" >
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="mode"
                                                                options={this.props.orderingModes}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleOrderModesInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Cost Price <span className="text-danger">*</span></label>
                                                            <input id="combocost" type="number" onWheel={(e: any) => e.target.blur()} min="1" onKeyDown={this.blockInvalidChar} name="combocost" value={combocost} required data-msg="Please enter Combo Cost" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Cost Price <span className="text-danger">*</span></label>
                                                            <input id="combocost" type="number" onWheel={(e:any) => e.target.blur()} min="1" onKeyDown={this.blockInvalidChar} name="combocost" value={combocost} required data-msg="Please enter Combo Cost" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Max Retail Price <small className="text-danger">*</small></label>
                                                            <input
                                                                name="combomrp"
                                                                type="number" onWheel={(e:any) => e.target.blur()}
                                                                value={combomrp}
                                                                min="1"
                                                                onKeyDown={this.blockInvalidChar}
                                                                data-msg="Please enter mrp"
                                                                className="input-material"
                                                                onChange={this.handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    {this.state.order_modes_price.length > 0 && this.state.order_modes_price.map((channel: any, index: any) => (
                                                        <>
                                                            <div className="col-lg-4 col-sm-4 col-6">
                                                                <strong>{index + 1} -</strong>
                                                                <strong style={{ fontSize: '16px' }} className="ml-2">
                                                                    {channel.order_mode}
                                                                </strong>
                                                                <div className="form-group">
                                                                    <label className="form-control-label mb-3">Max Retail Price <span className="text-danger">*</span></label>
                                                                    <input
                                                                        name="mrp"
                                                                        type="number" onWheel={(e: any) => e.target.blur()}
                                                                        value={channel.mrp}
                                                                        data-msg="Please enter mrp"
                                                                        className="input-material"
                                                                        onChange={e => this.handleVariantPriceInputChange(e, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}
                                                </div>
                                                <div className='row'>
                                                    <div className="col-lg-2 col-sm-4 col-4 mt-5" >
                                                        <div >
                                                            <input id="checkboxCustom1" type="checkbox" disabled={this.props.bsLimit} name="is_featured" checked={is_featured} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom1">Featured</label>
                                                            {
                                                                this.props.bsLimit ?
                                                                    <small className='d-block text-danger'>Limit Reached to 5</small>
                                                                :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-sm-4 mt-5">
                                                        <div >
                                                            <input id="checkboxCustom2" type="checkbox" name="topDeal" checked={topDeal} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom2">Top Deal</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-sm-4 mt-5">
                                                        <div >
                                                            <input id="checkboxCustom3" type="checkbox" name="is_hide" checked={is_hide} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom3">Is Hidden</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-sm-4 mt-5">
                                                        <div >
                                                            <input id="checkboxCustom4" type="checkbox" name="is_voucher" checked={is_voucher} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom4">Is Voucher</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-sm-4 mt-5">
                                                        <div >
                                                            <input id="checkboxCustom4" type="checkbox" name="is_foodpanda" checked={is_foodpanda} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom4">Is Food Panda</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-sm-4 mt-5">
                                                        <div >
                                                            <input id="checkboxCustom5" type="checkbox" name="is_blurry" checked={is_blurry} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom5">Is MidNight</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.is_featured ?
                                                    <div className='row mt-3'>
                                                        <div className='col-4'>
                                                            <div className="form-group">
                                                                <label className="form-control-label">Featured Image  <span className="text-danger">*</span></label>
                                                                <input id="files" type="file" name="files" className="form-control-file" onChange={this.handleFeatureImage} />
                                                                <p style={{ color: "red", fontSize: "12px" }}>Image size should be less than 1MB</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                <div className="row">
                                                    <div className="col-lg-3 col-sm-3 col-6 mt-5" >
                                                        <div >
                                                            <input id="radioCustom1" type="radio" name="settime" value="alltime" checked={settime === 'alltime'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom1">Available for all time</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-3 col-6 mt-5" >
                                                        <div >
                                                            <input id="radioCustom2" type="radio" name="settime" value="customtime" checked={settime === 'customtime'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom2">Available for specific time</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-3 col-6 mt-5" >
                                                        <div>
                                                            <input id="checkboxCustom0" type="checkbox" name="specific_days"
                                                                checked={specific_days} onChange={this.handleSpecificDaysCheck}
                                                                className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom0">Available for specific days</label>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-2 col-sm-6 col-6 mt-5" >
                                                        <div >
                                                            <input id="checkboxCustom1" type="checkbox" name="taxstatus" checked={hero_item} onChange={this.handleHeroItem} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom1">Hero Item</label>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                {
                                                    hero_item &&
                                                    <div className='row'>
                                                        <div className="col-6 mt-2">
                                                            <div className="form-group">
                                                                <label className="form-control-label mb-3">Hero Item Image(Desktop) <span className="text-danger">*</span></label>
                                                                <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={this.heroItemImageSelectedHandler} />
                                                                <p style={{ color: "red", fontSize: "12px" }}>Image size should be less than 1 mb</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 mt-2">
                                                            <div className="form-group">
                                                                <label className="form-control-label mb-3">Hero Item Image(Mobile)</label>
                                                                <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={this.heroItemMobileImageSelectedHandler} />
                                                                <p style={{ color: "red", fontSize: "12px" }}>Image size should be less than 1 mb</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    settime === 'customtime' &&
                                                    <div>
                                                        <div className="row mt-3">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-lable">Combo Timing</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <input id="combostart" type="date" name="combostart" required data-msg="Please enter starting time" className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <input id="comboclose" type="date" name="comboclose" required data-msg="Please enter closing time" className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-lg-3 col-sm-3 col-6 mt-5" >
                                                        <div >
                                                            <input id="radioCustom3" type="radio" name="is_lsm" value="0" checked={is_lsm == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Available for all stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-3 col-6 mt-5" >
                                                        <div >
                                                            <input id="radioCustom4" type="radio" name="is_lsm" value="1" checked={is_lsm == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Available for specific stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-3 col-6 mt-5" >
                                                        <div >
                                                            <input id="radioCustom5" type="radio" name="is_lsm" value="2" checked={is_lsm == '2'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom5">Available for stores except</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-3 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority <span className="text-danger">*</span></label>
                                                            <input id="priority" type="text" name="priority" required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        is_lsm == '1' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={storesJson}
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    {
                                                        is_lsm == '2' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={storesJson}
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                {
                                                    specific_days &&
                                                    <div className='row'>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                {
                                                                    daysTiming.map((x: any, i: any) => {
                                                                        let timePeriod = x.timePeriods;
                                                                        return (
                                                                            <div key={i} className='mb-2' style={{ borderBottom: "1px dotted grey" }}>
                                                                                <div className="row">
                                                                                    <div className="col-lg-8 col-md-8 col-8">
                                                                                        <div className="form-group">
                                                                                            <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                                            <Select
                                                                                                options={this.weeklyDays.filter(o1 => !daysTiming.some((o2: any) => o1.value === o2.day))}
                                                                                                className="text-capitalize basic-multi-select"
                                                                                                classNamePrefix="select"
                                                                                                value={{ value: x.value, label: x.label }}
                                                                                                onChange={(e) => this.handleWeeklyDaysInputChange(e, i)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4 col-md-4 col-4 mt-4 py-2">
                                                                                        {daysTiming.length !== 1 &&
                                                                                            <button className="btn btn-sm btn-outline-danger"
                                                                                                onClick={() => this.handleWeeklyDaysRemoveClick(i)}><i className="fa fa-trash"></i></button>}
                                                                                        {(daysTiming.length - 1 === i && daysTiming.length < 7) && <button className="btn btn-sm btn-primary ml-2" onClick={(e: any) => this.handleweeklyDaysAddClick(e, i)}><i className="fa fa-plus"></i></button>}
                                                                                    </div>
                                                                                </div>
                                                                                {timePeriod.map((t: any, pindex: any) => {
                                                                                    return (
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-lg-3 col-md-3">
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 col-6">
                                                                                                <div className="form-group">
                                                                                                    <label className="form-control-label mb-0">Open Time <span className="text-danger">*</span></label>
                                                                                                    <input id="open" type="time" name="open" value={t.open} className="input-material" onChange={(e) => this.handleWeeklyDaysInputChange(e, i, pindex)} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 col-6">
                                                                                                <div className="form-group">
                                                                                                    <label className="form-control-label mb-0">Close Time <small className="text-danger">*</small></label>
                                                                                                    <input id="close" type="time" name="close" value={t.close} className="input-material" onChange={(e) => this.handleWeeklyDaysInputChange(e, i, pindex)} />
                                                                                                    {(timePeriod.length - 1 == pindex) && <small className="text-primary" style={{ cursor: 'pointer' }} onClick={(e: any) => this.handleweeklyDaysAddClick(e, i, pindex)}>+ Add hours</small>}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 col-12">
                                                                                                {timePeriod.length !== 1 &&
                                                                                                    <button className="btn btn-sm btn-outline-danger"
                                                                                                        onClick={() => this.handleWeeklyDaysRemoveClick(i, pindex)}><i className="fa fa-trash"></i></button>}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    {(this.props.comboLoad) ?
                                                        <button className='btn btn-primary d-flex justify-content-end align-item-center' disabled={this.props.comboLoad}><Loader type="TailSpin" color="white" height={30} width={30} /></button> :
                                                        <button className='btn btn-primary' disabled={!this.isComboReady() || !this.state.flag} onClick={this.handleSaveBtnClick}>Save</button>
                                                    }
                                                </div>
                                            </div >
                                        </div >
                                    </div >
                                </div >
                            </div >
                        </section >
                        <Footer />
                    </div >
                </div >
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        menus: state.menu.menus,
        combos: state.menu.combos,
        channels: state.store.channels,
        combooptions: state.menu.combooptions,
        comboChoiceItems: state.menu.comboChoiceItems,
        choiceIndex: state.menu.choiceIndex,
        stores: state.menu.storesoptions,
        groups: state.menu.groups,
        addons: state.menu.addons,
        crusts: state.menu.crusts,
        topings: state.menu.topings,
        condiments: state.menu.condiments,
        flavours: state.menu.flavours,
        orderingModes: state.menu.orderModes,
        subGroups: state.menu.subGroups,
        variants: state.menu.variants,
        comboLoad: state.menu.comboLoad,
        bsLimit: state.menu.bsLimit
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        combosList: function () {
            dispatch(combosList())
        },
        addCombo: function (data: any) {
            dispatch(addCombo(data));
        },
        channelList: function () {
            dispatch(channelList())
        },
        groupedItemsList: function (menu_id: any) {
            dispatch(groupedItemsList(menu_id))
        },
        storesList: () => {
            dispatch(storesListForMultiSelect())
        },
        menusList: function () {
            dispatch(menusList())
        },
        groupsList: function () {
            dispatch(groupsList())
        },
        modGroupsList: function () {
            dispatch(modGroupsList())
        },
        comboChoiceItemsByGroupId: function (id: any, index: any) {
            dispatch(comboChoiceItemsByGroupId(id, index))
        },
        OrderingModes: () => {
            dispatch(OrderingModes())
        },
        subGroupListByGroupId: function (id: any) {
            dispatch(subGroupListByGroupId(id))
        },
        variantsList: function () {
            dispatch(variantsList())
        },
        getBSLimit: function () {
            dispatch(getBSLimit())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddCombo);