import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { EditBrandProps } from '../../interfaces/brands'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { logoutUser, getBrand, editBrand, handleBrandInput } from '../../redux'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'

class EditBrand extends Component<EditBrandProps, {}> {
    constructor(props: any) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getBrand(id);
        document.title = "Crust Culture | Brands"
    }
    isBrandReady = () => {
        const { brandname } = this.props;
        return (brandname !== "");
    }
    handleSubmit = () => {
        let { brandname, brandtype, branddetails,brandTime } = this.props;
        let id = this.props.match.params.id;
        let data = {
            brand_name: brandname,
            brand_type: brandtype,
            brand_details: branddetails,
            brand_time: brandTime
        }
        this.props.editBrand(id, data);
    }
    blockInvalidChar = (e: any) => {
        var regex = new RegExp("[a-zA-Z]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isUpdated, message, brandname, brandtype, branddetails, brandTime } = this.props;
        if (isUpdated) {
            msg = message;
            messcolor = 'green'
        } else {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                <CheckChanges path="/edit-brand" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Brands Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/brands" className="text-primary">Brands</Link></li>
                                <li className="breadcrumb-item active">Edit Brand</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label ">Brand Name <span className="text-danger">*</span></label>
                                                            <input id="brandname" type="text" name="brandname" onKeyDown={this.blockInvalidChar} value={brandname} required data-msg="Please enter Brand Name" className="input-material" onChange={this.props.handleBrandInput} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand Type</label>
                                                            <input id="brandtype" type="text" name="brandtype" onKeyDown={this.blockInvalidChar} value={brandtype} className="input-material" onChange={this.props.handleBrandInput} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand Details</label>
                                                            <input id="branddetails" type="text" name="branddetails" value={branddetails} className="input-material" onChange={this.props.handleBrandInput} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-3">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand Time</label>
                                                            <input id="brandTime" type="text" name="brandTime" value={brandTime} className="input-material" onChange={this.props.handleBrandInput} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    {/* <input type="reset" value="cancel" className="btn btn-danger mr-2" /> */}
                                                    <button onClick={this.handleSubmit} disabled={!this.isBrandReady()} className="btn btn-primary">Update Brand</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>

                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        brandname: state.brand.brandname,
        branddetails: state.brand.branddetails,
        brandTime: state.brand.brandTime,
        brandtype: state.brand.brandtype,
        message: state.brand.message,
        isUpdated: state.brand.isUpdated
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: () => { dispatch(logoutUser()); },
        handleBrandInput: (event: any) => { dispatch(handleBrandInput(event)) },
        editBrand: (id: any, data: any) => { dispatch(editBrand(id, data)); },
        getBrand: (id: number) => { dispatch(getBrand(id)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditBrand);