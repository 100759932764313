import React, { Component } from 'react';
import { connect } from 'react-redux'
import { logoutUser, storesListForMultiSelect, editMode, getMode } from '../../../redux'
import Footer from '../../../components/footer/main';
import Sidebar from '../../../components/sidebar';
import Topbar from '../../../components/topbar';
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { Link, Redirect } from 'react-router-dom';
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
class EditMode extends Component<{
    logoutUser: () => {}, storesList: () => {}, getMode: (id: any) => {}
    match: any, stores: any, editMode: (id: any, data: any) => {}, modeData: any
}, {
    [x: number]: any; all_store: any, modename: any, storesJson: any[]
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            modename: "",
            all_store: '0',
            storesJson: [],
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        document.title = "Crust Culture | Menu Items"
        let id = this.props.match.params.id;
        this.props.storesList();
        this.props.getMode(id);
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({
            modename: nextProps.modeData.name,
            storesJson: nextProps.modeData.stores_json ? JSON.parse(nextProps.modeData.stores_json) : this.state.storesJson,
            all_store: nextProps.modeData.all_store
        })
    }
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    isOrderModeReady = () => {
        const { all_store, storesJson } = this.state;
        if (all_store == "1") {
            return (storesJson.length > 0);
        }
        return true
    }
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ storesJson: e });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    handleSaveBtnClick = (event: any) => {
        let { modename, all_store, storesJson } = this.state;
        let id = this.props.match.params.id;
        let data = {
            name: modename,
            all_store: all_store,
            stores_json: all_store == 0 ? null : JSON.stringify(storesJson)
        }
        this.props.editMode(id, data);
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const { modeData } = this.props;
        const { all_store, storesJson } = this.state;
        return (
            <div className="page">
                <CheckChanges path="/edit-mode" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Order Mode Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/order-modes" className="text-primary">Order Modes</Link></li>
                                <li className="breadcrumb-item active">Edit Order Modes</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Order Mode <span className="text-danger">*</span></label>
                                                            <input id="modename" type="text" name="modename" disabled defaultValue={modeData.name} required data-msg="Please enter mode name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-sm-4 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom3" type="radio" name="all_store" value="0" checked={all_store == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Sync all stores with FBR</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom4" type="radio" name="all_store" value="1" checked={all_store == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Sync specific stores with FBR</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        all_store == '1' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={storesJson}
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button onClick={this.handleSaveBtnClick} disabled={!this.isOrderModeReady()} className="btn btn-primary">Update Item</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        stores: state.menu.storesoptions,
        modeData: state.setting.modeData
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        editMode: function (id: any, data: any) {
            dispatch(editMode(id, data));
        },
        getMode: function (id: number) {
            dispatch(getMode(id));
        },
        storesList: () => {
            dispatch(storesListForMultiSelect())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMode);