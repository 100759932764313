import React, { Component } from 'react';
import { connect } from 'react-redux'
import { handleBrandInput, addBrand, logoutUser } from '../../../redux'
import { AddBannerProps, AddBannerState } from '../../../interfaces/menu';
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import Select from 'react-select';
import { secretKey } from '../../../secret'
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import CheckChanges from '../../../components/confirmOnLeave'
import { addBanner, itemsListForBanners, storesListForMultiSelect } from '../../../redux/actions/menuAction';
import moment from 'moment';
class AddBanner extends Component<AddBannerProps, AddBannerState> {
    weeklyDays: { value: string; label: string; }[];
    constructor(props: any) {
        super(props);
        this.weeklyDays = [
            { value: 'MONDAY', label: 'Mon' },
            { value: 'TUESDAY', label: 'Tue' },
            { value: 'WEDNESDAY', label: 'Wed' },
            { value: 'THURSDAY', label: 'Thu' },
            { value: 'FRIDAY', label: 'Fri' },
            { value: 'SATURDAY', label: 'Sat' },
            { value: 'SUNDAY', label: 'Sun' },
        ];
        this.state = {
            banner_type: "popup",
            sync_type: "items",
            sync_type_id: "",
            sync_type_label: "",
            bannerDesktop: "",
            bannerMobile: "",
            settime: 'alltime',
            itemstart: "",
            is_lsm: '0',
            storesJson: [],
            itemclose: "",
            specific_days: false,
            priority: "",
            daysTiming: [
                {
                    day: "",
                    label: "",
                    timePeriods: [{
                        open: "",
                        close: ""
                    }]
                }
            ]
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    }
    componentDidMount() {
        document.title = "Crust Culture | Banners"
        this.props.itemsListForBanners();
        this.props.storesList();
    }
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    isBannerReady = () => {
        const { bannerDesktop, bannerMobile, banner_type, priority,sync_type, sync_type_id, settime, itemclose, itemstart } = this.state;
        if (settime == 'customtime') {
            if (banner_type == "menu") {
                return (bannerDesktop !== "" && priority!=="" &&  bannerMobile !== "" && sync_type_id !== "" && itemstart !== "" && itemclose !== "");
            } else {
                return (bannerDesktop !== "" && priority!=="" && bannerMobile !== "" && itemstart !== "" && itemclose !== "");
            }
        } else {
            if (banner_type == "menu") {
                return (bannerDesktop !== "" && priority!=="" && bannerMobile !== "" && sync_type_id !== "");
            } else {
                return (bannerDesktop !== "" && priority!=="" && bannerMobile !== "");
            }
        }
    }
    handleBannerType = (event: { target: { name: any; value: any; }; }) => {
        if (event.target.name == "banner_type") {
            this.setState({
                [event.target.name]: event.target.value
            });
        } else {
            if (event.target.name == "sync_type") {
                this.setState({
                    [event.target.name]: event.target.value,
                    sync_type_id: "",
                    sync_type_label: ""
                });
            } else {
                this.setState({
                    [event.target.name]: event.target.value,
                });
            }
        }
    }
    heroItemImageSelectedHandler = (e: any) => {
        if (e.target.files.length > 0) {
            this.setState({ bannerDesktop: e.target.files })
        }
    }
    heroItemMobileImageSelectedHandler = (e: any) => {
        if (e.target.files.length > 0) {
            this.setState({ bannerMobile: e.target.files })
        }
    }
    handleWeeklyDaysInputChange = (e: any, index: any, pindex?: any) => {
        if (e.target) {
            const { name, value } = e.target;
            const list: any = this.state.daysTiming;
            let timeP = list[index].timePeriods;
            timeP[pindex][name] = value;
            list[index].timePeriods = timeP;
            this.setState({ daysTiming: list });
        } else {
            console.log("e", e)
            const list: any = this.state.daysTiming;
            list[index]["day"] = e.value;
            list[index]["label"] = e.label;
            this.setState({ daysTiming: list });
        }
    };
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ storesJson: e });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    // handle click event of the Remove days button
    handleWeeklyDaysRemoveClick = (index: any, pindex?: any) => {
        const list = this.state.daysTiming;
        if (pindex >= 0) {
            let timePer = list[index].timePeriods;
            timePer.splice(pindex, 1);
            list[index].timePeriods = timePer;
            this.setState({ daysTiming: list });
        } else {
            list.splice(index, 1);
            this.setState({ daysTiming: list });
        }
    };
    // handle click event of the Add button
    handleweeklyDaysAddClick = (e: any, i: any, pindex?: any) => {
        const list: any = this.state.daysTiming;
        if (pindex >= 0) {
            if (list[i].timePeriods[list[i].timePeriods.length - 1].open == "" || list[i].timePeriods[list[i].timePeriods.length - 1].close == "") {
                alert("Please fill in selected day hours")
            } else {
                let timePeriods = {
                    open: "",
                    close: ""
                }
                list[i].timePeriods.push(timePeriods)
                this.setState({ daysTiming: list });
            }
        } else {
            if (list[i]["day"] == "" || list[i].timePeriods[list[i].timePeriods.length - 1].open == "" || list[i].timePeriods[list[i].timePeriods.length - 1].close == "") {
                alert("Please fill in selected day timing")
            } else {
                let timePeriods = {
                    open: "",
                    close: ""
                }
                const newlist: any = {
                    day: "",
                    timePeriods: []
                }
                newlist.timePeriods.push(timePeriods)
                const days: any = this.state.daysTiming.concat(newlist)
                this.setState({ daysTiming: days });
            }
        }
        e.preventDefault()
    };
    handleSpecificDaysCheck = (event: { target: { name: any; value: any; }; }) => {
        const { specific_days } = this.state;
        this.setState({ specific_days: !specific_days })
    }
    handleItemsInputChange = (e: any, index: any) => {
        this.setState({ sync_type_id: e.value, sync_type_label: e.label })
    }
    handleVouchersInputChange = (e: any, index: any) => {
        this.setState({ sync_type_id: e.value, sync_type_label: e.label })
    }
    blockInvalidChar = (e: any) => {
        var regex = new RegExp("^[a-zA-Z0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    handleSaveBtnClick = () => {
        let { bannerDesktop, bannerMobile, banner_type, is_lsm, storesJson, sync_type, sync_type_id, sync_type_label, specific_days, daysTiming, settime, itemclose, itemstart, priority } = this.state;
        const data: any = new FormData();
        const Days = JSON.stringify(daysTiming);
        data.append('banner_type', banner_type)
        data.append('priority', priority)
        if (sync_type !== "") {
            data.append('sync_type', sync_type)
            data.append('sync_type_id', sync_type_id)
            data.append('sync_type_label', sync_type_label)
        }
        if (settime === 'customtime') {
            data.append('item_start_time', moment(itemstart).utc(false))
            data.append('item_close_time', moment(itemclose).utc(false))
        }
        if (bannerDesktop) {
            var file = bannerDesktop[0];
            var newFileName = file.name.split(".")[0] + "_desktop_image." + file.name.split(".")[1];
            data.append('files', file, newFileName);
        }
        if (bannerMobile) {
            var file = bannerMobile[0];
            var newFileName = file.name.split(".")[0] + "_mobile_image." + file.name.split(".")[1];
            data.append('files', file, newFileName);
        }
        if (is_lsm == '0') {
            data.append('is_lsm', 0)
        } else if (is_lsm == '1') {
            data.append('is_lsm', 1)
            data.append('stores_json', JSON.stringify(storesJson))
        }
        else if (is_lsm == '2') {
            data.append('is_lsm', 2)
            data.append('stores_json', JSON.stringify(storesJson))
        }
        if (specific_days) {
            if (daysTiming[daysTiming.length - 1]["day"] !== "" && daysTiming[daysTiming.length - 1].timePeriods[daysTiming[daysTiming.length - 1].timePeriods.length - 1].open !== "" && daysTiming[daysTiming.length - 1].timePeriods[daysTiming[daysTiming.length - 1].timePeriods.length - 1].close !== "") {
                data.append('serving_hours', Days)
                this.props.addBanner(data);
            } else {
                alert("Please fill in selected day timing")
            }
        } else {
            this.props.addBanner(data);
        }
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const { settime, specific_days, daysTiming, is_lsm } = this.state;
        const { menuItemsForBanners, combosForBanners } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/add-banner" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Banners Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/banners" className="text-primary">Banners</Link></li>
                                <li className="breadcrumb-item active">Add Banner</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className='row'>
                                                    <div className="col-6 mt-2">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Banner Image(Desktop) <span className="text-danger">*</span></label>
                                                            <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={this.heroItemImageSelectedHandler} />
                                                            <p style={{ color: "red", fontSize: "12px" }}>Image size should be less than 1 mb</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 mt-2">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Banner Image(Mobile) <span className="text-danger">*</span></label>
                                                            <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={this.heroItemMobileImageSelectedHandler} />
                                                            <p style={{ color: "red", fontSize: "12px" }}>Image size should be less than 1 mb</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Banner Type <span className="text-danger">*</span></label>
                                                            <select name="banner_type" className="form-control text-capitalize mt-2" required onChange={this.handleBannerType}>
                                                                <option value='popup'>Popup</option>
                                                                <option value='landing'>Landing</option>
                                                                <option value='top_right'>Top Right Landing</option>
                                                                <option value='bottom_right'>Bottom Right Landing</option>
                                                                <option value='menu'>Menu</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority <span className="text-danger">*</span></label>
                                                            <input id="priority" type="number" onWheel={(e:any) => e.target.blur()} name="priority" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Priority" className="input-material" onChange={this.handleBannerType} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Link with <span className="text-danger">*</span></label>
                                                            <select name="sync_type" className="form-control text-capitalize mt-2" required onChange={this.handleBannerType}>
                                                                <option value='items'>Menu item</option>
                                                                <option value='combos'>Combos</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {this.state.sync_type === "items" &&
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Items <span className="text-danger">*</span></label>
                                                                <Select
                                                                    name="items"
                                                                    options={menuItemsForBanners}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleItemsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.sync_type === "combos" &&
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Combos <span className="text-danger">*</span></label>
                                                                <Select
                                                                    name="coupons"
                                                                    options={combosForBanners}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleVouchersInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-12 mt-3" >
                                                        <div>
                                                            <input id="radioCustom1" type="radio" name="settime" value="alltime" checked={settime === 'alltime'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom1">Available for all time</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-12 mt-3" >
                                                        <div>
                                                            <input id="radioCustom2" type="radio" name="settime" value="customtime" checked={settime === 'customtime'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom2">Available for specific time</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-12 mt-3" >
                                                        <div>
                                                            <input id="checkboxCustom2" type="checkbox" name="specific_days" checked={specific_days} onChange={this.handleSpecificDaysCheck} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom2">Available for specific days</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-sm-4 col-6 mt-5" >
                                                        <div>
                                                            <input id="radioCustom3" type="radio" name="is_lsm" value="0" checked={is_lsm == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Available for all stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-6 mt-5" >
                                                        <div>
                                                            <input id="radioCustom4" type="radio" name="is_lsm" value="1" checked={is_lsm == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Available for specific stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-6 mt-5" >
                                                        <div>
                                                            <input id="radioCustom5" type="radio" name="is_lsm" value="2" checked={is_lsm == '2'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom5">Available for stores except</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    settime === 'customtime' &&
                                                    <div>
                                                        <div className="row mt-3">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-lable">Banner Timing</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <input id="itemstart" type="date" name="itemstart" required data-msg="Please enter starting date" className="input-material" onChange={this.handleChangeRad} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <input id="itemclose" type="date" name="itemclose" required data-msg="Please enter closing date" className="input-material" onChange={this.handleChangeRad} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    {
                                                        is_lsm == '1' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    {
                                                        is_lsm == '2' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                {specific_days &&
                                                    <div className='row'>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                {
                                                                    daysTiming.map((x: any, i: any) => {
                                                                        let timePeriod = x.timePeriods;
                                                                        return (
                                                                            <div key={i} className='mb-2' style={{ borderBottom: "1px dotted grey" }}>
                                                                                <div className="row">
                                                                                    <div className="col-lg-8 col-md-8 col-8">
                                                                                        <div className="form-group">
                                                                                            <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                                            <Select
                                                                                                options={this.weeklyDays.filter(o1 => !daysTiming.some((o2: any) => o1.value === o2.day))}
                                                                                                className="text-capitalize basic-multi-select"
                                                                                                classNamePrefix="select"
                                                                                                value={{ value: x.value, label: x.label }}
                                                                                                onChange={(e) => this.handleWeeklyDaysInputChange(e, i)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4 col-md-4 col-4 mt-4 py-2">
                                                                                        {daysTiming.length !== 1 &&
                                                                                            <button className="btn btn-sm btn-outline-danger"
                                                                                                onClick={() => this.handleWeeklyDaysRemoveClick(i)}><i className="fa fa-trash"></i></button>}
                                                                                        {(daysTiming.length - 1 === i && daysTiming.length < 7) && <button className="btn btn-sm btn-primary ml-2" onClick={(e: any) => this.handleweeklyDaysAddClick(e, i)}><i className="fa fa-plus"></i></button>}
                                                                                    </div>
                                                                                </div>
                                                                                {timePeriod.map((t: any, pindex: any) => {
                                                                                    return (
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-lg-3 col-md-3">
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 col-6">
                                                                                                <div className="form-group">
                                                                                                    <label className="form-control-label mb-0">Open Time <span className="text-danger">*</span></label>
                                                                                                    <input id="open" type="time" name="open" value={t.open} className="input-material" onChange={(e) => this.handleWeeklyDaysInputChange(e, i, pindex)} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 col-6">
                                                                                                <div className="form-group">
                                                                                                    <label className="form-control-label mb-0">Close Time <small className="text-danger">*</small></label>
                                                                                                    <input id="close" type="time" name="close" value={t.close} className="input-material" onChange={(e) => this.handleWeeklyDaysInputChange(e, i, pindex)} />
                                                                                                    {(timePeriod.length - 1 == pindex) && <small className="text-primary" style={{ cursor: 'pointer' }} onClick={(e: any) => this.handleweeklyDaysAddClick(e, i, pindex)}>+ Add hours</small>}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 col-12">
                                                                                                {timePeriod.length !== 1 &&
                                                                                                    <button className="btn btn-sm btn-outline-danger"
                                                                                                        onClick={() => this.handleWeeklyDaysRemoveClick(i, pindex)}><i className="fa fa-trash"></i></button>}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isBannerReady()} onClick={this.handleSaveBtnClick} >Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        menuItemsForBanners: state.menu.menuItemsForBanners,
        combosForBanners: state.menu.combosForBanners,
        stores: state.menu.storesoptions
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: () => { dispatch(logoutUser()); },
        addBanner: (data: any) => { dispatch(addBanner(data)); },
        itemsListForBanners: function () {
            dispatch(itemsListForBanners())
        },
        storesList: () => {
            dispatch(storesListForMultiSelect())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddBanner);