import React, { Component } from 'react';
import { connect } from 'react-redux'
import { brandsList, menusList, logoutUser, addDiscount, groupsListForMultiSelect, itemsListForMultiSelect } from '../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import Select from 'react-select';
import CheckChanges from '../../components/confirmOnLeave'
import { EditFPDiscountProps, EditFPDiscountState } from '../../interfaces/fpDiscount';
import { addFoodPandaDiscount, editFPDiscount, getFPDiscount } from '../../redux/actions/discountAction';
class EditFPDiscount extends Component<EditFPDiscountProps, EditFPDiscountState> {
    constructor(props: any) {
        super(props);
        this.state = {
            fp_pos_code: "",
            fp_discount_name: "",

        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        document.title = "Crust Culture | Discounts"
        let id = this.props.match.params.id;
        this.props.getDiscount(id)

    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        console.log(nextProps, "nextPropsnextProps");

        if (nextProps && nextProps.data) {
            this.setState({
                fp_discount_name: nextProps.data.fp_discount_name,
                fp_pos_code: nextProps.data.fp_pos_code

            })
        }
    }

    isDiscountReady = () => {
        const { fp_pos_code, fp_discount_name } = this.state;

        return (fp_discount_name !== "" && fp_pos_code !== "")
    }
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    handleSaveBtnClick = () => {
        let { fp_discount_name, fp_pos_code } = this.state;
        let id = this.props.match.params.id;
        let data: any = {
            fp_discount_name: fp_discount_name,
            fp_pos_code: fp_pos_code
        }

        this.props.editDiscount(id, data);
    }

    handleDiscountLevel(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        return (
            <div className="page">
                <CheckChanges path="/add-discount" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">FoodPanda Discounts Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/fpDiscount" className="text-primary">FoodPanda Discounts</Link></li>
                                <li className="breadcrumb-item active">Edit FoodPanda Discount</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Foodpanda Discount Name<span className="text-danger">*</span></label>
                                                            <input id="fp_discount_name" type="text" name="fp_discount_name" value={this.state.fp_discount_name} required data-msg="Please enter POS Code" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">POS Voucher Code<span className="text-danger">*</span></label>
                                                            <input id="fp_pos_code" type="text" name="fp_pos_code" value={this.state.fp_pos_code} required data-msg="Please enter POS Code" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isDiscountReady()} onClick={() => this.handleSaveBtnClick()}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        data: state.discount.fpdiscountData,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        editDiscount: function (data: any, history: any) {
            dispatch(editFPDiscount(data, history));
        },
        getDiscount: function (id: any) {
            dispatch(getFPDiscount(id));
        },


    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditFPDiscount);