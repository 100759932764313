import React, { Component } from 'react';
import { connect } from 'react-redux'
import { editNotification, getNotification, logoutUser } from '../../redux'
import { Link, Redirect } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { secretKey } from '../../secret';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
import { EditPushProps, EditPushState } from '../../interfaces/notifications';
import moment from 'moment';
class EditNotification extends Component<EditPushProps, EditPushState> {
    constructor(props: any) {
        super(props);
        this.state = {
            title: "",
            description: "",
            expiryDate: "",
            error: "",
            is_schedule: false,
            scheduleDate: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getNotification(id);
        document.title = "Crust Culture | Push Notifications"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any, nextState: any) {
        this.setState({
            title: nextProps.notificationData.title,
            description: nextProps.notificationData.description,
            expiryDate: nextProps.notificationData.expire_date,
            is_schedule: nextProps.notificationData.is_schedule == 1 ? true : false,
            scheduleDate: nextProps.notificationData.pn_start_time ? moment(nextProps.notificationData.pn_start_time).utc(false).format('YYYY-MM-DDTHH:mm:ss') : ""
        })
    }
    handleSchedule = (event: { target: { name: any; value: any; }; }) => {
        const { is_schedule } = this.state;
        this.setState({ is_schedule: !is_schedule })
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.name == "description" && event.target.value.length > 150) {
            this.setState({
                error: "Description must be 150 characters"
            });
        } else {
            this.setState({
                error: ""
            });
        }
    }
    isNotificationReady = () => {
        const { title, description, expiryDate, is_schedule, scheduleDate } = this.state
        if (is_schedule) {
            return (title !== "" && (description && description.length <= 150) && expiryDate !== "" && scheduleDate !== "");
        } else {
            return (title !== "" && (description && description.length <= 150) && expiryDate !== "");
        }

    }
    handleSubmit = (event: any) => {
        let { title, description, expiryDate, is_schedule, scheduleDate } = this.state;
        let id = this.props.match.params.id;
        let data: any = {
            title: title,
            body: description,
            expire_date: expiryDate
        }
        if (is_schedule) {
            data.is_schedule = 1;
            data.pn_start_time = scheduleDate;
        } else {
            data.is_schedule = 0;
            data.pn_start_time = null;
        }
        this.props.editNotification(id, data)
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let today = moment().format("YYYY-MM-DDThh:mm");
        const { notificationData } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/edit-promo" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Compaigns Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/marketing/2" className="text-primary">Compaigns</Link></li>
                                <li className="breadcrumb-item active">Edit Push Notification</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Title <span className="text-danger">*</span></label>
                                                            <input id="title" type="text" defaultValue={notificationData.title} name="title" required data-msg="Please enter title" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description <span className="text-danger">*</span></label>
                                                            <input id="description" type="text" name="description" defaultValue={notificationData.description} required data-msg="Please enter Description" className="input-material" onChange={this.handleInputChange} />
                                                            <small className="form-text text-danger">{this.state.error}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Valid till <span className="text-danger">*</span></label>
                                                            <input id="expiryDate" type="date" name="expiryDate" defaultValue={notificationData.expire_date} required data-msg="Please enter Expire Date" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-6 my-2">
                                                        <div>
                                                            <input id="checkboxCustom3" type="checkbox" name="is_schedule" checked={this.state.is_schedule} onChange={this.handleSchedule} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom3">Schedule Notifications</label>
                                                        </div>
                                                    </div>
                                                    {this.state.is_schedule &&
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label mb-3">Schedule Notification <span className="text-danger">*</span></label>
                                                                <input type="datetime-local" name="scheduleDate" defaultValue={this.state.scheduleDate} min={today} data-msg="Please enter Date" placeholder="DD/MM/YYYY" className="form-control" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="form-group float-right">
                                                    <button onClick={this.handleSubmit} disabled={!this.isNotificationReady()} className="btn btn-primary">Update Notification</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        notificationData: state.notification.notificationData
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        editNotification: function (id: any, data: any) {
            dispatch(editNotification(id, data));
        },
        getNotification: function (id: number) {
            dispatch(getNotification(id));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditNotification);