import React, { Component } from 'react';
import { connect } from 'react-redux'
import Topbar from '../../../../components/topbar'
import Sidebar from '../../../../components/sidebar'
import Footer from '../../../../components/footer/main'
import CheckChanges from '../../../../components/confirmOnLeave'
import { editNutItem, getNutItem, itemsListForMultiSelect, nutritionalCatList } from '../../../../redux'
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { EditNutItemProps, EditNutItemState } from '../../../../interfaces/menu';
class EditNutItem extends Component<EditNutItemProps, EditNutItemState> {
    constructor(props: any) {
        super(props);
        this.state = {
            itemName: "",
            nutCatId: "",
            menuItem: "",
            nutCatlabel: "",
            is_variation: 0,
            total_piece: "",
            cal_per_piece: "",
            total_cal: "",
            total_fat: "",
            trans_fat_acid: "",
            cholesterol: "",
            sat_fat: "",
            total_carbs: "",
            sugar: "",
            fibre: "",
            protein: "",
            sodium: "",
            sizejson: [
                {
                    variant_name: "",
                    total_piece: "",
                    cal_per_piece: "",
                    total_cal: "",
                    total_fat: "",
                    trans_fat_acid: "",
                    cholesterol: "",
                    sat_fat: "",
                    total_carbs: "",
                    sugar: "",
                    fibre: "",
                    protein: "",
                    sodium: ""
                }
            ]
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        document.title = "Crust Culture | Nutritional Items"
        let id = this.props.match.params.id;
        this.props.getNutItem(id);
        this.props.nutritionalCatList()
        this.props.itemsListForMultiSelect()
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (nextProps.nutItemData.is_variation == "0") {
            this.setState({
                itemName: nextProps.nutItemData.item_name,
                nutCatId: nextProps.nutItemData.nutritional_cat_id ? nextProps.nutItemData.nutritional_cat_id : "",
                menuItem: nextProps.nutItemData.menu_item_id ? nextProps.nutItemData.menu_item_id.menu_item_id : "",
                is_variation: nextProps.nutItemData.is_variation,
                total_piece: nextProps.nutItemData.total_piece,
                cal_per_piece: nextProps.nutItemData.calories_per_piece,
                total_cal: nextProps.nutItemData.total_calories,
                total_fat: nextProps.nutItemData.total_fat,
                trans_fat_acid: nextProps.nutItemData.trans_fatty_acid,
                cholesterol: nextProps.nutItemData.cholesterol,
                sat_fat: nextProps.nutItemData.sat_fat,
                total_carbs: nextProps.nutItemData.total_carbs,
                sugar: nextProps.nutItemData.sugars,
                fibre: nextProps.nutItemData.dietary_fibre,
                protein: nextProps.nutItemData.protein,
                sodium: nextProps.nutItemData.sodium,
            })
        } else if (nextProps.nutItemData.is_variation == "1") {
            let optionsObj: any = []
            for (let i = 0; i < nextProps.nutItemData.nutritional_variation_id.length; i++) {
                let obj: any = {
                    variant_name: nextProps.nutItemData.nutritional_variation_id[i].variation,
                    total_piece: nextProps.nutItemData.nutritional_variation_id[i].total_piece,
                    cal_per_piece: nextProps.nutItemData.nutritional_variation_id[i].calories_per_piece,
                    total_cal: nextProps.nutItemData.nutritional_variation_id[i].total_calories,
                    total_fat: nextProps.nutItemData.nutritional_variation_id[i].total_fat,
                    trans_fat_acid: nextProps.nutItemData.nutritional_variation_id[i].trans_fatty_acid,
                    cholesterol: nextProps.nutItemData.nutritional_variation_id[i].cholesterol,
                    sat_fat: nextProps.nutItemData.nutritional_variation_id[i].sat_fat,
                    total_carbs: nextProps.nutItemData.nutritional_variation_id[i].total_carbs,
                    sugar: nextProps.nutItemData.nutritional_variation_id[i].sugars,
                    fibre: nextProps.nutItemData.nutritional_variation_id[i].dietary_fibre,
                    protein: nextProps.nutItemData.nutritional_variation_id[i].protein,
                    sodium: nextProps.nutItemData.nutritional_variation_id[i].sodium
                }
                optionsObj.push(obj)
            }
            this.setState({
                itemName: nextProps.nutItemData.item_name,
                nutCatId: nextProps.nutItemData.nutritional_cat_id ? nextProps.nutItemData.nutritional_cat_id : "",
                menuItem: nextProps.nutItemData.menu_item_id ? nextProps.nutItemData.menu_item_id.menu_item_id : "",
                is_variation: nextProps.nutItemData.is_variation,
                sizejson: optionsObj
            })
        }
    }

    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    isNutritionalItemsReady = () => {
        const { itemName, nutCatId, menuItem, is_variation, total_piece, total_cal, cal_per_piece, sugar, sat_fat, total_fat, total_carbs, protein, fibre, trans_fat_acid, cholesterol, sodium } = this.state
        if (is_variation == '0') {
            return (itemName !== "" && nutCatId !== "" && menuItem !== "" && total_piece !== "" && total_cal !== "" && cal_per_piece !== "" && sat_fat !== "" && total_fat !== "" && fibre !== "" && total_carbs !== "" && sugar !== "" && protein !== "" && trans_fat_acid !== "" && cholesterol !== "" && sodium !== "");
        } else {
            return (itemName !== "" && nutCatId !== "" && menuItem !== "");
        }
    }
    handleCategory = (e: any, index: any) => {
        if (e && e.value !== "") {
            this.setState({ nutCatId: e.value });
        } else {
            this.setState({ nutCatId: "" });
        }
    };
    handleMenuItem = (e: any, index: any) => {

        if (e && e.menu_item_id !== "") {
            console.log(e.menu_item_id, "sdasadsa");
            this.setState({ menuItem: e.menu_item_id });
        } else {
            this.setState({ menuItem: "" });
        }
    };
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    handleVariantInputChange = (e: any, index: any) => {
        const { name, value } = e.target;
        const list: any = this.state.sizejson;
        list[index][name] = value;
        this.setState({ sizejson: list });
    };
    // handle click event of the Remove button
    handleRemoveClick = (index: any) => {
        const list = this.state.sizejson;
        list.splice(index, 1);
        this.setState({ sizejson: list });
    };
    // handle click event of the Add button
    handleAddClick = (i: any) => {
        const list: any = this.state.sizejson;
        if (list[i]["variant_name"] == "" && list[i]["total_piece"] == "" && list[i]["total_cal"] == "" &&
            list[i]["cal_per_piece"] == "" && list[i]["sugar"] == "" && list[i]["sat_fat"] == "" &&
            list[i]["total_fat"] == "" && list[i]["total_carbs"] == "" && list[i]["protein"] == "" &&
            list[i]["fibre"] == "" && list[i]["trans_fat_acid"] == "" && list[i]["cholesterol"] == "" && list[i]["sodium"] == "") {
            alert("Please fill all mandatory variant fields")
        } else {
            let obj: any = {
                variant_name: "",
                total_piece: "",
                cal_per_piece: "",
                total_cal: "",
                total_fat: "",
                trans_fat_acid: "",
                cholesterol: "",
                sat_fat: "",
                total_carbs: "",
                sugar: "",
                fibre: "",
                protein: "",
                sodium: ""
            }
            list.push(obj)
            this.setState({ sizejson: list })
        }
    };
    handleSaveBtnClick = (event: any) => {
        const { itemName, nutCatId, menuItem, sizejson, is_variation, total_piece, total_cal, cal_per_piece, sugar, sat_fat, total_fat, total_carbs, protein, fibre, trans_fat_acid, cholesterol, sodium } = this.state
        let id = this.props.match.params.id;
        let data: any = {
            item_name: itemName,
            nutritional_cat_id: nutCatId,
            menu_item_id: menuItem,
            is_variation: is_variation
        }
        if (is_variation == 0) {
            data.total_piece = total_piece;
            data.total_calories = total_cal;
            data.calories_per_piece = cal_per_piece;
            data.sugars = sugar;
            data.sat_fat = sat_fat;
            data.total_fat = total_fat;
            data.total_carbs = total_carbs;
            data.protein = protein;
            data.dietary_fibre = fibre;
            data.trans_fatty_acid = trans_fat_acid;
            data.cholesterol = cholesterol;
            data.sodium = sodium;
            this.props.editNutItem(id, data);
        } else {
            data.variants = JSON.stringify(sizejson)
            if (sizejson[sizejson.length - 1]["variant_name"] !== "" && sizejson[sizejson.length - 1]["total_piece"] !== "" && sizejson[sizejson.length - 1]["total_cal"] !== '' &&
                sizejson[sizejson.length - 1]["cal_per_piece"] !== "" && sizejson[sizejson.length - 1]["sugar"] !== "" && sizejson[sizejson.length - 1]["sat_fat"] !== '' &&
                sizejson[sizejson.length - 1]["total_fat"] !== "" && sizejson[sizejson.length - 1]["total_carbs"] !== "" && sizejson[sizejson.length - 1]["protein"] !== '' &&
                sizejson[sizejson.length - 1]["fibre"] !== "" && sizejson[sizejson.length - 1]["trans_fat_acid"] !== "" && sizejson[sizejson.length - 1]["cholesterol"] !== '' && sizejson[sizejson.length - 1]["sodium"] !== '') {
                this.props.editNutItem(id, data);
            } else {
                alert("Please fill all mandatory variant fields")
            }
        }
    }
    render() {
        let itemArry: any = []
        let { is_variation, sizejson, nutCatId, nutCatlabel, menuItem } = this.state;
        let { nutItemData } = this.props;
        this.props.items && this.props.items.map((obj: any) => {
            console.log(obj, "sdadad");
            if (obj.menu_item_id === menuItem) {
                let obj2 = {
                    value: obj.menu_item_id,
                    label:obj.label
                }
             itemArry.push(obj2)   
            }

        })
        return (
            <div className="page">
                <CheckChanges path="/add-meal" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Nutritional Items Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/nut-items" className="text-primary">Nutritional Items</Link></li>
                                <li className="breadcrumb-item active">Edit Item</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Item Name <span className="text-danger">*</span></label>
                                                            <input id="itemName" type="text" name="itemName" defaultValue={nutItemData.item_name} required data-msg="Please enter item name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Nutritional Categories <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="nutCategories"
                                                                isClearable
                                                                options={this.props.nutCategoriesForItems}
                                                                value={{ value: nutCatId, label: "Pizza" }}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleCategory(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Menu Item <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="nutCategories"
                                                                isClearable
                                                                options={this.props.items}
                                                                value={itemArry}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleMenuItem(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-sm-6 col-6 mb-3" >
                                                        <div >
                                                            <input id="radioCustom3" type="radio" name="is_variation" value="0" checked={is_variation == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">No Variation</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6 col-6 mb-3" >
                                                        <div >
                                                            <input id="radioCustom4" type="radio" name="is_variation" value="1" checked={is_variation == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Multiple Variation</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {is_variation == "0" ?
                                                    <>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Total Piece <span className="text-danger">*</span></label>
                                                                    <input id="total_piece" type="text" name="total_piece" defaultValue={nutItemData.total_piece} required className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Calories per piece <span className="text-danger">*</span></label>
                                                                    <input id="cal_per_piece" type="text" name="cal_per_piece" defaultValue={nutItemData.calories_per_piece} required className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Total Calories <span className="text-danger">*</span></label>
                                                                    <input id="total_cal" type="text" name="total_cal" defaultValue={nutItemData.total_calories} required className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Total Fat<span className="text-danger">*</span></label>
                                                                    <input id="total_fat" type="text" name="total_fat" defaultValue={nutItemData.total_fat} required className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Saturated Fat <span className="text-danger">*</span></label>
                                                                    <input id="sat_fat" type="text" name="sat_fat" defaultValue={nutItemData.sat_fat} required className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Trans Fatty Acid <span className="text-danger">*</span></label>
                                                                    <input id="trans_fat_acid" type="text" name="trans_fat_acid" defaultValue={nutItemData.trans_fatty_acid} required className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Cholesterol <span className="text-danger">*</span></label>
                                                                    <input id="cholesterol" type="text" name="cholesterol" defaultValue={nutItemData.cholesterol} required className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Sodium <span className="text-danger">*</span></label>
                                                                    <input id="sodium" type="text" name="sodium" defaultValue={nutItemData.sodium} required className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Total Carbs <span className="text-danger">*</span></label>
                                                                    <input id="total_carbs" type="text" name="total_carbs" defaultValue={nutItemData.total_carbs} required className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Sugars <span className="text-danger">*</span></label>
                                                                    <input id="sugar" type="text" name="sugar" defaultValue={nutItemData.sugars} required className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Dietary Fibre<span className="text-danger">*</span></label>
                                                                    <input id="fibre" type="text" name="fibre" defaultValue={nutItemData.dietary_fibre} required className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Protein <span className="text-danger">*</span></label>
                                                                    <input id="protein" type="text" name="protein" defaultValue={nutItemData.protein} required className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> :
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                {console.log("sizejson",sizejson)}
                                                                {sizejson.map((x: any, ind: any) => {
                                                                    return (
                                                                        <div key={ind}>
                                                                            <div className="row">
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Variant Name<span className="text-danger">*</span></label>
                                                                                        <input id="variant_name" type="text" name="variant_name" value={x.variant_name} required className="input-material" onChange={e => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Total Piece <span className="text-danger">*</span></label>
                                                                                        <input id="total_piece" type="text" name="total_piece" value={x.total_piece} required className="input-material" onChange={e => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Calories per piece <span className="text-danger">*</span></label>
                                                                                        <input id="cal_per_piece" type="text" name="cal_per_piece" value={x.cal_per_piece} required className="input-material" onChange={e => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Total Calories <span className="text-danger">*</span></label>
                                                                                        <input id="total_cal" type="text" name="total_cal" value={x.total_cal} required className="input-material" onChange={e => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Total Fat<span className="text-danger">*</span></label>
                                                                                        <input id="total_fat" type="text" name="total_fat" value={x.total_fat} required className="input-material" onChange={e => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Saturated Fat <span className="text-danger">*</span></label>
                                                                                        <input id="sat_fat" type="text" name="sat_fat" value={x.sat_fat} required className="input-material" onChange={e => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Trans Fatty Acid <span className="text-danger">*</span></label>
                                                                                        <input id="trans_fat_acid" type="text" name="trans_fat_acid" value={x.trans_fat_acid} required className="input-material" onChange={e => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Cholesterol <span className="text-danger">*</span></label>
                                                                                        <input id="cholesterol" type="text" name="cholesterol" value={x.cholesterol} required className="input-material" onChange={e => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Sodium <span className="text-danger">*</span></label>
                                                                                        <input id="sodium" type="text" name="sodium" value={x.sodium} required className="input-material" onChange={e => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Total Carbs <span className="text-danger">*</span></label>
                                                                                        <input id="total_carbs" type="text" name="total_carbs" value={x.total_carbs} required className="input-material" onChange={e => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Sugars <span className="text-danger">*</span></label>
                                                                                        <input id="sugar" type="text" name="sugar" value={x.sugar} required className="input-material" onChange={e => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Dietary Fibre<span className="text-danger">*</span></label>
                                                                                        <input id="fibre" type="text" name="fibre" value={x.fibre} required className="input-material" onChange={e => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Protein <span className="text-danger">*</span></label>
                                                                                        <input id="protein" type="text" name="protein" value={x.protein} required className="input-material" onChange={e => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6 d-flex align-items-center">
                                                                                    <div className="form-group">
                                                                                        {sizejson.length !== 1 &&
                                                                                            <button className="btn btn-sm btn-outline-danger" onClick={() => this.handleRemoveClick(ind)}><i className="fa fa-trash"></i></button>}
                                                                                        {sizejson.length - 1 === ind && <button className="btn btn-sm btn-primary ml-2 float-right" onClick={() => this.handleAddClick(ind)}><i className="fa fa-plus"></i></button>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="form-group float-right mt-3">
                                                    <button onClick={() => { this.props.history.push("/nut-items") }} className="btn btn-danger mr-2">Cancel</button>
                                                    <button className='btn btn-primary' disabled={!this.isNutritionalItemsReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div >
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        nutCategoriesForItems: state.menu.nutCategoriesForItems,
        nutItemData: state.menu.nutItemData,
        items: state.menu.allActiveItems
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        editNutItem: (id: any, data: any) => {
            dispatch(editNutItem(id, data));
        },
        getNutItem: function (id: number) {
            dispatch(getNutItem(id));
        },
        nutritionalCatList: () => {
            dispatch(nutritionalCatList())
        },
        itemsListForMultiSelect: function () {
            dispatch(itemsListForMultiSelect())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditNutItem);