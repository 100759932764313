import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { getSuggCombos, itemsListForBanners, logoutUser, pingConnectivity, updateSuggestiveDeals, fpMenuLogs } from '../../redux'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import { storesList, } from '../../redux/actions/storeAction';
import { fpCombosList, fpitemsList, fpschedule, getschedule } from '../../redux/actions/menuAction';
import Select from 'react-select'
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class FPSchedules extends Component<{ storesList: () => {}, items:any,itemsList:()=>{},combos: any,schedules:any, combosList:()=>{} ,getScheduleData:()=>{},scheduleSubmit:(data:any)=>{}, pingConnectivity: (data: any) => {}, logoutUser: () => {}, stores: any[], pingResponse: any, pingLoad: any }, { daysTiming:any}> {
    
    weeklyDays: { value: string; label: string; }[]

    constructor(props: any) {
        super(props);

        this.weeklyDays = [
            { value: 'MONDAY', label: 'Mon' },
            { value: 'TUESDAY', label: 'Tue' },
            { value: 'WEDNESDAY', label: 'Wed' },
            { value: 'THURSDAY', label: 'Thu' },
            { value: 'FRIDAY', label: 'Fri' },
            { value: 'SATURDAY', label: 'Sat' },
            { value: 'SUNDAY', label: 'Sun' },
        ];
    
        this.state = {
            daysTiming:[
                {
                    day: "",
                    label: "    ",
                    timePeriods: [{
                        open: "",
                        close: "",
                        combosJson: [],
                        itemsJson:[]
                    }], 
                    
                }
            ],
        }
        
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        
        if(nextProps.schedules.length !=0)
        {
            this.setState({
                daysTiming: nextProps.schedules
            })
        }
    
    }

    componentDidMount() {
        this.props.combosList()
        this.props.itemsList()
        this.props.getScheduleData()
        document.title = "Crust Culture | FP Schedules"
    
    }
    
    handleSubmit = () => {  

        let data = this.state
        this.props.scheduleSubmit(data);

    }
    
    handleWeeklyDaysInputChange = (e: any, index: any, pindex?: any) => {
        
        if (e.target) {
            const { name, value } = e.target;
            const list: any = this.state.daysTiming;
            
            let timeP = list[index].timePeriods;
            let oldClose = timeP[pindex].close
            let oldStart = timeP[pindex].open
            timeP[pindex][name] = value;
            list[index].timePeriods = timeP;

            if(name =='close'){
                if(list[index].timePeriods[pindex].open == ''){
                    alert("Please Fill Start Time First")
                    list[index].timePeriods[pindex].close = oldClose
                }
            }

            if(list[index].timePeriods[pindex].close !=""){

                
                if(list[index].timePeriods[pindex].open>list[index].timePeriods[pindex].close){
                    alert("Close time should Be greater than open time")
                    if(name =='close')
                    {
                        list[index].timePeriods[pindex].close = oldClose
                    }
                    if(name=='open'){
                        list[index].timePeriods[pindex].open = oldStart
                    }
                }
                else
                {
                    if(name =='close')
                    {
                        if(list[index].timePeriods[pindex].open == ''){
                            alert("Please Fill Start Time First")
                            list[index].timePeriods[pindex].close = oldClose
                        }
                        else{
                            this.setState({ daysTiming: list });
                        }
                    }
                    else{
                        this.setState({ daysTiming: list });
                    }
                }
            }
            else{
                this.setState({ daysTiming: list });
            }
        } else {
            console.log("e", e)
            const list: any = this.state.daysTiming;
            list[index]["day"] = e.value;
            list[index]["label"] = e.label;
            this.setState({ daysTiming: list });
        }
    };

    handlecomboJson = (e: any, index: any, pindex: any, t:any) => {
       
        if(t.action =='select-option'){
            if (e) {
          
                const list: any = this.state.daysTiming;
                let timeP = list[index].timePeriods;
                timeP[pindex].combosJson.push({"label":e[e.length-1].label,value:e[e.length-1].value})
                console.log("das")
                this.setState({ daysTiming: list }); 
            } 
        }
        if(t.action =='remove-value') {

            let removevalue =t.removedValue.value
            const list: any = this.state.daysTiming;
            let timeP = list[index].timePeriods;
            let array = timeP[pindex].combosJson.filter((theitem:any) => theitem.value != removevalue);
            timeP[pindex].combosJson = array
            this.setState({ daysTiming: list });
            
        }
        if(t.action =='clear'){
            const list: any = this.state.daysTiming;
            let timeP = list[index].timePeriods
            timeP[pindex].combosJson =[]
            this.setState({ daysTiming: list });

        }
    
    };

    handleitemJson = (e: any, index: any, pindex: any, t:any) => {
       
        if(t.action =='select-option'){
            if (e) {
          
                const list: any = this.state.daysTiming;
                let timeP = list[index].timePeriods;
                timeP[pindex].itemsJson.push({"label":e[e.length-1].label,value:e[e.length-1].value})
                console.log("das")
                this.setState({ daysTiming: list }); 
            } 
        }
        if(t.action =='remove-value') {

            let removevalue =t.removedValue.value
            const list: any = this.state.daysTiming;
            let timeP = list[index].timePeriods;
            let array = timeP[pindex].itemsJson.filter((theitem:any) => theitem.value != removevalue);
            timeP[pindex].itemsJson = array
            this.setState({ daysTiming: list });
            
        }
        if(t.action =='clear'){
            const list: any = this.state.daysTiming;
            let timeP = list[index].timePeriods
            timeP[pindex].itemsJson =[]
            this.setState({ daysTiming: list });

        }
    
    };

    handleWeeklyDaysRemoveClick = (index: any, pindex?: any) => {
        const list = this.state.daysTiming;
        if (pindex >= 0) {
            let timePer = list[index].timePeriods;
            timePer.splice(pindex, 1);
            list[index].timePeriods = timePer;
            this.setState({ daysTiming: list });
        } else {
            list.splice(index, 1);
            this.setState({ daysTiming: list });
        }
    };

    handleweeklyDaysAddClick = (e: any, i: any, pindex?: any) => {
        const list: any = this.state.daysTiming;
        if (pindex >= 0) {
            if (list[i].timePeriods[list[i].timePeriods.length - 1].open == "" || list[i].timePeriods[list[i].timePeriods.length - 1].close == "") {
                alert("Please fill in selected day hours")
            } else {
                let timePeriods = {
                    open: "",
                    close: "",
                    combosJson: [],
                    itemsJson:[]
                    
                }
                list[i].timePeriods.push(timePeriods)
                this.setState({ daysTiming: list });
            }
        } else {
            if (list[i]["day"] == "" || list[i].timePeriods[list[i].timePeriods.length - 1].open == "" || list[i].timePeriods[list[i].timePeriods.length - 1].close == "") {
                alert("Please fill in selected day timing")
            } else {
                let timePeriods = {
                    open: "",
                    close: "",
                    combosJson: [],
                    itemsJson:[]
                }
                const newlist: any = {
                    day: "",
                    timePeriods: [],
                    
                }
                newlist.timePeriods.push(timePeriods)
                const days: any = this.state.daysTiming.concat(newlist)
                this.setState({ daysTiming: days });
            }
        }
        e.preventDefault()
    };

    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Food Panda Schedules</h4>
                                </div>
                            </div>
                        </header>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                                    <div className='row'>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                {
                                                                    this.state.daysTiming.map((x: any, i: any) => {
                                                                        let timePeriod = x.timePeriods;
                                                                        return (
                                                                            <div key={i} className='mb-2' style={{ borderBottom: "1px dotted grey" }}>
                                                                                <div className="row">
                                                                                    <div className="col-lg-8 col-md-8 col-8">
                                                                                        <div className="form-group">
                                                                                            <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                                            <Select
                                                                                                options={this.weeklyDays.filter(o1 => !this.state.daysTiming.some((o2: any) => o1.value === o2.day))}
                                                                                                className="text-capitalize basic-multi-select"
                                                                                                classNamePrefix="select"
                                                                                                value={{ value: x.value, label: x.label }}
                                                                                                onChange={(e) => this.handleWeeklyDaysInputChange(e, i)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4 col-md-4 col-4 mt-4 py-2">
                                                                                        {this.state.daysTiming.length !== 1 &&
                                                                                            <button className="btn btn-sm btn-outline-danger"
                                                                                                onClick={() => this.handleWeeklyDaysRemoveClick(i)}><i className="fa fa-trash"></i></button>}
                                                                                        {(this.state.daysTiming.length - 1 === i && this.state.daysTiming.length < 7) && <button className="btn btn-sm btn-primary ml-2" onClick={(e: any) => this.handleweeklyDaysAddClick(e, i)}><i className="fa fa-plus"></i></button>}
                                                                                    </div>
                                                                                </div>
                                                                                {timePeriod.map((t: any, pindex: any) => {
                                                                                    return (
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-lg-5 col-md-3">
                                                                                            <div className="row">
                                                                                                <div className="col-6" >
                                                                                                    <div className="form-group">
                                                                                                        <label className="form-control-label">Select Combos </label>
                                                                                                        <Select
                                                                                                            isMulti
                                                                                                            name="combojson"
                                                                                                            options={this.props.combos}
                                                                                                            value={t.combosJson}
                                                                                                            className="text-capitalize basic-multi-select mt-2"
                                                                                                            classNamePrefix="select"
                                                                                                            onChange={(e,t) => this.handlecomboJson(e, i,pindex,t)}
                                                                                                          />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-6" >
                                                                                                    <div className="form-group">
                                                                                                        <label className="form-control-label">Select Items </label>
                                                                                                        <Select
                                                                                                            isMulti
                                                                                                            name="itemsjson"
                                                                                                            options={this.props.items}
                                                                                                            value={t.itemsJson}
                                                                                                            className="text-capitalize basic-multi-select mt-2"
                                                                                                            classNamePrefix="select"    
                                                                                                            onChange={(e,t) => this.handleitemJson(e, i,pindex,t)}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 col-6">
                                                                                                <div className="form-group">
                                                                                                    <label className="form-control-label mb-0">Open Time <span className="text-danger">*</span></label>
                                                                                                    <input id="open" type="time" name="open" value={t.open} className="input-material" onChange={(e) => this.handleWeeklyDaysInputChange(e, i, pindex)} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 col-6">
                                                                                                <div className="form-group">
                                                                                                    <label className="form-control-label mb-0">Close Time <small className="text-danger">*</small></label>
                                                                                                    <input id="close" type="time" name="close" value={t.close} className="input-material" onChange={(e) => this.handleWeeklyDaysInputChange(e, i, pindex)} />
                                                                                                    {(timePeriod.length - 1 == pindex) && <small className="text-primary" style={{ cursor: 'pointer' }} onClick={(e: any) => this.handleweeklyDaysAddClick(e, i, pindex)}>+ Add hours</small>}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-1 col-md-3 col-12">
                                                                                                {timePeriod.length !== 1 &&
                                                                                                    <button className="btn btn-sm btn-outline-danger"
                                                                                                        onClick={() => this.handleWeeklyDaysRemoveClick(i, pindex)}><i className="fa fa-trash"></i></button>}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group d-flex justify-content-end mt-4">
                                                    {(this.props.pingLoad) ?
                                                        <button className='btn btn-primary d-flex justify-content-end align-item-center' disabled={this.props.pingLoad}><Loader type="TailSpin" color="white" height={30} width={30} /></button> :
                                                        <button onClick={this.handleSubmit}   className="btn btn-primary">Submit</button>
                                                    }
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>

                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        stores: state.report.stores,
        pingResponse: state.store.fpMenuLogs,
        pingLoad: state.store.pingLoad,
        combos: state.menu.fpcombos,
        items:state.menu.fpitems,
        schedules: state.menu.fpschedule
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: () => { dispatch(logoutUser()); },
        combosList: () => {
            dispatch(fpCombosList())
        },
        itemsList: () => {
            dispatch(fpitemsList())
        },

        pingConnectivity: (data: any) => { dispatch(fpMenuLogs(data)) },
        scheduleSubmit:(data:any)=>{dispatch(fpschedule(data))},
        getScheduleData:()=>{ dispatch(getschedule())}
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FPSchedules);