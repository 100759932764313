import { LOGIN_USER, FORGOT_PASS, EDIT_PROFILE, GET_PROFILE } from '../actions/userType'
const initialState: any = {
    userData: {},
    message: "",
    isUpdated: "",
    isLoggedIn: "",
    isReset: ""
}

const userReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOGIN_USER: return {
            isLoggedIn: action.isLoggedIn,
            message: action.payload
        }
        case FORGOT_PASS: return {
            isReset: action.isReset,
            message: action.payload
        }
        case GET_PROFILE: return {
            ...state,
            userData: action.payload
        }
        case EDIT_PROFILE: return {
            ...state,
            message: action.payload,
            isUpdated: action.isUpdated
        }
        default: return state;
    }
}
export default userReducer;