import {
    MENUS_LIST, GET_BANNER, GET_MENU, BANNERS_LIST, GROUPS_LIST, SUBGROUPS_LIST, ADD_GROUP, GET_GROUP, EDIT_GROUP,
    ITEMS_LIST, GET_ITEM, EDIT_ITEM, COMBOS_LIST, ADD_COMBO, COMBO_OPTIONS, GET_COMBO, SUGGESTIVE_COMBOS_LIST, MOD_GROUP_LIST,
    ADD_MOD_GROUP, MOD_LIST, ADD_MOD, COMBO_CHOICE_ITEMS,
    GET_MOD, EDIT_MOD, GET_MOD_GROUP, EDIT_MOD_GROUP, GROUP_OPTIONS, STORES_OPTIONS, CHECK_DUP_POS, CHECK_DUP_ERP, ACTIVE_ITEMS_OPTIONS, STATUS_CHANGE_REASONS, GET_BRANCH_MENU, SEARCH_MENU_ITEM, SEARCH_GROUPS, SEARCH_COMBOS, ORDER_MODES, MAKE_A_COMBO_LIST, GET_MAKE_A_COMBO, ITEMS, VARIANTS, GET_SUBGROUP, SEARCH_SUBGROUPS, ACTIVE_ITEMS_FOR_BANNERS, ACTIVE_COMBOS_FOR_BANNERS,
    NUT_CAT_LIST,
    GET_NUT_CAT,
    NUT_ITEM_LIST,
    GET_NUT_ITEM,
    NUT_CATEGORIES,
    SEARCH_MODIFIERS,
    SEARCH_VARIANTS,
    SUB_GROUP_OPTIONS,
    ACTIVE_MOD_GROUP_LIST,
    VARIANTS_LIST,
    ACTIVE_SUGG_COMBOS,
    COMBO_LOAD,
    ITEM_LOAD,
    GET_SUGGESTIVE,
    FP_MOD_LIST,
    MENU_STORE_OPTIONS, 
    FP_COMBO,
    FP_SCHEDULE,
    FP_ITEM,
    BS_LIMIT
} from '../actions/menuType'
const initialState: any = {
    menus: [] || "",
    groups: [] || "",
    subGroups: [] || "",
    items: [] || "",
    combos: [] || "",
    suggestive_combos_list: [] || "",
    modgroups: [] || "",
    activeModgroups: [] || "",
    addons: [] || "",
    crusts: [] || "",
    topings: [] || "",
    flavours: [] || "",
    condiments: [] || "",
    modifiers: [] || "",
    fpModifiers:[]||"",
    combooptions: [] || "",
    groupsptions: [] || "",
    subgroupsptions: [] || "",
    allActiveItems: [] || "",
    storesoptions: [] || "",
    variants: [] || "",
    banners: [] || "",
    ingCat: [] || "",
    ingUom: [] || "",
    menuData: {},
    groupData: {},
    subgroupData: {},
    itemData: {},
    comboData: {},
    modData: {},
    bannerData: {},
    menuItemsForBanners: [] || "",
    combosForBanners: [] || "",
    suggestiveCombos: [] || "",
    suggestiveData: [] || "",
    modGroupData: {},
    make_a_combo_list: [] || "",
    make_a_combo: {},
    sizejson: [
        {
            id: "",
            pos_code: "",
            erp_id: "",
            size: "",
            cost: "",
            mrp: "",
            extra_price: "",
            description: "",
            image_url: null
        }
    ],
    comboChoices: [
        {
            mod_groups: [],
            crusts: [],
            flavours: [],
            condiments: [],
            topings: [],
            group_name: "",
            is_half_n_half: false,
            if_full_pizza: false,
        }
    ],
    comboChoiceItems: [] || "",
    choiceIndex: "",
    isInserted: "",
    isUpdated: "",
    message: "",
    duplicateItemPos: false,
    duplicateItemErp: false,
    groupsData: [] || "",
    subgroupsData: [] || "",
    itemsData: [] || "",
    combosData: [] || "",
    modifiersData: [] || "",
    searchGroupData: [] || "",
    searchSubGroupData: [] || "",
    searchItemData: [] || "",
    searchVariantData: [] || "",
    searchComboData: [] || "",
    searchModData: [] || "",
    statusChangeReasons: [] || "",
    orderModes: [] || "",
    itemsByGroup: [] || "",
    variantsByItem: [] || "",
    nutCategories: [] || "",
    nutCategoriesForItems: [] || "",
    nutCategoryData: {},
    nutItems: [] || "",
    nutItemData: {},
    comboLoad:false,
    itemLoad:false,
    menuStoresOptions:[]||"",
    fpcombos:[],
    fpitems:[],
    fpschedule:[],
    bsLimit: false
}

const menuReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case MENUS_LIST: return {
            ...state,
            menus: action.payload
        }
        case GET_BANNER: return {
            ...state,
            bannerData: action.payload
        }
        case GET_MENU: return {
            ...state,
            menuData: action.payload
        }
        case BANNERS_LIST: return {
            ...state,
            banners: action.payload
        }
        case GROUPS_LIST: return {
            ...state,
            groups: action.payload
        }
        case SUBGROUPS_LIST: return {
            ...state,
            subGroups: action.payload
        }
        case BS_LIMIT: return {
            ...state,
            bsLimit: action.payload
        }
        case GROUP_OPTIONS: return {
            ...state,
            groupsptions: action.payload
        }
        case SUB_GROUP_OPTIONS: return {
            ...state,
            subgroupsptions: action.payload
        }
        case GET_SUGGESTIVE: return {
            ...state,
            suggestiveData: action.payload
        }

        case ACTIVE_ITEMS_OPTIONS: return {
            ...state,
            allActiveItems: action.payload
        }
        case STORES_OPTIONS: return {
            ...state,
            storesoptions: action.payload
        }
        case VARIANTS_LIST: return {
            ...state,
            variants: action.payload
        }
        case ADD_GROUP: return {
            ...state,
            isInserted: action.isInserted,
            message: action.payload
        }
        case GET_GROUP: return {
            ...state,
            groupData: action.payload
        }
        case GET_SUBGROUP: return {
            ...state,
            subgroupData: action.payload
        }
        case EDIT_GROUP: return {
            ...state,
            isUpdated: action.isUpdated,
            message: action.payload
        }
        case GET_ITEM: return {
            ...state,
            sizejson: action.sizejson,
            itemData: action.payload
        }
        case EDIT_ITEM: return {
            ...state,
            isUpdated: action.isUpdated,
            message: action.payload
        }
        case ITEMS_LIST: return {
            ...state,
            items: action.payload
        }
        case COMBOS_LIST: return {
            ...state,
            combos: action.payload
        }
        case ADD_COMBO: return {
            ...state,
            isInserted: action.isInserted,
            message: action.payload
        }
        case COMBO_OPTIONS: return {
            ...state,
            combooptions: action.payload
        }
        case GET_COMBO: return {
            ...state,
            comboChoices: action.comboChoices,
            comboData: action.payload
        }
        case COMBO_LOAD: return {
            ...state,
            comboLoad: action.payload
        }
        case ITEM_LOAD: return {
            ...state,
            itemLoad: action.payload
        }
        case ACTIVE_MOD_GROUP_LIST: return {
            ...state,
            activeModgroups: action.payload,
            addons: action.payload.filter((obj: any) => { return obj.is_addon == 1 }),
            crusts: action.payload.filter((obj: any) => { return obj.is_crust == 1 }),
            topings: action.payload.filter((obj: any) => { return obj.is_toping == 1 }),
            flavours: action.payload.filter((obj: any) => { return obj.is_flavour == 1 }),
            condiments: action.payload.filter((obj: any) => { return obj.is_condiment == 1 }),
        }
        case MOD_GROUP_LIST: return {
            ...state,
            modgroups: action.payload
        }
        case ADD_MOD_GROUP: return {
            ...state,
            isInserted: action.isInserted,
            message: action.payload
        }
        case GET_MOD_GROUP: return {
            ...state,
            modGroupData: action.payload
        }
        case EDIT_MOD_GROUP: return {
            ...state,
            isUpdated: action.isUpdated,
            message: action.payload
        }
        case MOD_LIST: return {
            ...state,
            modifiers: action.payload
        }
        case FP_MOD_LIST: return {
            ...state,
            fpModifiers:action.payload
        }
        case ADD_MOD: return {
            ...state,
            isInserted: action.isInserted,
            message: action.payload
        }
        case GET_MOD: return {
            ...state,
            modData: action.payload
        }
        case EDIT_MOD: return {
            ...state,
            isUpdated: action.isUpdated,
            message: action.payload
        }
        case CHECK_DUP_POS: return {
            ...state,
            duplicateItemPos: action.duplicateItemPos
        }
        case CHECK_DUP_ERP: return {
            ...state,
            duplicateItemErp: action.duplicateItemErp
        }
        case COMBO_CHOICE_ITEMS: return {
            ...state,
            choiceIndex: action.choiceIndex,
            comboChoiceItems: action.payload
        }
        case GET_BRANCH_MENU: return {
            ...state,
            groupsData: action.groupsData,
            subgroupsData: action.subgroupsData,
            itemsData: action.itemsData,
            variantsData: action.variantsData,
            combosData: action.combosData,
            modifiersData: action.modifiersData
        }
        case STATUS_CHANGE_REASONS: return {
            ...state,
            statusChangeReasons: action.payload
        }
        case SEARCH_MENU_ITEM: return {
            ...state,
            searchItemData: action.searchItemData
        }
        case SEARCH_GROUPS: return {
            ...state,
            searchGroupData: action.searchGroupData
        }
        case SEARCH_SUBGROUPS: return {
            ...state,
            searchSubGroupData: action.searchSubGroupData
        }
        case SEARCH_COMBOS: return {
            ...state,
            searchComboData: action.searchComboData
        }
        case SEARCH_MODIFIERS: return {
            ...state,
            searchModData: action.searchModData
        }
        case SEARCH_VARIANTS: return {
            ...state,
            searchVariantData: action.searchVariantData
        }
        case ORDER_MODES: return {
            ...state,
            orderModes: action.payload
        }
        case MAKE_A_COMBO_LIST: return {
            ...state,
            make_a_combo_list: action.payload
        }
        case GET_MAKE_A_COMBO: return {
            ...state,
            make_a_combo: action.payload
        }
        case ITEMS: return {
            ...state,
            itemsByGroup: action.payload
        }
        case VARIANTS: return {
            ...state,
            variantsByItem: action.payload
        }
        case ACTIVE_ITEMS_FOR_BANNERS: return {
            ...state,
            menuItemsForBanners: action.payload
        }
        case ACTIVE_COMBOS_FOR_BANNERS: return {
            ...state,
            combosForBanners: action.payload
        }
        case ACTIVE_SUGG_COMBOS: return {
            ...state,
            suggestiveCombos: action.payload
        }
        case SUGGESTIVE_COMBOS_LIST: return {
            ...state,
            suggestive_combos_list: action.payload
        }
        case NUT_CAT_LIST: return {
            ...state,
            nutCategories: action.payload
        }
        case GET_NUT_CAT: return {
            ...state,
            nutCategoryData: action.payload
        }
        case NUT_ITEM_LIST: return {
            ...state,
            nutItems: action.payload
        }
        case GET_NUT_ITEM: return {
            ...state,
            nutItemData: action.payload
        }
        case NUT_CATEGORIES: return {
            ...state,
            nutCategoriesForItems: action.payload
        }
        case MENU_STORE_OPTIONS: return {
            ...state,
            menuStoresOptions: action.payload
        }
        case FP_COMBO: return {
            ...state,
            fpcombos : action.payload
        }
        case FP_ITEM: return {
            ...state,
            fpitems : action.payload
        }
        case FP_SCHEDULE: return{
            ...state,
            fpschedule: action.payload
        }
        default: return state;
    }
}
export default menuReducer;