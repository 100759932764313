import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { loginForAdmins } from '../redux/actions/userAction'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from 'react-router-dom';

const Multifactor = (props: any) => {

    const [authCode, setAuthCode] = useState("");
    const [lat, setlat] = useState(0)
    const [lng, setLng] = useState(0);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const user_id = searchParams.get('user');

    useEffect(() => {
        document.title = "Crust Culture | Authentication"
    }, [])

    useEffect(() => {
        if (authCode.length >= 4) {
            handleSubmit()
        }
    }, [authCode])

    const handleInputChange = (event: any) => {
        setAuthCode(event.target.value)
    }
    const isFormValid = () => {
        return authCode == "";
    }

    const handleSubmit = () => {
        props.loginForAdmins(user_id, authCode, lat, lng);
    }

    return (
        <div className="page login-page">
            <div className="container d-flex align-items-center">
                <div className="form-holder has-shadow">
                    <div className="row">
                        {/* Logo & Information Panel */}
                        <div className="col-lg-6">
                            <div className="info d-flex align-items-center justify-content-center">
                                <div className="content">
                                    <div className="logo text-center">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/cc_logo.png'} width="235" height="235" alt="..." className="img-fluid ml-3" />
                                        <h1 className="text-center mt-3">Crust Culture CMS</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* htmlForm Panel  */}
                        <div className="col-lg-6 bg-white">
                            <div className="form d-flex align-items-center">
                                <div className="content">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label className="form-control-label">Enter Code <span className="text-danger">*</span></label>
                                                    <input type="authCode" name="authCode" required data-msg="Please enter code" className="input-material" onChange={handleInputChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button onClick={handleSubmit} disabled={isFormValid()} className="btn btn-block btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    );
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        loginForAdmins: function (user_id: any, authCode: any, lat: any, lng: any) {
            dispatch(loginForAdmins(user_id, authCode, lat, lng));
        }
    }
}
export default connect(null, mapDispatchToProps)(Multifactor);