import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { connect } from 'react-redux'
import { modGroupList, logoutUser, modifierListByModGroupId, blockunblockModifierGroup } from '../../../redux'
import { ModGroupProps } from '../../../interfaces/menu';
class ActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    showmodifiers = (id: any) => {
        this.props.moddata.modifierListByModGroupId(id)
    }
    handleBlockUnblock = (id: any, is_active: any) => {
        this.props.moddata.blockunblockModifierGroup(id, is_active, this.props.moddata.history, this.props.row.mod_group_name);
    };
    render() {
        const { row, moddata } = this.props
        return (
            <div>
                <button title={row.is_active === 0 ? "UnBlock" : "Block"} data-toggle="modal" data-target={`#blockunblock${row.mod_group_id}`} className={row.is_active === 0 ? "btn btn-outline-success mr-2" : "btn btn-outline-danger mr-2"}><i className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}></i></button>
                {/* <button title="View Modifiers" data-toggle="modal" data-target={`#viewmodifier${row.mod_group_id}`} className="btn btn-outline-info" onClick={() => this.showmodifiers(row.mod_group_id)}><i className="fa fa-list"></i></button> */}
                <Link title="Edit Mod Group" className="btn btn-outline-primary ml-2 mr-4" to={`/edit-modgroup/${row.mod_group_id}`}><i className="fa fa-edit"></i></Link>
                {/* <!-- Modal--> */}
                <div id={`viewmodifier${row.mod_group_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Modifiers</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            {(moddata.modifiers.length > 0) ?
                                                <BootstrapTable version="4" data={moddata.modifiers} hover>
                                                    <TableHeaderColumn dataField="modifier_id" columnTitle isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='modifier_name' columnTitle>Modifier Name</TableHeaderColumn>
                                                </BootstrapTable> :
                                                <p className="text-center">Modifiers Not Found</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Block/Unblock Modal--> */}
                <div id={`blockunblock${row.mod_group_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 0 ? "UnBlock" : "Block"} Modifier Group</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to {row.is_active === 0 ? "UnBlock" : "Block"} this Modifier Group?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleBlockUnblock(row.mod_group_id, row.is_active)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
class ModGroupsFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                {row.group_json &&
                    <button title="View Groups" data-toggle="modal" data-target={`#modGroups${row.mod_group_id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button>
                }
                {/* <!-- Modal--> */}
                <div id={`modGroups${row.mod_group_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Groups</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            <BootstrapTable version='4' data={(row.group_json && this.IsJsonString(row.group_json)) ? JSON.parse(row.group_json) : ""} hover>
                                                <TableHeaderColumn dataField='value' csvHeader='#' width='50' dataSort={true} isKey>#</TableHeaderColumn>
                                                <TableHeaderColumn dataField='label' columnTitle>Group Name</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
class ItemsFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                {row.items_json &&
                    <button title="View Menu Items" data-toggle="modal" data-target={`#items${row.mod_group_id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button>
                }
                {/* <!-- Modal--> */}
                <div id={`items${row.mod_group_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Items</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            <BootstrapTable version='4' data={(row.items_json && this.IsJsonString(row.items_json)) ? JSON.parse(row.items_json) : ""} hover>
                                                <TableHeaderColumn dataField='label' columnTitle isKey>Item Name</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} moddata={props} />
    );
}
function groupsFormatter(cell: any, row: any, props: any) {
    return (
        <ModGroupsFormatter row={row} data={props} />
    );
}
function itemsFormatter(cell: any, row: any, props: any) {
    return (
        <ItemsFormatter row={row} data={props} />
    );
}
class ModGroup extends Component<ModGroupProps, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.modGroupList();
        document.title = "Crust Culture | Modifier Groups"
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-modgroup" /> },
            noDataText: 'Modifier Groups Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Modifier Group Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body text-capitalize">
                                                <BootstrapTable version="4" data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='ModGroups.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='mod_group_id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='erp_id' csvHeader='ERP ID' width='100' columnTitle>ERP ID</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='pos_code' csvHeader='POS Code' width='100' columnTitle>POS Code</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='mod_group_name' csvHeader='ModGroup Name' width='130' columnTitle>Mod Group name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='mod_group_name_hint' csvHeader='ModGroup Name' width='130' columnTitle>Mod Group name hint</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='min_quantity' csvHeader='Min Quantity' width='80' columnTitle>Min</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='max_quantity' csvHeader='Max Quantity' width='80' columnTitle>Max</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField="priority" csvHeader='priority' width='100' columnTitle>Priority</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField="mod_group_description" csvHeader='Description' width='120' columnTitle>Description</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField="type" csvHeader='Type' width='100' columnTitle>Level</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField="modifiers_type" csvHeader='Mod Type' width='100' dataFormat={typeFormatter} columnTitle>Type</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='100' dataFormat={itemsFormatter} export={false}>Menu Items</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='100' dataFormat={groupsFormatter} export={false}>Groups</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='250' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
function typeFormatter(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == "single" && { title: "single select" } || cell == "multiple" && { title: "multi select" })}>{cell == "single" && "single select" || cell == "multiple" && "multi select"}</span>
        </div>
    )
}
const mapStateToProps = (state: any) => {
    return {
        data: state.menu.modgroups,
        modifiers: state.menu.modifiers
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        modGroupList: function () {
            dispatch(modGroupList())
        },
        modifierListByModGroupId: function (id: any) {
            dispatch(modifierListByModGroupId(id))
        },
        blockunblockModifierGroup: function (id: any, is_active: any, history: any, modGroup: any) {
            dispatch(blockunblockModifierGroup(id, is_active, history, modGroup))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModGroup);