import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import Skelton from '../../pages/skeltonLoader'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import BeautyStars from 'beauty-stars';
import Invoice from './FbrInvoice/fbrInvoice';
import { cancelOrdersReasons, logoutUser, ordersList, orderStatusList, showorderItems, statesList, storesList, updatefutureOrder, updateOrder } from '../../redux'
import { OrderProps } from '../../interfaces/order';
import moment from 'moment';
import { deliveryBoysList, filterOrdersList, getOrderXML, newmanualSyncOrder, searchOrder, searchOrdersByQuery, updateSelectedRow } from '../../redux/actions/orderAction';
import { toast } from "react-toastify";
import { currency } from '../../client-config'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
import ReactTimer from './timer/timer'
import beautify from "xml-beautifier";
import ReactPaginate from 'react-paginate';
import './orders.css'
toast.configure();
let selectedOrderId = 0;
sessionStorage.removeItem("getOrder")
class DriverEditor extends React.Component<{ data: any, row: any }, { [x: number]: any, deliveryboy: any }> {
    constructor(props: any) {
        super(props);
        this.state = {
            deliveryboy: ""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        const { row } = this.props
        this.setState({ deliveryboy: row.delivery_boy })
    }
    handleSave = () => {
        const { row } = this.props
        const { deliveryboy } = this.state;
        const data = {
            delivery_boy: deliveryboy ? deliveryboy : ""
        }
        if (deliveryboy !== "" && deliveryboy > 0) {
            this.props.data.updateOrder(row.order_id, data, this.props.data.history)
        } else {
            this.setState({ deliveryboy: row.delivery_boy })
            toast.error("Please select the driver", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
        }

    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    
    render() {
        const { data, row } = this.props
        const { deliveryboy } = this.state;
        return (
            <div>
                {/* {(row.order_status_code === 6 || row.order_status_code === 8) ? <p>{row.user_name}</p> : */}
                    {(row.order_status_code !== 1 && row.order_status_code !== 2 && row.order_status_code !== 3) &&
                    (
                        <div>
                            <select
                                name="deliveryboy"
                                // {...row.order_status_code == 3 && { disabled: true }}
                                {...(row.order_status_code === 5 || row.order_status_code === 6) && { disabled: true }}
                                value={deliveryboy || ''}
                                onChange={this.handleInputChange}
                                className="text-capitalize">
                                <option value="">Select Driver</option>
                                {data.drivers &&
                                    data.drivers.map((driver: any, index: any) => (
                                        // To lock local rider after one order dispatched
                                        // ((driver.store_id && row.store_id == driver.store_id.store_id) && driver.clockIn == 1 && (driver.goPanda == "true" || ((driver.goPanda == "false" && !driver.order_id) || (driver.order_id && driver.order_id == row.order_id)))) ?
                                        // ((driver.store_id && row.store_id == driver.store_id.store_id) && driver.clockIn == 1) ?
                                        (((driver.store_id && row.store_id == driver.store_id.store_id) && driver.clockIn == 1) || driver.store_id == null) ?
                                            <option key={index} value={driver.id}>{driver.user_name.length > 15 ? `${driver.user_name.substring(0, 15)}...` : driver.user_name}</option>
                                            : ""
                                    ))
                                }
                            </select>
                            <br />
                            {/* {(row.order_status_code != 3 && row.aggregator_delivery == null) && <span className="badge badge-pill badge-info" style={{ cursor: 'pointer' }} onClick={this.handleSave}>save</span>} */}
                            {(row.order_status_code != 6 && row.aggregator_delivery == null) && <span className="badge badge-pill badge-info" style={{ cursor: 'pointer' }} onClick={this.handleSave}>save</span>}
                        </div>
                    )
                }
            </div>
        );
    }
}
class StatusEditor extends React.Component<{ data: any, row: any }, { [x: number]: any, cancelReason: any, otherReason: any, orderstatus: any }> {
    openModal: any;
    closeModal: any;
    constructor(props: any) {
        super(props);
        this.state = {
            orderstatus: "",
            cancelReason: "",
            otherReason: ""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.cancelOrder = this.cancelOrder.bind(this);
    }
    componentDidMount() {
        const { row } = this.props
        this.setState({ orderstatus: row.order_status_code })
    }
    handleSave = () => {
        const { row } = this.props
        const { orderstatus } = this.state;

        // let orderDate = moment(row.date_created).local().format('DD/MM/YYYY HH:mm:ss')
        // let currentDate = moment().format('DD/MM/YYYY HH:mm:ss');
        // let orderDateforComp = moment(row.date_created).local().format('YYYY-MM-DD');
        // let currentDateforComp = moment().format('YYYY-MM-DD');
        // var createdTime = moment(row.date_created).local().format('HH:mm');
        // var currentTime = moment().local().format('HH:mm');
        // var startTime = moment(createdTime, "HH:mm");
        // var endTime = moment(currentTime, "HH:mm");
        // var duration: any = moment.duration(endTime.diff(startTime));
        // var minutes = parseInt(duration.asMinutes());
        // var minutesduration = 0;
        // if (moment(currentDateforComp).isAfter(orderDateforComp)) {
        //     var ms = moment(currentDate, "DD/MM/YYYY HH:mm:ss").diff(moment(orderDate, "DD/MM/YYYY HH:mm:ss"));
        //     var d = moment.duration(ms);
        //     minutesduration = Math.floor(d.asMinutes());
        // } else {
        //     minutesduration = minutes;
        // }
        // console.log("minutesduration", minutesduration);
        // ==========================
        if (row.delivery_status == "Delivery") {
            if (orderstatus == row.order_status_code) {
                toast.error("Please change the status", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            } else {
                if (orderstatus == 5) {
                    if (row.delivery_boy !== "" && row.delivery_boy !== null) {
                        // deliverytime["ready"] = minutesduration - (deliverytime.pending + deliverytime.kitchen);
                        // console.log("ready", minutesduration - (deliverytime.pending + deliverytime.kitchen));
                        const data = {
                            order_status_code: orderstatus,
                            delivery_status: row.delivery_status,
                            orderData: row
                        }
                        this.props.data.updateOrder(row.order_id, data, this.props.data.history)
                    } else {
                        alert('Please Assign the order to driver first')
                    }
                } else if (orderstatus == 8) {
                    this.openModal.click();
                } else {
                    // if (orderstatus == 2) {
                    //     deliverytime["pending"] = minutesduration;
                    //     console.log("pending", minutesduration);
                    // }
                    // if (orderstatus == 4) {
                    //     deliverytime["dispatched"] = minutesduration - (deliverytime.pending + deliverytime.kitchen);
                    //     console.log("dispatched", minutesduration - (deliverytime.pending + deliverytime.kitchen))
                    // }
                    // if (orderstatus == 8) { 
                    //     deliverytime["kitchen"] = minutesduration - deliverytime.pending;
                    //     console.log("In Kitchen", minutesduration - deliverytime.pending)
                    // }
                    const data: any = {
                        order_status_code: orderstatus,
                        delivery_status: row.delivery_status,
                        orderData: row
                    }
                    this.props.data.updateOrder(row.order_id, data, this.props.data.history)
                }
            }
        } else {
            const data: any = {
                order_status_code: orderstatus,
                delivery_status: row.delivery_status,
            }
            if (orderstatus == row.order_status_code) {
                toast.error("Please change the status", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            } else {
                if (orderstatus == 8) {
                    this.openModal.click();
                } else {
                    this.props.data.updateOrder(row.order_id, data, this.props.data.history)
                }
            }
        }
    }
    isCancelOrderReady = () => {
        const { cancelReason } = this.state;
        return (cancelReason !== "");
    }
    cancelOrder(order_id: any) {
        const { orderstatus } = this.state;
        const data: any = {
            order_status_code: orderstatus,
            cancel_reason: this.state.cancelReason,
        }
        if (this.state.otherReason !== "") {
            data.cancel_reason_description = this.state.otherReason;
        }
        this.props.data.updateOrder(order_id, data, this.props.data.history);
        this.closeModal.click();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        const { data, row } = this.props;
        const { orderstatus } = this.state;
        return (
            <div>
                {(row.order_status_code === 6 || row.order_status_code === 7 || row.order_status_code === 8) ? <span {...(((row.order_status_code === 6 || row.order_status_code === 7) && { className: "badge badge-success text-capitalize p-1" }) || ((row.order_status_code === 8) && { className: "badge badge-danger text-capitalize p-1" }))}>{row.order_status_description}</span> :
                    <div>
                        <select
                            name="orderstatus"
                            value={orderstatus}
                            onChange={this.handleInputChange}
                            className="text-capitalize">
                            {data.statusList &&
                                data.statusList.map((status: any, index: any) => (
                                    (row.delivery_status == "Delivery")
                                        ?
                                        (status.order_status_code !== 3 && status.order_status_code !== 7) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                        :
                                        //else 2.3
                                        (status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code !== 6) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                ))
                            }
                        </select>
                        <br />
                        {data.buttonDisable == false && <span className="badge badge-pill badge-info text-capitalize" style={{ cursor: 'pointer' }} onClick={() => this.handleSave()} >save</span>}
                    </div>
                }
                <div style={{ display: "none" }}>
                    <button ref={el => this.openModal = el} data-toggle="modal" data-target={`#viewcancel${row.order_id}`} className="btn btn-outline-info ml-2"><i className="fa fa-file-pdf-o"></i></button>
                </div>
                <div id={`viewcancel${row.order_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Cancel Order</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <h6>Are you sure,you want to cancel the order?</h6>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <select
                                                name="cancelReason"
                                                onChange={this.handleInputChange}
                                                className="form-control mt-2">
                                                <option value="">Select Reason</option>
                                                {data.cancelReasons &&
                                                    data.cancelReasons.map((reason: any, index: any) => (
                                                        <option key={index} value={reason.id}>{reason.reason}</option>
                                                    ))
                                                }
                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input
                                                id="otherReason"
                                                style={{ fontSize: 15 }}
                                                type="text"
                                                name="otherReason"
                                                placeholder="Description"
                                                className="input-material"
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group d-flex justify-content-end mt-4">
                                    <button className='btn btn-primary' disabled={!this.isCancelOrderReady()} onClick={() => this.cancelOrder(row.order_id)}>Save</button>
                                </div>
                            </div>
                            <div style={{ display: "none" }}>
                                <button ref={el => this.closeModal = el} type="button" data-dismiss="modal" className="btn btn-info" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
class ActionFormatter extends Component<{ row: any, data: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    showorderItems = (id: any) => {
        let orders = null;
        if (this.props.data.filteredOrders && this.props.data.filteredOrders.length > 0) {
            orders = this.props.data.filteredOrders
        } else {
            orders = this.props.data.orders
        }
        this.props.data.showorderItems(id, orders);
        selectedOrderId = id
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                <button title="View Invoice" data-toggle="modal" data-target={`#viewinvoice`} className="btn btn-outline-info ml-2" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-file-pdf-o"></i></button>
            </div>
        )
    }
}
class XMLEditor extends Component<{ row: any }, { checkTimer: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            checkTimer: false
        }
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                <button
                    title="View Pulse Response"
                    className="btn btn-outline-primary"
                    data-toggle="modal"
                    data-target={`#ViewPulseResponse`}
                    onClick={() => {
                        // this.props.data.updateSelectedRow(row)
                        this.props.data.getOrderXML(row.order_id)
                    }}
                >
                    <i className="fa fa-eye"></i>
                </button>
            </div>
        );
    }
}
class ReqXMLEditor extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                <button
                    title="View Pulse Request"
                    className="btn btn-outline-primary"
                    data-toggle="modal"
                    data-target={`#ViewPulseReq`}
                    onClick={() => {
                        // this.props.data.updateSelectedRow(row)
                        this.props.data.getOrderXML(row.order_id)
                    }}
                >
                    <i className="fa fa-eye"></i>
                </button>
            </div >
        );
    }
}
function InvoiceComp(props: any) {
    return (
        <div id={`viewinvoice`} data-backdrop='static' data-keyboard="false" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
            <div role="document" className="modal-dialog modal-lg" style={{ width: '500px' }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 id="exampleModalLabel" className="modal-title">Invoice</h4>
                        <button type="button"
                            data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div className="modal-body">
                        <Invoice data={props.data} orderId={selectedOrderId} />
                    </div>
                </div>
            </div>
        </div>
    )
}
function PulseResponse(props: any) {
    let { selectedRow } = props;
    return (
        <div
            id={`ViewPulseResponse`}
            data-backdrop='static'
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            className="modal fade text-left"
        >
            <div role="document" className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 id="exampleModalLabel" className="modal-title">
                            Pulse Response
                        </h4>
                        <button
                            type="button"
                            data-dismiss="modal"
                            aria-label="Close"
                            className="close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-row" >
                            {selectedRow && selectedRow.failure_reason &&
                                <div className="form-group col-md-12">
                                    <h5 id="txt">Pulse Integration Fail Reason</h5>
                                    <textarea name="failure_reason" id="round2" value={selectedRow.failure_reason} disabled style={{ width: '100%', height: '50px', fontSize: 10 }} />
                                </div>
                            }
                            <div className="form-group col-md-12">
                                <h5 id="txt">Pulse Response XML</h5>
                                {selectedRow && !selectedRow.pos_response_xml ? <p className='text-center p-3 m-auto' >No XML Found</p> : <textarea name="pos_response_xml" id="round2" value={selectedRow && selectedRow.pos_response_xml && beautify(selectedRow.pos_response_xml)} disabled style={{ width: '100%', height: '300px', fontSize: 10 }} />}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            data-dismiss="modal"
                            className="btn btn-danger"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
class PulseRequest extends Component<{}, { [x: number]: any, editXml: any, pos_request_xml: any, order_id: any }> {
    closeModal: any;
    constructor(readonly props: any) {
        super(props);
        this.state = {
            editXml: false,
            pos_request_xml: "",
            order_id: ""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidUpdate(prevProps: any, prevState: Readonly<{ [x: number]: any; editXml: any; pos_request_xml: any; order_id: any; }>, snapshot?: any): void {
        if (prevProps.data.selectedRow && prevProps.data.selectedRow.order_id !== this.props.data.selectedRow.order_id) {
            this.setState({ pos_request_xml: this.props.data.selectedRow.pos_xml })
        } else {
            if (!prevProps.data.selectedRow && this.props.data.selectedRow) {
                this.setState({ pos_request_xml: this.props.data.selectedRow.pos_xml })
            }
        }
    }
    handleSaveSync = () => {
        const { data } = this.props
        const payload = {
            order_id: data.selectedRow ? data.selectedRow.order_id : "",
            pos_xml: this.state.pos_request_xml
        }
        console.log(payload, "dasdasdad");

        data.newmanualSyncOrder(data.selectedRow.order_id, payload, this.closeModal);
        this.setState({ editXml: false })
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        const { data } = this.props;
        return (
            <div
                id={`ViewPulseReq`}
                data-backdrop='static'
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                className="modal fade text-left"
            >
                <div role="document" className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 id="exampleModalLabel" className="modal-title">
                                Pulse Request Body
                            </h4>
                            <button
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                                className="close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='d-flex justify-content-between mb-2'>
                                <button
                                    type="button"
                                    className="btn btn-sm btn-info"
                                    onClick={() => this.setState({ editXml: true })}
                                    disabled={data.selectedRow && data.selectedRow.check_number == '' ? false : true}
                                >
                                    <i className="fa fa-edit"></i> Update XML
                                </button>
                                {(data.clickmenuSync) ?
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-info"
                                        disabled={data.clickmenuSync}
                                    >
                                        <Loader type="TailSpin" color="white" height={30} width={30} />
                                    </button> :
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-info"
                                        disabled={(this.state.editXml && data.selectedRow.check_number === '') ? false : true}
                                        onClick={this.handleSaveSync}
                                    >
                                        <i className="fa fa-undo"></i> Re-Push
                                    </button>
                                }
                            </div>
                            <div className="form-row" >
                                <div className="form-group col-md-12">
                                    {!this.state.pos_request_xml ? <p className='text-center p-3 m-auto' >No XML Found</p> : <textarea name="pos_request_xml" id="round2" value={this.state.pos_request_xml} disabled={this.state.editXml ? false : true} style={{ width: '100%', height: '300px', fontSize: 10 }} onChange={this.handleInputChange} />}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                data-dismiss="modal"
                                className="btn btn-danger"
                            >
                                Close
                            </button>
                        </div>
                        <div style={{ display: "none" }}>
                            <button ref={el => this.closeModal = el} type="button" data-dismiss="modal" className="btn btn-info" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const actionFormatter = (cell: any, row: any, props: any) => {
    return (
        <ActionFormatter row={row} data={props} />
    );
}
function skeltonFormatter() {
    return (
        <Skelton></Skelton>
    );
}
const selectDriverEditor = (cell: any, row: any, props: any) => {
    return (
        row.delivery_status == "Delivery" ?
            <DriverEditor row={row} data={props} /> : ""
    )
}
const selectStatusEditor = (cell: any, row: any, props: any) => {
    return (
        <StatusEditor row={row} data={props} />
    )
}
const xmlformatter = (cell: any, row: any, props: any) => {
    return (
        <XMLEditor row={row} data={props} />
    )
}
const pulseReqformatter = (cell: any, row: any, props: any) => {
    return (
        <ReqXMLEditor row={row} data={props} />
    )
}
class Orders extends Component<OrderProps, { startDate: any, endDate: any, validTimeFlag: any, debounceTimeout: any, [x: number]: any, searchQuery: any, days: any, orderId: any, loader: any, refresh: any, listType: any, manualCloseTimer: any }> {
    closeModal: any;
    constructor(readonly props: any) {
        super(props);
        this.state = {
            days: 0,
            startDate: "",
            endDate: "",
            validTimeFlag: "",
            orderId: "",
            loader: true,
            refresh: true,
            listType: 'current',
            manualCloseTimer: false,
            searchQuery: "",
            debounceTimeout: null
        }
        this.rowClassNameFormat = this.rowClassNameFormat.bind(this);
        this.handleDaysFilter = this.handleDaysFilter.bind(this);
        this.handleTimer = this.handleTimer.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidTime = this.handleValidTime.bind(this);
        this.handleInput = this.handleInput.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (nextProps.orders.length > 0) {
            var audio = new Audio();
            audio.src = process.env.PUBLIC_URL + "/assets/sound/old-style-phone-ringing.mp3";
            nextProps.orders.map((element: any) => {
                if (element.order_status_code == 1) {
                    audio.oncanplaythrough = (event) => {
                        var playedPromise = audio.play();
                        if (playedPromise) {
                            playedPromise.catch((e) => {
                                console.log(e)
                                if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                                    console.log(e.name);
                                }
                            }).then(() => {
                                return
                            });
                        }
                    }
                }
            })
        }
    }

    rowClassNameFormat(row: any, rowIdx: any) {

        if (row.aggregator_orderId) {
            return 'td-column-function-aggregator-example';
        } else {
            if (row.future_status == 1 && row.order_status_code == 1) {
                return 'td-column-function-odd-example tr-pending-orders'
            } else if (row.future_status == 1) {
                return 'td-column-function-odd-example'
            } else if (row.isNew == true && row.order_status_code == 1 && row.is_order_transfer == 1) {
                return 'td-column-function-even-example tr-transfered-orders'
            } else if (row.isNew == true && row.order_status_code == 1) {
                return 'td-column-function-even-example tr-pending-orders'
            } else if (row.isNew == true && row.is_order_transfer == 1) {
                return 'td-column-function-even-example tr-transfered-orders'
            } else if (row.isNew == true) {
                return 'td-column-function-even-example'
            } else if (row.order_status_code == 1) {
                return 'tr-pending-orders'
            } else if (row.is_order_transfer == 1) {
                return 'tr-transfered-orders'
            } else {
                return ''
            }
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        let getOrderValue = sessionStorage.getItem("getOrder")
        let obj = { days: this.state.days };
        if (!getOrderValue) {
            this.props.ordersList(obj, false, 0, this.updateLoader, this.closeTimer, this.startTimer);
            this.props.orderStatusList();
            this.props.statesList();
            this.props.storesList();
            this.props.cancelOrdersReasons();
            this.props.deliveryBoysList()
            sessionStorage.setItem("getOrder", 'true')
        }
        document.title = "Crust Culture | Orders"
    }
    updateLoader = (value: any) => {
        this.setState({ loader: value })
    }
    handleDaysFilter(event: { target: { name: any; value: any; }; }) {
        let obj = { days: event.target.value };
        this.props.ordersList(obj);
    }
    handleInput(event: { target: { name: any; value: any; }; }) {
        this.setState({ orderId: event.target.value })
    };
    FilterbyStatus(status_code: any) {
        let order_status_codes = []
        let obj: any = { days: this.state.days };
        let { startDate, endDate } = this.state;
        let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
        let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
        let data: any = {
            start: startDateUtc,
            end: endDateUtc
        }
        if (status_code === 3) {
            order_status_codes.push(3);
            order_status_codes.push(4);
            obj.order_status = order_status_codes;
            data.order_status = order_status_codes;
        }else if (status_code === 6) {
            order_status_codes.push(6);
            order_status_codes.push(7);
            obj.order_status = order_status_codes;
            data.order_status = order_status_codes;
        } else if (status_code == "failedIntegration") {
            obj.failedOrders = true;
            data.failedOrders = true;
        } else {
            order_status_codes.push(status_code)
            obj.order_status = order_status_codes;
            data.order_status = order_status_codes;
        }
        if (this.state.listType == "current") {
            this.props.filterOrdersList(obj, this.updateLoader, this.closeTimer, this.startTimer)
        } else {
            this.props.filterOrdersList(data, this.updateLoader, this.closeTimer, this.startTimer)
        }
    }
    handleValidTime(event: { target: { name: any; value: any; }; }) {
        let futureDatetime = new Date(event.target.value)
        let validMinDateTime = new Date();
        if (futureDatetime <= validMinDateTime) {
            this.setState({ [event.target.name]: event.target.value, validTimeFlag: true });
        } else {
            this.setState({ validTimeFlag: false })
            toast.error("Invalid date and time selected");
        }
    }
    isOrderDateReady = () => {
        let { startDate, endDate, validTimeFlag } = this.state;
        return (startDate !== "" && endDate !== "" && validTimeFlag == true);
    }
    handleSubmit() {
        let { startDate, endDate } = this.state;
        let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
        let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
        let data: any = {
            start: startDateUtc,
            end: endDateUtc
        }
        this.setState({ listType: "dateTime" })
        var start = moment(startDate, 'YYYY-MM-DD HH:mm:ss');
        var end = moment(endDate, 'YYYY-MM-DD HH:mm:ss');
        //Difference in number of days
        let days = moment.duration(end.diff(start)).asDays();

        let days_round = Math.round(days);
        if (startDate >= endDate) {
            toast.error("End date cannot be less than start date")
        } else if (days_round > 1) {
            toast.error("Maximum 2 day can be selected")
        } else {
            this.setState({ loader: true })
            this.props.ordersList(data, true, 0, this.updateLoader, this.closeTimer, this.startTimer);
        }
    }
    handleSearchOrder = (event: any) => { //here
        event.preventDefault();
        let { orderId } = this.state;
        this.props.searchOrder(orderId)
    }
    handleTimer() {
        if (this.state.refresh) {
            this.setState({ refresh: false, manualCloseTimer: true })
        } else {
            this.setState({ refresh: true, manualCloseTimer: false })
        }
        this.closeModal.click();
    }
    closeTimer = () => {
        this.setState({ refresh: false })
    }
    startTimer = () => {
        this.setState({ refresh: true })
    }
    updateListType = () => {
        this.setState({ listType: "current" })
    }
    fetchPaginatedOrders = (obj: any, page: any) => {
        let { startDate, endDate } = this.state;
        let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
        let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
        let data: any = {
            start: startDateUtc,
            end: endDateUtc
        }
        if (this.state.listType == "current") {
            this.props.ordersList(obj, false, page, this.updateLoader, this.closeTimer, this.startTimer, this.state.manualCloseTimer);
        } else {
            this.props.ordersList(data, false, page, this.updateLoader, this.closeTimer, this.startTimer, this.state.manualCloseTimer)
        }
    };
    handlePageClick = (e: any) => {
        this.setState({ loader: true })
        let obj = { days: this.state.days };
        const selectedPage = e.selected;
        this.fetchPaginatedOrders(obj, selectedPage);
    };
    handleSearchQuery = (e: any) => {
        const { startDate, endDate } = this.state;
        const startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm');
        const endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm');
        let data: any = {
            start: startDateUtc,
            end: endDateUtc
        };

        const searchQuery = e.target.value;
        this.setState({ searchQuery });
        clearTimeout(this.state.debounceTimeout);

        const newDebounceTimeout = setTimeout(() => {
            if (searchQuery && searchQuery.length >= 3) {
                console.log(this.state.days, "asfdsaffsaafa");

                if (this.state.listType === "dateTime") {
                    this.props.searchOrdersByQuery(searchQuery, data);
                } else {
                    const obj = {
                        days: 0
                    };
                    this.props.searchOrdersByQuery(searchQuery, obj);
                }
            } else if (!searchQuery) {
                const obj = { days: this.state.days };

                if (this.state.listType === "dateTime") {
                    this.props.ordersList(data, false, 0, null, null, null);
                } else {
                    this.props.ordersList(obj, false, 0, null, null, null);
                }
            }
        }, 400);
        this.setState({ debounceTimeout: newDebounceTimeout });
    };


    render() {

        const dataa = [
            { id: 1, name: 'John Smith', age: 32, email: 'john.smith@example.com' },
            { id: 2, name: 'Jane Doe', age: 27, email: 'jane.doe@example.com' },
            { id: 3, name: 'Bob Johnson', age: 41, email: 'bob.johnson@example.com' },
            { id: 4, name: 'Alice Williams', age: 25, email: 'alice.williams@example.com' },
            { id: 5, name: 'Jack Brown', age: 38, email: 'jack.brown@example.com' },
            { id: 6, name: 'Linda Davis', age: 29, email: 'linda.davis@example.com' },
            { id: 7, name: 'Mike Garcia', age: 45, email: 'mike.garcia@example.com' },
            { id: 8, name: 'Samantha Lee', age: 31, email: 'samantha.lee@example.com' },
            { id: 9, name: 'David Nguyen', age: 36, email: 'david.nguyen@example.com' },
            { id: 10, name: 'Emily Taylor', age: 24, email: 'emily.taylor@example.com' }
        ];
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let maxDate = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS')
        let { orders, filteredOrders, emptyFilteredOrder, avg, prepCount, readyCount, recievedOrderCount, dispatchCount, completedOrderCount } = this.props;
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            noDataText: 'Orders Not Found',
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                {/* <div className="d-flex bd-highlight py-3">
                                    <div className="mr-auto bd-highlight"><h4 className="mt-2">Orders Management</h4></div>
                                    <div className="bd-highlight"><button title={this.props.timerStatus == false ? "Enable Refresh" : "Disable Refresh"} data-toggle="modal" data-target={`#enableTimer`} className={this.props.timerStatus == false ? "btn btn-outline-success mr-2" : "btn btn-outline-danger mr-2"}><i className={"fa fa-refresh"}></i></button></div>
                                    <div className="p-2 bd-highlight">
                                        <select name="days" id="days" onChange={this.handleDaysFilter} style={{ padding: '0 15px' }}>
                                            <option value="0" {...dayVal.days == 0 && { selected: true }}>Today</option>
                                            <option value="1" {...dayVal.days == 1 && { selected: true }}>Yesterday</option>
                                            <option value="7" {...dayVal.days == 7 && { selected: true }}>7 Days</option>
                                            <option value="15" {...dayVal.days == 15 && { selected: true }}>15 Days</option>
                                            <option value="30" {...dayVal.days == 30 && { selected: true }}>30 Days</option>
                                            <option value="60" {...dayVal.days == 60 && { selected: true }}>60 Days</option>
                                            <option value="90" {...dayVal.days == 90 && { selected: true }}>90 Days</option>
                                        </select>
                                    </div> */}
                                <div className="row py-2">
                                    <div className="col-lg-6 col-md-6 col-12" style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                        <h4 className="mt-2">Orders Management<button title={this.state.refresh == false ? "Enable Refresh" : "Disable Refresh"} data-toggle="modal" data-target={`#enableTimer`} className={this.state.refresh == false ? "btn btn-outline-success ml-2" : "btn btn-outline-danger ml-2"}><i className={"fa fa-refresh"}></i></button></h4>
                                        <div className="ml-4 ">
                                            {
                                                this.state.refresh &&
                                                <ReactTimer updateLoader={this.updateLoader} startTimer={this.startTimer} closeTimer={this.closeTimer} days={this.state.days} updateListType={this.updateListType} />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="form-group">
                                                    <input
                                                        id="start"
                                                        type="datetime-local"
                                                        name="startDate"
                                                        max={maxDate}
                                                        value={this.state.startDate}
                                                        className="input-material"
                                                        onChange={this.handleValidTime}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input
                                                        id="end"
                                                        type="datetime-local"
                                                        name="endDate"
                                                        max={maxDate}
                                                        value={this.state.endDate}
                                                        className="input-material"
                                                        onChange={this.handleValidTime}
                                                    />
                                                </div>
                                                <button disabled={!this.isOrderDateReady()} className="badge badge-pill btn-info text-capitalize" style={{ cursor: 'pointer', float: 'right' }} onClick={() => { this.handleSubmit() }}>get</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {/* Counters Section */}
                        <section className="dashboard-counts py-2">
                            <div className="container-fluid">
                                <div className="row bg-white has-shadow p-0">
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-4 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(1) }} className="item d-flex align-items-center p-0" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-green"><i className="fa fa-money"></i></div>
                                            <div className="title"><span>Pending</span><br />
                                                <strong className="text-bold text-dark">{recievedOrderCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${recievedOrderCount / 100}%`, height: "4px" }} className="progress-bar bg-green"></div>  {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{recievedOrderCount}</strong></div> */}
                                        </div>
                                    </div>

                                    {/* <!-- Item --> */}
                                    <div className="col-xl-4 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(2) }} className="item d-flex align-items-center p-0" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-blue"><i className="fa fa-cutlery"></i></div>
                                            <div className="title"><span>Prep</span> <br />
                                                <strong className="text-bold text-dark">{prepCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${prepCount / 100}%`, height: "4px" }} className="progress-bar bg-blue"></div>  {/*ariaValuenow="25" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{kitchenCount}</strong></div> */}
                                        </div>
                                    </div>
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-4 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(3) }} className="item d-flex align-items-center p-0" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-blue"><i className="fa fa-cutlery"></i></div>
                                            <div className="title"><span>Ready</span> <br />
                                                <strong className="text-bold text-dark">{readyCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${readyCount / 100}%`, height: "4px" }} className="progress-bar bg-blue"></div>  {/*ariaValuenow="25" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{kitchenCount}</strong></div> */}
                                        </div>
                                    </div>
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-4 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(5) }} className="item d-flex align-items-center pt-1" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-orange"><i className="fa fa-truck"></i></div>
                                            <div className="title"><span>Dispatch</span><br />
                                                <strong className="text-bold text-dark">{dispatchCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${dispatchCount / 100}%`, height: "4px" }} className="progress-bar bg-orange"></div>  {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{readyOrderCount}</strong></div> */}
                                        </div>
                                    </div>
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-4 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(6) }} className="item d-flex align-items-center pt-1" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-violet"><i className="fa fa-handshake-o"></i></div>
                                            <div className="title"><span>Completed</span> <br />
                                                <strong className="text-bold text-dark">{completedOrderCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${completedOrderCount / 100}%`, height: "4px" }} className="progress-bar bg-violet"></div> {/*ariaValuenow="50" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div><strong>{completedOrderCount}</strong></div> */}
                                        </div>
                                    </div>
                                    {/* Item */}
                                    <div className="col-xl-4 col-sm-6">
                                        <div className="item d-flex align-items-center pt-1" style={{ border: "none" }}>
                                            <div className="icon bg-red"><i className="fa fa-clock-o"></i></div>
                                            <div className="title"><span>Avg Delivery<br />Time <strong className="text-bold text-dark">{avg}</strong></span>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${avg / 100}%`, height: "4px" }} className="progress-bar bg-red"></div> {/*ariaValuenow="50" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div><strong>{avg}</strong></div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* End Counters Section */}
                        <section className="tables py-2">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-10 col-md-9 col-12">
                                                        <div className="form-group">
                                                            <input id="orderId" type="number" onWheel={(e: any) => e.target.blur()} value={this.state.orderId} name="orderId" required placeholder="Search by order id" className="input-material" onChange={this.handleInput} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-3 col-12 float-right mt-1">
                                                        <div className="form-group">
                                                            <button onClick={this.handleSearchOrder} disabled={this.state.orderId == ""} className="btn btn-primary btn-block">Search Order</button>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-2 col-md-3 col-12 float-right mt-1">
                                                        <div className="form-group">
                                                            <button onClick={() => { this.FilterbyStatus('failedIntegration') }} className="btn btn-danger btn-block">Sync Failed {failedIntegOrderCount}</button>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                {this.props.orderPageCount > 0 &&
                                                    <div className="row" >
                                                        <div className="col d-flex d-flex justify-content-end align-items-center">
                                                            <ReactPaginate
                                                                initialPage={parseInt(this.props.orderCurrentPage)}
                                                                breakLinkClassName={'page-link'}
                                                                pageClassName={'page-item'}
                                                                pageLinkClassName={'page-link'}
                                                                previousClassName={'page-item'}
                                                                previousLinkClassName={'page-link'}
                                                                nextClassName={'page-item'}
                                                                nextLinkClassName={'page-link'}
                                                                previousLabel={"prev"}
                                                                nextLabel={"next"}
                                                                breakLabel={"...."}
                                                                breakClassName={"page-item"}
                                                                pageCount={this.props.orderPageCount}
                                                                marginPagesDisplayed={1}
                                                                pageRangeDisplayed={2}
                                                                disableInitialCallback={true}
                                                                onPageChange={this.handlePageClick}
                                                                containerClassName={"pagination"}
                                                                activeClassName={"active"} />
                                                        </div>
                                                    </div>
                                                }
                                                {(this.props.applyLoaderonDate || (orders && orders.length == 0 && this.state.loader)) ?
                                                    <div >
                                                        {/* <Loader
                                                            type="TailSpin"
                                                            color="#007bff"
                                                            height={50}
                                                            width={50}
                                                        /> */}
                                                        <BootstrapTable version='4' data={dataa} search={true} options={options} csvFileName='orders.csv'>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold' }} width='80' tdStyle={{ fontSize: 10 }} dataField='1' dataFormat={skeltonFormatter} csvHeader='Order Id' dataSort={true} isKey>Order Id</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold' }} width='80' tdStyle={{ fontSize: 10 }} dataFormat={skeltonFormatter} >Details</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Order Recieved</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Store</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Order Type</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal', textTransform: 'capitalize' }} dataFormat={skeltonFormatter} columnTitle>Order Channel</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Delivery time' export={false}>Delivery Time</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10 }} dataFormat={skeltonFormatter}>Status</TableHeaderColumn>
                                                            {/* <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Pulse Status' columnTitle>Pulse Status</TableHeaderColumn> */}
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10 }} dataFormat={skeltonFormatter} formatExtraData={this.props} csvHeader='Delivery Driver'>Delivery Driver</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Customer Name' columnTitle>Name</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Phone' columnTitle>Phone</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Address' columnTitle>Address</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Price'>Order Price</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Discount'>Discount</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Payment Method</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Cancel Reason</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Cancel Reason Description' columnTitle>Cancel Reason Description</TableHeaderColumn>
                                                            {/* <TableHeaderColumn dataField='feedback' csvHeader='Feedback' width='150' columnTitle>Feedback</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='ratings' width='200' csvHeader='Rating' dataFormat={ratingsFormatter} columnTitle>Rating</TableHeaderColumn> */}
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Caller For Order Transfer</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Order Transfer</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Order Transfer Reason</TableHeaderColumn>
                                                            {/* <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>FBR Invoice Number</TableHeaderColumn> */}
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Check Number</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} columnTitle>Aggregator OrderId</TableHeaderColumn>
                                                        </BootstrapTable>
                                                    </div> :
                                                    <>
                                                        <input type='text' value={this.state.searchQuery} onChange={this.handleSearchQuery} placeholder='Search' className="input-dominos-search" />
                                                        <BootstrapTable version='4' data={filteredOrders.length > 0 || emptyFilteredOrder ? filteredOrders : orders} search={false} trClassName={this.rowClassNameFormat} options={options} exportCSV={true} csvFileName='orders.csv'>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold' }} width='80' tdStyle={{ fontSize: 10 }} dataField='order_id' csvHeader='Order Id' dataSort={true} isKey>Order Id</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold' }} width='80' tdStyle={{ fontSize: 10 }} dataField='action' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Details</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='date_created' csvHeader='Order Recieved' columnTitle>Order Recieved</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='store_name' csvHeader='Store' columnTitle>Store</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='delivery_status' csvHeader='Order Type' columnTitle>Order Type</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal', textTransform: 'capitalize' }} dataField='order_channel' columnTitle>Order Channel</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='delivery_time_json' dataFormat={calculateDeliveryTime} csvHeader='Delivery time' export={false}>Delivery Time</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10 }} dataField='order_status_description' dataFormat={selectStatusEditor} formatExtraData={this.props}>Status</TableHeaderColumn>
                                                            {/* <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='failure_reason' csvHeader='Pulse Status' columnTitle>Pulse Status</TableHeaderColumn> */}
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10 }} dataField='user_name' dataFormat={selectDriverEditor} formatExtraData={this.props} csvHeader='Delivery Driver'>Delivery Driver</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='login_name' csvHeader='Customer Name' columnTitle>Name</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='phone_number' csvHeader='Phone' columnTitle>Phone</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='delivery_address' csvHeader='Address' columnTitle>Address</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='order_grossprice' dataFormat={priceFormatter} csvHeader='Price'>Order Price</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='discount' dataFormat={priceFormatter} csvHeader='Discount'>Discount</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='payment_method' csvHeader='Payment Method' columnTitle>Payment Method</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='reason' csvHeader='Cancel Reason' columnTitle>Cancel Reason</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='cancel_reason_description' csvHeader='Cancel Reason Description' columnTitle>Cancel Reason Description</TableHeaderColumn>
                                                            {/* <TableHeaderColumn dataField='feedback' csvHeader='Feedback' width='150' columnTitle>Feedback</TableHeaderColumn>
    <TableHeaderColumn dataField='ratings' width='200' csvHeader='Rating' dataFormat={ratingsFormatter} columnTitle>Rating</TableHeaderColumn> */}
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='order_transfer_caller' csvHeader='Caller For Order Transfer' columnTitle>Caller For Order Transfer</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='is_order_transfer' csvHeader='Order Transfer' dataFormat={isOrderTransferFormatter} columnTitle>Order Transfer</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField="order_transfer_reason" csvHeader="Order Transfer Reason" columnTitle>Order Transfer Reason</TableHeaderColumn>
                                                            {/* <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='fbr_invoice_number' csvHeader='FBR Invoice Number' columnTitle>FBR Invoice Number</TableHeaderColumn> */}
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='check_number' csvHeader='Check Number' columnTitle>Check Number</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='aggregator_orderId' csvHeader='AggregatorOrderId' columnTitle>Aggregator OrderId</TableHeaderColumn>
                                                            {/* <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10 }} dataField='manual_sync' dataFormat={manualSyncApi} formatExtraData={this.props} csvHeader='Manual Sync'>Manual Sync</TableHeaderColumn> */}
                                                        </BootstrapTable></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.props.orderPageCount > 0 &&
                                    <div className="row" >
                                        <div className="col d-flex d-flex justify-content-end align-items-center">
                                            <p className='mr-3'><b className="text-primary">Total Data</b> &nbsp; | &nbsp; <span>{this.props.orderNumOfRows}</span></p>
                                            <ReactPaginate
                                                initialPage={parseInt(this.props.orderCurrentPage)}
                                                breakLinkClassName={'page-link'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link'}
                                                previousLabel={"prev"}
                                                nextLabel={"next"}
                                                breakLabel={"...."}
                                                breakClassName={"page-item"}
                                                pageCount={this.props.orderPageCount}
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={2}
                                                disableInitialCallback={true}
                                                onPageChange={this.handlePageClick}
                                                containerClassName={"pagination"}
                                                activeClassName={"active"} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div >
                <div>
                    <div id="enableTimer" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                        <div role="document" className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 id="exampleModalLabel" className="modal-title">Auto Refresh</h4>
                                    <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body">
                                    <p>Are you sure,you want to {this.state.refresh == false ? "enable" : "disable"} auto refresh?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" ref={el => this.closeModal = el} data-dismiss="modal" className="btn btn-secondary">Close</button>
                                    <button onClick={this.handleTimer} className="btn btn-primary">Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <InvoiceComp data={this.props} />
                <PulseRequest data={this.props} />
                <PulseResponse selectedRow={this.props.selectedRow} />
            </div >
        );
    }
};
function isOrderTransferFormatter(cell: any, row: any) {
    if (row.is_order_transfer == 0) {
        return <p title="No">No</p>;
    } else {
        return <p title="Yes">Yes</p>;
    }
}
function priceFormatter(cell: any, row: any) {
    if (row.aggregator_orderId) {
        return `${currency} ${cell}`;
    } else {
        return `${currency} ${Math.round(cell)}`;
    }
}
function calculateDeliveryTime(cell: any, row: any) {
    let DeliveryTime: any = row.delivery_time_json && JSON.parse(row.delivery_time_json);
    let ActualDeliveryTime = row.delivery_time_json ? DeliveryTime.order_placed + DeliveryTime.prep + DeliveryTime.ready + DeliveryTime.dispatch : 0
    return (
        <div>
            <span {...((row.order_status_code == 6 && row.delivery_time_json) && { title: `${ActualDeliveryTime} min` })}> {(row.order_status_code == 6 && row.delivery_time_json) && `${ActualDeliveryTime} min`} </span>
        </div>
    )
}
const mapStateToProps = (state: any) => {
    return {
        orders: state.order.data,
        filteredOrders: state.order.filteredOrders,
        emptyFilteredOrder: state.order.emptyFilterOrder,
        Items: state.order.orderItems,
        orderDetail: state.order.order,
        statusList: state.order.orderStatus,
        drivers: state.order.deliveryBoys,
        states: state.setting.states,
        stores: state.store.data,
        buttonDisable: state.order.buttonDisable,
        avg: state.order.avg,
        cancelReasons: state.order.cancelReasons,
        prepCount: state.order.prepCount,
        recievedOrderCount: state.order.recievedOrderCount,
        readyCount: state.order.readyCount,
        dispatchCount: state.order.dispatchCount,
        completedOrderCount: state.order.completedOrderCount,
        failedIntegOrderCount: state.order.failedIntegOrderCount,
        dayVal: state.order.dayVal,
        timerStatus: state.order.timerStatus,
        applyLoaderonDate: state.order.isLoading,
        updateTimer: state.order.isTimerUpdate,
        clickmenuSync: state.order.clickmenuSync,
        selectedRow: state.order.selectedRow,
        orderCurrentPage: state.order.currentPage,
        orderPageCount: state.order.pageCount,
        orderNumOfRows: state.order.numOfRows,
        orderXML: state.order.orderXML,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        ordersList: (obj: any, datetime: any, page: any, updateLoader?: any, closeTimer?: any, startTimer?: any, manualCloseTimer?: any) => {
            dispatch(ordersList(obj, datetime, page, updateLoader, closeTimer, startTimer, manualCloseTimer))
        },
        cancelOrdersReasons: () => {
            dispatch(cancelOrdersReasons())
        },
        showorderItems: function (id: any, orders: any) {
            dispatch(showorderItems(id, orders))
        },
        getOrderXML: function (id: any) {
            dispatch(getOrderXML(id))
        },
        orderStatusList: function () {
            dispatch(orderStatusList())
        },
        updateOrder: function (id: any, data: any, history: any) {
            dispatch(updateOrder(id, data, history))
        },
        newmanualSyncOrder: function (id: any, data: any, modal: any) {
            dispatch(newmanualSyncOrder(id, data, modal))
        },
        updatefutureOrder: function (id: any, data: any, history: any) {
            dispatch(updatefutureOrder(id, data, history))
        },
        statesList: function () {
            dispatch(statesList())
        },
        storesList: function () {
            dispatch(storesList())
        },
        filterOrdersList: (filteredArr: any, updateLoader?: any, closeTimer?: any, startTimer?: any) => {
            dispatch(filterOrdersList(filteredArr, updateLoader, closeTimer, startTimer))
        },
        searchOrder: (orderId: any) => {
            dispatch(searchOrder(orderId))
        },
        updateSelectedRow: (row: any) => {
            dispatch(updateSelectedRow(row))
        },
        searchOrdersByQuery: (query: any, obj: any) => {
            dispatch(searchOrdersByQuery(query, obj))
        },
        deliveryBoysList:()=>{
            dispatch(deliveryBoysList())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Orders);