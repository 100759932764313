import Api from '../../components/Api';
import { PARTNER_LIST, GET_PARTNER } from './partnerType';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
export const partnerList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/business-partners/partnersList', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    response.data.successResponse.forEach((obj:any)=>{
                        obj.label= obj.name;
                        obj.value= obj.id;
                 })
                    dispatch({
                        type: PARTNER_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addPartner = (data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post('/business-partners/add_partner', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/partners"
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editPartner = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.put(`/business-partners/edit_partner/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/partners"
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getPartner = (id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/business-partners/partner/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_PARTNER,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockPartner = (id: any, isActive: any, email_address: any) => {
    return function (dispatch: any) {
        let is_active:any;
        if (isActive === 0) {
            is_active = 1;
        } else if (isActive === 1) {
            is_active = 0;
        }
        let token: any = localStorage.getItem('token');
        let data = {
            is_active: is_active,
            email_address:email_address
        }
        Api.put(`/business-partners/block_unblock_partner/${id}`,data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/partners"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}