import { STORE_TABLE_DATA, STORE_TABLE_LIST, TABLE_LIST } from './dineInType'
import Api from '../../components/Api';
import { toast } from "react-toastify";
import jwt from 'jsonwebtoken'
import "react-toastify/dist/ReactToastify.css";
toast.configure();


export const tablesList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/dine-in/tables', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
        .then((response: any) => {
            if (response.data.success) {
                dispatch({
                    type: TABLE_LIST,
                    payload: response.data.successResponse
                })
            }
        }).catch((err: any) => {
            if (err.response) {
                console.log(err.response.data.message)
            } else {
                alert(err.message)
            }
        });
    }
}

export const addTable = (data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post('/dine-in/tables', data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
        .then((response: any) => {
            if (response.data.success) {
                toast.success(response.data.successResponse, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                window.location.href = "/tables"
            }
        }).catch((err: any) => {
            if (err.response) {
                console.log(err.response.data.message)
            } else {
                alert(err.message)
            }
        });
    }
}

export const storeTableList = (id: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/dine-in/store-table/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
        .then((response: any) => {
            if (response.data.success) {
                dispatch({
                    type: STORE_TABLE_LIST,
                    payload: response.data.successResponse
                })
            }
        }).catch((err: any) => {
            dispatch({
                type: STORE_TABLE_LIST,
                payload: []
            })
        });
    }
}

export const saveStoreTables = (data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post('/dine-in/store-table', data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
        .then((response: any) => {
            if (response.data.success) {
                toast.success(response.data.successResponse, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                window.location.href = '/store-table'
            }
        }).catch((err: any) => {
            if (err.response) {
                console.log(err.response.data.message)
            } else {
                alert(err.message)
            }
        });
    }
}