import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
// import DataTable from 'react-data-table-component'
import { ExcelRenderer } from 'react-excel-renderer';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import _ from 'lodash';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
//import { ProgressBar } from 'react-bootstrap';
import * as XLSX from "xlsx";
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { AddressbulkState, AddressbulkProps } from '../../interfaces/bulk';
import { AddressBulkUpload, CustomerBulkUpload } from '../../redux/actions/customerAction';

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class AddressBulk extends Component<AddressbulkProps, AddressbulkState> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      selectFile: "",
      isSelected: false,
      isLoad: false,
      file: null,
      rows: [],
      customerArr: ["CustomerName", "Email", "MobileNo","DeliveryAddress", "CityName"],
    }
    this.submitFile = this.submitFile.bind(this);
  }
  //npm i --save - dev @types/react-fileupload-progress npm install --save - dev @types/react-bootstrap

  componentDidMount() {
    document.title = "Crust Culture | Bulk Upload"
  }

  isEqual = (columns: any, fileFormat: any) => {
    if (columns.length != fileFormat.length) {
      return false;
    } else {
      for (var i = 0; i < columns.length; i++) {
        if (columns[i] != fileFormat[i]) {
          return false
        } else {
          return true;
        }
      }
    }
  }

  fileHandler = (event: any) => {
    let fileObj = event.target.files[0];
    console.log("file OBj", fileObj);

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err: any, resp: any) => {
      if (err) {
        console.log(err);
      }
      else {
        this.setState({
          rows: resp.rows
        });

        const realData = resp.rows;
        console.log("excel Data: ", realData);
        const cleanData = realData.filter((el: any) => {
          return el.length;
        })
        if (cleanData.length == 0) {
          alert("Empty files can't be slected!");
          this.props.history.push('/addressBulk');
        }
        // // Validate for Correct file
        const columns = cleanData.shift();
        // console.log("columns: ", columns);
        // if (this.state.selectFile === "customers") {
          let response = this.isEqual(columns, this.state.customerArr)
        //   console.log("asljdfasklfjklas", response)
          this.setState({ isSelected: true })
          if (response == true) {
          } else {
            alert("Please select the correct format file.")
            this.props.history.replace('/addressBulk');
          }
        // }
      }
    });
  }


  submitFile() {
    let { rows } = this.state;
    let arr: any = [];
    rows.forEach((element: any) => {
      let obj: any = {
        login_name: element[0],
        email_address: element[1],
        phone_number: element[2],
        address: element[3]
      }
      arr.push(obj);
    });
    arr.shift();
    this.props.AddressBulkUpload(arr);
    this.setState({ isLoad: true });
    this.setState({ isSelected: true })
  }

  render() {
    if (localStorage.token) {
      jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />
    }

    const { isLoad, rows, file } = this.state;
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Address Upload</h4>
                </div>
              </div>
            </header>

            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">

                        <div className="row form-group mt-3">
                          <div className="col-md-3"></div>
                          {(!isLoad) &&
                            <div className="col-md-5">
                              <input
                                type="file"
                                className="btn btn-primary text-white btn-sm"
                                // disabled={!this.state.selectFile}
                                name="rows"
                                //ref={this.inputRef}
                                onClick={(event: any) => {
                                  event.target.value = null
                                }}
                                multiple={false}
                                onChange={this.fileHandler}
                                style={{ "padding": "6px" }} />
                            </div>
                          }
                          <div className="col-md-2">
                           <button className="btn btn-primary" style={{}} onClick={this.submitFile}><i className="fa fa-upload"></i> Upload File</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    AddressBulkUpload: (data: any) => dispatch(AddressBulkUpload(data)),
  }
}
export default connect(null, mapDispatchToProps)(AddressBulk);