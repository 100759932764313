import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { EditQuestionProps, EditQuestionState } from '../../interfaces/survey';
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken';
import { secretKey } from '../../secret';
import { logoutUser, editQuestion } from '../../redux';
import Topbar from '../../components/topbar';
import Sidebar from '../../components/sidebar';
import Footer from '../../components/footer/main';
import CheckChanges from '../../components/confirmOnLeave';
import { getQuestion } from '../../redux/actions/surveyAction';

class EditQuestion extends Component<EditQuestionProps, EditQuestionState> {
    constructor(props: any) {
        super(props);
        this.state = {
            question: "",
            options: [
                {
                    option: "",
                    priority: "",
                    color: ""
                }
            ]
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getQuestion(id);
        document.title = "Crust Culture | NPS"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({
            question: nextProps.questionData.question,
            options: nextProps.questionData.survey_options
        })
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    isQuestionReady = () => {
        const { question } = this.state
        return (question !== "");
    }
    handleOptionInputChange = (e: any, index: any) => {
        const { name, value } = e.target;
        const list: any = this.state.options;
        list[index][name] = value;
        this.setState({ options: list });
    };
    // handle click event of the Remove button
    handleRemoveClick = (index: any) => {
        const list = this.state.options;
        list.splice(index, 1);
        this.setState({ options: list });
    };
    handleAddClick = (i: any) => {
        const list: any = this.state.options;
        if (list[i]["option"] == "" || list[i]["priority"] == "") {
            alert("Please fill all mandatory option fields")
        } else {
            let obj: any = {
                option: "",
                priority: "",
                color: ""
            }
            list.push(obj)
            this.setState({ options: list })
        }
    };
    handleSaveBtnClick = (event: any) => {
        let { question, options } = this.state;
        let id = this.props.match.params.id;
        let data: any = {
            question: question,
            options: JSON.stringify(options)
        }
        if (options[options.length - 1]["option"] !== "" && options[options.length - 1]["priority"] !== "") {
            this.props.editQuestion(id, data);
        } else {
            alert("Please fill all mandatory option fields")
        }
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let { options } = this.state;
        const { questionData } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/edit-nps" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">NPS Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/nps" className="text-primary">NPS</Link></li>
                                <li className="breadcrumb-item active">Edit Question</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Question <span className="text-danger">*</span></label>
                                                            <input id="question" type="text" name="question" required data-msg="Please enter question" className="input-material" defaultValue={questionData.question} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            {options.map((x: any, ind: any) => {
                                                                return (
                                                                    <div key={ind}>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label">Option {ind + 1}<span className="text-danger">*</span></label>
                                                                                    <input id="option" type="text" name="option" required defaultValue={x.option} className="input-material" onChange={e => this.handleOptionInputChange(e, ind)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label">Priority<span className="text-danger">*</span></label>
                                                                                    <input id="priority" type="text" name="priority" required defaultValue={x.priority} className="input-material" onChange={e => this.handleOptionInputChange(e, ind)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label">Color (Hex code)</label>
                                                                                    <input id="color" type="text" name="color" required defaultValue={x.color} className="input-material" onChange={e => this.handleOptionInputChange(e, ind)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className="col d-flex justify-content-end">
                                                                                <div className="form-group">
                                                                                    {options.length !== 1 &&
                                                                                        <button className="btn btn-sm btn-outline-danger" onClick={() => this.handleRemoveClick(ind)}><i className="fa fa-trash"></i></button>}
                                                                                    {options.length - 1 === ind && <button className="btn btn-sm btn-primary ml-2 float-right" onClick={() => this.handleAddClick(ind)}><i className="fa fa-plus"></i></button>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group float-right mt-3">
                                                    <button onClick={() => { this.props.history.push("/questions") }} className="btn btn-danger mr-2">Cancel</button>
                                                    <button className='btn btn-primary' disabled={!this.isQuestionReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        questionData: state.survey.questionData
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        editQuestion: function (id: any, data: any) {
            dispatch(editQuestion(id, data));
        },
        getQuestion: function (id: number) {
            dispatch(getQuestion(id));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditQuestion);