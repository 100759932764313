import { COUPONS_LIST, GET_COUPON, ADD_COUPON, EDIT_COUPON, SEARCH_COUPONS_LIST } from '../actions/couponType'
const initialState: any = {
    data: [] || "",
    searchCoupons: [] || "",
    couponData: {},
    isInserted: "",
    isUpdated: "",
    message: "",
    currentPage: 0,
    pageCount: 0,
    numOfRows: 0,
}

const couponReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case COUPONS_LIST: return {
            ...state,
            data: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows
        }
        case SEARCH_COUPONS_LIST: return {
            ...state,
            searchCoupons: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows
        }
        case GET_COUPON: return {
            ...state,
            couponData: action.payload
        }
        case ADD_COUPON: return {
            ...state,
            isInserted: action.isInserted,
            message: action.payload
        }
        case EDIT_COUPON: return {
            ...state,
            isUpdated: action.isUpdated,
            message: action.payload
        }
        default: return state;
    }
}
export default couponReducer;