import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { storesList, blockunblockstore, logoutUser } from '../../redux'
import { StoreProps } from '../../interfaces/store';
import QRCode from 'qrcode.react'
import { statusChangeReasons, unresolvedordersList, UnsuspendStore, updateFPStoreStatus, updateStoreSnooze, uploadStoreImage } from '../../redux/actions/storeAction';
import { API_URL } from '../../client-config'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import moment from 'moment';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
class ActionFormatter extends Component<{ row: any, itemStatusReasons: any, unresolvedStoreOrder: any, unresolvedOrderLength: (obj: any) => {}, blockunblock: (id: any, is_active: any, data: any) => {}, UnsuspendStore: (id: any, data: any) => {}, uploadStoreImage: (store_id: any, data: any) => {}, updateStoreSnooze: (store_id: any, data: any) => {},updateFPStoreStatus:(store_id:any)=>{} }, { [x: number]: any, statusReason: any, image: any, startDate: any, endDate: any, snoozeReason: any ,fp_store_status:any,isLoad:any}> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            statusReason: "",
            image: "",
            startDate: '',
            endDate: '',
            snoozeReason: '',
            fp_store_status:'',
            isLoad:""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    isStoreSnoozeReady = () => {
        const { startDate, endDate, snoozeReason } = this.state
        return (startDate !== "" && endDate !== "" && snoozeReason !== "");
    }
    handleBlockUnblock = (id: any, is_active: any) => {
        //  localStorage.ge
        let { row } = this.props;
        let { statusReason } = this.state
        let token: any = localStorage.getItem('token')
        let userInfo: any = jwt.decode(token)
        let data: any = {
            role: userInfo.role,
            user_name: userInfo.first_name + " " + userInfo.last_name,
            reason: statusReason,
            activity_type: 'Stores',
            activity_name: row.store_name,
            status: is_active == 0 ? "Unblock" : "Block"
        }
        this.props.blockunblock(id, is_active, data);
    };
    handleUnsuspendStore = (id: any, is_active: any) => {
        //  localStorage.ge
        let { row } = this.props;
        let obj = {
            is_suspend: 0,
            is_active: 1
        }
        this.props.UnsuspendStore(id, obj)
    };
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    getOrdersLength = (store_id: any) => {
        let obj = {
            store_id: store_id,
            days: 1
        }
        this.props.unresolvedOrderLength(obj)
    }
    fileSelectedHandler = (e: any) => {
        this.setState({ image: e.target.files })
    }
    handleSubmit = (store_id: any) => {
        let { image } = this.state;
        const data: any = new FormData()
        if (image) {
            data.append('file', image[0])
        }
        this.props.uploadStoreImage(store_id, data);
    }
    handleSnooze = (store_id: any) => {
        let { startDate, endDate, snoozeReason } = this.state;
        let data = {
            store_id: store_id,
            snooze_start_time: startDate,
            snooze_end_time: endDate,
            snoozeReason: snoozeReason
        }
        this.props.updateStoreSnooze(store_id, data);
    }
    handleFPStoreStatus = (id: any) => {
        let { fp_store_status } = this.state;
        let { row } = this.props;
        if(row.fpRestaurantId && row.fpRestaurantId!==""){
            if(row.fp_branch_code && row.fp_branch_code!==""){
                this.setState({isLoad:true})
                let obj = {
                    fpRestaurantId: row.fpRestaurantId,
                    store_status: fp_store_status,
                    fp_branch_code: row.fp_branch_code
                }
                this.props.updateFPStoreStatus(id, obj)
            }else{
                toast.error("Aggregator Branch Code not exist", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            }
        }else {
            toast.error("Aggregator Restaurant Id not exist", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
        }
    };
    render() {
        const { row, itemStatusReasons, unresolvedStoreOrder } = this.props
        let today = moment().format("YYYY-MM-DDThh:mm");
        return (
            <div>
                {!row.is_suspend &&
                    <Link to="#" title={row.is_active === 0 ? "Activate" : "Deactivate"} data-toggle="modal" data-target={`#blockunblock${row.store_id}`} className={row.is_active === 0 ? "btn btn-outline-success mx-2" : "btn btn-outline-danger mx-2"}><i className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}></i></Link>
                }
                {row.is_suspend == 1 &&
                    <Link to="#" onClick={() => this.getOrdersLength(row.store_id)} title={row.is_suspend === 1 ? "Reinstate" : "Suspend"} data-toggle="modal" data-target={`#suspend${row.store_id}`} className="btn btn-outline-success mx-2"><i className="fa fa-refresh"></i></Link>
                }
                <Link title="Edit Store" className="btn btn-outline-primary mx-2" to={`/edit-store/${row.store_id}`}><i className="fa fa-edit"></i></Link>
                <Link to="#" title={"Upload Image"} data-toggle="modal" data-target={`#uploadImage${row.store_id}`} className={"btn btn-outline-success mx-2"}><i className={"fa fa-camera"}></i></Link>
                <Link to="#" title={!row.snooze_start_time ? "Snooze Store" : "End Snooze Store"} data-toggle="modal" data-target={`#snoozeStore${row.store_id}`} className={"btn btn-outline-warning m-2"}><i className={"fa fa-clock-o"}></i></Link>
                <Link to="#" title={"FP Store Status Sync"} data-toggle="modal" data-target={`#fp_store_status${row.store_id}`} className={"btn btn-outline-fp"}><i className="fa fa-refresh"></i></Link>
                {/* <!-- B/U Modal--> */}
                <div id={`blockunblock${row.store_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 0 ? "Activate" : "Deactivate"} Store</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <p>Are you sure,you want to {row.is_active === 0 ? "Activate" : "Deactivate"} this store?</p>
                                    </div>
                                </div>
                                {row.is_active === 1 &&
                                    <div className="col-12">
                                        <div className="form-group">
                                            <select
                                                name="statusReason"
                                                onChange={this.handleInputChange}
                                                className="form-control mt-2">
                                                <option value="">Select Reason</option>
                                                {itemStatusReasons &&
                                                    itemStatusReasons.map((reason: any, index: any) => (
                                                        <option key={index} value={reason.reason}>{reason.reason}</option>
                                                    ))
                                                }
                                            </select>

                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleBlockUnblock(row.store_id, row.is_active)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id={`suspend${row.store_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Reinstate Store</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        {unresolvedStoreOrder.length > 0 ?
                                            <p>Store has some unresolved orders pending. </p>
                                            :
                                            <p>Are you sure,you want to reinstate this store?</p>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                {unresolvedStoreOrder.length > 0 ?
                                    <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                    :
                                    <>
                                        <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                        <button onClick={() => this.handleUnsuspendStore(row.store_id, row.is_suspend)} className="btn btn-primary">Confirm</button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div id={`uploadImage${row.store_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Upload Store Image</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-control-label mb-3">Store Image <span className="text-danger"> {(row.store_image && row.store_image !== "") && `(Store has 1 image)`}</span></label>
                                            <input id="file" type="file" name="file" className="form-control-file" onChange={this.fileSelectedHandler} />
                                            <p style={{ color: "red", fontSize: "12px" }}>Image size should be less than 1 mb</p>
                                        </div>
                                    </div>
                                    {(row.store_image && row.store_image !== "") &&
                                        <div className="col-12">
                                            <img className="img-fluid" src={`${API_URL}/${row.store_image}`} />
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleSubmit(row.store_id)} className="btn btn-primary">Upload</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id={`snoozeStore${row.store_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{!row.snooze_start_time ? "Snooze" : "End Snooze"} Store </h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                {row.snooze_start_time &&
                                    <div className="row">
                                        <div className="col-12">
                                            <p>Are you sure,you want to End Snooze of this store?</p>
                                        </div>
                                    </div>}
                                <div className='row'>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="form-control-label mb-3">Snooze Start <span className="text-danger">*</span></label>
                                            <input type="datetime-local" name="startDate" disabled={row.snooze_start_time ? true : false} min={today} defaultValue={moment(row.snooze_start_time).utc(false).format('YYYY-MM-DDTHH:mm:ss')} required data-msg="Please enter Date" placeholder="DD/MM/YYYY" className="form-control" onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="form-control-label mb-3">Snooze End <span className="text-danger">*</span></label>
                                            <input type="datetime-local" name="endDate" disabled={row.snooze_start_time ? true : false} min={today} defaultValue={moment(row.snooze_end_time).utc(false).format('YYYY-MM-DDTHH:mm:ss')} required data-msg="Please enter Date" placeholder="DD/MM/YYYY" className="form-control" onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    {!row.snooze_start_time &&
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-control-label">Snooze Reason <span className="text-danger">*</span></label>
                                                <select
                                                    name="snoozeReason"
                                                    onChange={this.handleInputChange}
                                                    className="form-control mt-2">
                                                    <option value="">Select Reason</option>
                                                    {itemStatusReasons &&
                                                        itemStatusReasons.map((reason: any, index: any) => (
                                                            <option key={index} value={reason.reason}>{reason.reason}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleSnooze(row.store_id)} disabled={!row.snooze_start_time ? !this.isStoreSnoozeReady() : false} className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id={`fp_store_status${row.store_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">FP Store Status</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                            <div className="col-12">
                                        <div className="form-group">
                                            <select
                                                name="fp_store_status"
                                                onChange={this.handleInputChange}
                                                className="form-control mt-2">
                                                <option value="">Select Status</option>
                                                <option value={"OPEN"}>{"OPEN"}</option>
                                                <option value={"CLOSED"}>{"CLOSED"}</option>
                                                {/* <option value={"CLOSED_UNTIL"}>{"CLOSED_UNTIL"}</option> */}
                                            </select>
                                        </div>
                                    </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                {(this.state.isLoad) ?
                              <button className='btn btn-primary d-flex justify-content-end align-item-center' disabled={this.state.isLoad}><Loader type="TailSpin" color="white" height={30} width={30} /></button> :
                              <button disabled={this.state.fp_store_status==""} onClick={() => this.handleFPStoreStatus(row.store_id)} className="btn btn-primary">Confirm</button>
                            }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} unresolvedStoreOrder={props.unresolvedStoreOrder} unresolvedOrderLength={props.unresolvedordersList} blockunblock={props.blockunblockstore} uploadStoreImage={props.uploadStoreImage} updateStoreSnooze={props.updateStoreSnooze} UnsuspendStore={props.UnsuspendStore} itemStatusReasons={props.itemStatusReasons} updateFPStoreStatus={props.updateFPStoreStatus}/>
    );
}
class WeekylyTimingFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                {(row.daysTiming && row.isDays == 1) &&
                    <button title="View weeklyTimings" data-toggle="modal" data-target={`#weeklytiming${row.store_id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button>
                }
                {/* <!-- Modal--> */}
                <div id={`weeklytiming${row.store_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Weekly Timing</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            <BootstrapTable version='4' data={(row.daysTiming && this.IsJsonString(row.daysTiming)) ? JSON.parse(row.daysTiming) : ""} hover>
                                                <TableHeaderColumn dataField='day' dataSort={true} isKey>Day</TableHeaderColumn>
                                                <TableHeaderColumn dataField='opening' columnTitle>Opening</TableHeaderColumn>
                                                <TableHeaderColumn dataField='closing' columnTitle>Closing</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
function weeklyTimingFormatter(cell: any, row: any, props: any) {
    return (
        <WeekylyTimingFormatter row={row} data={props} />
    );
}
class Stores extends Component<StoreProps, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.statusChangeReasons();
        this.props.storesList();
        document.title = "Crust Culture | Stores"
    }
    // createInsertModal = (onModalClose: any, onSave: any, columns: any, validateState: any, ignoreEditable: any) => {
    //     const attr = {
    //         onModalClose, onSave, columns, validateState, ignoreEditable
    //     };
    //     return (
    //         <CustomInsertModal {...attr} />
    //     );
    // }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-store" /> },
            noDataText: 'Stores Not Found'
        };
        // const selectRowProp: any = {
        //     mode: 'checkbox',
        //     bgColor: '#4ac1ec',
        //     columnWidth: '40px'
        // };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Stores Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='stores.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='store_id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='200' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='is_snooze' width='100' dataFormat={SnoozeFormatter} export={false}>Snooze</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='branch_code' csvHeader='POS Branch Code' width='100' columnTitle>POS Branch Code</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='fp_status' csvHeader='FP Status' width='100' columnTitle>Agg Status</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='fpRestaurantId' csvHeader='Aggregator Restaurant ID' width='100' columnTitle>Agg Restaurant ID</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='fp_branch_code' csvHeader='Aggregator Branch Code' width='100' columnTitle>Agg Branch Code</TableHeaderColumn>
                                                    {/* <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='aloha_branch_code' csvHeader='POS Branch Code' width='100' columnTitle>POS Branch Code</TableHeaderColumn> */}
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='pos_no' csvHeader='Pos No' width='100' columnTitle>Pos No</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='store_name' csvHeader='StoreName' width='130' columnTitle>Store name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='is_active' width='100' dataFormat={statusFormatter} export={false}>Status</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='store_email' csvHeader='StoreEmail' width='100' columnTitle>Store email</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='brand_name' csvHeader='Brand' width='100' columnTitle>Brand</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='channel_name' csvHeader='Channel' width='100' columnTitle>Sales Channel</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='store_type_name' csvHeader='Type' width='100' columnTitle>Store Type</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='ntn_number' csvHeader='Ntn number' width='100' columnTitle>NTN</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='city_name' csvHeader='City' width='100' columnTitle>City</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='state_name' csvHeader='State' width='100' columnTitle>State</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='country_name' csvHeader='Country' width='100' columnTitle>Country</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='address' csvHeader='Address' width='150' columnTitle>Address</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='store_open_time' dataFormat={timeFormatter} csvHeader='OpeningTime' width='100' columnTitle>Opening time</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='store_close_time' dataFormat={timeFormatter} csvHeader='ClosingTime' width='100' columnTitle>Closing time</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='100' dataFormat={weeklyTimingFormatter} export={false}>Weekly Timings</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='escalation_email' csvHeader='EscalationEmail' width='100' columnTitle>Escalation email</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='contact1' csvHeader='Contact1' width='130' columnTitle>Contact 1</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='qrCode' csvHeader='qrCode' dataFormat={qrCode} export={false} width='160' columnTitle>QR Code</TableHeaderColumn>
                                                </BootstrapTable>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
function timeFormatter(cell: any) {
    //convert the opening time 24 hour to 12 hour
    let time = cell.split(":")
    let Hours = time[0]
    let AmOrPm = Hours >= 12 ? 'PM' : 'AM';
    Hours = (Hours % 12) || 12;
    let Finaltime = Hours + ":" + time[1] + " " + AmOrPm;
    return Finaltime;
}

function qrCode(cell: any, props: any) {

    let url = `https://crustculture.com.pk/menu?store_id=${props.store_id}&md=Qr-Pickup`
    // let url = `http://192.168.100.15:5001/menu?store_id=${props.store_id}&md=Qr-Pickup`
    // props.store_id
    // return <a style={{ cursor: 'pointer' }}>Click Here</a>;
    return <div>
        <QRCode
            id={`qr_${props.store_id}`}
            value={url}
            includeMargin={true}
        />

        <div style={{ display: 'none' }}>
            <QRCode
                id={`qr2_${props.store_id}`}
                value={url}
                includeMargin={true}
                size={3000}
            />
        </div>
        <a style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={() => downloadQR(props.store_id, props.store_name)}> Download QR </a>
    </div>
}
function SnoozeFormatter(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell === 1 && { className: "badge badge-success p-2" })}>{cell === 1 && <i className="fa fa-check-square-o"></i>}</span>
        </div>
    )
}
function downloadQR(id: any, name: any) {
    let doc = document as any
    const canvas = doc.getElementById(`qr2_${id}`);
    const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.style.height = '1000px'
    downloadLink.href = pngUrl;
    downloadLink.download = `QR_${name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}
function statusFormatter(cell: any, row: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" }) || (cell == 0 && { className: "badge badge-danger p-2" })}>{cell == 1 ? "active" : "Inactive"}</span>
            {row.is_suspend == 1 &&
                <span className="badge badge-danger p-2 mt-1">Suspended</span>
            }
        </div>
    )
}
const mapStateToProps = (state: any) => {
    return {
        data: state.store.data,
        itemStatusReasons: state.store.statusChangeReasons,
        unresolvedStoreOrder: state.store.unresolvedStoreOrder
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        storesList: function () {
            dispatch(storesList())
        },
        blockunblockstore: function (id: any, is_active: any, data: any) {
            dispatch(blockunblockstore(id, is_active, data))
        },
        UnsuspendStore: function (id: any, data: any) {
            dispatch(UnsuspendStore(id, data))
        },
        statusChangeReasons: function () {
            dispatch(statusChangeReasons())
        },
        unresolvedordersList: function (obj: any) {
            dispatch(unresolvedordersList(obj))
        },
        uploadStoreImage: (store_id: any, data: any) => {
            dispatch(uploadStoreImage(store_id, data))
        },
        updateStoreSnooze: (store_id: any, data: any) => {
            dispatch(updateStoreSnooze(store_id, data))
        },
        updateFPStoreStatus:(store_id: any, data:any)=>{
            dispatch(updateFPStoreStatus(store_id, data))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Stores);