import { PROMOS_LIST, GET_PROMO, ADD_PROMO, EDIT_PROMO } from '../actions/promoType'
const initialState: any = {
    data: [] || "",
    promoData: {},
    isInserted: "",
    isUpdated: "",
    message: "",
}

const promoReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case PROMOS_LIST: return {
            ...state,
            data: action.payload
        }
        case GET_PROMO: return {
            ...state,
            promoData: action.payload
        }
        case ADD_PROMO: return {
            ...state,
            isInserted: action.isInserted,
            message: action.payload
        }
        case EDIT_PROMO: return {
            ...state,
            isUpdated: action.isUpdated,
            message: action.payload
        }
        default: return state;
    }
}
export default promoReducer;