export const HANDLE_INPUT="HANDLE_INPUT"
export const TRADEZONE_LIST = "TRADEZONE_LIST"
export const GET_TRADEZONE = "GET_TRADEZONE"
export const KML_TRADEZONE_LIST = "KML_TRADEZONE_LIST"
export const GET_KML_TRADEZONE = "GET_KML_TRADEZONE"
export const ACTIVE_KML_TRADEZONE = "ACTIVE_KML_TRADEZONE"
export const ACTIVE_TRADEZONE = "ACTIVE_TRADEZONE"

export const HANDLE_AREA_INPUT="HANDLE_AREA_INPUT"
export const TRADEAREA_LIST = "TRADEAREA_LIST"
export const GET_TRADEAREA = "GET_TRADEAREA"
