import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
// import DataTable from 'react-data-table-component'
import { ExcelRenderer } from 'react-excel-renderer';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import _ from 'lodash';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
//import { ProgressBar } from 'react-bootstrap';
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { bulkState, bulkProps } from '../../interfaces/bulk';
import { BusinessPartnersBulkUpload, CustomerBulkUpload, EmployeesBulkUpload } from '../../redux/actions/customerAction';

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class Bulk extends Component<bulkProps, bulkState> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      selectFile: "",
      isSelected: false,
      isLoad: false,
      file: null,
      rows: [],

      customerArr: ["name", "email_address", "phone_number", "town_city"],
      employeesArr: ["Name", "Email", "Mobile", "VoucherCode", "RedemptionLimit", "Vip", "Type"],
      partners: ["Name", "Email", "Phone", "Company", "vip"]
      // coupons: ["CouponName", "CouponCode", "TotalLimit", "Mode", "Type", "CouponValue", "Channel", "MinCartAmount", "MinOrders", "UsagePerCustomer", "StartDate", "ExpiryDate"],
    }
    this.submitFile = this.submitFile.bind(this);
    this.handleSlecetChange = this.handleSlecetChange.bind(this);
    //this.inputRef = React.createRef();
  }
  inputRef: any;
  //npm i --save - dev @types/react-fileupload-progress npm install --save - dev @types/react-bootstrap

  componentDidMount() {
    document.title = "Crust Culture | Bulk Upload"
  }

  isEqual = (columns: any, fileFormat: any) => {
    if (columns.length != fileFormat.length) {
      return false;
    } else {
      for (var i = 0; i < columns.length; i++) {
        if (columns[i] != fileFormat[i]) {
          return false
        } else {
          return true;
        }
      }
    }
  }

  fileHandler = (event: any) => {
    let fileObj = event.target.files[0];
    console.log("file OBj", fileObj);

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err: any, resp: any) => {
      if (err) {
        console.log("ERROR", err);
      }
      else {
        this.setState({
          rows: resp.rows
        });

        const realData = resp.rows;
        console.log("excel Data: ", realData);
        const cleanData = realData.filter((el: any) => {
          return el.length;
        })
        console.log("Clean Data: ", cleanData);

        // File Limit
        if (cleanData.length > 10000) {
          alert("Please select file upto 10000 rows!");
          this.setState({ isSelected: false })
        }
        if (cleanData.length == 0) {
          alert("Empty files can't be slected!");
          this.setState({ isSelected: false })
        }
        // Validate for Correct file
        const columns = cleanData.shift();
        console.log("columns: ", columns);
        if (this.state.selectFile === "customers") {
          let response = this.isEqual(columns, this.state.customerArr)
          console.log("customerResponse", response)

          if (response == true) {
            this.setState({ isSelected: true })
          } else {
            alert("Please select the correct format file.")
            this.props.history.replace('/bulk');
          }
        } else if (this.state.selectFile === "employees") {
          let response = this.isEqual(columns, this.state.employeesArr)
          console.log("EmployeesResponse", response)
          if (response == true) {
            this.setState({ isSelected: true })
          } else {
            alert("Please select the correct format file.")
            this.setState({ isSelected: false })
          }
        } else if (this.state.selectFile === "partners") {
          let response = this.isEqual(columns, this.state.partners)
          console.log("EmployeesResponse", response)
          if (response == true) {
            this.setState({ isSelected: true })
          } else {
            alert("Please select the correct format file.")
            this.props.history.replace('/bulk');
          }
        }
      }
    });
  }

  handleSlecetChange(event: { target: { name: any; value: any; } }) {
    this.setState({ [event.target.name]: event.target.value, rows: [], isSelected: false });
  }
  ExcelDateToJSDate = (date: any) => {
    let converted_date: any = new Date(Math.round((date - 25569) * 864e5));
    converted_date = String(converted_date).slice(4, 15)
    date = converted_date.split(" ")
    let day = date[1];
    let month = date[0];
    month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1
    if (month.toString().length <= 1)
      month = '0' + month
    let year = date[2];
    return String(day + '-' + month + '-' + year)
  }
  submitFile() {

    if (this.state.selectFile === "customers") {
      let { rows } = this.state;
      console.log("After Submit: ", rows)
      let arr: any = [];
      rows.forEach((element: any) => {
        let obj: any = {
          login_name: element[0],
          email_address: element[1],
          phone_number: element[2],
          town_city: element[3]
        }
        arr.push(obj);
      });
      arr.shift();
      //this.inputRef.current.value = '';
      // Clean Array to avoid null data
      const cleanArr = arr.filter((el: any) => {
        return el.phone_number !== undefined;
      })
      console.log("cleanArr: ", cleanArr);

      // Loop for getting lower case email
      // const clean = cleanArr.map((el: any) => {
      //   return el.email_address && el.email_address.toLowerCase();
      //   //return (el.email_address.toLowerCase());
      // })
      // console.log("lowerCase: ", clean);
      // Use filter to get Array wit lower case email
      // const lower = cleanArr.filter((el: any, i: any) => {
      //   return el.email_address = clean[i];
      // })
      // console.log("LowerData", lower);

      // Find Unique rows from array of abjects
      const unique = cleanArr.filter((v: any, i: any) => {
        return (
          // lower.map((val: any) => val.email_address).indexOf(v.email_address) == i &&
          cleanArr.map((val: any) => val.phone_number).indexOf(v.phone_number) == i
        )
      })
      console.log("Unique: ", unique);
      this.props.CustomerBulkUpload(unique);
      this.setState({ isLoad: true });
      this.setState({ isSelected: true })
      console.log("Dup Data", this.props.dupData);
    } else if (this.state.selectFile === "employees") {
      let { rows } = this.state;
      console.log("After Submit: ", rows)
      let arr: any = [];
      rows.forEach((element: any) => {
        let obj: any = {
          name: element[0],
          email_address: element[1],
          phone_number: element[2],
          voucher_codes: element[3],
          redemption_limit: element[4],
          vip: element[5],
          employee_type_id: element[6] == "Partner" ? 2 : 1,
        }
        arr.push(obj);
      });
      arr.shift();
      // Clean Array to avoid null data
      const cleanArr = arr.filter((el: any) => {
        return el.phone_number !== undefined;
      })
      console.log("cleanArr: ", cleanArr);
      const unique = cleanArr.filter((v: any, i: any) => {
        return (
          // lower.map((val: any) => val.email_address).indexOf(v.email_address) == i &&
          cleanArr.map((val: any) => val.phone_number).indexOf(v.phone_number) == i
        )
      })
      console.log("Unique: ", unique);
      this.props.EmployeesBulkUpload(unique);
      this.setState({ isLoad: true });
      console.log("Dup Data", this.props.dupData);
    } else if (this.state.selectFile === "partners") {
      let { rows } = this.state;
      console.log("After Submit: ", rows)
      let arr: any = [];
      rows.forEach((element: any) => {
        let obj: any = {
          name: element[0],
          email_address: element[1],
          phone_number: element[2],
          company: element[3],
          vip: element[4]
        }
        arr.push(obj);
      });
      arr.shift();
      //this.inputRef.current.value = '';
      // Clean Array to avoid null data
      const cleanArr = arr.filter((el: any) => {
        return el.phone_number !== undefined;
      })
      console.log("cleanArr: ", cleanArr);
      const unique = cleanArr.filter((v: any, i: any) => {
        return (
          // lower.map((val: any) => val.email_address).indexOf(v.email_address) == i &&
          cleanArr.map((val: any) => val.phone_number).indexOf(v.phone_number) == i
        )
      })
      console.log("Unique: ", unique);
      this.props.BusinessPartnersBulkUpload(unique);
      this.setState({ isLoad: true });
      this.setState({ isSelected: true })
      console.log("Dup Data", this.props.dupData);
    }
  }

  render() {
    if (localStorage.token) {
      jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />
    }

    const { dupData } = this.props;
    console.log("Duplicate Data: ", dupData);

    const { isLoad } = this.state;

    const options: any = {
      sizePerPage: 10, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      noDataText: 'Data Not Found'
    };
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Bulk Upload</h4>
                </div>
              </div>
            </header>

            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">

                        <div className="form-horizontal">
                          <div className="row form-group">
                            <label
                              className="col-md-3 col-sm-3 form-control-label font-weight-bold"
                              style={{ paddingTop: "1em" }}
                            >
                              Select File
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9 col-sm-9">
                              <select
                                name="selectFile"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select file"
                                onChange={this.handleSlecetChange}
                              >
                                <option value="">=== Select File ===</option>
                                <option value="customers">Customers</option>
                                <option value="employees">Employees</option>
                                {/* <option value="partners">Business Partners</option> */}
                                {/* <option value="coupons">Coupons</option> */}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row form-group mt-3">
                          <div className="col-md-3"></div>
                          {(!isLoad) &&
                            <div className="col-md-5">
                              <input
                                type="file"
                                className="btn btn-primary text-white btn-sm"
                                disabled={!this.state.selectFile}
                                name="rows"
                                //ref={this.inputRef}
                                onClick={(event: any) => {
                                  event.target.value = null
                                }}
                                multiple={false}
                                onChange={this.fileHandler}
                                style={{ "padding": "6px" }} />
                            </div>
                          }
                          {(dupData && dupData.length > 0) &&
                            <Link to="/bulk" className="btn btn-primary col-md-6" style={{}}><i className="fa fa-upload"></i> Upload Again</Link>
                          }
                          <div className="col-md-2">
                            {(isLoad) ?
                              <button className='btn btn-primary d-flex justify-content-end align-item-center' disabled={this.state.isLoad}><Loader type="TailSpin" color="white" height={30} width={30} /></button> :
                              <button className='btn btn-primary' disabled={!this.state.isSelected} onClick={this.submitFile}><i className="fa fa-upload"></i> Upload File</button>
                            }
                          </div>

                        </div>
                      </div>

                      <div className="card-body">
                        {
                          (this.state.selectFile == "customers" && dupData && dupData.length > 0) ?
                            <BootstrapTable version='4' data={this.props.dupData} search={true} pagination={this.props.dupData.length > 10 && true} options={options} insertRow={false} exportCSV={true} csvFileName='customers.csv' hover>
                              <TableHeaderColumn dataField='customer_id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                              <TableHeaderColumn dataField='email_address' csvHeader='Email' width='100' columnTitle>Email Address</TableHeaderColumn>
                              <TableHeaderColumn dataField='phone_number' csvHeader='Phone' width='100' columnTitle>Phone Number</TableHeaderColumn>
                            </BootstrapTable>
                            : (this.state.selectFile == "employees" && dupData && dupData.length > 0) ?
                              <BootstrapTable version='4' data={this.props.dupData} search={true} pagination={this.props.dupData.length > 10 && true} options={options} insertRow={false} exportCSV={true} csvFileName='employees.csv' hover>
                                <TableHeaderColumn dataField='id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                <TableHeaderColumn dataField='name' csvHeader='Name' width='100' columnTitle>Name</TableHeaderColumn>
                                <TableHeaderColumn dataField='email_address' csvHeader='Email' width='100' columnTitle>Email Address</TableHeaderColumn>
                                <TableHeaderColumn dataField='phone_number' csvHeader='Phone' width='100' columnTitle>Phone Number</TableHeaderColumn>
                              </BootstrapTable> : (this.state.selectFile == "partners" && dupData && dupData.length > 0) &&
                              <BootstrapTable version='4' data={this.props.dupData} search={true} pagination={this.props.dupData.length > 10 && true} options={options} insertRow={false} exportCSV={true} csvFileName='business_partners.csv' hover>
                                <TableHeaderColumn dataField='id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                <TableHeaderColumn dataField='name' csvHeader='Name' width='100' columnTitle>Name</TableHeaderColumn>
                                <TableHeaderColumn dataField='email_address' csvHeader='Email' width='100' columnTitle>Email Address</TableHeaderColumn>
                                <TableHeaderColumn dataField='phone_number' csvHeader='Phone' width='100' columnTitle>Phone Number</TableHeaderColumn>
                              </BootstrapTable>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <Footer />
          </div>
        </div >
      </div >
    );
  }
};

const mapStateToProps = (state: any) => {

  return {
    dupData: state.customer.dupData
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    CustomerBulkUpload: (data: any) => dispatch(CustomerBulkUpload(data)),
    EmployeesBulkUpload: (data: any) => dispatch(EmployeesBulkUpload(data))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Bulk);