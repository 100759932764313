import React, { Component } from 'react';
import { EditMenuItemProps, EditMenuItemState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import moment from 'moment';
import { getMenuItem, editItem, getBSLimit, groupsList, menusList, statesList, logoutUser, storesListForMultiSelect, checkDuplicatePosCode, checkDuplicateErpId, channelList } from '../../../redux'
import Footer from '../../../components/footer/main';
import Sidebar from '../../../components/sidebar';
import Topbar from '../../../components/topbar';
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { Link, Redirect } from 'react-router-dom';
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { OrderingModes, subGroupListByGroupId, variantsList } from '../../../redux/actions/menuAction';
import _ from 'lodash';
import { API_URL, IMAGE_URL } from '../../../client-config';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
toast.configure();
class EditMenuItem extends Component<EditMenuItemProps, EditMenuItemState> {
    weeklyDays: { value: string; label: string; }[];
    constructor(props: any) {
        super(props);
        this.weeklyDays = [
            { value: 'MONDAY', label: 'Mon' },
            { value: 'TUESDAY', label: 'Tue' },
            { value: 'WEDNESDAY', label: 'Wed' },
            { value: 'THURSDAY', label: 'Thu' },
            { value: 'FRIDAY', label: 'Fri' },
            { value: 'SATURDAY', label: 'Sat' },
            { value: 'SUNDAY', label: 'Sun' },
        ];
        this.state = {
            itemname: "",
            erpid: "",
            poscode: "",
            group: "",
            subgroup: "",
            type: "",
            itemdesc: "",
            priority: "",
            images: null,
            hero_image: null,
            hero_mobile_image: null,
            hero_item: false,
            settime: 'alltime',
            is_lsm: '0',
            itemstart: "",
            itemclose: "",
            tax_percent: '',
            state: "",
            metaTitle: "",
            metaDesc: "",
            flag: false,
            mode: [],
            storesJson: [],
            is_featured: false,
            is_foodpanda: false,
            is_half_n_half: false,
            is_full_pizza: false,
            recipe: "",
            is_new: false,
            is_veg: false,
            is_hot: false,
            is_vegan: false,
            featured_image: null,
            save_feature_image: null,
            is_best: false,
            is_glutenFree: false,
            is_suggestive: false,
            cross_sell: false,
            channel: "",
            specific_days: false,
            featuredChecked: false,
            daysTiming: [
                {
                    day: "",
                    label: "",
                    timePeriods: [{
                        open: "",
                        close: ""
                    }]
                }
            ],
            sizejson: [
                {
                    id: "",
                    pos_code: "",
                    erp_id: "",
                    nutritional_info: "",
                    serving: "",
                    size: "",
                    cost: "",
                    mrp: "",
                    variant_id: "",
                    extra_price: "",
                    description: "",
                    alt_text: "",
                    image_url: null,
                    thumbnail_url: null,
                    order_modes_price: []
                }
            ]
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        document.title = "Crust Culture | Menu Items"
        let id = this.props.match.params.id;
        this.props.menusList();
        this.props.getMenuItem(id);
        this.props.getBSLimit();
        this.props.groupsList();
        this.props.storesList();
        this.props.variantsList();
        this.props.OrderingModes();
        this.props.channelList();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        if (event.target.value == ' ') {
            event.target.value = event.target.value.replace(/\s/g, "");
        }
        if (event.target.name == "group") {
            this.setState({
                group: event.target.value,
                subgroup: ""
            });
            this.props.subGroupListByGroupId(event.target.value)
        } else {
            this.setState({
                [event.target.name]: event.target.value
            });
        }

    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (!_.isEmpty(nextProps.itemData) && this.props.itemData.item_name !== nextProps.itemData.item_name) {  //this condition is used
            console.log("//to call statements only first time")
            this.setState({ group: nextProps.itemData.item_group_id && nextProps.itemData.item_group_id.group_id, subgroup: nextProps.itemData.subgroup_id && nextProps.itemData.subgroup_id.id })
        }
        if (nextProps.duplicateItemPos == this.props.duplicateItemPos && nextProps.duplicateItemErp == this.props.duplicateItemErp) {
            this.setState({
                itemname: nextProps.itemData.item_name,
                recipe: nextProps.itemData.recipe,
                is_featured: nextProps.itemData.is_featured,
                featuredChecked: nextProps.itemData.is_featured,
                cross_sell: nextProps.itemData.cross_sell,
                save_feature_image: nextProps.itemData?.featured_image ? nextProps.itemData.featured_image : null,
                is_foodpanda: nextProps.itemData.is_foodpanda,
                is_half_n_half: nextProps.itemData.is_half_n_half,
                is_full_pizza: nextProps.itemData.is_full_pizza,
                // is_suggestive: nextProps.itemData.is_suggestive_item,
                erpid: nextProps.itemData.erp_id == null ? "" : nextProps.itemData.erp_id,
                poscode: nextProps.itemData.pos_code == null ? "" : nextProps.itemData.pos_code,
                channel: nextProps.itemData.item_channel_id && nextProps.itemData.item_channel_id.sales_channel_id,
                // subgroup: nextProps.itemData.subgroup_id && nextProps.itemData.subgroup_id.id,
                type: nextProps.itemData.item_type,
                priority: nextProps.itemData.priority,
                hero_item: nextProps.itemData.hero_item == 1 ? true : false,
                flag: (nextProps.sizejson && nextProps.sizejson[0].image_url) ? true : false,
                // sizejson: nextProps.itemData.item_size ? JSON.parse(nextProps.itemData.item_size) : [],
                storesJson: nextProps.itemData.stores_json ? JSON.parse(nextProps.itemData.stores_json) : this.state.storesJson,
                is_lsm: nextProps.itemData.is_lsm,
                metaTitle: nextProps.itemData.meta_title ? nextProps.itemData.meta_title : "",
                metaDesc: nextProps.itemData.meta_description ? nextProps.itemData.meta_description : "",
                is_new: nextProps.itemData.is_new,
                specific_days: nextProps.itemData.serving_hours ? true : false,
                daysTiming: nextProps.itemData.serving_hours ? JSON.parse(nextProps.itemData.serving_hours) : this.state.daysTiming,
                is_veg: nextProps.itemData.is_veg,
                is_hot: nextProps.itemData.is_hot,
                is_vegan: nextProps.itemData.is_vegan,
                is_best: nextProps.itemData.is_best,
                is_glutenFree: nextProps.itemData.is_glutenFree
            })

            if (nextProps.sizejson) {
                this.setState({ sizejson: nextProps.sizejson.length > 0 ? nextProps.sizejson : this.state.sizejson });
            }
            if (nextProps.orderingModes.length > 0 && this.props.itemData) {
                let orderingModes = nextProps.orderingModes;
                let mode = nextProps.itemData.item_mode ? JSON.parse(nextProps.itemData.item_mode) : []
                const results = orderingModes.filter(({ label: id1 }: any) => mode.some(({ label: id2 }: any) => id2 === id1));
                this.setState({ mode: results })
            }

            if (nextProps.itemData.item_start_time !== null) {
                this.setState({
                    "settime": "customtime",
                    itemstart: moment(nextProps.itemData.item_start_time).local().format('YYYY-MM-DDTHH:mm'),
                    itemclose: moment(nextProps.itemData.item_close_time).local().format('YYYY-MM-DDTHH:mm')
                })
            } else {
                this.setState({
                    "settime": "alltime",
                    itemstart: "",
                    itemclose: ""
                })
            }
        }
    }
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    handleVariantInputChange = (e: any, index: any) => {
        const { name, value } = e.target;
        const list: any = this.state.sizejson;
        list[index][name] = value;
        this.setState({ sizejson: list });
        if (name == "pos_code") {
            this.props.checkDuplicatePosCode(value)
        }
        if (name == "erp_id") {
            this.props.checkDuplicateErpId(value)
        }
    };
    handleSpecificDaysCheck = (event: { target: { name: any; value: any; }; }) => {
        const { specific_days } = this.state;
        this.setState({ specific_days: !specific_days })
    }
    handleVariantPriceInputChange = (e: any, index: any, sizeJsonindex: any) => {
        const { name, value } = e.target;
        const list: any = this.state.sizejson;
        list[sizeJsonindex][name] = value;
        list[sizeJsonindex].order_modes_price[index][name] = value;
        this.setState({ sizejson: list });
    };
    handlePricingOrderModesInputChange = (e: any, indexing: any, ind: any) => {
        if (e && e.length > 0) {
            if (e.length === this.state.sizejson[ind].order_modes_price.length + 1) {
                if (this.state.sizejson[ind].order_modes_price.length == 0) {
                    let item_variant_id = this.state.sizejson[ind].id;
                    let mode_id = e[this.state.sizejson[ind].order_modes_price.length].value;
                    let mode_label = e[this.state.sizejson[ind].order_modes_price.length].label;
                    let mrp = this.state.sizejson[ind].mrp;
                    let extra_price = this.state.sizejson[ind].extra_price;
                    let tempsizeJson = this.state.sizejson;
                    let obj = {
                        item_variant_id: item_variant_id,
                        order_mode_id: mode_id,
                        order_mode: mode_label,
                        mrp: mrp,
                        extra_price: extra_price,
                        label: mode_label,
                        value: mode_id
                    }
                    tempsizeJson[ind].order_modes_price.push(obj);
                    this.setState({ sizejson: tempsizeJson })
                } else {
                    if (this.state.sizejson[ind].order_modes_price[this.state.sizejson[ind].order_modes_price.length - 1].mrp > 0) {
                        let item_variant_id = this.state.sizejson[ind].id;
                        let mode_id = e[this.state.sizejson[ind].order_modes_price.length].value;
                        let mode_label = e[this.state.sizejson[ind].order_modes_price.length].label;
                        let mrp = this.state.sizejson[ind].mrp;
                        let extra_price = this.state.sizejson[ind].extra_price;
                        let tempsizeJson = this.state.sizejson;
                        let obj = {
                            item_variant_id: item_variant_id,
                            order_mode_id: mode_id,
                            order_mode: mode_label,
                            mrp: mrp,
                            extra_price: extra_price,
                            label: mode_label,
                            value: mode_id
                        }
                        tempsizeJson[ind].order_modes_price.push(obj);
                        this.setState({ sizejson: tempsizeJson })
                    } else {
                        toast.error("Max retail price should not be 0,Please update", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    }
                }
            } else {
                let tempsizeJson = this.state.sizejson;
                let dubtempsizeJson = tempsizeJson[ind].order_modes_price.filter((item: any) => (item.order_mode_id != indexing.removedValue.order_mode_id))
                tempsizeJson[ind].order_modes_price = dubtempsizeJson;
                this.setState({ sizejson: tempsizeJson })
            }
        } else {
            let tempsizeJson = this.state.sizejson;
            tempsizeJson[ind].order_modes_price = []
            this.setState({ sizejson: tempsizeJson })
        }
    };

    // handle click event of the Remove button
    handleRemoveClick = (index: any) => {
        const list = this.state.sizejson;
        list.splice(index, 1);
        this.setState({ sizejson: list });
    };
    // handle click event of the Add button
    handleAddClick = (e: any, i: any) => {
        const list: any = this.state.sizejson;
        let { duplicateItemErp, duplicateItemPos } = this.props;
        if (list[i].order_modes_price[list[i].order_modes_price.length - 1]) {
            if (list[i]["size"] == "" || list[i]["cost"] == "" || (list[i]["pos_code"] !== "" && duplicateItemPos == true) || (list[i]["erp_id"] !== "" && duplicateItemErp == true) || list[i].order_modes_price[list[i].order_modes_price.length - 1].mrp == 0) {
                alert("Please fill all mandatory variant fields")
            } else if (i == '9') {
                alert("Sorry you can select only 10 variants")
            } else {
                const newlist: any = { id: "", pos_code: "", erp_id: "", variant_id: "", size: "", cost: "", mrp: "", extra_price: "", description: "", image_url: null, thumbnail_url: null, order_modes_price: [] }
                this.state.mode && this.state.mode.forEach((elem) => {
                    let mode_id = elem.value;
                    let mode_label = elem.label;
                    let obj = {
                        item_variant_id: "",
                        order_mode_id: mode_id,
                        mrp: 0,
                        variant_id: "",
                        extra_price: 0,
                        label: mode_label,
                        value: mode_id
                    }
                    newlist.order_modes_price.push(obj);
                })
                const size: any = this.state.sizejson.concat(newlist)
                this.setState({ sizejson: size });
            }
        } else {
            alert("Please select at least one ordering mode!")
        }
        e.preventDefault()
    };
    itemFileSelectedHandler = (e: any, index: any) => {
        const list: any = this.state.sizejson;
        // if (e.target.files.length > 0) {
        //     list[index]["image_url"] = e.target.files;
        //     this.setState({ sizejson: list });
        // }
        if (e.target.files.length > 0) {
            var size = 153600;

            if (e.target.files[0].size > size) {

                toast.error(" Item image size too large. Upload image less then 150kb", { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: false, autoClose: 3000 })

                this.setState({
                    flag: false
                })
            }
            else {
                list[index]["image_url"] = e.target.files;
                this.setState({ sizejson: list, flag: true });
            }
        }
    }
    thumbnailFileSelectedHandler = (e: any, index: any) => {
        const list: any = this.state.sizejson;
        // if (e.target.files.length > 0) {
        //     list[index]["thumbnail_url"] = e.target.files;
        //     this.setState({ sizejson: list });
        // }
        if (e.target.files.length > 0) {
            var size = 51200;

            if (e.target.files[0].size > size) {

                toast.error(" Item image size too large. Upload image less then 50kb", { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: false, autoClose: 3000 })

                this.setState({
                    flag: false
                })
            }
            else {
                list[index]["thumbnail_url"] = e.target.files;
                this.setState({ sizejson: list, flag: true });
            }
        }
    }
    heroItemImageSelectedHandler = (e: any) => {
        if (e.target.files.length > 0) {
            this.setState({ hero_image: e.target.files })
        }
    }
    heroItemMobileImageSelectedHandler = (e: any) => {
        if (e.target.files.length > 0) {
            this.setState({ hero_mobile_image: e.target.files })
        }
    }
    handleHeroItem = (event: { target: { name: any; value: any; }; }) => {
        const { hero_item } = this.state;
        this.setState({ hero_item: !hero_item })
    }
    handleWeeklyDaysRemoveClick = (index: any, pindex?: any) => {
        const list = this.state.daysTiming;
        if (pindex >= 0) {
            let timePer = list[index].timePeriods;
            timePer.splice(pindex, 1);
            list[index].timePeriods = timePer;
            this.setState({ daysTiming: list });
        } else {
            list.splice(index, 1);
            this.setState({ daysTiming: list });
        }
    };
    handleweeklyDaysAddClick = (e: any, i: any, pindex?: any) => {
        const list: any = this.state.daysTiming;
        if (pindex >= 0) {
            if (list[i].timePeriods[list[i].timePeriods.length - 1].open == "" || list[i].timePeriods[list[i].timePeriods.length - 1].close == "") {

                toast.error("Please fill in selected day hours", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            } else {
                let timePeriods = {
                    open: "",
                    close: ""
                }
                list[i].timePeriods.push(timePeriods)
                this.setState({ daysTiming: list });
            }
        } else {
            if (list[i]["day"] == "" || list[i].timePeriods[list[i].timePeriods.length - 1].open == "" || list[i].timePeriods[list[i].timePeriods.length - 1].close == "") {

                toast.error("Please fill in selected day timing", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            } else {
                let timePeriods = {
                    open: "",
                    close: ""
                }
                const newlist: any = {
                    day: "",
                    timePeriods: []
                }
                newlist.timePeriods.push(timePeriods)
                const days: any = this.state.daysTiming.concat(newlist)
                this.setState({ daysTiming: days });
            }
        }
        e.preventDefault()
    };
    checkMRPForAllModes = () => {
        let tempsizeJson = this.state.sizejson;
        let variants: any = [];
        for (let i = 0; i < tempsizeJson.length; i++) {
            let order_modes_price: any[] = tempsizeJson[i].order_modes_price;
            var filtered = order_modes_price && order_modes_price.filter(function (el) {
                return (el.mrp == 0 || el.mrp == "");
            });
            if (filtered && filtered.length > 0) {
                variants.push(tempsizeJson[i])
            }
        }
        if (variants.length > 0) {
            return false;
        } else {
            return true
        }
    }
    isMenuItemReady = () => {
        const { itemname, group, subgroup, settime, itemstart, mode, itemclose, is_lsm, storesJson, channel, priority } = this.state;
        let { duplicateItemPos, duplicateItemErp } = this.props;
        if (settime == 'customtime') {
            if (is_lsm == "0") {
                return (itemname !== "" && channel !== "" && group !== "" && subgroup !== "" && itemstart !== "" && itemclose !== "" && mode.length > 0 && duplicateItemPos !== true && duplicateItemErp !== true && priority !== "" && this.checkMRPForAllModes());
            } else if (is_lsm == "1") {
                return (itemname !== "" && channel !== "" && group !== "" && subgroup !== "" && itemstart !== "" && itemclose !== "" && storesJson.length > 0 && mode.length > 0 && duplicateItemPos !== true && duplicateItemErp !== true && priority !== "" && this.checkMRPForAllModes());
            }
            else if (is_lsm == "2") {
                return (itemname !== "" && channel !== "" && group !== "" && subgroup !== "" && itemstart !== "" && itemclose !== "" && storesJson.length > 0 && mode.length > 0 && duplicateItemPos !== true && duplicateItemErp !== true && priority !== "" && this.checkMRPForAllModes());
            }

        } else {
            if (is_lsm == "0") {
                return (itemname !== "" && channel !== "" && group !== "" && subgroup !== "" && mode.length > 0 && duplicateItemPos !== true && duplicateItemErp !== true && priority !== "" && this.checkMRPForAllModes());
            } else if (is_lsm == "1") {
                return (itemname !== "" && channel !== "" && group !== "" && subgroup !== "" && storesJson.length > 0 && mode.length > 0 && duplicateItemPos !== true && duplicateItemErp !== true && priority !== "" && this.checkMRPForAllModes());
            }
            else if (is_lsm == "2") {
                return (itemname !== "" && channel !== "" && group !== "" && subgroup !== "" && storesJson.length > 0 && mode.length > 0 && duplicateItemPos !== true && duplicateItemErp !== true && priority !== "" && this.checkMRPForAllModes());
            }
        }
    }
    handleOrderModesInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            if (e.length < this.state.mode.length) {
                let tempsizeJson = this.state.sizejson;
                for (let i = 0; i < tempsizeJson.length; i++) {
                    let tempArr: any[] = tempsizeJson[i].order_modes_price;
                    let selectedItems = tempArr.filter(obj => e.find((s: any) => s.value === obj.value));
                    tempsizeJson[i].order_modes_price = selectedItems;
                }
                this.setState({ sizejson: tempsizeJson })
            } else {
                let tempsizeJson = this.state.sizejson;
                for (let i = 0; i < tempsizeJson.length; i++) {
                    let item_variant_id = this.state.sizejson[i].id;
                    let mode_id = e[this.state.sizejson[i].order_modes_price.length].value;
                    let mode_label = e[this.state.sizejson[i].order_modes_price.length].label;
                    let mrp = this.state.sizejson[i].mrp;
                    let extra_price = this.state.sizejson[i].extra_price;
                    let obj = {
                        item_variant_id: item_variant_id,
                        order_mode_id: mode_id,
                        order_mode: mode_label,
                        mrp: mrp ? mrp : 0,
                        extra_price: extra_price ? extra_price : 0,
                        label: mode_label,
                        value: mode_id
                    }
                    tempsizeJson[i].order_modes_price.push(obj);
                }
                this.setState({ sizejson: tempsizeJson })
            }
            this.setState({ mode: e });
        } else {
            this.setState({ mode: [] });
            let tempsizeJson = this.state.sizejson;
            for (let i = 0; i < tempsizeJson.length; i++) {
                tempsizeJson[i].order_modes_price = [];
            }
            this.setState({ sizejson: tempsizeJson })
        }
    };
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ storesJson: e });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    isUnique = (arr: any) => {
        const len = arr.length;
        for (let i = 0; i < len; i++) {
            for (let j = 0; j < len; j++) {
                // if the elements match, this wouldn't be a unique array
                if ((arr[i].pos_code !== "" && arr[j].pos_code !== "")) {
                    if (i !== j && (arr[i].pos_code === arr[j].pos_code)) {
                        return false;
                    }
                }
            }
        }
        return true;
    }
    blockSpecialCharacters = () => {
        var checkString = this.state.itemname;
        if (checkString != "") {
            if (/[%]/.test(checkString)) {
                alert("Your item name has '%' or '_' sign. \nIt is not allowed.\nPlease remove it and try again.");
                return (false);
            } else {
                return true
            }
        }
    }
    handleFeatured = (event: { target: { name: any; value: any; }; }) => {
        const { is_featured } = this.state;
        this.setState({ is_featured: !is_featured })
    }

    handleFoodPanda = (event: { target: { name: any; value: any; }; }) => {
        const { is_foodpanda } = this.state;
        this.setState({ is_foodpanda: !is_foodpanda })
    }
    handleWeeklyDaysInputChange = (e: any, index: any, pindex?: any) => {
        if (e.target) {
            const { name, value } = e.target;
            const list: any = this.state.daysTiming;
            let timeP = list[index].timePeriods;
            timeP[pindex][name] = value;
            list[index].timePeriods = timeP;
            this.setState({ daysTiming: list });
        } else {
            const list: any = this.state.daysTiming;
            list[index]["day"] = e.value;
            list[index]["label"] = e.label;
            this.setState({ daysTiming: list });
        }
    };

    handleFeatureImage = (e:any) => {
        let { files } = e.target
        if (files.length > 0) {

            let size = 1024000;

            if (files[0].size > size) {
                toast.error(" Item image size too large. Upload image less then 1mb", { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: false, autoClose: 3000 })
            }
            else {
                this.setState({ featured_image: files[0] });
            }
        }
    }

    handleCheckBoxes = (event: { target: { name: any; value: any; }; }) => {
        const { is_new, is_veg, is_hot, is_vegan, is_glutenFree, is_half_n_half, is_full_pizza, is_best, is_suggestive, cross_sell } = this.state;
        if (event.target.name == "is_new") {
            this.setState({ is_new: !is_new })
        } else if (event.target.name == "is_veg") {
            this.setState({ is_veg: !is_veg })
        } else if (event.target.name == "is_hot") {
            this.setState({ is_hot: !is_hot })
        } else if (event.target.name == "is_vegan") {
            this.setState({ is_vegan: !is_vegan })
        } else if (event.target.name == "is_glutenFree") {
        } else if (event.target.name == "is_best") {
            this.setState({ is_best: !is_best })
        } else if (event.target.name == "is_glutenFree") {
            this.setState({ is_glutenFree: !is_glutenFree })
        } else if (event.target.name == "is_half_n_half") {
            this.setState({ is_half_n_half: !is_half_n_half, is_full_pizza: false })
        } else if (event.target.name == "is_full_pizza") {
            this.setState({ is_full_pizza: !is_full_pizza, is_half_n_half: false })
        } else if (event.target.name == "is_suggestive") {
            this.setState({ is_suggestive: !is_suggestive })
        } else if (event.target.name == "cross_sell") {
            this.setState({ cross_sell: !cross_sell })
        }
    }
    handleSaveBtnClick = (event: any) => {
        let { itemname, channel, priority, subgroup, is_featured, is_foodpanda, is_half_n_half, is_full_pizza, specific_days, daysTiming, cross_sell, hero_item, mode, hero_image, hero_mobile_image, group, type, sizejson, settime, itemstart, itemclose, is_lsm, storesJson, metaTitle, metaDesc, recipe, is_new, is_veg, is_hot, is_vegan, is_best, is_glutenFree, featured_image } = this.state;
        let id = this.props.match.params.id;
        const data: any = new FormData()
        const Days = JSON.stringify(daysTiming);
        let itemNamevalid = this.blockSpecialCharacters();
        if (itemNamevalid) {
            if (this.state.sizejson) {
                for (var x = 0; x < this.state.sizejson.length; x++) {
                    if (this.state.sizejson[x].image_url !== null) {
                        if (typeof (this.state.sizejson[x].image_url) === "object") {
                            var file = this.state.sizejson[x].image_url[0];
                            var newFileName = file.name.split(".")[0] + `_variant_${x}.` + file.name.split(".")[1];
                            data.append('files', file, newFileName)
                        }
                    }
                }
                for (var x = 0; x < this.state.sizejson.length; x++) {
                    if (this.state.sizejson[x].thumbnail_url !== null) {
                        if (typeof (this.state.sizejson[x].thumbnail_url) === "object") {
                            var file = this.state.sizejson[x].thumbnail_url[0];
                            var newFileName = file.name.split(".")[0] + `_thumbnail_${x}.` + file.name.split(".")[1];
                            data.append('files', file, newFileName)
                        }
                    }
                }
            }
            const SizeJsonString = JSON.stringify(sizejson);
            if (hero_image) {
                var file = hero_image[0];
                var newFileName = file.name.split(".")[0] + "_hero_image." + file.name.split(".")[1];
                data.append('files', file, newFileName);
            }
            if (hero_mobile_image) {
                var file = hero_mobile_image[0];
                var newFileName = file.name.split(".")[0] + "_hero_mobile_image." + file.name.split(".")[1];
                data.append('files', file, newFileName);
            }
            if (featured_image) {
                var file = featured_image;
                var newFileName = file.name.split(".")[0] + "_featured_image." + file.name.split(".")[1];
                data.append('files', file, newFileName);
            }
            data.append('item_name', itemname)
            if (hero_item === true) {
                data.append('hero_item', 1)
            } else {
                data.append('hero_item', 0)
            }
            // if (poscode !== null) {
            //     data.append('pos_code', poscode)
            // }
            data.append('item_channel_id', channel)
            data.append('item_group_id', group)
            data.append('subgroup_id', subgroup)
            data.append('is_featured', is_featured ? 1 : 0)
            data.append('is_foodpanda', is_foodpanda ? 1 : 0)
            data.append("is_half_n_half", is_half_n_half ? 1 : 0)
            data.append("is_full_pizza", is_full_pizza ? 1 : 0)
            // data.append("is_suggestive_item", is_suggestive ? 1 : 0)
            data.append('item_type', type)
            data.append('item_mode', JSON.stringify(mode))
            data.append('priority', priority)
            data.append('meta_title', metaTitle)
            data.append('meta_description', metaDesc)
            data.append('recipe', recipe)
            data.append("is_new", is_new ? 1 : 0)
            data.append("is_veg", is_veg ? 1 : 0)
            data.append("is_hot", is_hot ? 1 : 0)
            data.append("is_vegan", is_vegan ? 1 : 0)
            data.append("is_best", is_best ? 1 : 0)
            data.append("is_glutenFree", is_glutenFree ? 1 : 0)
            data.append('cross_sell', cross_sell ? 1 : 0)
            if (settime === 'customtime') {
                data.append('item_start_time', moment(itemstart).utc(false))
                data.append('item_close_time', moment(itemclose).utc(false))
            } else if (settime === 'alltime') {
                data.append('item_start_time', null)
                data.append('item_close_time', null)
            }
            if (is_lsm == '0') {
                data.append('is_lsm', 0)
            } else if (is_lsm == '1') {
                data.append('is_lsm', 1)
                data.append('stores_json', JSON.stringify(storesJson))
            }
            else if (is_lsm == '2') {
                data.append('is_lsm', 2)
                data.append('stores_json', JSON.stringify(storesJson))
            }
            if (sizejson[sizejson.length - 1]["size"] !== "" && sizejson[sizejson.length - 1]["variant_id"] !== "" && sizejson[sizejson.length - 1]["cost"] !== "" && (sizejson[sizejson.length - 1].order_modes_price.length > 0 && sizejson[sizejson.length - 1].order_modes_price[sizejson[sizejson.length - 1].order_modes_price.length - 1].mrp > 0)) {
                if (this.isUnique(sizejson)) {
                    data.append('itemSizes', SizeJsonString)
                    if (specific_days) {
                        if (daysTiming[daysTiming.length - 1]["day"] !== "" && daysTiming[daysTiming.length - 1].timePeriods[daysTiming[daysTiming.length - 1].timePeriods.length - 1].open !== "" && daysTiming[daysTiming.length - 1].timePeriods[daysTiming[daysTiming.length - 1].timePeriods.length - 1].close !== "") {
                            data.append('serving_hours', Days)
                            this.props.editItem(id, data);
                        } else {
                            alert("Please fill in selected day timing")
                        }
                    } else {
                        this.props.editItem(id, data);
                    }
                } else {
                    toast.error("Please fill unique POS codes", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                }
            } else {
                alert("Please fill all mandatory variant fields")
            }
        }
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const { groups, subGroups, itemData, variants, channels } = this.props;
        const { sizejson, settime, hero_item, priority, itemstart, daysTiming, mode, itemclose, is_lsm, storesJson, subgroup, specific_days } = this.state;
        return (
            <div className="page">
                <CheckChanges path="/edit-item" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Menu Item Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/menu-items" className="text-primary">Menu Items</Link></li>
                                <li className="breadcrumb-item active">Edit Menu Item</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-12 col-sm-12 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Item Name <span className="text-danger">*</span></label>
                                                            <input id="itemname" type="text" name="itemname" defaultValue={itemData.item_name} required data-msg="Please enter Item Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-3 col-sm-6 col-6" >
                                                        <div>
                                                            <input id="checkboxCustom8" type="checkbox" name="is_half_n_half" checked={this.state.is_half_n_half} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom8">HALF n HALF</label>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-lg-4 col-sm-6 col-6" >
                                                        <div>
                                                            <input id="checkboxCustom9" type="checkbox" name="is_full_pizza" checked={this.state.is_full_pizza} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom9">Full Pizza</label>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-3 col-sm-6 col-6" >
                                                        <div>
                                                            <input id="checkboxCustom10" type="checkbox" name="is_suggestive" checked={this.state.is_suggestive} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom10">Suggestive Item</label>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">ERP ID</label>
                                                            <input id="erpid" type="text" name="erpid" defaultValue={itemData.erp_id} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">POS Code <span className="text-danger">*</span></label>
                                                            <input id="poscode" type="text" name="poscode" defaultValue={itemData.pos_code} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Groups <span className="text-danger">*</span></label>
                                                            <select name="group" className="form-control mt-2" required data-msg="Please select Group" onChange={this.handleInputChange}>
                                                                <option value="">Select Group</option>
                                                                {groups &&
                                                                    groups.map((group, index) => (
                                                                        <option key={index} value={group.group_id} {...(itemData.item_group_id && itemData.item_group_id.group_id === group.group_id) && { selected: true }}>{group.group_name + "-" + group.menu_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">SubGroups <span className="text-danger">*</span></label>
                                                            <select name="subgroup" className="form-control text-capitalize mt-2" required data-msg="Please select SubGroup" onChange={this.handleInputChange}>
                                                                <option value="">Select SubGroup</option>
                                                                {subGroups &&
                                                                    subGroups.map((group, index) => (
                                                                        group &&
                                                                        <option key={index} value={group.id} {...(itemData.subgroup_id && subgroup === group.id) && { selected: true }}>{group.sub_group_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Recipe</label>
                                                            <input id="recipe" type="text" name="recipe" defaultValue={itemData.recipe} required data-msg="Please enter recipe" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Meta title </label>
                                                            <input id="metaTitle" type="text" value={this.state.metaTitle} name="metaTitle" required data-msg="Please enter Item Type" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Meta description</label>
                                                            <input id="metaDesc" type="text" value={this.state.metaDesc} name="metaDesc" required data-msg="Please enter Item Type" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">

                                                    <div className="col-12" >
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="mode"
                                                                value={mode}
                                                                options={this.props.orderingModes}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleOrderModesInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Variants</label>
                                                            {sizejson && sizejson.length > 0 &&
                                                                sizejson.map((x: any, ind: any) => {
                                                                    return (
                                                                        <div key={ind}>
                                                                            <div className="row">
                                                                                <div className="col-lg-3 col-sm-3 col-6">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label mb-3">POS Code</label>
                                                                                        <input type="text" name="pos_code" value={x.pos_code} className="input-material" onChange={e => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-3 col-sm-3 col-6">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label mb-3">ERP Id</label>
                                                                                        <input type="text" name="erp_id" value={x.erp_id} className="input-material" onChange={e => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-3 col-sm-3 col-6">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label mb-3">Nutritional Info</label>
                                                                                        <input id="nutritional_info" type="number" onWheel={(e: any) => e.target.blur()} name="nutritional_info" value={x.nutritional_info} className="input-material" onChange={(e) => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-3 col-sm-3 col-6">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label mb-3">Serving</label>
                                                                                        <input type="text" name="serving" value={x.serving} className="input-material" onChange={e => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-3 col-sm-3 col-6">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label mb-3">Size <span className="text-danger">*</span></label>
                                                                                        <input
                                                                                            name="size"
                                                                                            type="text"
                                                                                            value={x.size}
                                                                                            data-msg="Please enter size"
                                                                                            className="input-material"
                                                                                            onChange={e => this.handleVariantInputChange(e, ind)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-3 col-sm-3 col-6">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label mb-3">Cost <span className="text-danger">*</span></label>
                                                                                        <input
                                                                                            name="cost"
                                                                                            type="number" onWheel={(e: any) => e.target.blur()}
                                                                                            value={x.cost}
                                                                                            data-msg="Please enter cost"
                                                                                            className="input-material"
                                                                                            onChange={e => this.handleVariantInputChange(e, ind)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-3 col-sm-3 col-6">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label mb-3">Description</label>
                                                                                        <input id="description" type="text" name="description" value={x.description} className="input-material" onChange={(e) => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-3 col-sm-3 col-6">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label mb-3">Variant  <span className="text-danger">*</span></label>
                                                                                        <select onChange={(e: any) => this.handleVariantInputChange(e, ind)} name="variant_id" className="form-control text-capitalize mt-2" >
                                                                                            <option value="Select variant" selected disabled>Select variant</option>
                                                                                            {variants &&
                                                                                                variants.map((variant: any, index: any) => (
                                                                                                    <option key={index} value={variant.id} {...(x.variant_id && x.variant_id.id === variant.id) && { selected: true }} >{variant.name}</option>
                                                                                                ))
                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="col-lg-12" >
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Pricing By Ordering Modes <span className="text-danger">*</span></label>
                                                                                        <Select
                                                                                            isMulti
                                                                                            name="mode"
                                                                                            value={x.order_modes_price}
                                                                                            options={mode}
                                                                                            className="text-capitalize basic-multi-select mt-2"
                                                                                            classNamePrefix="select"
                                                                                            onChange={(e, i) => this.handlePricingOrderModesInputChange(e, i, ind)}
                                                                                        />
                                                                                    </div>
                                                                                </div> */}
                                                                                {x.order_modes_price && x.order_modes_price.length > 0 && x.order_modes_price.map((channel: any, index: any) => (
                                                                                    <>
                                                                                        <div className="col-12 mb-2">
                                                                                            <strong>{index + 1} -</strong>
                                                                                            <strong style={{ fontSize: '16px' }} className="ml-2">
                                                                                                {channel.label}
                                                                                            </strong>
                                                                                        </div>
                                                                                        <div className="col-6">
                                                                                            <div className="form-group">
                                                                                                <label className="form-control-label mb-3">Max Retail Price <span className="text-danger">*</span></label>
                                                                                                <input
                                                                                                    name="mrp"
                                                                                                    type="number" onWheel={(e: any) => e.target.blur()}
                                                                                                    value={channel.mrp}
                                                                                                    data-msg="Please enter mrp"
                                                                                                    className="input-material"
                                                                                                    onChange={e => this.handleVariantPriceInputChange(e, index, ind)}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6">
                                                                                            <div className="form-group">
                                                                                                <label className="form-control-label mb-3">Extra Price</label>
                                                                                                <input
                                                                                                    name="extra_price"
                                                                                                    type="number" onWheel={(e: any) => e.target.blur()}
                                                                                                    value={channel.extra_price}
                                                                                                    className="input-material"
                                                                                                    onChange={e => this.handleVariantPriceInputChange(e, index, ind)}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ))}
                                                                                {/* <div className="row"> */}
                                                                                <div className="col-lg-2 col-sm-2 col-4 mt-2">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label mb-3">Item Image  <span className="text-danger"> {(x.image_url !== null) && `(1 Item Image)`}</span></label>
                                                                                        <input id="files" type="file" name="files" className="form-control-file" onChange={(e) => this.itemFileSelectedHandler(e, ind)} />
                                                                                        <p style={{ color: "red", fontSize: "12px" }}>Image size should be less than 150 kb</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-2 col-sm-2 col-4 mt-2">
                                                                                    <img style={{ width: "100px" }} alt="No Image" src={`${IMAGE_URL}${x.image_url}`} />
                                                                                </div>
                                                                                <div className="col-lg-2 col-sm-2 col-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label mb-3 mt-2">Thumbnail Image  <span className="text-danger"> {(x.thumbnail_url !== null) && `(1 Thumbnail Image)`}</span></label>
                                                                                        <input id="files" type="file" name="files" className="form-control-file" onChange={(e) => this.thumbnailFileSelectedHandler(e, ind)} />
                                                                                        <p style={{ color: "red", fontSize: "12px" }}>Image size should be less than 50 kb</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-2 col-sm-2 col-4 mt-2">
                                                                                    <img style={{ width: "100px" }} alt="No Image" src={`${IMAGE_URL}${x.thumbnail_url}`} />
                                                                                </div>
                                                                                <div className="col-lg-2 col-sm-2 col-4">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label mb-3">Alt Tag</label>
                                                                                        <input id="alt_text" type="text" value={x.alt_text} name="alt_text" className="input-material" onChange={(e) => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-2 col-sm-2 col-4 d-flex align-items-center">
                                                                                    {sizejson.length !== 1 &&
                                                                                        <button className="btn btn-sm btn-outline-danger"
                                                                                            onClick={() => this.handleRemoveClick(ind)}><i className="fa fa-trash"></i></button>}
                                                                                    {sizejson.length - 1 === ind && <button className="btn btn-sm btn-primary ml-2" onClick={(e: any) => this.handleAddClick(e, ind)}><i className="fa fa-plus"></i></button>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-3 col-sm-3 col-6 mt-5" >
                                                        <div>
                                                            <input id="radioCustom1" type="radio" name="settime" value="alltime" checked={settime === 'alltime'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom1">Available for all time</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-3 col-6 mt-5" >
                                                        <div>
                                                            <input id="radioCustom2" type="radio" name="settime" value="customtime" checked={settime === 'customtime'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom2">Available for specific time</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-3 col-6 mt-5" >
                                                        <div>
                                                            <input id="checkboxCustom1" type="checkbox" name="specific_days" checked={specific_days} onChange={this.handleSpecificDaysCheck} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom1">Available for specific days</label>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-2 col-sm-4 col-4 mt-5" >
                                                        <div>
                                                            <input id="checkboxCustom1" type="checkbox" name="taxstatus" checked={hero_item} onChange={this.handleHeroItem} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom1">Hero Item</label>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-lg-3 col-sm-3 col-6 mt-5" >
                                                        <div>
                                                            <input id="checkboxCustom2" type="checkbox" disabled={!this.state.featuredChecked && this.props.bsLimit} name="taxstatus" checked={this.state.is_featured} onChange={this.handleFeatured} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom2">Featured</label>
                                                            {
                                                                !this.state.featuredChecked ? 
                                                                    this.props.bsLimit ?
                                                                        <small className='d-block text-danger'>Limit Reached to 5</small>
                                                                    :
                                                                    null
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-3 col-sm-3 col-6 mt-5" >
                                                        <div>
                                                            <input id="checkboxCustom2" type="checkbox" name="taxstatus" checked={this.state.is_foodpanda} onChange={this.handleFoodPanda} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom2">IS FOOD PANDA</label>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                {
                                                    this.state.is_featured ?
                                                    <div className='row mt-3'>
                                                        <div className='col-4'>
                                                            <div className="form-group">
                                                                <label className="form-control-label">Featured Image  <span className="text-danger">*</span></label>
                                                                <input id="files" type="file" name="files" className="form-control-file" onChange={this.handleFeatureImage} />
                                                                <p style={{ color: "red", fontSize: "12px" }}>Image size should be less than 1MB</p>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.save_feature_image ?
                                                            <div className='col-4'>
                                                                <img style={{ width: "100px" }} alt="No Image" src={`${IMAGE_URL}${this.state.save_feature_image}`} />
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    :
                                                    null
                                                }
                                                <div className='row'>
                                                    <div className="col mt-5" >
                                                        <div>
                                                            <input id="checkboxCustom3" type="checkbox" name="cross_sell" checked={this.state.cross_sell} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom3">Cross Sell</label>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-2 col-sm-4 col-4 mt-5" >
                                                        <div>
                                                            <input id="checkboxCustom3" type="checkbox" name="is_new" checked={this.state.is_new} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom3">New</label>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col-lg-2 col-sm-4 col-4 mt-5" >
                                                        <div>
                                                            <input id="checkboxCustom4" type="checkbox" name="is_veg" checked={this.state.is_veg} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom4">Vegetarian</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-sm-4 col-4 mt-5" >
                                                        <div>
                                                            <input id="checkboxCustom5" type="checkbox" name="is_hot" checked={this.state.is_hot} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom5">Hot</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-sm-4 col-4 mt-5" >
                                                        <div>
                                                            <input id="checkboxCustom6" type="checkbox" name="is_best" checked={this.state.is_best} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom6">Best Seller</label>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-lg-4 col-12 mt-2">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Channel <span className="text-danger">*</span></label>
                                                            <select name="channel" className="form-control text-capitalize mt-2" required data-msg="Please select Channel" onChange={this.handleInputChange}>
                                                                <option value="">Select Channel</option>
                                                                {channels &&
                                                                    channels.map((channel, index) => (
                                                                        <option key={index} value={channel.sales_channel_id} {...itemData.item_channel_id && itemData.item_channel_id.sales_channel_id === channel.sales_channel_id && { selected: true }}>{channel.channel_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-2 col-sm-4 col-4 mt-5" >
                                                        <div>
                                                            <input id="checkboxCustom6" type="checkbox" name="is_vegan" checked={this.state.is_vegan} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom6">Vegan</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-sm-4 col-4 mt-5" >
                                                        <div>
                                                            <input id="checkboxCustom7" type="checkbox" name="is_glutenFree" checked={this.state.is_glutenFree} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom7">Gluten Free</label>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                {hero_item &&
                                                    <div className='row'>
                                                        <div className="col-6 mt-2">
                                                            <div className="form-group">
                                                                <label className="form-control-label mb-3">Hero Item Image(Desktop)</label>
                                                                <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={this.heroItemImageSelectedHandler} />
                                                                <p style={{ color: "red", fontSize: "12px" }}>Image size should be less than 1 mb</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 mt-2">
                                                            <div className="form-group">
                                                                <label className="form-control-label mb-3">Hero Item Image(Mobile)</label>
                                                                <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={this.heroItemMobileImageSelectedHandler} />
                                                                <p style={{ color: "red", fontSize: "12px" }}>Image size should be less than 1 mb</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    settime === 'customtime' &&
                                                    <div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-lable">Menu Item Timing</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <input id="itemstart" type="date" name="itemstart" required value={itemstart} data-msg="Please enter starting time" className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <input id="itemclose" type="date" name="itemclose" required value={itemclose} data-msg="Please enter closing time" className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-lg-3 col-sm-3 col-6 mt-5" >
                                                        <div>
                                                            <input id="radioCustom3" type="radio" name="is_lsm" value="0" checked={is_lsm == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Available for all stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-3 col-6 mt-5" >
                                                        <div>
                                                            <input id="radioCustom4" type="radio" name="is_lsm" value="1" checked={is_lsm == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Available for specific stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-3 col-6 mt-5" >
                                                        <div>
                                                            <input id="radioCustom5" type="radio" name="is_lsm" value="2" checked={is_lsm == '2'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom5">Available for stores except</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-3 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority<span className="text-danger">*</span></label>
                                                            <input id="priority" type="text" name="priority" value={priority} required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        is_lsm == '1' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={storesJson}
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    {
                                                        is_lsm == '2' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={storesJson}
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                {specific_days &&
                                                    <div className='row'>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                {
                                                                    daysTiming.map((x: any, i: any) => {
                                                                        let timePeriod = x.timePeriods;
                                                                        return (
                                                                            <div key={i} className='mb-2' style={{ borderBottom: "1px dotted grey" }}>
                                                                                <div className="row">
                                                                                    <div className="col-lg-8 col-md-8 col-8">
                                                                                        <div className="form-group">
                                                                                            <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                                            <Select
                                                                                                options={this.weeklyDays.filter(o1 => !daysTiming.some((o2: any) => o1.value === o2.day))}
                                                                                                className="text-capitalize basic-multi-select"
                                                                                                classNamePrefix="select"
                                                                                                value={{ value: x.value, label: x.label }}
                                                                                                onChange={(e) => this.handleWeeklyDaysInputChange(e, i)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4 col-md-4 col-4 mt-4 py-2">
                                                                                        {daysTiming.length !== 1 &&
                                                                                            <button className="btn btn-sm btn-outline-danger"
                                                                                                onClick={() => this.handleWeeklyDaysRemoveClick(i)}><i className="fa fa-trash"></i></button>}
                                                                                        {(daysTiming.length - 1 === i && daysTiming.length < 7) && <button className="btn btn-sm btn-primary ml-2" onClick={(e: any) => this.handleweeklyDaysAddClick(e, i)}><i className="fa fa-plus"></i></button>}
                                                                                    </div>
                                                                                </div>
                                                                                {timePeriod.map((t: any, pindex: any) => {
                                                                                    return (
                                                                                        <div className="row mb-3">
                                                                                            <div className="col-lg-3 col-md-3">
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 col-6">
                                                                                                <div className="form-group">
                                                                                                    <label className="form-control-label mb-0">Open Time <span className="text-danger">*</span></label>
                                                                                                    <input id="open" type="time" name="open" value={t.open} className="input-material" onChange={(e) => this.handleWeeklyDaysInputChange(e, i, pindex)} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 col-6">
                                                                                                <div className="form-group">
                                                                                                    <label className="form-control-label mb-0">Close Time <small className="text-danger">*</small></label>
                                                                                                    <input id="close" type="time" name="close" value={t.close} className="input-material" onChange={(e) => this.handleWeeklyDaysInputChange(e, i, pindex)} />
                                                                                                    {(timePeriod.length - 1 == pindex) && <small className="text-primary" style={{ cursor: 'pointer' }} onClick={(e: any) => this.handleweeklyDaysAddClick(e, i, pindex)}>+ Add hours</small>}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 col-12">
                                                                                                {timePeriod.length !== 1 &&
                                                                                                    <button className="btn btn-sm btn-outline-danger"
                                                                                                        onClick={() => this.handleWeeklyDaysRemoveClick(i, pindex)}><i className="fa fa-trash"></i></button>}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    {(this.props.itemLoad) ?
                                                        <button className='btn btn-primary d-flex justify-content-end align-item-center' disabled={this.props.itemLoad}><Loader type="TailSpin" color="white" height={30} width={30} /></button> :
                                                        <button className='btn btn-primary' disabled={!this.isMenuItemReady() || !this.state.flag} onClick={this.handleSaveBtnClick}>Update Item</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        groups: state.menu.groups,
        states: state.setting.states,
        menus: state.menu.menus,
        stores: state.menu.storesoptions,
        itemData: state.menu.itemData,
        sizejson: state.menu.sizejson,
        taxData: state.setting.taxData,
        channels: state.store.channels,
        duplicateItemPos: state.menu.duplicateItemPos,
        duplicateItemErp: state.menu.duplicateItemErp,
        orderingModes: state.menu.orderModes,
        variants: state.menu.variants,
        subGroups: state.menu.subGroups,
        itemLoad: state.menu.itemLoad,
        bsLimit: state.menu.bsLimit
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        getBSLimit: function () {
            dispatch(getBSLimit())
        },
        editItem: function (id: any, formData: any) {
            dispatch(editItem(id, formData));
        },
        checkDuplicatePosCode: (pos_code: any) => {
            dispatch(checkDuplicatePosCode(pos_code))
        },
        checkDuplicateErpId: (erp_id: any) => {
            dispatch(checkDuplicateErpId(erp_id))
        },
        variantsList: function () {
            dispatch(variantsList())
        },
        groupsList: function () {
            dispatch(groupsList())
        },
        getMenuItem: function (id: number) {
            dispatch(getMenuItem(id));
        },
        menusList: function () {
            dispatch(menusList())
        },
        storesList: () => {
            dispatch(storesListForMultiSelect())
        },
        statesList: function () {
            dispatch(statesList())
        },
        OrderingModes: () => {
            dispatch(OrderingModes())
        },
        subGroupListByGroupId: function (id: any) {
            dispatch(subGroupListByGroupId(id))
        },
        channelList: function () {
            dispatch(channelList())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMenuItem);