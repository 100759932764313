import React, { Component } from 'react';
import { AddSubGroupProps, AddSubGroupState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { addSubGroup, logoutUser, groupsList, storesListForMultiSelect } from '../../../redux'
import { Link, Redirect } from 'react-router-dom';
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';
import { OrderingModes } from '../../../redux/actions/menuAction';
class AddSubGroup extends Component<AddSubGroupProps, AddSubGroupState> {
    constructor(props: any) {
        super(props);
        this.state = {
            groupname: "",
            group: "",
            priority: "",
            fp_priority: "",
            mode: [],
            poscode: null,
            erpid: null,
            is_lsm: '0',
            storesJson: [],
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        this.props.groupsList();
        this.props.storesList();
        this.props.OrderingModes();
        document.title = "Crust Culture | SubGroups"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    blockInvalidChar = (e: any) => {
        var regex = new RegExp("^[a-zA-Z0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    handleOrderModesInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ mode: e });
        } else {
            this.setState({ mode: [] });
        }
    };
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ storesJson: e });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    isGroupReady = () => {
        const { groupname, group, mode, is_lsm, storesJson,fp_priority,priority } = this.state
        if (is_lsm == "0") {
            return (groupname !== "" &&fp_priority!=="" && priority!=="" && mode.length > 0 && group !== "");
        } else if (is_lsm == "1") {
            return (groupname !== "" &&fp_priority!=="" && priority!=="" && mode.length > 0 && group !== "" && storesJson.length > 0);
        }
    }
    handleSaveBtnClick = () => {
        let { groupname, group, mode, priority,fp_priority, poscode, erpid, is_lsm, storesJson } = this.state;
        let data:any ={
            sub_group_name:groupname,
            group_id:group,
            mode:JSON.stringify(mode)
        }
        if (poscode !== null) {
            data.pos_code=poscode;
        }
        if (erpid !== null) {
            data.erp_id=erpid;
        }
        if (is_lsm == '0') {
            data.is_lsm=0;
        } else if (is_lsm == '1') {
            data.is_lsm = 1;
            data.stores_json = JSON.stringify(storesJson);
        }
        data.priority = Number(priority);
        data.fp_priority=Number(fp_priority);
        this.props.addSubGroup(data);
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const { groups } = this.props;
        const { is_lsm, storesJson } = this.state;
        return (
            <div className="page">
                <CheckChanges path="/add-sub-group" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">SubGroups Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/sub-groups" className="text-primary">SubGroups</Link></li>
                                <li className="breadcrumb-item active">Add SubGroup</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">SubGroup name <span className="text-danger">*</span></label>
                                                            <input id="groupname" type="text" name="groupname" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Menu Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">POS Code</label>
                                                            <input id="poscode" type="text" name="poscode" onKeyDown={this.blockInvalidChar} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">ERP Id</label>
                                                            <input id="erpid" type="text" name="erpid" onKeyDown={this.blockInvalidChar} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Group <span className="text-danger">*</span></label>
                                                            <select name="group" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange}>
                                                                <option>Select Group</option>
                                                                {groups &&
                                                                    groups.map((group, index) => (
                                                                        <option key={index} value={group.group_id}>{group.group_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority<span className="text-danger">*</span></label>
                                                            <input id="priority" type="number" onWheel={(e:any) => e.target.blur()} name="priority" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">FP Priority<span className="text-danger">*</span></label>
                                                            <input id="fp_priority" type="number" onWheel={(e:any) => e.target.blur()} name="fp_priority" onKeyDown={this.blockInvalidChar} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col" >
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="mode"
                                                                options={this.props.orderingModes}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleOrderModesInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                        <div >
                                                            <input id="radioCustom3" type="radio" name="is_lsm" value="0" checked={is_lsm == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Available for all stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                        <div >
                                                            <input id="radioCustom4" type="radio" name="is_lsm" value="1" checked={is_lsm == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Available for specific stores</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        is_lsm == '1' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={storesJson}
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isGroupReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >

        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        groups: state.menu.groups,
        stores: state.menu.storesoptions,
        orderingModes: state.menu.orderModes
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        addSubGroup: function (data: any) {
            dispatch(addSubGroup(data));
        },
        groupsList: function () {
            dispatch(groupsList())
        },
        storesList: () => {
            dispatch(storesListForMultiSelect())
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },
        OrderingModes: () => {
            dispatch(OrderingModes())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddSubGroup);