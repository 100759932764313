import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { connect } from 'react-redux'
import { suggestiveComboList, logoutUser } from '../../../redux'
import { SuggestiveComboProps } from '../../../interfaces/menu';
import moment from 'moment';
import { blockunblockSuggestive } from '../../../redux/actions/menuAction';
function childProdFormatter(cell: any, row: any, props: any) {
    return (
        <SuggestiveChildProductsFormatter row={row} data={props} />
    );
}
class SuggestiveChildProductsFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                {(row.suggestive_child_products && row.suggestive_child_products.length>0) &&
                    <button title="View Combos" data-toggle="modal" data-target={`#itemmodes${row.id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button>
                }
                {/* <!-- Modal--> */}
                <div id={`itemmodes${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Suggestive Linked Combos</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            <BootstrapTable version='4' data={(row.suggestive_child_products) ? row.suggestive_child_products : ""} hover>
                                                <TableHeaderColumn dataField='combo_id' columnTitle isKey>Id</TableHeaderColumn>
                                                <TableHeaderColumn dataField='combo_name' columnTitle>Combo</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

class SuggestiveActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleDelete = (id: any) => {
        this.props.data.deleteDiscount(id, this.props.data.history)
    };
    handleActiveInActive = (id: any, is_active: any) => {
        this.props.data.blockunblockSuggestive(id, is_active);
    };
    render() {
        const { row } = this.props;
        return (
            <div>
                <button title={row.is_active === 1 ? "Inactive" : "Active"} data-toggle="modal" data-target={`#activeInactive${row.id}`} className={row.is_active === 1 ? "btn btn-outline-danger mr-2" : "btn btn-outline-success mr-2"}><i className={row.is_active === 1 ? "fa fa-lock" : "fa fa-unlock"}></i></button>
                {/* <button title="Delete Suggestive Combo" className="btn btn-outline-danger" data-toggle="modal" data-target={`#DelSuggestive${row.id}`}><i className="fa fa-trash"></i></button> */}
                <Link title="Edit Suggestive Combo" className="btn btn-outline-primary ml-2" to={`/editSuggestiveCombos/${row.id}`}><i className="fa fa-edit"></i></Link>
                {/* <!-- Modal--> */}
                {/* <div id={`DelDiscount${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete Suggestive Combo</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to delete this Suggestive Combo?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                <button onClick={() => this.handleDelete(row.id)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <!-- Block/Unblock Modal--> */}
                <div id={`activeInactive${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 1 ? "Inactive" : "Active"} Suggestive Combo</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to {row.is_active === 1 ? "Inactive" : "Active"} this Suggestive Combo?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleActiveInActive(row.id, row.is_active)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function suggestiveActionFormatter(cell: any, row: any, props: any) {
    return (
        <SuggestiveActionFormatter row={row} data={props} />
    );
}
class SuggestiveCombos extends Component<SuggestiveComboProps, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.suggestiveComboList()
        document.title = "Crust Culture | Combos"
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/attachSuggestiveCombos" /> },
            noDataText: 'Suggestive Combos Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Suggestive Combos Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body text-capitalize">
                                                <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={false} insertRow csvFileName='suggestive_deals.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='id' csvHeader='#' width='40' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='combo_name' csvHeader='Menu' width='100' columnTitle>Combo</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='70' dataFormat={childProdFormatter} formatExtraData={this.props} export={false}>Suggestive Linked Combos</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='50' dataFormat={suggestiveActionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        data: state.menu.suggestive_combos_list
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        suggestiveComboList: function () {
            dispatch(suggestiveComboList())
        },
        blockunblockSuggestive: function (id: any, is_active: any,) {
            dispatch(blockunblockSuggestive(id, is_active))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SuggestiveCombos);