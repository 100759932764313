import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { getSuggCombos, itemsListForBanners, logoutUser, pingConnectivity, updateSuggestiveDeals, fpMenuLogs } from '../../redux'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import { storesList, } from '../../redux/actions/storeAction';
import Select from 'react-select'
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class MenuLogs extends Component<{ storesList: () => {}, pingConnectivity: (data: any) => {}, logoutUser: () => {}, stores: any[], pingResponse: any, pingLoad: any }, { store: any }> {
    constructor(props: any) {
        super(props);
        this.state = {
            store: "",
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        this.props.storesList();
        document.title = "Crust Culture | FP Menu Logs"
    }
    isPingReady = () => {
        const { store } = this.state;
        return (store !== "");
    }
    handleSubmit = () => {
        let { store } = this.state;
        let data = {
            store_id: store.store_id,
            fp_branch_code: store.fp_branch_code,
            aloha_branch_code: store.aloha_branch_code,
        }
        this.props.pingConnectivity(data);
    }
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ store: e });
        } else {
            this.setState({ store: "" });
        }
    };
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Menu Logs</h4>
                                </div>
                            </div>
                        </header>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label ">Store<span className="text-danger">*</span></label>
                                                            <Select
                                                                isClearable
                                                                options={this.props.stores}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                  this.props.pingResponse && this.props.pingResponse.length > 0 ?
                                                  <div className="row my-4">                                          
                                                    <div className="col">
                                                        <div className="table-responsive">
                                                            <table className="table table-striped table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Message</th>
                                                                        <th scope="col">Menu Import ID</th>
                                                                        <th scope="col">Status</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.props.pingResponse.map((item: any, index: any) => (
                                                                        <tr key={index}>
                                                                            <td className='col-5'>{item.message}</td>
                                                                            <td className='col-3'>{item.menuImportId}</td>
                                                                            <td className='col-3 text-capitalize'>
                                                                              <span className={`badge ${item.status === 'done' ? 'badge-success' : 'badge-danger'} p-2`}>{item.status}</span>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                  </div> : null
                                                }
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    {(this.props.pingLoad) ?
                                                        <button className='btn btn-primary d-flex justify-content-end align-item-center' disabled={this.props.pingLoad}><Loader type="TailSpin" color="white" height={30} width={30} /></button> :
                                                        <button onClick={this.handleSubmit} disabled={!this.isPingReady()} className="btn btn-primary">Submit</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>

                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        stores: state.report.stores,
        pingResponse: state.store.fpMenuLogs,
        pingLoad: state.store.pingLoad
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: () => { dispatch(logoutUser()); },
        storesList: () => {
            dispatch(storesList())
        },
        pingConnectivity: (data: any) => { dispatch(fpMenuLogs(data)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MenuLogs);