import { SEARCH_COUPONS_LIST } from '../actions/couponType';
const initialState = {
    couponSearch: '',
    searchResults: [],
    searchPageCount: 0,
    searchCurrentPage: 0,
    searchLoading: false,
  };

const searchCouponsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SEARCH_COUPONS_LIST + '_REQUEST': // Set loading to true when making a request
      return {
        ...state,
        searchLoading: true,
      };
    case SEARCH_COUPONS_LIST + '_SUCCESS': // Set loading to false when data is successfully fetched
      const page = action.payload.currentPage;
      const pageSize = 100;

      return {
        ...state,
        couponSearch: action.payload.couponSearch,
        searchResults: action.payload.couponsData,
        searchPageCount: Math.ceil(action.payload.totalCoupons / pageSize),
        searchCurrentPage: page,
        searchLoading: false,
      };
    default:
      return state;
  }
}

export default searchCouponsReducer;
