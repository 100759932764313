import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { connect } from 'react-redux'
import { blockunblockcombo, combosList, logoutUser } from '../../../redux'
import { ComboProps } from '../../../interfaces/menu';
import Select from 'react-select';
import moment from 'moment';
import { addStoreSpecificComboPrice, statusChangeReasons, updateComboSnooze, storesListForMultiSelect } from '../../../redux/actions/menuAction';
class ActionFormatter extends Component<{ row: any, itemStatusReasons: any[] }, { [x: number]: any, startDate: any, endDate: any, snoozeReason: any, storesJson: any[], allRemove: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            startDate: '',
            endDate: '',
            snoozeReason: '',
            allRemove: false,
            storesJson: [
                {
                    store_id: null,
                    mrp: 0
                }
            ],
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleBlockUnblock = (id: any, is_active: any) => {
        this.props.data.blockunblockcombo(id, is_active, this.props.row.combo_name);
    };
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    isComboSnoozeReady = () => {
        const { startDate, endDate, snoozeReason } = this.state
        return (startDate !== "" && endDate !== "" && snoozeReason !== "");
    }
    handleSnooze = (combo_id: any) => {
        let { startDate, endDate, snoozeReason } = this.state;
        let data = {
            combo_id: combo_id,
            snooze_start_time: startDate,
            snooze_end_time: endDate,
            snoozeReason: snoozeReason
        }
        this.props.updateComboSnooze(combo_id, data);
    }
    // handle click event of the Remove button
    handleRemoveClick = (index: any) => {
        const list = this.state.storesJson;
        list.splice(index, 1);
        this.setState({ storesJson: list });
    };
    // handle click event of the Add button
    handleAddClick = (i: any) => {
        const list: any = this.state.storesJson;
        if (list[i]["store_id"] == null || list[i]["mrp"] == 0) {
            alert("Please fill all mandatory fields")
        } else {
            const newlist: any = { store_id: null, mrp: 0 }
            const stores: any = this.state.storesJson.concat(newlist)
            this.setState({ storesJson: stores });
        }
    };
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.target) {
            const { name, value } = e.target;
            const list: any = this.state.storesJson;
            list[index][name] = value !== "" ? value : 0;
            this.setState({ storesJson: list });
        } else {
            const list: any = this.state.storesJson;
            if (e && e.value > 0) {
                list[index]["store_id"] = e.value;
            } else {
                list[index]["store_id"] = null;
            }
            this.setState({ storesJson: list });
        }
    };
    handlePriceForSpecificStores = (combo_id: any) => {
        let { storesJson, allRemove } = this.state;
        if (allRemove) {
            let data = {
                storesJson: null
            }
            this.props.addStoreSpecificComboPrice(combo_id, data);
        } else {
            if (storesJson[storesJson.length - 1]["store_id"] !== null && storesJson[storesJson.length - 1]["mrp"] !== 0) {
                let data = {
                    storesJson: JSON.stringify(storesJson)
                }
                this.props.addStoreSpecificComboPrice(combo_id, data);
            } else {
                alert("Please fill all mandatory fields")
            }
        }
    }
    handleCheckBoxes = (event: { target: { name: any; value: any; }; }) => {
        const { allRemove } = this.state;
        if (event.target.name == "allRemove") {
            this.setState({ allRemove: !allRemove })
        }
    }
    render() {
        const { row, itemStatusReasons, data } = this.props;
        let { storesJson, allRemove } = this.state;
        let today = moment().format("YYYY-MM-DDThh:mm");
        return (
            <div>
                <button title={row.is_active === 0 ? "UnBlock" : "Block"} data-toggle="modal" data-target={`#blockunblock${row.combo_id}`} className={row.is_active === 0 ? "btn btn-outline-success" : "btn btn-outline-danger"}><i className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}></i></button>
                {/* <button title="View Items" data-toggle="modal" data-target={`#itemdetails${row.combo_id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button> */}
                <Link title="Edit Combo" className="btn btn-outline-primary mx-2" to={`/edit-combo/${row.combo_id}`}><i className="fa fa-edit"></i></Link>
                <Link to="#" title={!row.snooze_start_time ? "Snooze Combo" : "End Snooze Combo"} data-toggle="modal" data-target={`#snoozeCombo${row.combo_id}`} className={"btn btn-outline-warning mx-2"}><i className={"fa fa-clock-o"}></i></Link>
                {/* <Link to="#" title={"Price for Specific Stores"} data-toggle="modal" data-target={`#diffPrice${row.combo_id}`} className={"btn btn-outline-warning"} onClick={() => row.storesJson && this.setState({ storesJson: JSON.parse(row.storesJson) })}><i className={"fa fa-money"}></i></Link> */}
                {/* <!-- Block/Unblock Modal--> */}
                <div id={`blockunblock${row.combo_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 0 ? "UnBlock" : "Block"} Combo</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p style={{ textTransform: "none" }}>Are you sure,you want to {row.is_active === 0 ? "UnBlock" : "Block"} this combo?</p>
                                {(row.is_active === 0 && row.combo_choices && row.combo_choices.length > 0) && <p style={{ textTransform: "none" }}><b style={{ color: "red" }}>!</b> Kindly activate these items first, which are available in this combo.</p>}
                                {(row.is_active === 0 && row.combo_choices && row.combo_choices.length > 0) &&
                                    <ul>
                                        {row.combo_choices && row.combo_choices.map((obj: any) => {
                                            return (
                                                <li>{obj?.menu_item_id?.item_name}</li>
                                            )
                                        })}

                                    </ul>
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button disabled={(row.is_active === 0 && row.combo_choices && row.combo_choices.length > 0) ? true : false} onClick={() => this.handleBlockUnblock(row.combo_id, row.is_active)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id={`snoozeCombo${row.combo_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{!row.snooze_start_time ? "Snooze" : "End Snooze"} Combo </h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                {row.snooze_start_time &&
                                    <div className="row">
                                        <div className="col-12">
                                            <p>Are you sure,you want to End Snooze of this combo?</p>
                                        </div>
                                    </div>}
                                <div className='row'>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="form-control-label mb-3">Snooze Start <span className="text-danger">*</span></label>
                                            <input type="datetime-local" name="startDate" disabled={row.snooze_start_time ? true : false} min={today} defaultValue={moment(row.snooze_start_time).utc(false).format('YYYY-MM-DDTHH:mm:ss')} required data-msg="Please enter Date" placeholder="DD/MM/YYYY" className="form-control" onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="form-control-label mb-3">Snooze End <span className="text-danger">*</span></label>
                                            <input type="datetime-local" name="endDate" disabled={row.snooze_start_time ? true : false} min={today} defaultValue={moment(row.snooze_end_time).utc(false).format('YYYY-MM-DDTHH:mm:ss')} required data-msg="Please enter Date" placeholder="DD/MM/YYYY" className="form-control" onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    {!row.snooze_start_time &&
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-control-label">Snooze Reason <span className="text-danger">*</span></label>
                                                <select
                                                    name="snoozeReason"
                                                    onChange={this.handleInputChange}
                                                    className="form-control mt-2">
                                                    <option value="">Select Reason</option>
                                                    {itemStatusReasons &&
                                                        itemStatusReasons.map((reason: any, index: any) => (
                                                            <option key={index} value={reason.reason}>{reason.reason}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleSnooze(row.combo_id)} disabled={!row.snooze_start_time ? !this.isComboSnoozeReady() : false} className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Diff pricing for Diff Stores */}
                <div id={`diffPrice${row.combo_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Price for Specific Stores </h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <input id="checkboxCustom1" type="checkbox" name="allRemove" checked={allRemove == true && true} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                    <label htmlFor="checkboxCustom1">All Store Remove</label>
                                </div>
                                {storesJson.map((x: any, ind: any) => {
                                    return (
                                        <div className='row' key={ind}>
                                            <div className="col-6" >
                                                <div className="form-group">
                                                    <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                    <Select
                                                        name="stores"
                                                        options={data.stores.filter((o1: any) => !storesJson.some((o2: any) => o1.value === o2.store_id))}
                                                        value={data.stores.map((element: any) => {
                                                            if (element.value == x.store_id) {
                                                                return { label: element.label, value: element.value }
                                                            }
                                                        })}
                                                        className="text-capitalize select mt-2"
                                                        classNamePrefix="select"
                                                        onChange={(e, i) => this.handleStoresInputChange(e, ind)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label className="form-control-label">Max Retail Price <span className="text-danger">*</span></label>
                                                    <input
                                                        name="mrp"
                                                        type="number" onWheel={(e: any) => e.target.blur()}
                                                        value={x.mrp}
                                                        data-msg="Please enter mrp"
                                                        className="input-material"
                                                        onChange={(e) => this.handleStoresInputChange(e, ind)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-end">
                                                <div className="form-group">
                                                    {storesJson.length !== 1 &&
                                                        <button className="btn btn-sm btn-outline-danger" onClick={() => this.handleRemoveClick(ind)}><i className="fa fa-trash"></i></button>}
                                                    {storesJson.length - 1 === ind && <button className="btn btn-sm btn-primary ml-2 float-right" onClick={() => this.handleAddClick(ind)}><i className="fa fa-plus"></i></button>}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handlePriceForSpecificStores(row.combo_id)} className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter updateComboSnooze={props.updateComboSnooze} addStoreSpecificComboPrice={props.addStoreSpecificComboPrice} itemStatusReasons={props.itemStatusReasons} row={row} data={props} />
    );
}
function orderModesFormatter(cell: any, row: any, props: any) {
    return (
        <OrderModesFormatter row={row} data={props} />
    );
}
class OrderModesFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                {row.item_mode &&
                    <button title="View Modes" data-toggle="modal" data-target={`#itemmodes${row.combo_id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button>
                }
                {/* <!-- Modal--> */}
                <div id={`itemmodes${row.combo_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Order Modes</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            <BootstrapTable version='4' data={(row.item_mode && this.IsJsonString(row.item_mode)) ? JSON.parse(row.item_mode) : ""} hover>
                                                <TableHeaderColumn dataField='label' columnTitle isKey>Order Mode</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
class Combo extends Component<ComboProps, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.statusChangeReasons();
        this.props.combosList()
        this.props.storesList();
        document.title = "Crust Culture | Combos"
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-combo" /> },
            noDataText: 'Combos Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Combo Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body text-capitalize">
                                                <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='combos.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='combo_id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='erp_id' csvHeader='ERP ID' width='100' columnTitle>ERP ID</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='pos_code' csvHeader='POS Code' width='100' columnTitle>POS Code</TableHeaderColumn>
                                                    {/* <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='comboUnique' csvHeader='Combo Unique ID' width='100' columnTitle>Combo Unique Id</TableHeaderColumn> */}
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='combo_name' csvHeader='Combo Name' width='100' columnTitle>Combo name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='menu_name' csvHeader='Menu' width='100' columnTitle>Menu</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='group_name' csvHeader='Group' width='100' columnTitle>Group</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='priority' csvHeader='Priority' width='100' columnTitle>Priority</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='item_mode' csvHeader='Mode' width='100' dataFormat={orderModesFormatter} columnTitle>Mode</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='is_hide' width='100' dataFormat={hiddenFormatter} export={false}>Hide</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='is_voucher' width='100' dataFormat={voucherFormatter} export={false}>VoucherDeal</TableHeaderColumn>
                                                    {/* <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='combo_cost_price' csvHeader='Cost Price' dataFormat={priceFormatter} width='100' columnTitle>Cost Price</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='combo_mrp_price' csvHeader='Max Retail Price' dataFormat={priceFormatter} width='130' columnTitle>Max Retail Price</TableHeaderColumn> */}
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='combo_start_time' csvHeader='Combo Start Time' dataFormat={timeFormatter} width='150'>Combo Start Time</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='combo_close_time' csvHeader='Combo Close Time' dataFormat={timeFormatter} width='150'>Combo Close Time</TableHeaderColumn>
                                                    {/* <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='hero_item' width='100' dataFormat={heroItemFormatter} export={false}>HeroItem</TableHeaderColumn> */}
                                                    {/* <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='topDeal' width='100' dataFormat={topDealFormatter} export={false}>TopDeal</TableHeaderColumn> */}
                                                    {/* <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='combo_block_reason' csvHeader='Block Reason' width='100' columnTitle>Block Reason</TableHeaderColumn> */}
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='is_snooze' width='100' dataFormat={SnoozeFormatter} export={false}>Snooze</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='250' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
function heroItemFormatter(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" })}>{cell == 1 && <i className="fa fa-check-square-o"></i>}</span>
        </div>
    )
}
function topDealFormatter(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" })}>{cell == 1 && <i className="fa fa-check-square-o"></i>}</span>
        </div>
    )
}
function hiddenFormatter(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" })}>{cell == 1 && <i className="fa fa-check-square-o"></i>}</span>
        </div>
    )
}
function voucherFormatter(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" })}>{cell == 1 && <i className="fa fa-check-square-o"></i>}</span>
        </div>
    )
}
function SnoozeFormatter(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell === 1 && { className: "badge badge-success p-2" })}>{cell === 1 && <i className="fa fa-check-square-o"></i>}</span>
        </div>
    )
}
function timeFormatter(cell: any) {
    return (
        <div>
            <span {...((cell !== "" && cell !== null) && { title: moment(cell).local().format('YYYY-MM-DD HH:mm') })}> {(cell !== "" && cell !== null) && moment(cell).local().format('YYYY-MM-DD HH:mm')} </span>
        </div>
    )
}
const mapStateToProps = (state: any) => {
    return {
        data: state.menu.combos,
        itemStatusReasons: state.menu.statusChangeReasons,
        stores: state.menu.storesoptions,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        combosList: function () {
            dispatch(combosList())
        },
        blockunblockcombo: function (id: any, is_active: any, combo_name: any) {
            dispatch(blockunblockcombo(id, is_active, combo_name))
        },
        statusChangeReasons: () => {
            dispatch(statusChangeReasons())
        },
        updateComboSnooze: (id: any, data: any) => {
            dispatch(updateComboSnooze(id, data))
        },
        storesList: () => {
            dispatch(storesListForMultiSelect())
        },
        addStoreSpecificComboPrice: (id: any, data: any) => {
            dispatch(addStoreSpecificComboPrice(id, data))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Combo);