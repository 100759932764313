import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { EditCategoryProps, EditCategoryState } from '../../../../interfaces/menu';
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken';
import { secretKey } from '../../../../secret';
import { logoutUser, editNutCategory, getNutCategory } from '../../../../redux';
import Topbar from '../../../../components/topbar';
import Sidebar from '../../../../components/sidebar';
import Footer from '../../../../components/footer/main';
import CheckChanges from '../../../../components/confirmOnLeave';
import Select from 'react-select';

class EditMeal extends Component<EditCategoryProps, EditCategoryState> {
    constructor(props: any) {
        super(props);
        this.state = {
            categoryName: "",
            // categories: [
            //     {
            //         category: "",
            //         categoryAR: ""
            //     }
            // ],
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getNutCategory(id);
        document.title = "Crust Culture | Nutritional Categories"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({
            categoryName: nextProps.nutCategoryData.group_name
        })
        // var categories = nextProps && nextProps.mealData.nutritionalCat;
        // if (categories.length > 0) {
        //     var listCategories: any = [];
        //     categories.forEach((element: any) => {
        //         const newlist: any = { category: element.group_name, categoryAR: element.group_name_ar }
        //         listCategories.push(newlist);
        //         this.setState({ categories: listCategories });
        //     });
        // }
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    // handleCategoryInputChange = (e: any, index: any) => {
    //     if (e.target) {
    //         const { name, value } = e.target;
    //         const list: any = this.state.categories;
    //         list[index][name] = value;
    //         this.setState({ categories: list });
    //     }
    // };
    // handleRemoveCategory = (index: any) => {
    //     const list = this.state.categories;
    //     list.splice(index, 1);
    //     this.setState({ categories: list });
    // };
    // handleAddCategory = (i: any) => {
    //     const list: any = this.state.categories;
    //     if (list[i]["category"] == "") {
    //         alert("Please fill in current item")
    //     } else {
    //         const newlist: any = { category: "", categoryAR: "" }
    //         const categories: any = this.state.categories.concat(newlist)
    //         this.setState({ categories: categories });
    //     }
    // };
    isNuCategoryReady = () => {
        const { categoryName } = this.state
        return (categoryName !== "");
    }
    handleSaveBtnClick = (event: any) => {
        let { categoryName } = this.state;
        let id = this.props.match.params.id;
        let data: any = {
            group_name: categoryName,
        }
        this.props.editNutCategory(id, data);
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        // let { categories } = this.state;
        const { nutCategoryData } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/edit-meal" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Nutritional Categories Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/nut-categories" className="text-primary">Categories</Link></li>
                                <li className="breadcrumb-item active">Edit Category</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                        <label className="form-control-label">Category Name <span className="text-danger">*</span></label>
                                                            <input id="categoryName" type="text" name="categoryName" required data-msg="Please enter category name" className="input-material" defaultValue={nutCategoryData.group_name} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row" style={{ paddingTop: '1em' }}>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            {categories.map((x: any, i: any) => {
                                                                return (
                                                                    <div key={i} className="row">
                                                                        <div className="col-md-5 col-12">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Category Name <span className="text-danger">*</span></label>
                                                                                <input id="category" type="text" name="category" value={x.category} required data-msg="Please enter category name" className="input-material" onChange={e => this.handleCategoryInputChange(e, i)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5 col-12">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Category Name (Arabic)</label>
                                                                                <input id="categoryAR" type="text" name="categoryAR" value={x.categoryAR} required data-msg="Please enter category arabic name" className="input-material" onChange={e => this.handleCategoryInputChange(e, i)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2 col-12 py-4 text-right">
                                                                            {categories.length !== 1 &&
                                                                                <button className="btn btn-sm btn-outline-danger"
                                                                                    onClick={() => this.handleRemoveCategory(i)}><i className="fa fa-trash"></i></button>}
                                                                            {categories.length - 1 === i && <button className="btn btn-sm btn-primary ml-2" onClick={() => this.handleAddCategory(i)}><i className="fa fa-plus"></i></button>}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className="form-group float-right mt-3">
                                                    <button onClick={() => { this.props.history.push("/nut-categories") }} className="btn btn-danger mr-2">Cancel</button>
                                                    <button className='btn btn-primary' disabled={!this.isNuCategoryReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        nutCategoryData: state.menu.nutCategoryData
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        editNutCategory: function (id: any, data: any) {
            dispatch(editNutCategory(id, data));
        },
        getNutCategory: function (id: number) {
            dispatch(getNutCategory(id));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMeal);