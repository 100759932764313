import React, { Component } from 'react';
import { BulkCouponProps, BulkCouponState } from '../../interfaces/coupon'
import { connect } from 'react-redux'
import { brandsList, menusList, logoutUser, itemsListForMultiSelect, searchCustomerByPhone, CouponsBulkUpload, groupsListForMultiSelect } from '../../redux'
import { storesList } from '../../redux/actions/reportAction';
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
import Select from 'react-select';
import moment from 'moment';
import './coupon.css'
import { ExcelRenderer } from 'react-excel-renderer';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
class BulkCoupons extends Component<BulkCouponProps, BulkCouponState> {
    constructor(props: any) {
        super(props);
        this.state = {
            couponname: "",
            coupondesc: "",
            couponvalue: "",
            pos_code: "",
            expiryDate: "",
            startDate: "",
            percent: "",
            mode: "all",
            channel: "all",
            type: "menu",
            discountType: "value",
            limit: "1",
            type_id: "",
            multiJson: [],
            multiGroupJson: [],
            multiStoreJson: [],
            freeDelivery: false,
            minamount: "",
            totalusagebycus: "",
            minorders: "",
            specificCustomer: false,
            vipCustomer: false,
            is_nps: false,
            phone: "",
            multiCustomerJson: [],
            setType: "",
            isLoad: false,
            isSelected: false,
            rows: [],
            coupons: ["CustomerVoucherCode"],
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCouponType = this.handleCouponType.bind(this);
        this.handleDiscountType = this.handleDiscountType.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
    }
    componentDidMount() {
        document.title = "Crust Culture | Bulk Coupons Upload"
        this.props.storesList();
        this.props.itemsListForMultiSelect();
        this.props.menusList();
        this.props.groupsList();
    }
    updateLoad=()=>{
        this.setState({isLoad:false})
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        if (event.target.value == ' ') {
            event.target.value = event.target.value.replace(/\s/g, "");
        }
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    handleCouponType(event: { target: { name: any; value: any; }; }) {
        this.setState({
            type_id: "",
            [event.target.name]: event.target.value,
        });
    }
    handleGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiGroupJson: e });
        }
    }
    handleItemsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiJson: e });
        }
    }
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiStoreJson: e });
        } else {
            this.setState({ multiStoreJson: [] });
        }
    }
    handleCustomersInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiCustomerJson: e });
        } else {
            this.setState({ multiCustomerJson: [] });
        }
    }
    handleDiscountType(event: { target: { name: any; value: any; }; }) {
        this.setState({
            percent: "",
            couponvalue: "",
            [event.target.name]: event.target.value,
        });
    }

    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    isCouponReady = () => {
        const { isSelected, specificCustomer, pos_code, multiCustomerJson, freeDelivery, couponname, couponvalue, startDate, expiryDate, multiJson, multiStoreJson, multiGroupJson, mode, type, discountType, limit, type_id, percent } = this.state
        if (type == "menu") {
            return (isSelected && couponname !== "" && pos_code !== "" && startDate !== "" && expiryDate !== "" && mode !== "" && type !== "" && discountType !== ""
                && limit !== "" && type_id !== "" && (discountType !== "combo" ? (percent !== "" || couponvalue !== "") : true) && (specificCustomer ? multiCustomerJson.length > 0 : true));
        } else if (type == "group") {
            return (isSelected && couponname !== "" && pos_code !== "" && startDate !== "" && expiryDate !== "" && mode !== "" && type !== "" && discountType !== ""
                && limit !== "" && (multiGroupJson && multiGroupJson.length > 0) && (discountType !== "combo" ? (percent !== "" || couponvalue !== "") : true) && (specificCustomer ? multiCustomerJson.length > 0 : true));
        } else if (type == "item") {
            return (isSelected && couponname !== "" && pos_code !== "" && startDate !== "" && expiryDate !== "" && mode !== "" && type !== "" && discountType !== ""
                && limit !== "" && multiJson.length > 0 && (discountType !== "combo" ? (percent !== "" || couponvalue !== "") : true) && (specificCustomer ? multiCustomerJson.length > 0 : true));
        } else if (type == "store") {
            return (isSelected && couponname !== "" && pos_code !== "" && startDate !== "" && expiryDate !== "" && mode !== "" && type !== "" && discountType !== ""
                && limit !== "" && multiStoreJson.length > 0 && (discountType !== "combo" ? (percent !== "" || couponvalue !== "") : true) && (specificCustomer ? multiCustomerJson.length > 0 : true));
        } else if (freeDelivery) {
            return (isSelected && couponname !== "" && pos_code !== "" && startDate !== "" && expiryDate !== "" && mode !== "" && discountType !== ""
                && limit !== "" && (discountType !== "combo" ? (percent !== "" || couponvalue !== "") : true) && (specificCustomer ? multiCustomerJson.length > 0 : true));
        }
    }
    handleFreeDelivery = (event: { target: { name: any; value: any; }; }) => {
        const { freeDelivery } = this.state;
        if (freeDelivery) {
            this.setState({ type: "menu", })
        } else {
            this.setState({ type: "" })
        }
        this.setState({ freeDelivery: !freeDelivery, type_id: "" })
    }
    handleVip = (event: { target: { name: any; value: any; }; }) => {
        const { vipCustomer } = this.state;
        this.setState({ vipCustomer: !vipCustomer })
    }
    validatePhone(event: { target: { name: any; value: any; }; }) {
        if (event.target.value.length < 11) {
            this.setState({ phone: event.target.value })
        } else {
            if (event.target.value.length === 11) {
                this.setState({ phone: event.target.value });
            }
        }
        if (event.target.value.length >= 4) {
            this.props.searchCustomerByPhone(event.target.value)
        }
    };
    handleCheckBoxes = (event: { target: { name: any; value: any; }; }) => {
        if (event.target.name == "is_emp") {
            if (this.state.setType == "is_emp") {
                this.setState({ setType: "", specificCustomer: false, })
            } else {
                this.setState({ setType: event.target.name, specificCustomer: false, })
            }
        } else if (event.target.name == "specificCustomer") {
            const { specificCustomer } = this.state;
            if (this.state.setType == "specificCustomer") {
                this.setState({ specificCustomer: false, setType: "", vipCustomer: false })
            } else {
                this.setState({ specificCustomer: true, setType: 'specificCustomer', vipCustomer: false })
            }
        } else if (event.target.name == "is_nps") {
            this.setState({ is_nps: !this.state.is_nps })
        }
    }
    isEqual = (columns: any, fileFormat: any) => {
        if (columns.length != fileFormat.length) {
            return false;
        } else {
            for (var i = 0; i < columns.length; i++) {
                if (columns[i] != fileFormat[i]) {
                    return false
                } else {
                    return true;
                }
            }
        }
    }
    fileHandler = (event: any) => {
        let fileObj = event.target.files[0];
        console.log("file OBj", fileObj);

        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err: any, resp: any) => {
            if (err) {
                console.log(err);
            }
            else {
                this.setState({
                    rows: resp.rows
                });

                const realData = resp.rows;
                console.log("excel Data: ", realData);

                // File Limit
                if (realData.length > 10000) {
                    alert("Please select file upto 10000 rows!");
                    this.setState({ isSelected: false })
                }
                if (realData.length == 0) {
                    alert("Empty files can't be slected!");
                    this.setState({ isSelected: false })
                }
                // Validate for Correct file
                const columns = realData.shift();
                console.log("columns: ", columns);
                let response = this.isEqual(columns, this.state.coupons)
                console.log("CouponsResponse", response)
                if (response == true) {
                    this.setState({ isSelected: true })
                } else {
                    alert("Please select the correct format file.")
                    this.setState({ isSelected: false })
                }
            }
        });
    }
    handleSaveBtnClick = (event: any) => {
        let { rows, minorders, minamount, setType, is_nps, totalusagebycus, couponname, coupondesc, pos_code, couponvalue, startDate, channel, expiryDate, mode, type, limit, type_id, percent, multiJson, multiStoreJson, freeDelivery, specificCustomer, multiCustomerJson, multiGroupJson, discountType } = this.state;
        let arr: any = [];
        rows.forEach((element: any) => {
            let data: any = {
                coupon_name: couponname,
                coupon_code: element[0],
                pos_code: pos_code,
                coupon_description: coupondesc,
                coupon_value: couponvalue,
                start_date: moment(startDate).utc(false).format('YYYY-MM-DD HH:mm:ss'),
                expire_date: moment(expiryDate).utc(false).format('YYYY-MM-DD HH:mm:ss'),
                mode: mode,
                channel: channel,
                limit: limit,
                total_usage: limit,
                percent: percent,
                is_archive: 0,
                min_amount: minamount,
                min_orders: minorders,
                total_usage_customer: (totalusagebycus !== "" && totalusagebycus > 0) ? totalusagebycus : 0,
                discount_type: discountType
            }
            if (type == "menu") {
                data.type_id = type_id;
            } else if (type == "group") {
                data.multiGroupJson = JSON.stringify(multiGroupJson);
            } else if (type == "item") {
                data.items_json = JSON.stringify(multiJson);
            } else if (type == "store") {
                data.stores_json = JSON.stringify(multiStoreJson);
            }
            if (specificCustomer) {
                data.customers_json = JSON.stringify(multiCustomerJson);
                data.for_customer = 1;
            }
            if (setType == "is_emp") {
                data.is_emp = 1;
            } else if (setType == "is_partner") {
                data.is_partner = 1;
            }
            if (is_nps) {
                data.is_nps = 1
            }else{
                data.is_nps = 0
            }
            // if (vipCustomer) {
            //     data.is_vip = 1
            // }
            if (freeDelivery) {
                data.type = type;
                data.free_delivery = 1
            } else {
                data.free_delivery = 0;
                data.type = type;
            }
            arr.push(data);
        });
        console.log("BulkCoupons", arr)
        this.props.CouponsBulkUpload(arr,this.updateLoad);
        this.setState({ isLoad: true })
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const { menus, groups, items, stores, searchCustomers } = this.props;
        let { is_nps } = this.state;
        return (
            <div className="page">
                <CheckChanges path="/add-coupon" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Compaigns Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/marketing/0" className="text-primary">Compaigns</Link></li>
                                <li className="breadcrumb-item active">Bulk Coupons Upload</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Coupon name <span className="text-danger">*</span></label>
                                                            <input id="couponname" type="text" name="couponname" required data-msg="Please enter Coupon Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description</label>
                                                            <input id="coupondesc" type="text" name="coupondesc" required data-msg="Please enter Description" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row my-3 d-flex justify-content-between align-items-center">
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">POS coupon code <span className="text-danger">*</span></label>
                                                            <input id="pos_code" type="text" name="pos_code" value={this.state.pos_code} required data-msg="Please enter POS Code" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-4 mt-3">
                                                        <div>
                                                            <input id="checkboxCustom2" type="checkbox" name="freeDelivery" checked={this.state.freeDelivery} onChange={this.handleFreeDelivery} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom2">Free Delivery
                                                                <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Free Delivery represents that Customer can place order with 0 delivery fee">
                                                                    i
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <input
                                                            type="file"
                                                            className="btn btn-primary text-white btn-sm"
                                                            name="rows"
                                                            //ref={this.inputRef}
                                                            onClick={(event: any) => {
                                                                event.target.value = null
                                                            }}
                                                            multiple={false}
                                                            onChange={this.fileHandler}
                                                            style={{ "padding": "6px" }} />
                                                    </div>
                                                </div>
                                                {this.state.freeDelivery == false &&
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Coupon Level <span className="text-danger">*</span>
                                                                    <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Coupon level represents that coupon can be used in which level.">
                                                                        i
                                                                    </span></label>
                                                                <select name="type" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleCouponType}>
                                                                    <option value='menu'>Menu</option>
                                                                    <option value='group'>Group</option>
                                                                    {/* <option value='item'>Item</option> */}
                                                                    <option value='store'>Store</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        {this.state.type === "menu" &&
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Select Menu <span className="text-danger">*</span></label>
                                                                    <select name="type_id" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange}>
                                                                        <option>Select Menu</option>
                                                                        {menus &&
                                                                            menus.map((menu: any, index: any) => (
                                                                                <option key={index} value={menu.menu_id}>{menu.menu_name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.state.type === "group" &&
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Select Group <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        isMulti
                                                                        name="groups"
                                                                        options={groups}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                                                                    />
                                                                    {/* <select name="type_id" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange}>
                                                                        <option>Select Group</option>
                                                                        {groups &&
                                                                            groups.map((group: any, index: any) => (
                                                                                <option key={index} value={group.group_id}>{group.group_name}</option>
                                                                            ))
                                                                        }
                                                                    </select> */}
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.type === "item" &&
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Select Items <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        isMulti
                                                                        name="items"
                                                                        options={items}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleItemsInputChange(e, i)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.type === "store" &&
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Select Stores <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        isMulti
                                                                        name="stores"
                                                                        options={stores}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Channel <span className="text-danger">*</span>
                                                                <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Ordering Channel represents customer can use coupon in which channel.">
                                                                    i
                                                                </span></label>
                                                            <select name="channel" className="form-control text-capitalize mt-2" required data-msg="Please select channel" onChange={this.handleInputChange}>
                                                                <option value='all'>All</option>
                                                                <option value='delivery'>Delivery</option>
                                                                <option value='pickup'>Pick Up</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span>
                                                                <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Ordering Mode represents customer can use coupon in which order mode.">
                                                                    i
                                                                </span></label>
                                                            <select name="mode" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleInputChange}>
                                                                <option value='all'>All</option>
                                                                <option value='online'>Online</option>
                                                                <option value='callcenter'>Call Center</option>
                                                                <option value='mobile'>Mobile</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Coupon Limit <span className="text-danger">*</span>
                                                                <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Coupon Limit represents that coupon can be used for only x time. ">
                                                                    i
                                                                </span></label>
                                                            <input id="limit" type="number" onWheel={(e:any) => e.target.blur()} name="limit" defaultValue='1' min="1" required data-msg="Please enter Limit" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Coupon Type <span className="text-danger">*</span>
                                                                <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Coupon Type represents that coupon will be applicable on flat cart value or will on percentage of cart value.">
                                                                    i
                                                                </span></label>
                                                            <select name="discountType" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleDiscountType}>
                                                                <option value='value'>Value</option>
                                                                <option value='percentage'>Percentage</option>
                                                                <option value='combo'>Combo</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {this.state.discountType === "percentage" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Coupon Percentage <span className="text-danger">*</span></label>
                                                                <input id="percent" type="number" onWheel={(e:any) => e.target.blur()} name="percent" min="1" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Coupon Percentage" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.discountType === "value" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Coupon value <span className="text-danger">*</span></label>
                                                                <input id="couponvalue" type="number" onWheel={(e:any) => e.target.blur()} name="couponvalue" min="1" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Coupon Value" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Min total<span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Min Total represents the minimum cart value customer holds to fulfill coupon requirement.">
                                                                i
                                                            </span></label>
                                                            <input id="minamount" type="number" onWheel={(e:any) => e.target.blur()} name="minamount" min="0" onKeyDown={this.blockInvalidChar} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Coupon usage/customer<span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Coupon usage/customer represents that customer can use coupon for only x time.">
                                                                i
                                                            </span></label>
                                                            <input id="totalusagebycus" type="number" onWheel={(e:any) => e.target.blur()} name="totalusagebycus" min="0" onKeyDown={this.blockInvalidChar} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Min Orders<span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Min Orders represents the minimum orders customer holds to fulfill coupon requirement.">
                                                                i
                                                            </span></label>
                                                            <input id="minorders" type="number" onWheel={(e:any) => e.target.blur()} name="minorders" min="0" onKeyDown={this.blockInvalidChar} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-6 my-2">
                                                        <div>
                                                            <input id="checkboxCustom1" type="checkbox" name="is_emp" checked={this.state.setType === 'is_emp'} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom1">For Employees</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-6 my-2">
                                                        <div>
                                                            <input id="checkboxCustom3" type="checkbox" name="specificCustomer" checked={this.state.setType === 'specificCustomer'} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom3">For Specific Customer</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-6 my-2">
                                                        <div>
                                                            <input id="checkboxCustom4" type="checkbox" name="is_nps" checked={is_nps} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom4">For NPS</label>
                                                        </div>
                                                    </div>
                                                    {/* {this.state.setType !== "" && this.state.setType !== "specificCustomer" &&
                                                        <div className="col-lg-3 col-md-3 col-6 my-2">
                                                            <div>
                                                                <input id="checkboxCustom4" type="checkbox" name="vip" checked={this.state.vipCustomer} onChange={this.handleVip} className="checkbox-template" />
                                                                <label htmlFor="checkboxCustom4">For VIP</label>
                                                            </div>
                                                        </div>
                                                    } */}
                                                </div>
                                                {this.state.specificCustomer &&
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-lable">Phone</label>
                                                                <input id="phone" type="number" onWheel={(e:any) => e.target.blur()} value={this.state.phone} onKeyDown={this.blockInvalidChar} name="phone" required data-msg="Please enter Phone" className="input-material" onChange={this.validatePhone} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Customers <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="customers"
                                                                    options={searchCustomers}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleCustomersInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Valid from <span className="text-danger">*</span></label>
                                                            <input id="startDate" type="datetime-local" name="startDate" required data-msg="Please enter Start Date" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Valid till <span className="text-danger">*</span></label>
                                                            <input id="expiryDate" type="datetime-local" name="expiryDate" required data-msg="Please enter Expire Date" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    {(this.state.isLoad) ?
                                                        <button className='btn btn-primary d-flex justify-content-end align-item-center' disabled={this.state.isLoad}><Loader type="TailSpin" color="white" height={30} width={30} /></button> :
                                                        <button className='btn btn-primary' disabled={!this.isCouponReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        brands: state.store.brands,
        menus: state.menu.menus,
        groups: state.menu.groupsptions,
        items: state.menu.allActiveItems,
        stores: state.report.stores,
        searchCustomers: state.customer.searchCustomers
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        brandsList: function () {
            dispatch(brandsList())
        },
        menusList: function () {
            dispatch(menusList())
        },
        groupsList: function () {
            dispatch(groupsListForMultiSelect("campaign"))
        },
        itemsListForMultiSelect: function () {
            dispatch(itemsListForMultiSelect("campaign"))
        },
        storesList: () => {
            dispatch(storesList())
        },
        searchCustomerByPhone: (phone: any) => {
            dispatch(searchCustomerByPhone(phone))
        },
        CouponsBulkUpload: (data: any,updateLoadFn:any) => dispatch(CouponsBulkUpload(data,updateLoadFn))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BulkCoupons);