import Api from '../../components/Api';
import { HANDLE_INPUT, ADD_CITY, CITY_LIST, EDIT_CITY, GET_CITY } from './cityType';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwt from 'jsonwebtoken'
toast.configure();
export const handleCityInput = (event: { target: { name: any; value: any; }; }) => {
    return {
        type: HANDLE_INPUT,
        input: event.target
    }
}
export const cityList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/cities', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    /////////////////////////////////////////////////////////
                    response.data.successResponse.forEach((obj:any,index:any)=>{
                        obj.label= obj.name;
                        obj.value= obj.id;
                        obj.country=obj.country_id && obj.country_id.country_name
                 })
                    console.log("response.data.successResponse",response.data.successResponse)
                    dispatch({
                        type: CITY_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addCity = (data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        data.user_info = jwt.decode(token);
        Api.post('/admin/save_city', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log('response.data.success',response.data.success);
                    
                    window.location.href = "/cities"
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editCity = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        
        Api.put(`/admin/edit_city/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/cities"
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getCity = (id: number,setCityState:any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/get_city/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    setCityState(response.data.successResponse.name,response.data.successResponse.country)
                    dispatch({
                        type: GET_CITY,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const delCity = (id: number, city_name: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        let data = {
            city: city_name,
            //user_info: jwt.decode(token)
        }
        Api.delete(`/admin/del_city/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/cities";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
