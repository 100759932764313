import React, { Component } from 'react';
import { AddModGroupProps, AddModGroupState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import { addModGroup, menusList, logoutUser, groupsListForMultiSelect, itemsListForMultiSelect, subgroupsListForMultiSelect } from '../../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';
class AddModGroup extends Component<AddModGroupProps, AddModGroupState> {
    constructor(props: any) {
        super(props);
        this.state = {
            groupJson: [],
            subgroupJson: [],
            itemsJson: [],
            poscode: null,
            erpid: null,
            modgroupname: "",
            modgroupnamehint: "",
            modgroupdesc: "",
            level: "group",
            type: "single",
            priority: "",
            min_quantity: "",
            max_quantity: "",
            is_crust: false,
            is_addon: true,
            is_toping: false,
            is_flavour: false,
            is_condiment: false,
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleModifiersLevel = this.handleModifiersLevel.bind(this);
    }
    componentDidMount() {
        this.props.groupsList();
        this.props.subgroupsList();
        this.props.itemsListForMultiSelect();
        document.title = "Crust Culture | Modifier Groups"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleCheckBoxes = (event: { target: { name: any; value: any; }; }) => {
        const { is_crust, is_addon, is_toping, is_condiment } = this.state;
        if (event.target.name == "is_crust") {
            this.setState({ is_crust: true, is_addon: false, is_flavour: false, is_toping: false, is_condiment: false })
        } else if (event.target.name == "is_addon") {
            this.setState({ is_addon: true, is_crust: false, is_flavour: false, is_toping: false, is_condiment: false })
        } else if (event.target.name == "is_toping") {
            this.setState({ is_toping: true, is_addon: false, is_flavour: false, is_crust: false, is_condiment: false })
        } else if (event.target.name == "is_flavour") {
            this.setState({ is_flavour: true, is_addon: false, is_crust: false, is_toping: false, is_condiment: false })
        } else if (event.target.name == "is_condiment") {
            this.setState({ is_condiment: true, is_flavour: false, is_addon: false, is_crust: false, is_toping: false  })
        }
    }
    handleModifiersLevel(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    handleGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ itemsJson: [], groupJson: e });
        }
    };
    handleSubGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ itemsJson: [], groupJson: [], subgroupJson: e });
        }
    };
    handleItemsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ groupJson: [], itemsJson: e });
        }
    }
    isModGroupReady = () => {
        const { modgroupname, groupJson, itemsJson, subgroupJson, type, level, priority, min_quantity, max_quantity } = this.state
        return (modgroupname !== "" && (level == "group" ? groupJson.length > 0 : level == "subgroup" ? subgroupJson.length > 0 : itemsJson.length > 0) && type != "" && level != "" && priority !== "" && (min_quantity !== "" && (min_quantity == 0 || min_quantity > 0)) && (max_quantity !== "" && max_quantity > 0));
    }
    handleSaveBtnClick = (event: any) => {
        let { groupJson, subgroupJson, itemsJson, modgroupnamehint, modgroupname, erpid, modgroupdesc, poscode, type, level, priority, min_quantity, max_quantity, is_crust, is_addon, is_toping, is_flavour, is_condiment } = this.state;
        let data: any = {
            mod_group_name: modgroupname,
            mod_group_description: modgroupdesc,
            type: level,
            modifiers_type: type,
            priority: priority,
            min_quantity: min_quantity,
            max_quantity: max_quantity,
            is_crust: is_crust ? 1 : 0,
            is_addon: is_addon ? 1 : 0,
            is_toping: is_toping ? 1 : 0,
            is_flavour: is_flavour ? 1 : 0,
            is_condiment: is_condiment ? 1 : 0
        }
        if (modgroupnamehint !== "") {
            data.mod_group_name_hint = modgroupnamehint
        }
        if (level == 'group') {
            data["group_json"] = JSON.stringify(groupJson);
        } else if (level == 'item') {
            data["items_json"] = JSON.stringify(itemsJson);
        } else if (level == "subgroup") {
            data["group_json"] = JSON.stringify(subgroupJson);
        }
        if (poscode !== null) {
            data["pos_code"] = poscode;
        }
        if (erpid !== null) {
            data['erp_id'] = erpid;
        }
        this.props.addModGroup(data);
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const { groups, subgroups, items } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/add-modgroup" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Modifier Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/mod-groups" className="text-primary">Modifier Groups</Link></li>
                                <li className="breadcrumb-item active">Add Modifier Group</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Mod Group name <span className="text-danger">*</span></label>
                                                            <input id="modgroupname" type="text" name="modgroupname" required data-msg="Please enter Modifier Group Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Mod group name hint</label>
                                                            <input id="modgroupnamehint" type="text" name="modgroupnamehint" data-msg="Please enter Modifier Group Name Hint" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">POS Code</label>
                                                            <input id="poscode" type="text" name="poscode" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">ERP Id</label>
                                                            <input id="erpid" type="text" name="erpid" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifiers Level <span className="text-danger">*</span></label>
                                                            <select name="level" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleModifiersLevel}>
                                                                <option value='group'>Group</option>
                                                                <option value='subgroup'>SubGroup</option>
                                                                <option value='item'>Menu Item</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {this.state.level === "group" &&
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Group <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="groups"
                                                                    options={groups}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.level === "subgroup" &&
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Sub Groups <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="subgroup"
                                                                    options={subgroups}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleSubGroupsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.level === "item" &&
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Items <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="items"
                                                                    options={items}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleItemsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Min Quantity <span className="text-danger">*</span></label>
                                                            <input id="min_quantity" type="number" onWheel={(e: any) => e.target.blur()} name="min_quantity" min={0} required data-msg="Please enter Min Quantity" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Max Quantity <span className="text-danger">*</span></label>
                                                            <input id="max_quantity" type="number" onWheel={(e: any) => e.target.blur()} name="max_quantity" min={1} required data-msg="Please enter Max Quantity" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description</label>
                                                            <input id="modgroupdesc" type="text" name="modgroupdesc" required data-msg="Please enter Group Description" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Group <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="groups"
                                                                options={groups}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifier Type <span className="text-danger">*</span></label>
                                                            <select name="type" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleInputChange}>
                                                                <option value='single'>Single Select</option>
                                                                <option value='multiple' >Multi Select</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority<span className="text-danger">*</span></label>
                                                            <input id="priority" type="number" onWheel={(e: any) => e.target.blur()} name="priority" required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-lg-2 col-sm-4 col-4 mt-5" >
                                                        <div>
                                                            <input id="checkboxCustom1" type="checkbox" name="is_addon" checked={this.state.is_addon} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom1">Addon</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-sm-4 col-4 mt-5" >
                                                        <div>
                                                            <input id="checkboxCustom2" type="checkbox" name="is_crust" checked={this.state.is_crust} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom2">Crust</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-sm-4 col-4 mt-5" >
                                                        <div>
                                                            <input id="checkboxCustom3" type="checkbox" name="is_toping" checked={this.state.is_toping} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom3">Toping</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-sm-4 col-4 mt-5" >
                                                        <div>
                                                            <input id="checkboxCustom4" type="checkbox" name="is_flavour" checked={this.state.is_flavour} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom4">Flavour</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-sm-4 col-4 mt-5" >
                                                        <div>
                                                            <input id="checkboxCustom5" type="checkbox" name="is_condiment" checked={this.state.is_condiment} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom5">Condiment</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isModGroupReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        menus: state.menu.menus,
        groups: state.menu.groupsptions,
        subgroups: state.menu.subgroupsptions,
        items: state.menu.allActiveItems
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addModGroup: function (data: any) {
            dispatch(addModGroup(data));
        },
        menusList: function () {
            dispatch(menusList())
        },
        groupsList: function () {
            dispatch(groupsListForMultiSelect())
        },
        subgroupsList: function () {
            dispatch(subgroupsListForMultiSelect())
        },
        itemsListForMultiSelect: function () {
            dispatch(itemsListForMultiSelect())
        }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddModGroup);