import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { EditmenuProps, EditMenuState } from '../../interfaces/menu'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { storeTypeList, editMenu, logoutUser, getMenu, brandsList, countryList, statesList } from '../../redux'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'

class EditMenu extends Component<EditmenuProps, EditMenuState> {
    constructor(props: any) {
        super(props);
        this.state = {
            menuname: "",
            storetype: "",
            brand: "",
            country: "",
            state: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        // this.props.countryList();
        // this.props.statesList();
        this.props.storeTypeList();
        this.props.brandsList();
        let id = this.props.match.params.id;
        this.props.getMenu(id);
        document.title = "Crust Culture | Menus"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({
            menuname: nextProps.menuData.menu_name,
            storetype: nextProps.menuData.store_type_id,
            brand: nextProps.menuData.brand_id,
            // state: nextProps.menuData.state_id
        })
        // if (nextProps.states.length > 0) {
        //     let statesList: any[] = nextProps.states;
        //     statesList.forEach((obj: any) => {
        //         if (obj.state_id == nextProps.menuData.state_id) {
        //             this.setState({ country: obj.country_id })
        //         }
        //     })
        // }
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
        // if (event.target.name == "country") {
        //     if (event.target.value == "") {
        //         this.setState({ state: "" });
        //     } else {
        //         if (event.target.value !== this.state.country) {
        //             this.setState({ state: "" });
        //         }
        //     }
        // }
    }
    isMenuReady = () => {
        const { menuname, brand, storetype } = this.state
        return (menuname !== "" && brand !== "" && storetype !== "");
    }
    handleSubmit = (event: any) => {
        let { menuname, storetype, brand } = this.state;
        let id = this.props.match.params.id;
        let data = {
            menu_name: menuname,
            store_type_id: storetype,
            brand_id: brand
        }
        this.props.editMenu(id, data);
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        let { country } = this.state;
        const { isUpdated, message, storetype, brands, menuData } = this.props;
        if (isUpdated) {
            msg = message;
            messcolor = 'green'
        } else {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                <CheckChanges path="/edit-menu" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Menus Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/menus" className="text-primary">Menus</Link></li>
                                <li className="breadcrumb-item active">Edit Menu</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label ">Menu Name <span className="text-danger">*</span></label>
                                                            <input id="menuname" type="text" name="menuname" defaultValue={menuData.menu_name} required data-msg="Please enter Menu Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand <span className="text-danger">*</span></label>
                                                            <select name="brand" className="form-control text-capitalize mt-2" required data-msg="Please select brand" onChange={this.handleInputChange}>
                                                                <option value="">Select Brand</option>
                                                                {brands &&
                                                                    brands.map((brand, index) => (
                                                                        brand.is_active == 1 &&
                                                                        <option key={index} value={brand.brand_id} {...menuData.brand_id === brand.brand_id && { selected: true }}>{brand.brand_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Store Type <span className="text-danger">*</span></label>
                                                            <select name="storetype" className="form-control text-capitalize mt-2" required data-msg="Please select type" onChange={this.handleInputChange}>
                                                                <option disabled>Select Store Type</option>
                                                                {storetype &&
                                                                    storetype.map((type, index) => (
                                                                        <option key={index} value={type.store_type_id} {...menuData.store_type_id === type.store_type_id && { selected: true }}>{type.store_type_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">
                                                                    Country <span className="text-danger">*</span>
                                                                </label>
                                                                <select
                                                                    name="country"
                                                                    className="form-control mt-2"
                                                                    required
                                                                    data-msg="Please enter Country"
                                                                    onChange={this.handleInputChange}
                                                                >
                                                                    <option value="">Select Country</option>
                                                                    {countries &&
                                                                        countries.map((country: any, index: any) => (
                                                                            <option key={index} value={country.country_id} {...this.state.country === country.country_id && { selected: true }}>
                                                                                {country.country_name}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">
                                                                    States <span className="text-danger">*</span>
                                                                </label>
                                                                <select
                                                                    name="state"
                                                                    className="form-control mt-2"
                                                                    required
                                                                    data-msg="Please select state"
                                                                    onChange={this.handleInputChange}
                                                                >
                                                                    <option value="">Select State</option>
                                                                    {country !== "" &&
                                                                        states &&
                                                                        states.map(
                                                                            (state: any, index: any) =>
                                                                                state.country_id == country && (
                                                                                    <option key={index} value={state.state_id} {...this.state.state === state.state_id && { selected: true }}>
                                                                                        {state.state_name}
                                                                                    </option>
                                                                                )
                                                                        )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    {/* <input type="reset" value="cancel" className="btn btn-danger mr-2" /> */}
                                                    <button onClick={this.handleSubmit} disabled={!this.isMenuReady()} className="btn btn-primary">Update Menu</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>

                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        storetype: state.store.types,
        brands: state.store.brands,
        menuData: state.menu.menuData,
        message: state.menu.message,
        isUpdated: state.menu.isUpdated
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        storeTypeList: function () {
            dispatch(storeTypeList())
        },
        brandsList: function () {
            dispatch(brandsList())
        },
        editMenu: function (id: any, data: any) {
            dispatch(editMenu(id, data));
        },
        getMenu: function (id: number) {
            dispatch(getMenu(id));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMenu);