import { PARTNER_LIST, GET_PARTNER } from '../actions/partnerType'
const initialState: any = {
    partners: [] || "",
    partnerData: {}
}

const partnerReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case PARTNER_LIST: return {
            ...state,
            partners: action.payload
        }
        case GET_PARTNER: return {
            ...state,
            partnerData: action.payload
        }
        default: return state;
    }
}
export default partnerReducer;