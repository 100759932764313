import moment from 'moment';
import React, { Component } from 'react';
import ReactToPrint from "react-to-print";
import { currency } from '../../../client-config'
import './fbrInvoice.css'
var QRCode = require('qrcode.react');
class Invoice extends Component<{ data: any, orderId: any }, {}> {
    componentRef: any;
    calcSubTotalTotal = (orderItems: any) => {
        let sum = 0;
        orderItems.forEach((item: any) => {
            sum += parseInt(item.order_item_grossprice);
        });
        return Math.round(sum);
    };
    calcTotal = (orderItems: any, orderDetail: any) => {
        let sum = 0;
        orderItems.forEach((item: any) => {
            sum += parseInt(item.order_item_grossprice);
        });
        if (orderDetail.order_channel == "FoodPanda" && orderDetail.discount > 0) {
            sum = sum - orderDetail.discount
        }
        return Math.round(sum);
    };
    render() {
        let { data, orderId } = this.props;
        // console.log("data.orderDetail.fbr_invoice_number",data.orderDetail.fbr_invoice_number);
        let DateCreated: any = "";
        let FutureDate: any = "";
        let utcDateTime: any = "";
        let FbrNumber: any = "";
        let name: any = "";
        let FbrNumberComplete: any = "";
        if (data.orderDetail) {
            DateCreated = moment(data.orderDetail.date_created).local().format('YYYY-MM-DD HH:mm').split(' ');
            DateCreated = DateCreated[1] + " , " + DateCreated[0];

            if (data.orderDetail.fbr_invoice_number != undefined) {
                FbrNumberComplete = data.orderDetail.fbr_invoice_number;
                FbrNumber = FbrNumberComplete.split(':');
                name = FbrNumber[0];
                FbrNumber = FbrNumber[1];
                console.log("FbrNumber", FbrNumber);
            }
        }
        if (data.orderDetail && data.orderDetail.future_date) {
            utcDateTime = moment(data.orderDetail.future_date).utc(false);
            FutureDate = moment(utcDateTime).local().format('YYYY-MM-DD HH:mm').split(' ');
            FutureDate = FutureDate[1] + " , " + FutureDate[0];
        }
        return (
            <div>
                <div className="d-flex justify-content-end ">
                    {
                        data.orderDetail.order_status_code !== 1 &&
                        <ReactToPrint
                            trigger={() => <button type="button" className="btn btn-success">Print</button>}
                            content={() => this.componentRef}
                        />
                    }
                </div>
                <div style={{ width: '120mm' }} ref={el => (this.componentRef = el)}>
                    <header className="clearfix">
                        <div id="logo">
                            <img width={143} height={132} style={{ borderRadius: 10 }} src={process.env.PUBLIC_URL + "/assets/img/cc_logo.png"} />
                        </div>
                        <h4 className="text-center mt-3 change_color"><b>Crust Culture</b></h4>
                        <h6 className="heading_text text-center mt-3 change_color" >{data.orderDetail.store_name}</h6>
                        <h6 className="heading_text text-center mt-3 change_color">{data.orderDetail.address}</h6>
                        <h6 className="heading_text text-center mt-3 change_color">{(data.orderDetail.business_type_id == 1 && data.orderDetail.franchise_name && data.orderDetail.franchise_name !== "") ? data.orderDetail.franchise_name : ''}</h6>
                        <h6 className="heading_text text-center mt-3 change_color">NTN # {data.orderDetail.ntn_number}</h6>
                        {(FbrNumber && FbrNumber !== "") && <h6 className="heading_text text-center mt-3 change_color">POS NO: {data.orderDetail.pos_no}</h6>}

                        <div id="project">
                            {/* <h3>Customer Information</h3> */}
                            <div style={{ display: 'flex' }}><span><b>ORDER NO:</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto', marginLeft: 40 }}> {data.orderDetail && data.orderDetail.fiscal_serial_number == null || data.orderDetail.fiscal_serial_number == "" ? orderId : data.orderDetail.fiscal_serial_number}</span></div>
                            <div style={{ display: 'flex' }}><span><b>Order Date:</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto', marginLeft: 40 }}> {data.orderDetail && DateCreated}</span></div>
                            <div style={{ display: 'flex' }}><span><b>Customer:</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto', marginLeft: 40 }}> {data.orderDetail && data.orderDetail.login_name}</span></div>
                            {(data.orderDetail && data.orderDetail.delivery_address) && <div style={{ display: 'flex' }}><span><b>Address:</b> </span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto', marginLeft: 40 }}> {data.orderDetail.delivery_address}</span></div>}
                            {(data.orderDetail && data.orderDetail.phone_number) && <div style={{ display: 'flex' }}><span><b>Phone:</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto', marginLeft: 40 }}> {data.orderDetail.phone_number}</span></div>}
                            <div style={{ display: 'flex' }}><span><b>Payment Mode: </b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto', marginLeft: 40 }}>{data.orderDetail && data.orderDetail.payment_method}</span></div>
                            <div style={{ display: 'flex' }}><span><b>Ordering Mode: </b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto', marginLeft: 40, textTransform: 'capitalize' }}>{data.orderDetail && data.orderDetail.order_channel}</span></div>
                            {data.orderDetail && data.orderDetail.delivery_status === "Delivery" && <div style={{ display: 'flex' }}><span><b>Promise Time: </b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto', marginLeft: 40, textTransform: 'capitalize' }}>{data.orderDetail && data.orderDetail.expected_delivery_time} mins</span></div>}
                            {/* {(data.orderDetail && data.orderDetail.failure_reason) && <div style={{ display: 'flex' }}><span><b>Pulse Status:</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto', marginLeft: 40 }}> {data.orderDetail.failure_reason}</span></div>} */}
                            {(data.orderDetail && data.orderDetail.special_inst) && <div style={{ display: 'flex' }}><span><b>Special Ins: </b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto', marginLeft: 40 }}>{data.orderDetail && data.orderDetail.special_inst}</span></div>}
                            {(data.orderDetail.order_status_code === 6 || data.orderDetail.order_status_code === 7 || data.orderDetail.order_status_code === 8) && <div style={{ display: 'flex' }}><span><b>Status:</b></span> <span style={{ whiteSpace: "normal", textAlign: 'left', width: "80px", textTransform: 'capitalize', marginLeft: 40 }}>{data.orderDetail.order_status_description}</span></div>}
                            {utcDateTime._isValid && <div><span><b>{data.orderDetail && data.orderDetail.delivery_status === "Delivery" ? 'Delivery' : 'Pickup'} Time:</b></span><br /><span>{FutureDate}</span></div>}
                        </div>
                    </header>
                    <main>
                        <table className="invoicetable table table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ width: 40 }}>#</th>
                                    <th style={{ width: 135 }}>DESC</th>
                                    <th style={{ width: 70, overflow: 'unset' }}>PRICE</th>
                                    <th style={{ width: 70, overflow: 'unset' }}>TAX %</th>
                                    <th style={{ width: 40, overflow: 'unset' }}>QTY</th>
                                    <th style={{ width: 60, overflow: 'unset' }}>GST</th>
                                    <th style={{ width: 60, overflow: 'unset' }}>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.Items.map((obj: any, index: any) => {
                                    let AddOnsPrice: any = 0;
                                    let AddOnsTax: any = 0;
                                    let HalfNHalfAddOnsPrice: any = 0;
                                    let HalfNHalfAddOnsTax: any = 0;
                                    let comboChoicesPrice: any = 0;
                                    let comboChoiceTax: any = 0;
                                    let qcPrice: any = 0
                                    obj.quick_combos && obj.quick_combos.map((qc: any) => {
                                        qcPrice = qcPrice + (qc.mrp * qc.quantity)
                                    })
                                    const ingredients = obj.combo_choices && JSON.parse(obj.combo_choices).map((element: any) => {
                                        return <>
                                            <b className='mb-0'>{element.group_name}</b>

                                            {element.is_full_pizza == 1 ? JSON.parse(element.modifiers).map((modifier: any, index: any) => {
                                                return (
                                                    <ul className='mb-0'>
                                                        <li>
                                                            <p>{modifier.item_name} {modifier.modifier_sale_price > 0 && `(+${currency} ${modifier.modifier_sale_price * modifier.quantity})`} </p>
                                                        </li>
                                                    </ul>
                                                )
                                            })
                                                //  For Half N Half Modifiers
                                                : element.is_half_n_half == 1 ?
                                                    <>
                                                        {element.modifiers && JSON.parse(element.modifiers).map(
                                                            (mod: any, index: any) => {
                                                                return (
                                                                    <ul className='mb-0'>
                                                                        <li>
                                                                            <p>{mod.modifier_name} {mod.modifier_sale_price > 0 && `+${currency} ${mod.modifier_sale_price * mod.quantity}`} </p>
                                                                        </li>
                                                                    </ul>
                                                                )
                                                            }
                                                            //  For Half N Half Modifiers 
                                                        )}
                                                        {(element.half_n_half_modifiers) &&
                                                            JSON.parse(element.half_n_half_modifiers).map((obj: any, modIndex: any) => {
                                                                return <div>
                                                                    <b>{(modIndex == 0 && obj.ItemModifierPart == 1) ? <p>1st Half </p> : (modIndex == 0 && obj.ItemModifierPart == 2) && <p>2nd Half</p>}</b>
                                                                    {obj.data.map((modifier: any, index: any) => {
                                                                        return (
                                                                            <ul className='mb-0'>
                                                                                <li>
                                                                                    <p>{modifier.modifier_name} {modifier.modifier_sale_price > 0 && `(+${currency} ${modifier.modifier_sale_price * modifier.quantity})`} </p>
                                                                                </li>
                                                                            </ul>
                                                                        )
                                                                    }
                                                                    )
                                                                    }
                                                                </div>
                                                            })}
                                                    </>
                                                    : element.modifiers && JSON.parse(element.modifiers).map((modifier: any, index: any) => {
                                                        return <ul className='mb-0'><li>
                                                            <p>{modifier.modifier_name} {modifier.modifier_sale_price > 0 && `(+${currency} ${modifier.modifier_sale_price * modifier.quantity})`} </p>
                                                        </li>
                                                        </ul>
                                                    })
                                            }
                                        </>
                                    })
                                    return (
                                        <>
                                            <tr title={(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size})`)}>
                                                <td style={{ whiteSpace: 'normal' }} colSpan={7}>
                                                    <b>
                                                        {(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size})`)}
                                                    </b>
                                                </td>
                                            </tr>

                                            <tr key={index}>
                                                <td title={`${index + 1}`}>{index + 1}</td>
                                                <td style={{ padding: 10, width: 150 }}>
                                                    <span style={{ whiteSpace: 'normal' }}>
                                                        {ingredients}
                                                        <br />
                                                        <b>{(obj.modifiers && JSON.parse(obj.modifiers).length > 0) && "Add Ons"}</b>
                                                    </span>
                                                </td>
                                                <td>{obj.modifiers && JSON.parse(obj.modifiers).map((modifier: any) => {
                                                    if ((modifier.discount_price || modifier.discount_price == 0)) {
                                                        if (data.orderDetail.coupon && JSON.parse(data.orderDetail.coupon).discount_type!=="combo") {
                                                            AddOnsPrice += (modifier.quantity * modifier.modifier_sale_price);
                                                        } else {
                                                            AddOnsPrice += (modifier.quantity * Math.round(modifier.discount_price));
                                                        }
                                                    } else {
                                                        AddOnsPrice += (modifier.quantity * modifier.modifier_sale_price);
                                                    }
                                                    AddOnsTax += modifier.tax_amount * modifier.quantity;
                                                })}
                                                    {(obj.is_half_n_half == 1 && obj.half_n_half_modifiers) && JSON.parse(obj.half_n_half_modifiers).map((modifier: any) => {
                                                        modifier.data.map((item: any, modIndex: any) => {

                                                            if ((item.discount_price || item.discount_price == 0)) {
                                                                if (data.orderDetail.coupon && JSON.parse(data.orderDetail.coupon).discount_type!=="combo") {
                                                                    HalfNHalfAddOnsPrice += (item.quantity * item.modifier_sale_price);
                                                                } else {
                                                                    HalfNHalfAddOnsPrice += (item.quantity * Math.round(item.discount_price));
                                                                }
                                                            } else {
                                                                HalfNHalfAddOnsPrice += (item.quantity * item.modifier_sale_price);
                                                            }
                                                            HalfNHalfAddOnsTax += item.tax_amount * item.quantity;
                                                        })
                                                    })}
                                                    {data.orderDetail.tax_type == 1 ?
                                                        `${currency} ${obj.order_item_grossprice - AddOnsPrice - HalfNHalfAddOnsPrice}` :
                                                        `${currency} ${Math.round(obj.orderitem_net_pricewtax)}`
                                                    }
                                                </td>
                                                <td title={`${obj.tax_percent}`}>{obj.tax_percent}%</td>
                                                <td title={`${obj.order_item_quantity}`}>{obj.order_item_quantity}</td>
                                                <td title={`${(obj.tax_amount - AddOnsTax - HalfNHalfAddOnsTax).toFixed(2)}`}>{(obj.tax_amount - AddOnsTax - HalfNHalfAddOnsTax).toFixed(2)}</td>
                                                <td title={data.orderDetail.tax_type == 1 ? `${currency} ${obj.order_item_grossprice + Math.round((obj.tax_amount - AddOnsTax)) - AddOnsPrice - HalfNHalfAddOnsPrice - qcPrice}` : `${currency} ${obj.order_item_grossprice - AddOnsPrice - HalfNHalfAddOnsPrice - qcPrice}`}>{data.orderDetail.tax_type == 1 ? `${currency} ${obj.order_item_grossprice + Math.round((obj.tax_amount - AddOnsTax)) - AddOnsPrice - HalfNHalfAddOnsPrice - qcPrice}` : `${currency} ${obj.order_item_grossprice - AddOnsPrice - HalfNHalfAddOnsPrice - qcPrice}`}</td>
                                            </tr>
                                            {
                                                obj.modifiers && JSON.parse(obj.modifiers).map((modifier: any) => {
                                                    let AddOnsPrice: any = 0;
                                                    if ((modifier.discount_price || modifier.discount_price == 0)) {
                                                        if (data.orderDetail.coupon && JSON.parse(data.orderDetail.coupon).discount_type!=="combo") {
                                                            AddOnsPrice += (modifier.quantity * modifier.modifier_sale_price);
                                                        } else {
                                                            AddOnsPrice += (modifier.quantity * Math.round(modifier.discount_price));
                                                        }
                                                    } else {
                                                        AddOnsPrice += (modifier.quantity * modifier.modifier_sale_price);
                                                    }
                                                    return <tr>

                                                        <td></td>
                                                        <td style={{ textAlign: "left" }}>
                                                            <b>{modifier.quantity} </b>{modifier.modifier_name}
                                                        </td>
                                                        <td title={data.orderDetail.tax_type == 1 ? `${currency} ${(modifier.modifier_sale_price * modifier.quantity)}` : `${currency} ${(AddOnsPrice) - (Math.round(modifier.tax_amount * modifier.quantity))}`}>
                                                            {data.orderDetail.tax_type == 1 ? `${currency} ${(modifier.modifier_sale_price * modifier.quantity)}` : `${currency} ${(AddOnsPrice) - (Math.round(modifier.tax_amount * modifier.quantity))}`}
                                                        </td>
                                                        <td title={`${modifier.tax_percent}`}>{modifier.tax_percent}%</td>
                                                        <td title={`${modifier.quantity}`}>{modifier.quantity}</td>
                                                        <td title={`${(modifier.tax_amount * modifier.quantity).toFixed(2)}`}>{(modifier.tax_amount * modifier.quantity).toFixed(2)}</td>
                                                        <td title={data.orderDetail.tax_type == 1 ? `${currency} ${(AddOnsPrice * modifier.quantity) + Math.round(modifier.tax_amount * modifier.quantity)}` : `${currency} ${(AddOnsPrice)}`}>
                                                            {data.orderDetail.tax_type == 1 ? `${currency} ${(AddOnsPrice * modifier.quantity) + Math.round(modifier.tax_amount * modifier.quantity)}` : `${currency} ${(AddOnsPrice)}`}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            {(obj.is_half_n_half == 1 && obj.half_n_half_modifiers) && JSON.parse(obj.half_n_half_modifiers).map((modifier: any) => {
                                                return modifier.data.map((item: any, modIndex: any) => {
                                                    let AddOnsPrice: any = 0;
                                                    if ((item.discount_price || item.discount_price == 0)) {
                                                        if (data.orderDetail.coupon && JSON.parse(data.orderDetail.coupon).discount_type!=="combo") {
                                                            AddOnsPrice += (item.quantity * item.modifier_sale_price);
                                                        } else {
                                                            AddOnsPrice += (item.quantity * Math.round(item.discount_price));
                                                        }
                                                    } else {
                                                        AddOnsPrice += (item.quantity * item.modifier_sale_price);
                                                    }
                                                    return (
                                                        <tr>
                                                            <td></td>
                                                            <td style={{ textAlign: "left" }}>
                                                                <b>{(modIndex == 0 && modifier.ItemModifierPart == 1) ? <p>1st Half </p> : (modIndex == 0 && modifier.ItemModifierPart == 2) && <p>2nd Half</p>}</b>
                                                                <b>{item.quantity} </b>{item.modifier_name}
                                                            </td>
                                                            <td title={data.orderDetail.tax_type == 1 ? `${currency} ${(item.modifier_sale_price * item.quantity)}` : `${currency} ${(AddOnsPrice) - (Math.round(item.tax_amount * item.quantity))}`}>
                                                                {data.orderDetail.tax_type == 1 ? `${currency} ${(item.modifier_sale_price * item.quantity)}` : `${currency} ${(AddOnsPrice) - (Math.round(item.tax_amount * item.quantity))}`}
                                                            </td>
                                                            <td title={`${item.tax_percent}`}>{item.tax_percent}%</td>
                                                            <td title={`${item.quantity}`}>{item.quantity}</td>
                                                            <td title={`${(item.tax_amount * item.quantity).toFixed(2)}`}>{(item.tax_amount * item.quantity).toFixed(2)}</td>
                                                            <td title={data.orderDetail.tax_type == 1 ? `${currency} ${(AddOnsPrice * item.quantity) + Math.round(item.tax_amount * item.quantity)}` : `${currency} ${(AddOnsPrice)}`}>
                                                                {data.orderDetail.tax_type == 1 ? `${currency} ${(AddOnsPrice * item.quantity) + Math.round(item.tax_amount * item.quantity)}` : `${currency} ${(AddOnsPrice)}`}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            })
                                            }

                                            {obj.quick_combos && obj.quick_combos.length > 0 && <tr title="Quick Combos">
                                                <td style={{ whiteSpace: 'normal' }} colSpan={7}>
                                                    <b>
                                                        Make a combo
                                                    </b>
                                                </td>
                                            </tr>}
                                            {
                                                obj.quick_combos && obj.quick_combos.length > 0 && obj.quick_combos.map((element: any) => {
                                                    return <tr>
                                                        <td></td>
                                                        <td style={{ textAlign: "left" }}>
                                                            <b>{element.quantity} </b>{element.item_name} ({element.size})
                                                        </td>
                                                        <td>{currency} {element.mrp - element.tax_amount}</td>
                                                        <td title={`${element.tax_percent}`}>{element.tax_percent}%</td>
                                                        <td title={`${element.quantity}`}>{element.quantity}</td>
                                                        <td title={`${element.tax_amount}`}>{element.tax_amount}</td>
                                                        <td title={`${(element.mrp * element.quantity)}`}>{currency} {(element.mrp * element.quantity)}</td>
                                                        {/* <td title={data.orderDetail.tax_type == 1 ? `${currency} ${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount * element.quantity)}` : `${currency} ${(element.modifier_sale_price * element.quantity)}`}>{data.orderDetail.tax_type == 1 ? `${currency} ${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount * element.quantity)}` : `${currency} ${(element.modifier_sale_price * element.quantity)}`}</td> */}
                                                    </tr>
                                                })
                                            }
                                        </>
                                    )
                                })}
                                <tr>
                                    <td style={{ border: 'none' }}></td>
                                    <td style={{ border: 'none' }}></td>
                                    <td colSpan={3} style={{ textAlign: 'left' }} className="total">Total Net Amount</td>
                                    <td colSpan={2} className="total" title={`${currency} ${data.Items.length > 0 && this.calcSubTotalTotal(data.Items)}`}>{currency} {data.Items.length > 0 && this.calcSubTotalTotal(data.Items)}</td>
                                </tr>
                                {data.orderDetail.coupon && JSON.parse(data.orderDetail.coupon).discount_type!=="combo" &&
                                    <tr>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td colSpan={3} style={{ textAlign: 'left' }} className="discount total">Discount/Voucher</td>
                                        <td colSpan={2} className="discount total" title={`${currency} ${Math.round(data.orderDetail.coupon_discount_value)}`}>{currency} {Math.round(data.orderDetail.coupon_discount_value)} </td> {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                                    </tr>
                                }
                                {
                                    data.orderDetail.discount > 0 &&
                                    <tr>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td colSpan={3} style={{ textAlign: 'left' }} className="discount total">Discount</td>
                                        <td colSpan={2} className="discount total" title={`${currency} ${(data.orderDetail.discount).toFixed(1)}`}>{currency} {(data.orderDetail.discount).toFixed(1)} </td>
                                    </tr>
                                }
                                {(data.orderDetail && data.orderDetail.delivery_status == "Delivery") && <tr>
                                    <td style={{ border: 'none' }}></td>
                                    <td style={{ border: 'none' }}></td>
                                    <td colSpan={3} style={{ textAlign: 'left' }} className="total">Delivery Charges</td>
                                    <td colSpan={2} className="total" title={`${currency} ${data.orderDetail && data.orderDetail.delivery_fee}`}>{currency} {data.orderDetail && data.orderDetail.delivery_fee}</td>
                                </tr>
                                }
                                <tr>
                                    <td style={{ border: 'none' }}></td>
                                    <td style={{ border: 'none' }}></td>
                                    <td colSpan={3} style={{ textAlign: 'left' }} className="total">{data.orderDetail.tax_percent}% Sales Tax</td>
                                    <td colSpan={2} className="total" title={`${currency} ${data.orderDetail.tax_amount}`}>{currency} {data.orderDetail.tax_amount} </td> {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                                </tr>
                                {data.orderDetail.pos_service_fee > 0 &&
                                    <>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td colSpan={3} style={{ textAlign: 'left' }} className="total">Pos Service Fee</td>
                                        <td colSpan={2} className="total" title={`${currency} ${data.orderDetail.pos_service_fee}`}>{currency} {data.orderDetail.pos_service_fee}</td>
                                    </>
                                }
                                {data.orderDetail.aggregator_platform_fees > 0 &&
                                    <>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td colSpan={3} style={{ textAlign: 'left' }} className="total">FP Fee</td>
                                        <td colSpan={2} className="total" title={`${currency} ${data.orderDetail.aggregator_platform_fees}`}>{currency} {data.orderDetail.aggregator_platform_fees}</td>
                                    </>
                                }
                                <tr>
                                    <td style={{ border: 'none' }}></td>
                                    <td style={{ border: 'none' }}></td>
                                    <td colSpan={3} style={{ textAlign: 'left' }} className="grand total"><b>Payable</b></td>
                                    <td colSpan={2} className="grand total" title={data.orderDetail.tax_type == 1 ? `${currency} ${(data.Items.length > 0 && data.orderDetail) && (this.calcTotal(data.Items,data.orderDetail) - data.orderDetail.coupon_discount_value) + data.orderDetail.delivery_fee + Math.round(data.orderDetail.tax_amount) + data.orderDetail.pos_service_fee + data.orderDetail.aggregator_platform_fees}` : `${currency} ${(data.Items.length > 0 && data.orderDetail) && (this.calcTotal(data.Items,data.orderDetail) - data.orderDetail.coupon_discount_value) + data.orderDetail.delivery_fee + data.orderDetail.pos_service_fee + data.orderDetail.aggregator_platform_fees}`}><b>{data.orderDetail.tax_type == 1 ? `${currency} ${(data.Items.length > 0 && data.orderDetail) && (this.calcTotal(data.Items,data.orderDetail) - data.orderDetail.coupon_discount_value) + data.orderDetail.delivery_fee + Math.round(data.orderDetail.tax_amount) + data.orderDetail.pos_service_fee + data.orderDetail.aggregator_platform_fees}` : `${currency} ${(data.Items.length > 0 && data.orderDetail) && (this.calcTotal(data.Items,data.orderDetail) - data.orderDetail.coupon_discount_value) + data.orderDetail.delivery_fee + data.orderDetail.pos_service_fee + data.orderDetail.aggregator_platform_fees}`}</b> </td>
                                </tr>
                            </tbody>
                        </table>
                        {FbrNumber &&
                            <>
                                <p className="text-center my-4 change_color"><span><b style={{ fontSize: "13px" }}>{name}# </b>{FbrNumber}{moment(data.orderDetail.date_created).local().format('/MM/YYYY').split(' ')}</span></p>
                                <div className="d-flex justify-content-around">
                                    {name.includes("FBR") && <div><img width={100} height={100} style={{ borderRadius: 10 }} src={process.env.PUBLIC_URL + "/assets/img/FBR_IMG.png"} /></div>}
                                    <div><QRCode size='100' value={FbrNumber} /></div>
                                </div>
                            </>
                        }
                        <p className="text-center mt-3 mb-0 change_color">Crust Culture</p>
                        <p className="text-center mt-3 change_color">https://crustculture.com.pk/</p>
                        <h6 className="text-center mt-3 change_color">{data.orderDetail.contact1}</h6>
                        {name.includes("FBR") && <footer className="invoicefooter">
                            "Verify this invoice through FBR TaxAsan Mobile App or SMS at 9966 and win excisting prize in draw"
                        </footer>
                        }
                    </main>
                </div>
            </div>
        )
    }
}
export default Invoice;