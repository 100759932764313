import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import jwt from 'jsonwebtoken'
class Sidebar extends Component<{}, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    render() {
        let page = window.location.pathname.split('/');
        let name = localStorage.name;
        let tokendata: any = jwt.decode(localStorage.token)
        let roleId = tokendata.role_id;
        let features: any = {};
        if (localStorage.token) {
            features = tokendata.features ? JSON.parse(tokendata.features) : tokendata.features;
        }
        let allowedRoutes = (
            // {/* <!-- Sidebar Navidation Menus--> */}
            <>
                <li className={page[1] === 'dashboard' ? 'active' : ''}><Link to="/dashboard"> <i className="fa fa-home"></i>Dashboard</Link></li>
                <li className={page[1] === 'stores' || page[1] === 'add-store' || page[1] === 'edit-store' ? 'active' : ''}><Link to="/stores"> <i className="fa fa-shopping-basket"></i>Stores</Link></li>
                <li className={page[1] === 'branchmenu' ? 'active' : ''}><Link to="/branchmenu"> <i className="fa fa-bars"></i>Branch Menu</Link></li>
                <li><a href="#menuDropDown" aria-expanded="false" data-toggle="collapse"> <i className="fa fa-bars"></i>Menu</a>
                    <ul id="menuDropDown" className={page[1] === 'menus' || page[1] === 'add-menu' || page[1] === 'edit-menu' ||
                        page[1] === 'groups' || page[1] === 'add-group' || page[1] === 'edit-group' ||
                        page[1] === 'sub-groups' || page[1] === 'add-sub-group' || page[1] === 'edit-sub-group' ||
                        page[1] === 'menu-items' || page[1] === 'add-item' || page[1] === 'edit-item' ||
                        page[1] === 'combos' || page[1] === 'add-combo' || page[1] === 'edit-combo' ||
                        page[1] === 'mod-groups' || page[1] === 'add-modgroup' || page[1] === 'edit-modgroup' ||
                        page[1] === 'modifiers' || page[1] === 'add-modifier' || page[1] === 'edit-modifier' ||
                        page[1] === 'banners' || page[1] === 'add-banner' || page[1] === 'edit-banner' || page[1] === 'add-make-a-combo' || page[1] === 'make-a-combo' || page[1] === 'edit-make-a-combo' ||
                        page[1] === 'nut-categories' || page[1] === 'add-nut-categories' || page[1] === 'edit-nut-categories' || page[1] === 'nut-items' || page[1] === 'add-nut-items' || page[1] === 'edit-nut-items' || page[1] === 'suggestiveCombos' || page[1] === 'attachSuggestiveCombos' || page[1] === 'editSuggestiveCombos'||
                        page[1] === 'fp-modifiers'|| page[1] === 'view-fp-modifier' ||  page[1] === 'fp-schedule' ? '' : 'collapse list-unstyled'}>
                        <li className={page[1] === 'menus' || page[1] === 'add-menu' || page[1] === 'edit-menu' ? 'active' : ''}><Link to="/menus">Menus</Link></li>
                        <li className={page[1] === 'groups' || page[1] === 'add-group' || page[1] === 'edit-group' ? 'active' : ''}><Link to="/groups">Groups</Link></li>
                        <li className={page[1] === 'sub-groups' || page[1] === 'add-sub-group' || page[1] === 'edit-sub-group' ? 'active' : ''}><Link to="/sub-groups">Sub Groups</Link></li>
                        <li className={page[1] === 'menu-items' || page[1] === 'add-item' || page[1] === 'edit-item' ? 'active' : ''}><Link to="/menu-items">Items</Link></li>
                        <li className={page[1] === 'combos' || page[1] === 'add-combo' || page[1] === 'edit-combo' ? 'active' : ''}><Link to="/combos">Combo</Link></li>
                        {/* <li className={page[1] === 'suggestiveCombos' || page[1] === 'attachSuggestiveCombos' || page[1] === 'editSuggestiveCombos'  ? 'active' : ''}><Link to="/suggestiveCombos">Attach Suggestive Combos</Link></li> */}
                        {/* <li className={page[1] === 'edit-make-a-combo' || page[1] === 'make-a-combo' || page[1] === 'add-make-a-combo' ? 'active' : ''}><Link to="/make-a-combo">Make A Combo</Link></li> */}
                        <li className={page[1] === 'mod-groups' || page[1] === 'add-modgroup' || page[1] === 'edit-modgroup' ? 'active' : ''}><Link to="/mod-groups">Modifier Groups</Link></li>
                        <li className={page[1] === 'modifiers' || page[1] === 'add-modifier' || page[1] === 'edit-modifier' ? 'active' : ''}><Link to="/modifiers">Modifiers</Link></li>
                        {/* <li className={page[1] === 'fp-modifiers'|| page[1] === 'view-fp-modifier' ? 'active' : ''}><Link to="/fp-modifiers">FP Modifiers</Link></li>
                        <li className={page[1] === 'fp-menu-logs' ? 'active' : ''}><Link to="/fp-menu-logs">FP Menu Logs</Link></li>
                        <li className={page[1] === 'fp-schedule' ? 'active' : ''}><Link to="/fp-schedule">FP Schedules</Link></li> */}
                        <li className={page[1] === 'banners' || page[1] === 'add-banner' || page[1] === 'edit-banner' ? 'active' : ''}><Link to="/banners">Banners</Link></li>
                        {/* <li className={page[1] === 'nut-categories' || page[1] === 'add-nut-categories' || page[1] === 'edit-nut-categories' ? 'active' : ''}><Link to="/nut-categories">Nutritional Categories</Link></li>
                        <li className={page[1] === 'nut-items' || page[1] === 'add-nut-items' || page[1] === 'edit-nut-items' ? 'active' : ''}><Link to="/nut-items">Nutritional Items</Link></li> */}
                    </ul>
                </li>
                <li>
                    <a href="#dineInDropDown" aria-expanded="false" data-toggle="collapse"> 
                        <i className="fa fa-table"></i>Dine In
                    </a>
                    <ul id="dineInDropDown" className={page[1] === 'tables' || page[1] === 'add-table' || page[1] === 'store-table' || page[1] === 'edit-store-table' ? '' : 'collapse list-unstyled'}>
                        <li className={page[1] === 'tables' || page[1] === 'add-table' ? 'active' : ''}>
                            <Link to="/tables">Tables</Link>
                        </li>
                        <li className={page[1] === 'store-table' || page[1] === 'edit-store-table' ? 'active' : ''}>
                            <Link to="/store-table">Store Table</Link>
                        </li>
                    </ul>
                </li>
                <li className={page[1] === 'brands' || page[1] === 'add-brand' || page[1] === 'edit-brand' ? 'active' : ''}><Link to="/brands"> <i className="fa fa-font-awesome"></i>Brands</Link></li>
                <li>
                    <a href="#customerDropDown" aria-expanded="false" data-toggle="collapse"> <i className="fa fa-users"></i>Customers</a>
                    <ul id="customerDropDown" className={page[1] === 'customers' || page[1] === 'feedbacks' || page[1] === 'employees' || page[1] === 'complaints' || page[1] === 'guests' ? '' : 'collapse list-unstyled'}>
                        <li className={page[1] === 'customers' ? 'active' : ''}><Link to="/customers">Registered Customers</Link></li>
                        {/* <li className={page[1] === 'block-customers' ? 'active' : ''}><Link to="/block-customers">Blocked Customers</Link></li> */}
                        {/* <li className={page[1] === 'guests' ? 'active' : ''}><Link to="/guests">Guest Customers</Link></li> */}
                        <li className={page[1] === 'feedbacks' ? 'active' : ''}><Link to="/feedbacks">Customers Review</Link></li>
                        <li className={page[1] === 'complaints' ? 'active' : ''}><Link to="/complaints">Customers Feedback</Link></li>
                        <li className={page[1] === 'employees' ? 'active' : ''}><Link to="/employees">Employees</Link></li>
                        <li className={page[1] === 'nps_feedback' ? 'active' : ''}><Link to="/nps_feedback">NPS Feedback</Link></li>
                        {/* <li className={page[1] === 'partners' ? 'active' : ''}><Link to="/partners">Business Partners</Link></li> */}
                        {/* <li className={page[1] === 'franchise' ? 'active' : ''}><Link to="/franchise">Franchise Applications</Link></li> */}
                        {/* <li className={page[1] === 'newsletter' ? 'active' : ''}><Link to="/newsletter">NewsLetter Emails</Link></li> */}
                    </ul>
                </li>
                <li>
                    <a href="#marketingDropDown" aria-expanded="false" data-toggle="collapse"> <i className="fa fa-tag"></i>Marketing</a>
                    <ul id="marketingDropDown" className={page[1] === 'marketing' || page[1] === 'add-coupon' || page[1] === 'edit-coupon'
                        || page[1] === 'add-promo' || page[1] === 'edit-promo'
                        || page[1] === 'add-promo' || page[1] === 'edit-promo'|| page[1] === 'add-fp-discount' || page[1] === 'edit-fp-discount' || page[1] === 'fpDiscount'
                        || page[1] === 'add-discount' || page[1] === 'edit-discount' || page[1] === 'alliances' || page[1] === 'bulkCoupons' ? '' : 'collapse list-unstyled'}>
                        <li className={page[1] === 'marketing' ? 'active' : ''}><Link to="/marketing/0">Marketing</Link></li>
                        <li className={page[1] === 'bulkCoupons' ? 'active' : ''}><Link to="/bulkCoupons">Upload Bulk Coupons</Link></li>
                        <li className={page[1] === 'fpDiscount' ||page[1] === 'add-fp-discount' || page[1] === 'edit-fp-discount' ? 'active' : ''}><Link to="/fpDiscount">FoodPanda Discounts</Link></li>
                        <li className={page[1] === 'alliances' ? 'active' : ''}><Link to="/alliances">Alliances</Link></li>
                    </ul>
                </li>
                <li>
                    <a href="#ordersDropDown" aria-expanded="false" data-toggle="collapse"> <i className="fa fa-shopping-cart"></i>Orders</a>
                    <ul id="ordersDropDown" className={page[1] === 'orders'|| page[1] === 'card-orders' || page[1] === 'foodpanda-orders' || page[1] === 'outbound_contacts' ? '' : 'collapse list-unstyled'}>
                        <li className={page[1] === 'orders' ? 'active' : ''}><Link to="/orders">Orders </Link></li>
                        <li className={page[1] === 'card-orders' ? 'active' : ''}><Link to="/card-orders">Card Orders </Link></li>
                        {/* <li className={page[1] === 'foodpanda-orders' ? 'active' : ''}><Link to="/foodpanda-orders">Foodpanda Orders </Link></li> */}
                        {/* <li className={page[1] === 'outbound_contacts' ? 'active' : ''}><Link to="/outbound_contacts">OutBound Contacts</Link></li> */}
                    </ul>
                </li>
                <li className={page[1] === 'payments' ? 'active' : ''}><Link to="/payments"> <i className="fa fa-credit-card"></i>Payments</Link></li>
                {/* <li className={page[1] === 'bulk' ? 'active' : ''}><Link to="/bulk"> <i className="fa fa-upload"></i>Bulk Upload</Link></li> */}
                {/* <li className={page[1] === 'addressBulk' ? 'active' : ''}><Link to="/addressBulk"> <i className="fa fa-upload"></i>Address Upload</Link></li> */}
                <li className={page[1] === 'analytics' ? 'active' : ''}><Link to="/analytics"> <i className="fa fa-file-pdf-o"></i>Analytics</Link></li>
                <li><a href="#securityDropDown" aria-expanded="false" data-toggle="collapse"> <i className="fa fa-lock"></i>Security </a>
                    <ul id="securityDropDown" className={page[1] === 'users' || page[1] === 'add-user' || page[1] === 'edit-user' ||
                        page[1] === 'usergroups' || page[1] === 'add-usergroup' || page[1] === 'edit-usergroup' ||
                        page[1] === 'roles' || page[1] === 'add-role' || page[1] === 'edit-role' || page[1] === 'access-manage'
                        ? '' : 'collapse list-unstyled'}>
                        <li className={page[1] === 'users' || page[1] === 'add-user' || page[1] === 'edit-user' ? 'active' : ''}><Link to="/users"><i className="fa fa-user"></i>Users</Link></li>
                        <li className={page[1] === 'usergroups' || page[1] === 'add-usergroup' || page[1] === 'edit-usergroup' ? 'active' : ''}><Link to="/usergroups"><i className="fa fa-users"></i>Groups</Link></li>
                        <li className={page[1] === 'roles' || page[1] === 'add-role' || page[1] === 'edit-role' ? 'active' : ''}><Link to="/roles"><i className="fa fa-user-plus"></i>Roles</Link></li>
                        <li className={page[1] === 'access-manage' ? 'active' : ''}><Link to="/access-manage"> <i className="fa fa-cog"></i>I A M</Link></li>
                    </ul>
                </li>
                <li><a href="#settingsDropDown" aria-expanded="false" data-toggle="collapse"> <i className="fa fa-cogs"></i>Settings </a>
                    <ul id="settingsDropDown" className={page[1] === 'settings' ||
                        page[1] === 'states' || page[1] === 'add-state' || page[1] === 'edit-state' ||
                        page[1] === 'cities' || page[1] === 'add-cities' || page[1] === 'edit-cities' ||
                        page[1] === 'reasons' || page[1] === 'add-reason' || page[1] === 'edit-reason' || page[1] === 'drivers' ||
                        page[1] === 'nps' || page[1] === 'add-nps' || page[1] === 'edit-nps' ||
                        page[1] === 'kml' || page[1] === 'add-kml' || page[1] === 'edit-kml' ||
                        page[1] === 'tradezone' || page[1] === 'add-tradezone' || page[1] === 'edit-tradezone' || page[1] === "connectivity" ? '' : 'collapse list-unstyled'}>
                        <li className={page[1] === 'settings' ? 'active' : ''}><Link to="/settings"> <i className="fa fa-cog"></i>Global Settings</Link></li>
                        <li className={page[1] === 'states' || page[1] === 'add-state' || page[1] === 'edit-state' ? 'active' : ''}><Link to="/states"> <i className="fa fa-map-o"></i>States</Link></li>
                        <li className={page[1] === 'order-modes' ? 'active' : ''}><Link to="/order-modes"> <i className="fa fa-cog"></i>Order Modes</Link></li>
                        <li className={page[1] === 'cities' || page[1] === 'add-cities' || page[1] === 'edit-cities' ? 'active' : ''}><Link to="/cities"> <i className="fa fa-font-awesome"></i>Cities</Link></li>
                        <li className={page[1] === 'kml' || page[1] === 'add-kml' || page[1] === 'edit-kml' ? 'active' : ''}><Link to="/kml"> <i className="fa fa-map-pin"></i>KML Zones</Link></li>
                        {/* <li className={page[1] === 'tradezone' || page[1] === 'add-tradezone' || page[1] === 'edit-tradezone' ? 'active' : ''}><Link to="/tradezone"> <i className="fa fa-map-pin"></i>CallCenter Zones</Link></li> */}
                        <li className={page[1] === 'reasons' || page[1] === 'add-reason' || page[1] === 'edit-reason' ? 'active' : ''}><Link to="/reasons"><i className="fa fa-file-o"></i>Reasons</Link></li>
                        {/* <li className={page[1] === 'drivers' ? 'active' : ''}><Link to="/drivers"><i className="fa fa-truck"></i>Drivers</Link></li> */}
                        {/* <li className={page[1] === 'nps' || page[1] === 'add-nps' || page[1] === 'edit-nps' ? 'active' : ''}><Link to="/nps"><i className="fa fa-clipboard"></i>NPS</Link></li> */}
                        {/* <li className={page[1] === 'connectivity' ? 'active' : ''}><Link to="/connectivity"><i className="fa fa-clipboard"></i>Connectivity</Link></li> */}
                    </ul>
                </li>
                {/* <li><Link to="#"> <i className="fa fa-question-circle"></i>Faq</Link></li>
            <li><Link to="#"> <i className="fa fa-support"></i>Support</Link></li> */}
            </>
        )
        return (
            // <!-- Side Navbar -->
            <nav className="side-navbar">
                {/* <!-- Sidebar Header--> */}
                <div className="sidebar-header d-flex align-items-center">
                    <div className="avatar" style={{  borderRadius: 30, marginBottom: 7 }}>
                        <img src={process.env.PUBLIC_URL + "/assets/img/cc_logo.png"} alt="..." className="img-fluid rounded-circle" />
                    </div>
                    <div className="title">
                        <h1 className="h4">{name}</h1>
                    </div>
                </div>
                {/* <!-- Sidebar Navidation Menus--> */}
                <ul className="list-unstyled">
                    {(roleId == 1 || roleId == 2) ? allowedRoutes :
                        <>  {(features && features.feature1 && features.feature1.permission == 1) && <li className={page[1] === 'dashboard' ? 'active' : ''}><Link to="/dashboard"> <i className="fa fa-home"></i>Dashboard</Link></li>}
                            {(features && features.feature2 && features.feature2.permission == 1) && <>
                                <li className={page[1] === 'stores' || page[1] === 'add-store' || page[1] === 'edit-store' ? 'active' : ''}><Link to="/stores"> <i className="fa fa-shopping-basket"></i>Stores</Link></li>
                            </>}
                            {(features && features.feature3 && features.feature3.permission == 1) && <li className={page[1] === 'branchmenu' ? 'active' : ''}><Link to="/branchmenu"> <i className="fa fa-bars"></i>Branch Menu</Link></li>}
                            {(features && features.feature4 && features.feature4.permission == 1) && <li><a href="#menuDropDown" aria-expanded="false" data-toggle="collapse"> <i className="fa fa-bars"></i>Menu</a>
                                <ul id="menuDropDown" className={page[1] === 'menus' || page[1] === 'add-menu' || page[1] === 'edit-menu' ||
                                    page[1] === 'groups' || page[1] === 'add-group' || page[1] === 'edit-group' ||
                                    page[1] === 'sub-groups' || page[1] === 'add-sub-group' || page[1] === 'edit-sub-group' ||
                                    page[1] === 'menu-items' || page[1] === 'add-item' || page[1] === 'edit-item' ||
                                    page[1] === 'combos' || page[1] === 'add-combo' || page[1] === 'edit-combo' ||
                                    page[1] === 'mod-groups' || page[1] === 'add-modgroup' || page[1] === 'edit-modgroup' ||
                                    page[1] === 'modifiers' || page[1] === 'add-modifier' || page[1] === 'edit-modifier' ||
                                    page[1] === 'banners' || page[1] === 'add-banner' || page[1] === 'edit-banner' || page[1] === 'add-make-a-combo' || page[1] === 'make-a-combo' || page[1] === 'edit-make-a-combo' ||
                                    page[1] === 'nut-categories' || page[1] === 'add-nut-categories' || page[1] === 'edit-nut-categories' || page[1] === 'nut-items' || page[1] === 'add-nut-items' || page[1] === 'edit-nut-items' || page[1] === 'suggestiveCombos' || page[1] === 'attachSuggestiveCombos' || page[1] === 'editSuggestiveCombos' ||
                                    page[1] === 'fp-modifiers' ||  page[1] === 'fp-schedule' ? '' : 'collapse list-unstyled' 
                                
                                }
                                    
                                    
                                    >
                                    <li className={page[1] === 'menus' || page[1] === 'add-menu' || page[1] === 'edit-menu' ? 'active' : ''}><Link to="/menus">Menus</Link></li>
                                    <li className={page[1] === 'groups' || page[1] === 'add-group' || page[1] === 'edit-group' ? 'active' : ''}><Link to="/groups">Groups</Link></li>
                                    <li className={page[1] === 'sub-groups' || page[1] === 'add-sub-group' || page[1] === 'edit-sub-group' ? 'active' : ''}><Link to="/sub-groups">Sub Groups</Link></li>
                                    <li className={page[1] === 'menu-items' || page[1] === 'add-item' || page[1] === 'edit-item' ? 'active' : ''}><Link to="/menu-items">Items</Link></li>
                                    <li className={page[1] === 'combos' || page[1] === 'add-combo' || page[1] === 'edit-combo' ? 'active' : ''}><Link to="/combos">Combo</Link></li>
                                    {/* <li className={page[1] === 'suggestiveCombos' || page[1] === 'attachSuggestiveCombos' || page[1] === 'editSuggestiveCombos' ? 'active' : ''}><Link to="/suggestiveCombos">Attach Suggestive Combos</Link></li> */}
                                    {/* <li className={page[1] === 'edit-make-a-combo' || page[1] === 'make-a-combo' || page[1] === 'add-make-a-combo' ? 'active' : ''}><Link to="/make-a-combo">Make A Combo</Link></li> */}
                                    <li className={page[1] === 'mod-groups' || page[1] === 'add-modgroup' || page[1] === 'edit-modgroup' ? 'active' : ''}><Link to="/mod-groups">Modifier Groups</Link></li>
                                    <li className={page[1] === 'modifiers' || page[1] === 'add-modifier' || page[1] === 'edit-modifier' ? 'active' : ''}><Link to="/modifiers">Modifiers</Link></li>
                                    {/* <li className={page[1] === 'fp-modifiers' ? 'active' : ''}><Link to="/fp-modifiers">FP Modifiers</Link></li> */}
                                    {/* <li className={page[1] === 'fp-menu-logs' ? 'active' : ''}><Link to="/fp-menu-logs">FP Menu Logs</Link></li> */}
                                    {/* <li className={page[1] === 'fp-schedule' ? 'active' : ''}><Link to="/fp-schedule">FP Schedules</Link></li> */}
                                    <li className={page[1] === 'banners' || page[1] === 'add-banner' || page[1] === 'edit-banner' ? 'active' : ''}><Link to="/banners">Banners</Link></li>
                                    {/* <li className={page[1] === 'nut-categories' || page[1] === 'add-nut-categories' || page[1] === 'edit-nut-categories' ? 'active' : ''}><Link to="/nut-categories">Nutritional Categories</Link></li> */}
                                    {/* <li className={page[1] === 'nut-items' || page[1] === 'add-nut-items' || page[1] === 'edit-nut-items' ? 'active' : ''}><Link to="/nut-items">Nutritional Items</Link></li> */}
                                </ul>
                            </li>}
                            {(features && features.feature5 && features.feature5.permission == 1) && <li className={page[1] === 'brands' || page[1] === 'add-brand' || page[1] === 'edit-brand' ? 'active' : ''}><Link to="/brands"> <i className="fa fa-font-awesome"></i>Brands</Link></li>}
                            {(features && features.feature6 && features.feature6.permission == 1) && <li>
                                <a href="#customerDropDown" aria-expanded="false" data-toggle="collapse"> <i className="fa fa-users"></i>Customers</a>
                                <ul id="customerDropDown" className={page[1] === 'customers' || page[1] === 'feedbacks' || page[1] === 'employees' || page[1] === 'complaints' || page[1] === 'guests' ? '' : 'collapse list-unstyled'}>
                                    <li className={page[1] === 'customers' ? 'active' : ''}><Link to="/customers">Registered Customers</Link></li>
                                    {/* <li className={page[1] === 'block-customers' ? 'active' : ''}><Link to="/block-customers">Blocked Customers</Link></li> */}
                                    {/* <li className={page[1] === 'guests' ? 'active' : ''}><Link to="/guests">Guest Customers</Link></li> */}
                                    <li className={page[1] === 'feedbacks' ? 'active' : ''}><Link to="/feedbacks">Customers Review</Link></li>
                                    <li className={page[1] === 'complaints' ? 'active' : ''}><Link to="/complaints">Customers Feedback</Link></li>
                                    <li className={page[1] === 'employees' ? 'active' : ''}><Link to="/employees">Employees</Link></li>
                                    <li className={page[1] === 'nps_feedback' ? 'active' : ''}><Link to="/nps_feedback">NPS Feedback</Link></li>
                                    {/* <li className={page[1] === 'partners' ? 'active' : ''}><Link to="/partners">Business Partners</Link></li> */}
                                    {/* <li className={page[1] === 'franchise' ? 'active' : ''}><Link to="/franchise">Franchise Applications</Link></li> */}
                                    {/* <li className={page[1] === 'newsletter' ? 'active' : ''}><Link to="/newsletter">NewsLetter Emails</Link></li> */}
                                </ul>
                            </li>}
                            {(features && features.feature7 && features.feature7.permission == 1) && <li>
                                <a href="#marketingDropDown" aria-expanded="false" data-toggle="collapse"> <i className="fa fa-tag"></i>Marketing</a>
                                <ul id="marketingDropDown" className={page[1] === 'marketing' || page[1] === 'add-coupon' || page[1] === 'edit-coupon'
                                    || page[1] === 'add-promo' || page[1] === 'edit-promo'|| page[1] === 'fpDiscount'||  page[1] === 'add-fp-discount' || page[1] === 'edit-fp-discount'
                                    || page[1] === 'add-promo' || page[1] === 'edit-promo'
                                    || page[1] === 'add-discount' || page[1] === 'edit-discount' || page[1] === 'alliances' || page[1] === 'bulkCoupons' ? '' : 'collapse list-unstyled'}>
                                    <li className={page[1] === 'marketing' ? 'active' : ''}><Link to="/marketing/0">Marketing</Link></li>
                                    <li className={page[1] === 'bulkCoupons' ? 'active' : ''}><Link to="/bulkCoupons">Upload Bulk Coupons</Link></li>
                                    <li className={page[1] === 'fpDiscount' ||page[1] === 'add-fp-discount' || page[1] === 'edit-fp-discount' ? 'active' : ''}><Link to="/fpDiscount">FoodPanda Discounts</Link></li>
                                    <li className={page[1] === 'alliances' ? 'active' : ''}><Link to="/alliances">Alliances</Link></li>
                                </ul>
                            </li>}
                            {(features && features.feature8 && features.feature8.permission == 1) && <li>
                                <a href="#ordersDropDown" aria-expanded="false" data-toggle="collapse"> <i className="fa fa-shopping-cart"></i>Orders</a>
                                <ul id="ordersDropDown" className={page[1] === 'orders' || page[1] === 'foodpanda-orders' || page[1] === 'outbound_contacts' ? '' : 'collapse list-unstyled'}>
                                    <li className={page[1] === 'orders' ? 'active' : ''}><Link to="/orders">Orders </Link></li>
                                    {/* <li className={page[1] === 'foodpanda-orders' ? 'active' : ''}><Link to="/foodpanda-orders">Foodpanda Orders </Link></li> */}
                                    {/* <li className={page[1] === 'outbound_contacts' ? 'active' : ''}><Link to="/outbound_contacts">OutBound Contacts</Link></li> */}
                                </ul>
                            </li>
                            }
                            {(features && features.feature9 && features.feature9.permission == 1) && <li className={page[1] === 'payments' ? 'active' : ''}><Link to="/payments"> <i className="fa fa-credit-card"></i>Payments</Link></li>}
                            {/* {(features && features.feature10 && features.feature10.permission == 1) && <li className={page[1] === 'bulk' ? 'active' : ''}><Link to="/bulk"> <i className="fa fa-upload"></i>Bulk Upload</Link></li>} */}
                            {/* {(features && features.feature11 && features.feature11.permission == 1) && <li className={page[1] === 'addressBulk' ? 'active' : ''}><Link to="/addressBulk"> <i className="fa fa-upload"></i>Address Upload</Link></li>} */}
                            {(features && features.feature12 && features.feature12.permission == 1) && <li className={page[1] === 'analytics' ? 'active' : ''}><Link to="/analytics"> <i className="fa fa-file-pdf-o"></i>Analytics</Link></li>}
                            {(features && features.feature13 && features.feature13.permission == 1) && <li><a href="#settingsDropDown" aria-expanded="false" data-toggle="collapse"> <i className="fa fa-cogs"></i>Settings </a>
                                <ul id="settingsDropDown" className={page[1] === 'settings' ||
                                    page[1] === 'states' || page[1] === 'add-state' || page[1] === 'edit-state' ||
                                    page[1] === 'cities' || page[1] === 'add-cities' || page[1] === 'edit-cities' ||
                                    page[1] === 'drivers' ||
                                    page[1] === 'roles' || page[1] === 'add-role' || page[1] === 'edit-role' ||
                                    page[1] === 'reasons' || page[1] === 'add-reason' || page[1] === 'edit-reason' || page[1] === 'drivers' ||
                                    page[1] === 'nps' || page[1] === 'add-nps' || page[1] === 'edit-nps' ||
                                    page[1] === 'kml' || page[1] === 'add-kml' || page[1] === 'edit-kml' ||
                                    page[1] === 'tradezone' || page[1] === 'add-tradezone' || page[1] === 'edit-tradezone' || page[1] === "connectivity" ? '' : 'collapse list-unstyled'}>
                                    <li className={page[1] === 'settings' ? 'active' : ''}><Link to="/settings"> <i className="fa fa-cog"></i>Global Settings</Link></li>
                                    <li className={page[1] === 'states' || page[1] === 'add-state' || page[1] === 'edit-state' ? 'active' : ''}><Link to="/states"> <i className="fa fa-map-o"></i>States</Link></li>
                                    <li className={page[1] === 'cities' || page[1] === 'add-cities' || page[1] === 'edit-cities' ? 'active' : ''}><Link to="/cities"> <i className="fa fa-font-awesome"></i>Cities</Link></li>
                                    <li className={page[1] === 'kml' || page[1] === 'add-kml' || page[1] === 'edit-kml' ? 'active' : ''}><Link to="/kml"> <i className="fa fa-map-pin"></i>KML Zones</Link></li>
                                    {/* <li className={page[1] === 'tradezone' || page[1] === 'add-tradezone' || page[1] === 'edit-tradezone' ? 'active' : ''}><Link to="/tradezone"> <i className="fa fa-map-pin"></i>CallCenter Zones</Link></li> */}
                                    <li className={page[1] === 'reasons' || page[1] === 'add-reason' || page[1] === 'edit-reason' ? 'active' : ''}><Link to="/reasons"><i className="fa fa-file-o"></i>Reasons</Link></li>
                                    {/* <li className={page[1] === 'drivers' ? 'active' : ''}><Link to="/drivers"><i className="fa fa-truck"></i>Drivers</Link></li> */}
                                    {/* <li className={page[1] === 'nps' || page[1] === 'add-nps' || page[1] === 'edit-nps' ? 'active' : ''}><Link to="/nps"><i className="fa fa-clipboard"></i>NPS</Link></li> */}
                                    {/* <li className={page[1] === 'connectivity' ? 'active' : ''}><Link to="/connectivity"><i className="fa fa-clipboard"></i>Connectivity</Link></li> */}
                                </ul>
                            </li>}
                        </>
                    }
                </ul>
            </nav >
        );
    }
}
;
export default Sidebar;