import {
    MENUS_LIST, GET_MENU, BANNERS_LIST, GET_BANNER, GROUPS_LIST, GROUP_OPTIONS, ACTIVE_COMBOS_FOR_BANNERS, GET_GROUP, ACTIVE_ITEMS_FOR_BANNERS,
    ITEMS_LIST, ACTIVE_SUGG_COMBOS, GET_ITEM, EDIT_ITEM, COMBOS_LIST, COMBO_LOAD, GET_COMBO, SUGGESTIVE_COMBOS_LIST, COMBO_OPTIONS, MOD_GROUP_LIST, ACTIVE_MOD_GROUP_LIST,
    MOD_LIST, MENU_STORE_OPTIONS, EDIT_MOD, GET_MOD, GET_MOD_GROUP, CHECK_DUP_POS, STORES_OPTIONS, COMBO_CHOICE_ITEMS, ACTIVE_ITEMS_OPTIONS, CHECK_DUP_ERP,
    GET_BRANCH_MENU, STATUS_CHANGE_REASONS, SEARCH_MENU_ITEM, SEARCH_GROUPS, SEARCH_COMBOS, SEARCH_MODIFIERS, ORDER_MODES, MAKE_A_COMBO_LIST, GET_MAKE_A_COMBO, ITEMS, VARIANTS, SUBGROUPS_LIST, GET_SUBGROUP, SEARCH_SUBGROUPS, NUT_CAT_LIST, GET_NUT_CAT, GET_NUT_ITEM, NUT_ITEM_LIST, NUT_CATEGORIES, SEARCH_VARIANTS, SUB_GROUP_OPTIONS, VARIANTS_LIST, ITEM_LOAD, GET_SUGGESTIVE, FP_MOD_LIST,
    FP_COMBO,
    FP_SCHEDULE,
    FP_ITEM,
    BS_LIMIT
} from './menuType'
import jwt_decode from "jwt-decode";
import Api from '../../components/Api';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
const IsJsonString = (str: any) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const getschedule=() =>{

    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('admin/scheduleData', {
            headers: { 'Authorization': 'Bearer ' + token }, data:"dasf"
        })
            .then((response) => {
                if (response.data.success)  
                {  
                    dispatch({
                        type: FP_SCHEDULE,
                        payload: response.data.successResponse
                    })
                   
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message,{ position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 })
                } else {
                    toast.error(err.message,{ position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 })

                }
            });
    }

}
export const fpschedule=(data:any)=>{

    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post('admin/fpschedule',data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success)  
                {  
                    toast.success("Data Saved Succesfully", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                   
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message,{ position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 })
                } else {
                    toast.error(err.message,{ position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 })
                }
            });
    }

}
export const fpCombosList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/fpcombos', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success)  
                {   
                    dispatch({
                        type: FP_COMBO,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const fpitemsList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/fpitems', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success)  
                {   
                    dispatch({
                        type: FP_ITEM,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const syncMenu = () => {
    return function () {
        let token: any = localStorage.getItem('token');
        Api.post(`/admin/sync_menu_with_simplex_sync`, {}, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info(response.data.successResponse, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const variantsList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/variants', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: VARIANTS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const menusList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/menus', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MENUS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addMenu = (data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.post('/admin/add_menu', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/menus";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            error = "Menu already exist"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ")
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getMenu = (id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/menu/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_MENU,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editMenu = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.put(`/admin/edit_menu/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/menus";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const groupsListByMenuId = (menu_id: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        //if condition to prevent the Api hit without id
        if (menu_id === "") {
            dispatch({
                type: GROUPS_LIST,
                payload: ""
            })
        } else {
            Api.get(`/admin/menu/groups/${menu_id}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: GROUPS_LIST,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        dispatch({
                            type: GROUPS_LIST,
                            payload: ""
                        })
                        // console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}
export const publishMenu = (id: any, data: any, menu_name: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        data.menu_name = menu_name;
        data.user_info = jwt_decode(token);
        Api.put(`/admin/menu_publish/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/menus"
                }
            }).catch(err => {
                if (err.response) {
                    alert(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const publishLater = (id: any, data: any, menu_name: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        data.menu_name = menu_name;
        data.user_info = jwt_decode(token);
        Api.put(`/admin/publish_menu_future/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/menus"
                }
            }).catch(err => {
                if (err.response) {
                    alert(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const groupsList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/groups', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GROUPS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const groupsListForMultiSelect = (pageType?: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/groups_options', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    if (pageType && pageType == "campaign") {
                        let filteredGroups = response.data.successResponse.filter((obj: any) => {
                            return obj.category_type == "item"
                        })
                        dispatch({
                            type: GROUP_OPTIONS,
                            payload: filteredGroups
                        })
                    } else {
                        dispatch({
                            type: GROUP_OPTIONS,
                            payload: response.data.successResponse
                        })
                    }
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const subgroupsListForMultiSelect = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/sub_groups_options', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: SUB_GROUP_OPTIONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const itemsListForMultiSelect = (pageType?: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/getAllActiveItems', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    if (pageType && pageType == "campaign") {
                        let filteredITems = response.data.successResponse.filter((obj: any) => {
                            return obj.menu_item_id > 0
                        })
                        dispatch({
                            type: ACTIVE_ITEMS_OPTIONS,
                            payload: filteredITems
                        })
                    } else {
                        dispatch({
                            type: ACTIVE_ITEMS_OPTIONS,
                            payload: response.data.successResponse
                        })
                    }
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const storesListForMultiSelect = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/storesListForMenu', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: STORES_OPTIONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addGroup = (formData: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        formData.append('user_info', JSON.stringify(jwt_decode(token)))
        Api.post('/admin/add_group', formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/groups";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'EPR Id already exist'
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getGroup = (id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/group/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_GROUP,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editGroup = (id: any, formData: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        formData.append('user_info', JSON.stringify(jwt_decode(token)))
        Api.put(`/admin/edit_group/${id}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/groups";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'EPR Id already exist'
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockgroup = (id: any, isActive: any, group_name: any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = localStorage.getItem('token');
        let data = {
            is_active: is_active,
            group_name: group_name,
            user_info: jwt_decode(token)
        }
        Api.put(`/admin/group_block_unblock/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/groups"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const itemsListByGroupId = (group_id: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        if (group_id === "") {
            dispatch({
                type: ITEMS_LIST,
                payload: ""
            })
        } else {
            Api.get(`/admin/group/items/${group_id}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: ITEMS_LIST,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        dispatch({
                            type: ITEMS_LIST,
                            payload: ""
                        })
                        // console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}
export const comboChoiceItemsByGroupId = (group_id: any, index: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        if (group_id === "") {
            dispatch({
                type: COMBO_CHOICE_ITEMS,
                choiceIndex: "",
                payload: []
            })
        } else {
            Api.get(`/admin/combo_choice_items/${group_id}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: COMBO_CHOICE_ITEMS,
                            choiceIndex: index,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        dispatch({
                            type: COMBO_CHOICE_ITEMS,
                            choiceIndex: index,
                            payload: []
                        })
                        // console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}
export const itemsList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/menu_items', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ITEMS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addItems = (formData: any) => {
    return function (dispatch: any) {
        dispatch({
            type: ITEM_LOAD,
            payload: true
        })
        let token: any = localStorage.getItem('token');
        formData.append('user_info', JSON.stringify(jwt_decode(token)))
        Api.post('/admin/add_menu_item', formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/menu-items"
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'ERP Id already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    dispatch({
                        type: ITEM_LOAD,
                        payload: false
                    })
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const checkDuplicatePosCode = (pos_code: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/check_item_poscode/${pos_code}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                dispatch({
                    type: CHECK_DUP_POS,
                    duplicateItemPos: false,
                })
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: CHECK_DUP_POS,
                        duplicateItemPos: true,
                    })
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const checkDuplicateErpId = (erp_id: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/check_item_erpId/${erp_id}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                dispatch({
                    type: CHECK_DUP_ERP,
                    duplicateItemErp: false,
                })
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: CHECK_DUP_ERP,
                        duplicateItemErp: true,
                    })
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getBSLimit = () => {
    return function (dispatch: any) {
        let token = localStorage.getItem('token')
        Api.get(`admin/best_seller_limit`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response)=> {
                if (response.data.success) {
                    dispatch({
                        type: BS_LIMIT,
                        payload: response.data.successResponse
                    })
                }
            })
            .catch((err)=> {
                console.log(err)
            })
    }
}
export const getMenuItem = (id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/menu_item/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_ITEM,
                        sizejson: response.data.successResponse.item_size !== "" ? JSON.parse(response.data.successResponse.item_size) : "",
                        payload: response.data.successResponse
                    })
                    dispatch(subGroupListByGroupId(response.data.successResponse.item_group_id.group_id))
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockitem = (id: any, isActive: any, itemName: any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = localStorage.getItem('token');
        let data = {
            is_active: is_active,
            item_ame: itemName,
            user_info: jwt_decode(token)
        }
        Api.put(`/admin/item_block_unblock/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/menu-items"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editItem = (id: any, formData: any) => {
    return function (dispatch: any) {
        dispatch({
            type: ITEM_LOAD,
            payload: true
        })
        let token: any = localStorage.getItem('token');
        formData.append('user_info', JSON.stringify(jwt_decode(token)))
        Api.put(`/admin/edit_menu_item/${id}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/menu-items";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'ERP Id already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    dispatch({
                        type: ITEM_LOAD,
                        payload: false
                    })
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const combosList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/combos', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: COMBOS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const groupedItemsList = (menu_id: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        if (menu_id === "") {
            dispatch({
                type: COMBO_OPTIONS,
                payload: ""
            })
        } else {
            Api.get(`/admin/combo_options/${menu_id}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: COMBO_OPTIONS,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}
export const addCombo = (formData: any) => {
    return function (dispatch: any) {
        dispatch({
            type: COMBO_LOAD,
            payload: true
        })
        let token: any = localStorage.getItem('token');
        formData.append('user_info', JSON.stringify(jwt_decode(token)))
        Api.post('/admin/add_combo', formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/combos"
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("IDX_2af1f0816ff1443daf585a74e8") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'ERP Id already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    dispatch({
                        type: COMBO_LOAD,
                        payload: false
                    })
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getCombo = (id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/combo/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_COMBO,
                        comboChoices: IsJsonString(response.data.successResponse.comboChoices) ? JSON.parse(response.data.successResponse.comboChoices) : [
                            {
                                mod_groups: [],
                                crusts: [],
                                flavours: [],
                                topings: [],
                                condiments: [],
                                group_name: "",
                                priority: "",
                                is_half_n_half: false,
                                if_full_pizza: false
                            }
                        ],
                        payload: response.data.successResponse
                    })
                    dispatch(subGroupListByGroupId(response.data.successResponse.group_id.group_id))
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockcombo = (id: any, isActive: any, comboName: any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = localStorage.getItem('token');
        let data = {
            is_active: is_active,
            combo_name: comboName,
            user_info: jwt_decode(token)
        }
        Api.put(`/admin/combo_block_unblock/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/combos"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editCombo = (id: any, formData: any) => {
    return function (dispatch: any) {
        dispatch({
            type: COMBO_LOAD,
            payload: true
        })
        let token: any = localStorage.getItem('token');
        formData.append('user_info', JSON.stringify(jwt_decode(token)))
        Api.put(`/admin/edit_combo/${id}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/combos";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("IDX_2af1f0816ff1443daf585a74e8") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'ERP Id already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    dispatch({
                        type: COMBO_LOAD,
                        payload: false
                    })
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const modGroupList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/mod_groups', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MOD_GROUP_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addModGroup = (data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.post('/admin/add_mod_group', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/mod-groups";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getModGroup = (id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/mod_group/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_MOD_GROUP,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editModGroup = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.put(`/admin/edit_mod_group/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/mod-groups";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockModifierGroup = (id: any, isActive: any, history: any, modGroupName: any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = localStorage.getItem('token');
        let data = {
            is_active: is_active,
            mod_group_name: modGroupName,
            user_info: jwt_decode(token)
        }
        Api.put(`/admin/mod_group_block_unblock/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    history.push("/mod-groups")
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const modifierListByModGroupId = (id: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/modifiers/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MOD_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: MOD_LIST,
                        payload: ""
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}
export const modifierList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/modifiers', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MOD_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addModifier = (formData: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post('/admin/add_modifier', formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/modifiers";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'ERP Id already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getModifier = (id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/modifier/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_MOD,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockModifier = (id: any, isActive: any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = localStorage.getItem('token');
        let data = {
            is_active: is_active,
            // item_ame: itemName,
            user_info: jwt_decode(token)
        }
        Api.put(`/admin/modifier_block_unblock/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/modifiers"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editModifier = (id: any, formData: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.put(`/admin/edit_modifier/${id}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/modifiers";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'ERP Id already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const modGroupsList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/activeModGroups`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ACTIVE_MOD_GROUP_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const deleteModifier = (id: number, modName: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        let data = {
            modifier_name: modName,
            user_info: jwt_decode(token)
        }
        Api.post(`/admin/del_modifier/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/modifiers";
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getAllMenuList = (store_id: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/store/getAllMenu/${store_id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log("response.data.successResponse", response.data.successResponse)
                    let groupsData: any = [];
                    let subgroupsData: any = [];
                    let itemsData: any = [];
                    let variantsData: any = [];
                    let comboData: any = [];
                    let modData: any = [];
                    response.data.successResponse.forEach((obj: any) => {
                        if (obj.group_id) {
                            groupsData.push(obj)
                        } else if (obj.subgroup_id) {
                            subgroupsData.push(obj)
                        } else if (obj.menu_item_id) {
                            itemsData.push(obj)
                        } else if (obj.item_variant_id) {
                            variantsData.push(obj)
                        } else if (obj.combo_id) {
                            comboData.push(obj)
                        } else if (obj.modifier_id) {
                            modData.push(obj)
                        }
                    })
                    dispatch(searchMenuItem("", itemsData));
                    dispatch(searchVariants("", variantsData))
                    dispatch(searchGroup("", groupsData));
                    dispatch(searchSubGroup("", subgroupsData))
                    dispatch(searchCombos("", comboData));
                    dispatch(searchModifiers("", modData));
                    dispatch({
                        type: GET_BRANCH_MENU,
                        groupsData: groupsData,
                        subgroupsData: subgroupsData,
                        itemsData: itemsData,
                        variantsData: variantsData,
                        combosData: comboData,
                        modifiersData: modData
                    })
                }
            }).catch(err => {
                if (err.response) {
                    dispatch(searchMenuItem("", []));
                    dispatch(searchVariants("", []));
                    dispatch(searchGroup("", []));
                    dispatch(searchCombos("", []));
                    dispatch(searchModifiers("", []));
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const updateItemStatus = (data: any, history: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        var store: any = jwt_decode(token);
        data.user_id = store.user_id;
        Api.put(`/store/updateItemStatus/${data.store_id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info("Status changed successfully", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch(getAllMenuList(data.store_id))
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const statusChangeReasons = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post('/reasons/reasonByType', {
            type: 'menu'
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: STATUS_CHANGE_REASONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const searchGroup = (value: any, groupsData: any) => {
    return function (dispatch: any) {
        const groups = groupsData.filter((item: any) => item.group_id.group_name.toLowerCase().includes(value.toLowerCase()));
        dispatch({
            type: SEARCH_GROUPS,
            searchGroupData: value == "" ? groupsData : groups,
        })
    }
}

export const searchSubGroup = (value: any, subgroupsData: any) => {
    return function (dispatch: any) {
        const subgroups = subgroupsData.filter((item: any) => item.subgroup_id.sub_group_name.toLowerCase().includes(value.toLowerCase()));
        dispatch({
            type: SEARCH_SUBGROUPS,
            searchSubGroupData: value == "" ? subgroupsData : subgroups,
        })
    }
}

export const searchMenuItem = (value: any, menuItemData: any) => {
    return function (dispatch: any) {
        const items = menuItemData.filter((item: any) => item.menu_item_id.item_name.toLowerCase().includes(value.toLowerCase()));
        dispatch({
            type: SEARCH_MENU_ITEM,
            searchItemData: value == "" ? menuItemData : items,
        })
    }
}
export const searchCombos = (value: any, combosData: any) => {
    return function (dispatch: any) {
        const combos = combosData.filter((item: any) => item.combo_id.combo_name.toLowerCase().includes(value.toLowerCase()));
        dispatch({
            type: SEARCH_COMBOS,
            searchComboData: value == "" ? combosData : combos,
        })
    }
}
export const searchModifiers = (value: any, modifierData: any) => {
    return function (dispatch: any) {
        const items = modifierData.filter((item: any) => item.modifier_id.modifier_name.toLowerCase().includes(value.toLowerCase()));
        dispatch({
            type: SEARCH_MODIFIERS,
            searchModData: value == "" ? modifierData : items,
        })
    }
}

export const searchVariants = (value: any, variantData: any) => {
    return function (dispatch: any) {
        const items = variantData.filter((item: any) => (item.item_variant_id.size.toLowerCase().includes(value.toLowerCase()) || item.item_variant_id.menu_item_id.item_name.toLowerCase().includes(value.toLowerCase())));
        dispatch({
            type: SEARCH_VARIANTS,
            searchVariantData: value == "" ? variantData : items,
        })
    }
}
// api to get all the ordering modes from database
export const OrderingModes = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/order_modes', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                let order_modes_arr: any = [];
                let successResponse = response.data.successResponse;
                for (let i = 0; i < successResponse.length; i++) {
                    let obj = {
                        label: successResponse[i].name,
                        value: successResponse[i].id
                    }
                    order_modes_arr.push(obj)
                }
                if (response.data.success) {
                    dispatch({
                        type: ORDER_MODES,
                        payload: order_modes_arr
                    })
                }
            }).catch(err => {
                dispatch({
                    type: ORDER_MODES,
                    payload: []
                })
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const makeAComboList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/make_a_combos_list', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MAKE_A_COMBO_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getMakeACombo = (id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/make_a_combo/' + id, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_MAKE_A_COMBO,
                        payload: response.data.successResponse
                    })
                    // dispatch(getItemsByGroup(response.data.successResponse.group_id))
                    // dispatch(getVariantsByItem(response.data.successResponse.menu_item_id))
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const blockUnblockMakeACombo = (id: number, data: any) => {
    console.log("in action")
    // return function (dispatch: any) {
    let token: any = localStorage.getItem('token');
    Api.put('/admin/block_unblock_make_a_combo/' + id, data, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then((response) => {
            console.log("res")
            if (response.data.success) {
                window.location.reload();
            }
        }).catch(err => {
            console.log("err", err)
            if (err.response) {
                let error;
                if (typeof (err.response.data.message) === "string") {
                    if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                        error = "Please fill in all fields"
                    } else {
                        error = err.response.data.message;
                    }
                } else if (typeof (err.response.data.message) === "object") {
                    error = err.response.data.message[0];
                }
                toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            } else {
                alert(err.message)
            }
        });
    // }
}

export const addMakeACombo = (data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post('/admin/save_make_a_combo', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = '/make-a-combo';
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const updateMakeACombo = (id: number, data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.put('/admin/edit-make-a-combo/' + id, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = '/make-a-combo';
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getItemsByGroup = (group_id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/menu_items/' + group_id, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ITEMS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                dispatch({
                    type: ITEMS,
                    payload: []
                })
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getVariantsByItem = (item_id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/menu_item_variants/' + item_id, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: VARIANTS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                dispatch({
                    type: VARIANTS,
                    payload: []
                })
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const updateItemSnooze = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post(`/admin/update_item_snooze/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info(response.data.successResponse, { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 });
                    window.location.href = "/menu-items";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    setTimeout(() => {
                        window.location.href = "/menu-items";
                    }, 1000)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addStoreSpecificPrice = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post(`/admin/update_store_specific_item_price/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info(response.data.successResponse, { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 });
                    window.location.href = "/menu-items";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    setTimeout(() => {
                        window.location.href = "/menu-items";
                    }, 1000)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addStoreSpecificComboPrice = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post(`/admin/update_store_specific_combo_price/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info(response.data.successResponse, { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 });
                    window.location.href = "/combos";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    setTimeout(() => {
                        window.location.href = "/combos";
                    }, 1000)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const updateComboSnooze = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post(`/admin/update_combo_snooze/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info(response.data.successResponse, { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 });
                    window.location.href = "/combos";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    setTimeout(() => {
                        window.location.href = "/combos";
                    }, 1000)
                } else {
                    alert(err.message)
                }
            });
    }
}

//SubGroups
export const subgroupsList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/sub-groups', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: SUBGROUPS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getSubGroup = (id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/subgroup/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_SUBGROUP,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addSubGroup = (data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post('/admin/add_subgroup', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/sub-groups";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'EPR Id already exist'
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editSubGroup = (id: any, formData: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.put(`/admin/edit_subgroup/${id}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/sub-groups";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'EPR Id already exist'
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const subGroupListByGroupId = (group_id: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        if (group_id === "") {
            dispatch({
                type: SUBGROUPS_LIST,
                payload: []
            })
        } else {
            Api.get(`/admin/group/subgroups/${group_id}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: SUBGROUPS_LIST,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        dispatch({
                            type: SUBGROUPS_LIST,
                            payload: []
                        })
                        // console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}
export const blockunblocksubgroup = (id: any, isActive: any, group_name: any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = localStorage.getItem('token');
        let data = {
            is_active: is_active,
            sub_group_name: group_name,
            user_info: jwt_decode(token)
        }
        Api.put(`/admin/subgroup_block_unblock/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/sub-groups"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

//Banners
export const bannersList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/banners', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: BANNERS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getBanner = (id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/banner/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_BANNER,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addBanner = (formDAta: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post('/admin/add_banner', formDAta, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/banners";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockbanner = (id: any, isActive: any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = localStorage.getItem('token');
        let data = {
            is_active: is_active
        }
        Api.put(`/admin/banner_block_unblock/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/banners"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editBanner = (id: any, formData: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.put(`/admin/edit_banner/${id}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/banners";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const itemsListForBanners = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/getItemsForBanners', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    var menu_item_array = response.data.successResponse.filter(function (el: any) {
                        if (el.menu_item_id) {
                            return el
                        }
                    }
                    );
                    var combo_array = response.data.successResponse.filter(function (el: any) {
                        if (el.combo_id) {
                            return el
                        }
                    }
                    );
                    dispatch({
                        type: ACTIVE_ITEMS_FOR_BANNERS,
                        payload: menu_item_array
                    })
                    dispatch({
                        type: ACTIVE_COMBOS_FOR_BANNERS,
                        payload: combo_array
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const suggestiveComboList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/get_suggestive_deals', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: SUGGESTIVE_COMBOS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getSuggCombos = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/getSuggCombos', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ACTIVE_SUGG_COMBOS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const updateSuggestiveDeals = (data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post('/admin/attach_suggestive_deals', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/suggestiveCombos";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const blockunblockSuggestive = (id: any, isActive: any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = localStorage.getItem('token');
        let data = {
            is_active: is_active,
            // item_ame: itemName,
            user_info: jwt_decode(token)
        }
        Api.put(`/admin/suggestive_block_unblock/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/suggestiveCombos"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getSuggestive = (id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/suggestive/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_SUGGESTIVE,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const editSuggestive = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.put(`/admin/edit_suggestive_deals/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/suggestiveCombos";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
//Nutritional SubItems
export const nutItemList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/nutrition/nutritional_Items', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: NUT_ITEM_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addNutItem = (data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post('/nutrition/add_nutritional_Item', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/nut-items";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getNutItem = (id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/nutrition/nutritional_Item/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_NUT_ITEM,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editNutItem = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.put(`/nutrition/edit_nutritional_Item/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/nut-items";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockNutItem = (id: any, isActive: any, item_name: any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = localStorage.getItem('token');
        let data = {
            is_active: is_active,
            item_name: item_name,
        }
        Api.put(`/nutrition/nutritional_Item_block_unblock/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/nut-items"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const nutritionalCatList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/nutrition/nutritional_active_categories', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: NUT_CATEGORIES,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
// //categories

export const categoriesList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/nutrition/nutritional_categories', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: NUT_CAT_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const addNutCategories = (data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post('/nutrition/add_nutritional_category', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/nut-categories";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getNutCategory = (id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/nutrition/get_nutritional_category/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_NUT_CAT,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editNutCategory = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.put(`/nutrition/edit_nutritional_category/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/nut-categories";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const blockunblockcategory = (id: any, isActive: any, cat_name: any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = localStorage.getItem('token');
        let data = {
            is_active: is_active,
            group_name: cat_name,
        }
        Api.put(`/nutrition/nutritional_cat_block_unblock/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/nut-categories"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const fpmodifierList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/fpModifiers', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: FP_MOD_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const storesListForMenuStoreRelation = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/storesForMenuRelation`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MENU_STORE_OPTIONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: MENU_STORE_OPTIONS,
                        payload: []
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const syncMenuWithFP = (id: any, store_ids: any,enableSyncButton:any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post(`/aggregator/menus/${id}/publish`, { storeIds: store_ids }, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info(response.data.successResponse, { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 });
                    setTimeout(() => {
                        window.location.href = "/menus"
                    }, 3000);
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    enableSyncButton()
                } else {
                    alert(err.message)
                }
            });
    }
}