import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { Provider } from 'react-redux';
import store from './redux/store'
import login from './pages/login'
import forgot from './pages/forgotpass'
import home from './pages/home'
import profile from './pages/profile'
import customer from './pages/customers/customer'
import NewsLetter from './pages/customers/newsletter'
import blockcustomers from './pages/customers/block-customers'
import customerFeedback from './pages/customers/customer-feedback'
import customerNPSFeedback from './pages/customers/nps_feedback'
import FranchiseApp from './pages/customers//franchise'
import editCustomer from './pages/customers/edit-customer'
import stores from './pages/stores/stores'
import addStore from './pages/stores/add-store'
import editStore from './pages/stores/edit-store'
import brands from './pages/brands/brands'
import addBrand from './pages/brands/add-brand'
import editBrand from './pages/brands/edit-brand'
import employees from './pages/employees/employees'
import addEmp from './pages/employees/add-emp'
import editEmp from './pages/employees/edit-emp'
import partners from './pages/partners/partners'
import addParnter from './pages/partners/add-partner'
import editPartner from './pages/partners/edit-partner'
import alliances from './pages/alliances/alliances'
import addAlliance from './pages/alliances/add-alliance'
import editAlliance from './pages/alliances/edit-alliance'
import banners from './pages/menu/banners/banners'
import addBanners from './pages/menu/banners/add-banner'
import editBanners from './pages/menu/banners/edit-banner'
import nutCategories from './pages/menu/nutritional_info/categories/categories'
import addNutCategories from './pages/menu/nutritional_info/categories/add-category'
import editNutCategories from './pages/menu/nutritional_info/categories/edit-category'
import nutItems from './pages/menu/nutritional_info/items/items'
import addNutItems from './pages/menu/nutritional_info/items/add-item'
import editNutItems from './pages/menu/nutritional_info/items/edit-item'
import cities from './pages/Cities/cities';
import addCities from './pages/Cities/add-cities';
import editCities from './pages/Cities/edit-cities';
import menu from './pages/menu/menu'
import addMenu from './pages/menu/add-menu';
import editMenu from './pages/menu/edit-menu'
import groups from './pages/menu/groups/groups'
import addGroup from './pages/menu/groups/add-group'
import editGroup from './pages/menu/groups/edit-group'
import subgroups from './pages/menu/subGroups/groups'
import addSubGroup from './pages/menu/subGroups/add-group'
import editSubGroup from './pages/menu/subGroups/edit-group'
import menuItem from './pages/menu/menuItem/menu-item';
import addMenuItem from './pages/menu/menuItem/add-menu-item';
import editMenuItem from './pages/menu/menuItem/edit-menu-item';
import combo from './pages/menu/combo/combo'
import addCombo from './pages/menu/combo/add-combo'
import editCombo from './pages/menu/combo/edit-combo'
import attachSuggestiveCombos from './pages/menu/combo/attachSuggestiveCombos'
import editSuggestiveCombos from './pages/menu/combo/editSuggestiveCombos'
import modGroup from './pages/menu/modifiers/mod-group';
import addModGroup from './pages/menu/modifiers/add-mod-group';
import editModGroup from './pages/menu/modifiers/edit-mod-group';
import modifiers from './pages/menu/modifiers/modifiers'
import editFpModifier from './pages/menu/fpModifiers/edit-modifier';
import fpModifiers from './pages/menu/fpModifiers/modifiers'
import addModifier from './pages/menu/modifiers/add-modifier'
import editModifier from './pages/menu/modifiers/edit-modifier';
import BranchMenu from './pages/menu/branchMenu'
import bulkCoupons from './pages/coupons/bulkCoupons'
import addCoupon from './pages/coupons/add_coupon'
import addDiscount from './pages/discounts/add_discount'
import editCoupon from './pages/coupons/edit_coupon'
import editDiscount from './pages/discounts/edit_discount'
import promos from './pages/promos/promos';
import addPromo from './pages/promos/add_promo'
import editPromo from './pages/promos/edit_promo'
import addNotification from './pages/PushNotification/add_notification'
import editNotification from './pages/PushNotification/edit_notification'
import orders from './pages/orders/orders'
import OutBoundContacts from './pages/orders/outboundcontacts'
import payments from './pages/payments/payments'
import users from './pages/users/users'
import addUsers from './pages/users/add-user'
import editUsers from './pages/users/edit-user'
import userGroups from './pages/settings/userGroups/usergroups'
import addUserGroup from './pages/settings/userGroups/add-usergroup'
import editUserGroup from './pages/settings/userGroups/edit-usergroup'
import roles from './pages/roles/roles'
import addRoles from './pages/roles/add-role'
import editRoles from './pages/roles/edit-role'
import settings from './pages/settings/settings'
import states from './pages/settings/states/state'
import addState from './pages/settings/states/add-state'
import editState from './pages/settings/states/edit-state'
import tables from './pages/dineIn/tables/tables'
import addTable from './pages/dineIn/tables/add-table';
import storeTable from './pages/dineIn/store-table/store-table';
import editStoreTable from './pages/dineIn/store-table/edit-store-table';
import Reports from './pages/reports/reports'
import tradeZone from './pages/tradezone/tradezone'
import addTradezone from './pages/tradezone/add-tradezone'
import editTradezone from './pages/tradezone/edit-tradezone'
import kml from './pages/tradezone/kml-tradezones'
import addkml from './pages/tradezone/add-kml-tradezone'
import editkml from './pages/tradezone/edit-kml-tradezone'
// import tradeArea from './pages/tradezone/tradearea';
// import addTradearea from './pages/tradezone/add-tradearea';
// import editTradearea from './pages/tradezone/edit-tradearea';
import marketing from './pages/marketing/marketing';
import customerComplaints from './pages/customers/customer-complaints';
import Bulk from './pages/BulkUplaod/upload';
import AddressBulk from './pages/BulkUplaod/address';
import Drivers from './pages/drivers/drivers';
import reasons from './pages/reasonsList/reasons';
import addReason from './pages/reasonsList/add-reasons';
import editReason from './pages/reasonsList/edit-reasons';
import accessManage from './pages/settings/roleAccess'
import orderModes from './pages/settings/orderModes/modes'
import editMode from './pages/settings/orderModes/edit-mode'
import AddMakeACombo from './pages/menu/make_a_combo/add_make_a_combo';
import MakeACombo from './pages/menu/make_a_combo/make-a-combo';
import EditMakeACombo from './pages/menu/make_a_combo/edit-make-a-combo';
import questions from './pages/survey';
import addQuestion from './pages/survey/add-question';
import editQuestion from './pages/survey/edit-question';
import connectivity from './pages/settings/connectivity';
import suggestiveCombos from './pages/menu/combo/suggestiveCombos';
import cardOrders from './pages/orders/cardOrders';
import foodpandaOrders from './pages/orders/foodpandaOrders';
import add_fp_discount from './pages/fpDiscounts/add_fp_discount';
import fpDiscounts from './pages/fpDiscounts/fpDiscounts';
import edit_fp_discount from './pages/fpDiscounts/edit_fp_discount';
import Multifactor from "./pages/multifactoreAuth";
import fpMenuLogs from './pages/fpMenu/fpMenuLogs';
import fpSchedule from './pages/fpSchedule/fpSchedule';

const Notfound = () => <h1 className="text-center">Not found</h1>
function App() {
  let tokendata: any = jwt.decode(localStorage.token)
  let features: any = {};
  let roleId;
  if (localStorage.token) {
    features = JSON.parse(tokendata.features);
    roleId = tokendata.role_id;
  }
  let allowedRoutes = (
    <Switch>
      <Route exact path="/" component={login} />
      <Route exact path="/forgot" component={forgot} />
      <Route exact path="/dashboard" component={home} />
      <Route exact path="/profile" component={profile} />
      <Route exact path="/customers" component={customer} />
      <Route exact path="/newsletter" component={NewsLetter} />
      <Route exact path="/block-customers" component={blockcustomers} />
      <Route exact path="/edit-customer/:id" component={editCustomer} />
      <Route exact path="/feedbacks" component={customerFeedback} />
      <Route exact path="/nps_feedback" component={customerNPSFeedback} />
      <Route exact path="/franchise" component={FranchiseApp} />
      <Route exact path="/employees" component={employees} />
      <Route exact path="/add-emp" component={addEmp} />
      <Route exact path="/edit-emp/:id" component={editEmp} />
      {/* <Route exact path="/partners" component={partners} />
      <Route exact path="/add-partner" component={addParnter} />
      <Route exact path="/edit-partner/:id" component={editPartner} /> */}
      <Route exact path="/alliances" component={alliances} />
      <Route exact path="/add-alliance" component={addAlliance} />
      <Route exact path="/edit-alliance/:id" component={editAlliance} />
      <Route exact path="/analytics" component={Reports} />
      <Route exact path="/complaints" component={customerComplaints} />
      <Route exact path="/orders" component={orders} />
      <Route exact path="/card-orders" component={cardOrders} />
      <Route exact path="/foodpanda-orders" component={foodpandaOrders} />
      <Route exact path="/fpDiscount" component={fpDiscounts} />
      <Route exact path="/edit-fp-discount/:id" component={edit_fp_discount} />
      <Route exact path="/outbound_contacts" component={OutBoundContacts} />
      <Route exact path="/marketing/:id" component={marketing} />
      <Route exact path="/fp-menu-logs" component={fpMenuLogs} />
      <Route exact path="/fp-schedule" component={fpSchedule} />
      <Route exact path="/branchmenu" component={BranchMenu} />
      <Route exact path="/stores" component={stores} />
      <Route exact path="/add-store" component={addStore} />
      <Route exact path="/edit-store/:id" component={editStore} />
      <Route exact path="/tradezone" component={tradeZone} />
      <Route exact path="/add-tradezone" component={addTradezone} />
      <Route exact path="/edit-tradezone/:id" component={editTradezone} />
      <Route exact path="/kml" component={kml} />
      <Route exact path="/add-kml" component={addkml} />
      <Route exact path="/edit-kml/:id" component={editkml} />
      {/* <Route exact path="/tradearea" component={tradeArea} />
      <Route exact path="/add-tradearea" component={addTradearea} />
      <Route exact path="/edit-tradearea/:id" component={editTradearea} /> */}
      <Route exact path="/cities" component={cities} />
      <Route exact path="/add-cities" component={addCities} />
      <Route exact path="/edit-cities/:id" component={editCities} />
      <Route exact path="/brands" component={brands} />
      <Route exact path="/add-brand" component={addBrand} />
      <Route exact path="/edit-brand/:id" component={editBrand} />
      <Route exact path="/menus" component={menu} />
      <Route exact path="/add-menu" component={addMenu} />
      <Route exact path="/edit-menu/:id" component={editMenu} />
      <Route exact path="/groups" component={groups} />
      <Route exact path="/add-group" component={addGroup} />
      <Route exact path="/edit-group/:id" component={editGroup} />
      <Route exact path="/sub-groups" component={subgroups} />
      <Route exact path="/add-sub-group" component={addSubGroup} />
      <Route exact path="/edit-sub-group/:id" component={editSubGroup} />
      <Route exact path="/menu-items" component={menuItem} />
      <Route exact path="/add-item" component={addMenuItem} />
      <Route exact path="/edit-item/:id" component={editMenuItem} />
      <Route exact path="/combos" component={combo} />
      <Route exact path="/add-combo" component={addCombo} />
      <Route exact path="/edit-combo/:id" component={editCombo} />
      <Route exact path="/suggestiveCombos" component={suggestiveCombos} />
      <Route exact path="/attachSuggestiveCombos" component={attachSuggestiveCombos} />
      <Route exact path="/editSuggestiveCombos/:id" component={editSuggestiveCombos} />
      <Route exact path="/mod-groups" component={modGroup} />
      <Route exact path="/add-modgroup" component={addModGroup} />
      <Route exact path="/edit-modgroup/:id" component={editModGroup} />
      <Route exact path="/modifiers" component={modifiers} />
      <Route exact path="/add-modifier" component={addModifier} />
      <Route exact path="/view-fp-modifier/:id" component={editFpModifier} />
      <Route exact path="/edit-modifier/:id" component={editModifier} />
      <Route exact path="/fp-modifiers" component={fpModifiers} />
      <Route exact path="/banners" component={banners} />
      <Route exact path="/add-banner" component={addBanners} />
      <Route exact path="/edit-banner/:id" component={editBanners} />
      <Route exact path="/nut-categories" component={nutCategories} />
      <Route exact path="/add-nut-categories" component={addNutCategories} />
      <Route exact path="/edit-nut-categories/:id" component={editNutCategories} />
      <Route exact path="/nut-items" component={nutItems} />
      <Route exact path="/add-nut-items" component={addNutItems} />
      <Route exact path="/edit-nut-items/:id" component={editNutItems} />
      <Route exact path="/add-coupon" component={addCoupon} />
      <Route exact path="/add-discount" component={addDiscount} />
      <Route exact path="/add-fp-discount" component={add_fp_discount} />
      <Route exact path="/edit-coupon/:id" component={editCoupon} />
      <Route exact path="/bulkCoupons" component={bulkCoupons} />
      <Route exact path="/edit-discount/:id" component={editDiscount} />
      <Route exact path="/add-promo" component={addPromo} />
      <Route exact path="/edit-promo/:id" component={editPromo} />
      <Route exact path="/add-notification" component={addNotification} />x
      <Route exact path="/edit-notification/:id" component={editNotification} />
      <Route exact path="/payments" component={payments} />
      <Route exact path="/bulk" component={Bulk} />
      <Route exact path="/addressBulk" component={AddressBulk} />
      <Route exact path="/users" component={users} />
      <Route exact path="/drivers" component={Drivers} />
      <Route exact path="/add-user" component={addUsers} />
      <Route exact path="/edit-user/:id" component={editUsers} />
      <Route exact path="/usergroups" component={userGroups} />
      <Route exact path="/add-usergroup" component={addUserGroup} />
      <Route exact path="/edit-usergroup/:id" component={editUserGroup} />
      <Route exact path="/roles" component={roles} />
      <Route exact path="/add-role" component={addRoles} />
      <Route exact path="/edit-role/:id" component={editRoles} />
      <Route exact path="/settings" component={settings} />
      <Route exact path="/tables" component={tables} />
      <Route exact path="/add-table" component={addTable} />
      <Route exact path="/store-table" component={storeTable} />
      <Route exact path="/edit-store-table/:id" component={editStoreTable} />
      <Route exact path="/access-manage" component={accessManage} />
      <Route exact path="/states" component={states} />
      <Route exact path="/add-state" component={addState} />
      <Route exact path="/edit-state/:id" component={editState} />
      <Route exact path="/reasons" component={reasons} />
      <Route exact path="/add-reason" component={addReason} />
      <Route exact path="/edit-reason/:id" component={editReason} />
      <Route exact path="/order-modes" component={orderModes} />
      <Route exact path="/edit-mode/:id" component={editMode} />
      {/* <Route exact path="/add-make-a-combo" component={AddMakeACombo} />
      <Route exact path="/make-a-combo" component={MakeACombo} />
      <Route exact path="/edit-make-a-combo/:id" component={EditMakeACombo} /> */}
      <Route exact path="/nps" component={questions} />
      <Route exact path="/add-nps" component={addQuestion} />
      <Route exact path="/edit-nps/:id" component={editQuestion} />
      <Route exact path="/connectivity" component={connectivity} />
      <Route path="/authentication" component={Multifactor} />
      <Route path="*" exact component={Notfound} />
    </Switch>
  )
  return (
    // forceRefresh is used to force React Router to reload the page on every navigation
    <div className="App">
      < Provider store={store} >
        <Router forceRefresh>
          {(roleId == 1 || roleId == 2) ? allowedRoutes :
            <Switch>
              <Route exact path="/" component={login} />
              <Route exact path="/forgot" component={forgot} />
              <Route exact path="/profile" component={profile} />
              {(features && features.feature1 && features.feature1.permission == 1) && <Route exact path="/dashboard" component={home} />}
              {(features && features.feature2 && features.feature2.permission == 1) && <Route exact path="/stores" component={stores} />}
              {(features && features.feature2 && features.feature2.permission == 1) && <Route exact path="/add-store" component={addStore} />}
              {(features && features.feature2 && features.feature2.permission == 1) && <Route exact path="/edit-store/:id" component={editStore} />}
              {(features && features.feature3 && features.feature3.permission == 1) && <Route exact path="/branchmenu" component={BranchMenu} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/menus" component={menu} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/add-menu" component={addMenu} />}
              {/* {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/add-make-a-combo" component={AddMakeACombo} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/make-a-combo" component={MakeACombo} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/edit-make-a-combo/:id" component={EditMakeACombo} />} */}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/edit-menu/:id" component={editMenu} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/groups" component={groups} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/add-group" component={addGroup} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/edit-group/:id" component={editGroup} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/sub-groups" component={subgroups} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/add-sub-group" component={addSubGroup} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/edit-sub-group/:id" component={editSubGroup} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/menu-items" component={menuItem} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/add-item" component={addMenuItem} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/edit-item/:id" component={editMenuItem} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/combos" component={combo} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/add-combo" component={addCombo} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/edit-combo/:id" component={editCombo} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/suggestiveCombos" component={suggestiveCombos} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/attachSuggestiveCombos" component={attachSuggestiveCombos} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/editSuggestiveCombos/:id" component={editSuggestiveCombos} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/mod-groups" component={modGroup} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/add-modgroup" component={addModGroup} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/edit-modgroup/:id" component={editModGroup} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/modifiers" component={modifiers} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/add-modifier" component={addModifier} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/edit-modifier/:id" component={editModifier} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/fp-modifiers" component={modifiers} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/fp-menu-logs" component={fpMenuLogs} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/fp-schedule" component={fpSchedule} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/ " component={fpSchedule} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/banners" component={banners} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/add-banner" component={addBanners} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/edit-banner/:id" component={editBanners} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/nut-categories" component={nutCategories} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/edit-nut-categories/:id" component={editNutCategories} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/add-nut-categories" component={addNutCategories} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/nut-items" component={nutItems} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/add-nut-items" component={addNutItems} />}
              {(features && features.feature4 && features.feature4.permission == 1) && <Route exact path="/edit-nut-items/:id" component={editNutItems} />}
              {(features && features.feature5 && features.feature5.permission == 1) && <Route exact path="/brands" component={brands} />}
              {(features && features.feature5 && features.feature5.permission == 1) && <Route exact path="/add-brand" component={addBrand} />}
              {(features && features.feature5 && features.feature5.permission == 1) && <Route exact path="/edit-brand/:id" component={editBrand} />}
              {(features && features.feature6 && features.feature6.permission == 1) && <Route exact path="/customers" component={customer} />}
              {(features && features.feature6 && features.feature6.permission == 1) && <Route exact path="/newsletter" component={NewsLetter} />}
              {(features && features.feature6 && features.feature6.permission == 1) && <Route exact path="/block-customers" component={blockcustomers} />}
              {(features && features.feature6 && features.feature6.permission == 1) && <Route exact path="/edit-customer/:id" component={editCustomer} />}
              {(features && features.feature6 && features.feature6.permission == 1) && <Route exact path="/feedbacks" component={customerFeedback} />}
              {(features && features.feature6 && features.feature6.permission == 1) && <Route exact path="/franchise" component={FranchiseApp} />}
              {(features && features.feature6 && features.feature6.permission == 1) && <Route exact path="/complaints" component={customerComplaints} />}
              {(features && features.feature6 && features.feature6.permission == 1) && <Route exact path="/nps_feedback" component={customerNPSFeedback} />}
              {(features && features.feature6 && features.feature6.permission == 1) && <Route exact path="/employees" component={employees} />}
              {(features && features.feature6 && features.feature6.permission == 1) && <Route exact path="/add-emp" component={addEmp} />}
              {(features && features.feature6 && features.feature6.permission == 1) && <Route exact path="/edit-emp/:id" component={editEmp} />}
              {/* {(features && features.feature6 && features.feature6.permission == 1) && <Route exact path="/partners" component={partners} />}
              {(features && features.feature6 && features.feature6.permission == 1) && <Route exact path="/add-partner" component={addParnter} />}
              {(features && features.feature6 && features.feature6.permission == 1) && <Route exact path="/edit-partner/:id" component={editPartner} />} */}
              {(features && features.feature7 && features.feature7.permission == 1) && <Route exact path="/marketing/:id" component={marketing} />}
              {(features && features.feature7 && features.feature7.permission == 1) && <Route exact path="/alliances" component={alliances} />}
              {(features && features.feature7 && features.feature7.permission == 1) && <Route exact path="/add-alliance" component={addAlliance} />}
              {(features && features.feature7 && features.feature7.permission == 1) && <Route exact path="/edit-alliance/:id" component={editAlliance} />}
              {(features && features.feature7 && features.feature7.permission == 1) && <Route exact path="/add-coupon" component={addCoupon} />}
              {(features && features.feature7 && features.feature7.permission == 1) && <Route exact path="/add-discount" component={addDiscount} />}
              {(features && features.feature7 && features.feature7.permission == 1) && <Route exact path="/add-fp-discount" component={add_fp_discount} />}
              {(features && features.feature7 && features.feature7.permission == 1) && <Route exact path="/edit-fp-discount/:id" component={edit_fp_discount} />}
              {(features && features.feature7 && features.feature7.permission == 1) && <Route exact path="/edit-coupon/:id" component={editCoupon} />}
              {(features && features.feature7 && features.feature7.permission == 1) && <Route exact path="/bulkCoupons" component={bulkCoupons} />}
              {(features && features.feature7 && features.feature7.permission == 1) && <Route exact path="/edit-discount/:id" component={editDiscount} />}
              {(features && features.feature7 && features.feature7.permission == 1) && <Route exact path="/add-promo" component={addPromo} />}
              {(features && features.feature7 && features.feature7.permission == 1) && <Route exact path="/edit-promo/:id" component={editPromo} />}
              {(features && features.feature7 && features.feature7.permission == 1) && <Route exact path="/add-notification" component={addNotification} />}
              {(features && features.feature7 && features.feature7.permission == 1) && <Route exact path="/edit-notification/:id" component={editNotification} />}
              {(features && features.feature8 && features.feature8.permission == 1) && <Route exact path="/orders" component={orders} />}
              {(features && features.feature8 && features.feature8.permission == 1) && <Route exact path="/card-orders" component={cardOrders} />}
              {(features && features.feature8 && features.feature8.permission == 1) && <Route exact path="/foodpanda-orders" component={foodpandaOrders} />}
              {(features && features.feature8 && features.feature8.permission == 1) && <Route exact path="/fpDiscount" component={fpDiscounts} />}
              {(features && features.feature8 && features.feature8.permission == 1) && <Route exact path="/outbound_contacts" component={OutBoundContacts} />}
              {(features && features.feature9 && features.feature9.permission == 1) && <Route exact path="/payments" component={payments} />}
              {(features && features.feature10 && features.feature10.permission == 1) && <Route exact path="/bulk" component={Bulk} />}
              {(features && features.feature11 && features.feature11.permission == 1) && <Route exact path="/addressBulk" component={AddressBulk} />}
              {(features && features.feature12 && features.feature12.permission == 1) && <Route exact path="/analytics" component={Reports} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/drivers" component={Drivers} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/settings" component={settings} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/tables" component={tables} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/add-table" component={addTable} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/store-table" component={storeTable} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/edit-store-table" component={editStoreTable} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/states" component={states} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/add-state" component={addState} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/edit-state/:id" component={editState} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/tradezone" component={tradeZone} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/add-tradezone" component={addTradezone} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/edit-tradezone/:id" component={editTradezone} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/kml" component={kml} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/add-kml" component={addkml} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/edit-kml/:id" component={editkml} />}
              {/* {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/tradearea" component={tradeArea} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/add-tradearea" component={addTradearea} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/edit-tradearea/:id" component={editTradearea} />} */}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/cities" component={cities} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/add-cities" component={addCities} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/edit-cities/:id" component={editCities} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/reasons" component={reasons} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/add-reason" component={addReason} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/edit-reason/:id" component={editReason} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/nps" component={questions} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/add-nps" component={addQuestion} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/edit-nps/:id" component={editQuestion} />}
              {(features && features.feature13 && features.feature13.permission == 1) && <Route exact path="/connectivity" component={connectivity} />}
              <Route path="/authentication" component={Multifactor} />
              <Route path="*" exact component={Notfound} />
            </Switch>
          }
        </Router>
      </Provider>
    </div>
  );
}

export default App;
