export const GoogleMapsAPI = 'AIzaSyDjWkRYIRR3IjnY8pmpSyQ45H0bHSNJKZk'; // for our own yum solution
// export const TplMapsAPI = '';  //for dominos solution
var ip = window.location.hostname;
var protocol = window.location.protocol == "https:" ? 'https' : 'http';
// var baseURL  = window.location.origin;

export const API_URL = `${protocol}://${ip}/api/`;
export const IMAGE_URL = `${protocol}://${ip}/images/`;

// export const API_URL = `${protocol}://${ip}:4014/api/`;
// export const IMAGE_URL = `${protocol}://${ip}:4014/`;

// export const API_URL = `https://cc-cms.simplexts.com.au/api/`;
// export const IMAGE_URL = `https://cc-cms.simplexts.com.au/images`;

export const currency = 'Rs. '