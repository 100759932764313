import React, { useEffect, useRef, useState } from 'react'
import Timer from 'react-compound-timer'
import { connect} from 'react-redux';
import { aggregatorOrdersList } from '../../redux';

const ReactTimer = (props:any) => {
    const [timerKey, setTimerKey] = useState(0);
    const handleTimerComplete = () => {
        let obj = { days: props.days,pageNumber:props.pageNumber,pageSize:props.pageSize };
          console.log(obj,"objcdsd");
        props.aggregatorOrdersList(obj)
        setTimerKey(prevKey => prevKey + 1);
        props.clearDateTime();
        props.clearData()
    };
    
   return (
        <Timer
            key={timerKey}
            initialTime={30000}
            direction="backward"
            checkpoints={[{ time: 0, callback: handleTimerComplete }]}
        >
            {() => (
                <React.Fragment>
                   <strong className="text-bold" style={{color:" #1E4359", fontSize:"2em"}}>0<Timer.Minutes/>:<Timer.Seconds /></strong>
                </React.Fragment>
            )}
        </Timer>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        aggregatorOrdersList: (obj: any) => {
            dispatch(aggregatorOrdersList(obj));
        }
       
    }
}
export default connect(null,mapDispatchToProps)(ReactTimer);
