import React, { useState } from 'react'
import Timer from 'react-compound-timer'
import { connect } from 'react-redux';
import { ordersList } from '../../../redux';

const ReactTimer = (props:any) => {

    const [timerKey, setTimerKey] = useState(0);
    const handleTimerComplete = () => {
        let obj = { days: props.days };
        props.ordersList(obj, false, 0, props.updateLoader,props.closeTimer,props.startTimer);
        props.updateListType();
        setTimerKey(prevKey => prevKey + 1);
    };
    return (
        <Timer
            key={timerKey}
            initialTime={30000}
            direction="backward"
            checkpoints={[{ time: 0, callback: handleTimerComplete }]}
        >
            {() => (
                <React.Fragment>
                    <strong className="text-bold" style={{ color: " #1E4359", fontSize: "2em" }}> 00:<Timer.Seconds /></strong>
                </React.Fragment>
            )}
        </Timer>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        ordersList: (obj: any, datetime?: any,page?:any, updateLoader?: any,closeTimer?: any,startTimer?:any) => {
            dispatch(ordersList(obj, datetime,page, updateLoader,closeTimer,startTimer))
        }
       
    }
}
export default connect(null,mapDispatchToProps)(ReactTimer);
