import React, { Component } from 'react';
import { AddTableProps, AddTableState } from '../../../interfaces/settings'
import { connect } from 'react-redux'
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import { countryList, addStates } from '../../../redux'
import { Link, Redirect } from 'react-router-dom';
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import CheckChanges from '../../../components/confirmOnLeave'
import { addTable, tablesList } from '../../../redux/actions/dineInAction';
class AddTable extends Component<AddTableProps, AddTableState> {
    constructor(props: any) {
        super(props);
        this.state = {
            table_id: "",
            alreadyExists: false,
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        document.title = "Crust Culture | Tables"
        this.props.tablesList();
    }
    isAlreadyExist = (event:any) => {
        const tablesData = this.props.tables && this.props.tables.length > 0 ? this.props.tables : []
        const alreadyExists = tablesData.some((table:any) => table.value == event.value)
        this.setState({ alreadyExists: alreadyExists })
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.isAlreadyExist(event.target);
    }
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    isStateReady = () => {
        const { table_id, alreadyExists } = this.state
        return (table_id != '' && alreadyExists == false)
    }
    handleSaveBtnClick = (event: any) => {
        let { table_id } = this.state;
        let data = {
            table_id: table_id
        }
        this.props.addTableData(data)
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        
        return (
            <div className="page">
                <CheckChanges path="/add-table" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Tables Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/tables" className="text-primary">Tables</Link></li>
                                <li className="breadcrumb-item active">Add Table</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Table Number <span className="text-danger">*</span></label>
                                                            <input id="table_id" type="number" name="table_id" required data-msg="Please enter Table Number" className="input-material" onChange={this.handleInputChange} />
                                                            <small className="text-danger">
                                                                {
                                                                    this.state.alreadyExists ? "Table already exists" : ""
                                                                }
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group float-right mt-3">
                                                    <button className='btn btn-primary' disabled={!this.isStateReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        countries: state.store.countries,
        message: state.menu.message,
        isInserted: state.menu.isInserted,
        tables: state.dineIn.tablesList
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        addTableData: function (data: any) {
            dispatch(addTable(data));
        },
        tablesList: function () {
            dispatch(tablesList())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddTable);