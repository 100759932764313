import React, { Component } from 'react';
import { AddPromoProps, AddPromoState } from '../../interfaces/promo'
import { connect } from 'react-redux'
import { addPromo, logoutUser, menusList } from '../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
class AddPromo extends Component<AddPromoProps, AddPromoState> {
    constructor(props: any) {
        super(props);
        this.state = {
            promoname: "",
            promodesc: "",
            promodiscount: "",
            expiryDate: "",
            menu: "",
            image: "",
            error: ""
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        this.props.menusList();
        document.title = "Crust Culture | Promos"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.name == "promodesc" && event.target.value.length > 150) {
            this.setState({
                error: "Promo Description must be 150 characters"
            });
        } else {
            this.setState({
                error: ""
            });
        }
    }
    isPromoReady = () => {
        const { promoname, promodesc, menu, expiryDate, image } = this.state
        return (promoname !== "" && (promodesc !== "" && promodesc.length <= 150) && menu !== "" && expiryDate !== "" && image !== "");
    }
    fileSelectedHandler = (e: any) => {
        this.setState({ image: e.target.files })
    }
    handleSaveBtnClick = (event: any) => {
        let { promoname, promodesc, promodiscount, expiryDate, menu, image } = this.state;
        const data = new FormData();
        data.append('file', image[0]);
        data.append('promo_name', promoname);
        data.append('promo_description', promodesc);
        data.append('promo_discount_percentage', promodiscount);
        data.append('expire_date', expiryDate);
        data.append('menu_id', menu);
        data.append('is_archive', '0');
        this.props.addPromo(data);
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isInserted, message, menus } = this.props;
        if (!isInserted) {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                <CheckChanges path="/add-promo" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Compaigns Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/marketing/1" className="text-primary">Compaigns</Link></li>
                                <li className="breadcrumb-item active">Add Promo</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Promo name <span className="text-danger">*</span></label>
                                                            <input id="promoname" type="text" name="promoname" required data-msg="Please enter Promo Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description <span className="text-danger">*</span></label>
                                                            <input id="promodesc" type="text" name="promodesc" required data-msg="Please enter Description" className="input-material" onChange={this.handleInputChange} />
                                                            <small className="form-text text-danger">{this.state.error}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Valid till <span className="text-danger">*</span></label>
                                                            <input id="expiryDate" type="date" name="expiryDate" required data-msg="Please enter Expire Date" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Promo discount (%)</label>
                                                            <input id="promodiscount" type="text" name="promodiscount" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> 
                                                </div> */}
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Menu <span className="text-danger">*</span></label>
                                                            <select name="menu" className="form-control text-capitalize mt-2" required data-msg="Please select Menu" onChange={this.handleInputChange}>
                                                                <option>Select Menu</option>
                                                                {menus &&
                                                                    menus.map((menu, index) => (
                                                                        <option key={index} value={menu.menu_id}>{menu.menu_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Promo Image <span className="text-danger">*</span></label>
                                                            <input id="file" type="file" name="file" className="form-control-file" onChange={this.fileSelectedHandler} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isPromoReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        menus: state.menu.menus,
        message: state.promo.message,
        isInserted: state.promo.isInserted
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addPromo: function (data: any) {
            dispatch(addPromo(data));
        },
        menusList: function () {
            dispatch(menusList())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddPromo);