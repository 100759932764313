import { STORE_TABLE_DATA, STORE_TABLE_LIST, TABLE_LIST } from '../actions/dineInType'

const initialState: any = {
    tablesList: [] || "",
    storeTableList: [] || "",
    storeTableData: [] || ""
}

const dineInReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case TABLE_LIST: return {
            ...state,
            tablesList: action.payload
        }
        case STORE_TABLE_LIST: return {
            ...state,
            storeTableList: action.payload
        }
        case STORE_TABLE_DATA: return {
            ...state,
            storeTableData: action.payload
        }
        default: return state;
    }
}
export default dineInReducer;