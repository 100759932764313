import React, { Component } from 'react';
import { connect } from 'react-redux'
import { logoutUser, addAlliance } from '../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import './alliances.css';
import { AddAllianceProps, AddAllianceState } from '../../interfaces/alliances';
class AddAlliance extends Component<AddAllianceProps, AddAllianceState> {
    myRef: any;
    constructor(props: any) {
        super(props);
        this.state = {
            name: "",
            image: "",
            html_description: '',
            editorState: EditorState.createEmpty(),
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.myRef = React.createRef();
    }
    componentDidMount() {
        document.title = "Crust Culture | Alliances"
    }
    handleInputChange = (event: { target: { name: any; value: any; }; }) => {
        if (event.target.value == ' ') {
            event.target.value = event.target.value.replace(/\s/g, "");
        }
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    isAllianceReady = () => {
        const { name, html_description, image } = this.state;
        return (name !== "" && html_description !== "" && image !== "");
    }
    fileSelectedHandler = (e: any) => {
        this.setState({ image: e.target.files })
    }
    blockInvalidChar = (e: any) => {
        var regex = new RegExp("[a-zA-Z]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    onEditorStateChange = (editorState: any) => {
        this.setState({
            html_description: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())), editorState: editorState
        })
    };
    handleSaveBtnClick = () => {
        let { name, html_description, image, editorState } = this.state;
        const data: any = new FormData();
        data.append('file', image[0])
        data.append('name', name)
        data.append('plain_description', editorState.getCurrentContent().getPlainText())
        data.append('html_description', html_description)
        this.props.addAlliance(data);
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        return (
            <div className="page">
                <CheckChanges path="/add-alliance" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Alliances Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/alliances" className="text-primary">Alliances</Link></li>
                                <li className="breadcrumb-item active">Add Alliance</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Name <span className="text-danger">*</span></label>
                                                            <input id="name" type="text" name="name" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Image <span className="text-danger">*</span></label>
                                                            <input id="file" type="file" name="file" className="form-control-file" onChange={this.fileSelectedHandler} />
                                                            <p style={{ color: "red", fontSize: "12px" }}>Image size should be less than 1 mb</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label className="form-control-label">
                                                            Description  <span className="text-danger">*</span>
                                                        </label>
                                                        <Editor
                                                            editorState={this.state.editorState}
                                                            toolbarClassName="toolbarClassName"
                                                            wrapperClassName="wrapperClassName"
                                                            editorClassName="editorClassName"
                                                            ref={this.myRef}
                                                            onEditorStateChange={this.onEditorStateChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isAllianceReady()} onClick={this.handleSaveBtnClick} >Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: () => { dispatch(logoutUser()); },
        addAlliance: (data: any) => { dispatch(addAlliance(data)); }
    }
}
export default connect(null, mapDispatchToProps)(AddAlliance);