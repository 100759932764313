import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { logoutUser, emailList } from '../../redux'
import { NewsLetterEmailProps } from '../../interfaces/customers';
import moment from 'moment';

class NewsLetterEmail extends Component<NewsLetterEmailProps, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.emailList();
        document.title = "Crust Culture | NewsLetter Emails"
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            noDataText: 'Emails Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">NewsLetter Email Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} csvFileName='emails.csv' hover>
                                                    <TableHeaderColumn dataField='id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='email_address' csvHeader='Email' width='100' columnTitle>Email</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='date_created' csvHeader='date' width='100'>Email Recieved</TableHeaderColumn>
                                                    {/* <TableHeaderColumn dataField='action' width='140' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn> */}
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section >
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
function timeFormatter(cell: any) {
    console.log("cell",cell)
    return (
        <div>
            <span {...((cell !== "" && cell !== null) && { title: moment(cell).format('YYYY-MM-DD HH:mm') })}> {(cell !== "" && cell !== null) && moment(cell).format('YYYY-MM-DD HH:mm')} </span>
        </div>
    )
}
function emailStatus(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" }) || (cell == 0 && { className: "badge badge-danger p-2" })}>{cell == 0 ? "In active" : "Active"}</span>
        </div>
    )
}
const mapStateToProps = (state: any) => {
    return {
        data: state.customer.newsletterEmails
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        emailList: function () {
            dispatch(emailList())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NewsLetterEmail);