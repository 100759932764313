import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { EditModGroupProps, EditModGroupState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { logoutUser, editModGroup, getModGroup, groupsListForMultiSelect, itemsListForMultiSelect, subgroupsListForMultiSelect } from '../../../redux'
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';

class EditMod extends Component<EditModGroupProps, EditModGroupState> {
    constructor(props: any) {
        super(props);
        this.state = {
            modgroupname: "",
            groupJson: [],
            subgroupJson: [],
            itemsJson: [],
            poscode: "",
            erpid: "",
            modgroupdesc: "",
            type: "",
            modgroupnamehint: "",
            level: "",
            priority: "",
            min_quantity: "",
            max_quantity: "",
            is_crust: false,
            is_addon: true,
            is_toping: false,
            is_flavour: false,
            is_condiment: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleModifiersLevel = this.handleModifiersLevel.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getModGroup(id);
        this.props.subgroupsList();
        this.props.groupsList();
        this.props.itemsListForMultiSelect();
        document.title = "Crust Culture | Modifier Groups"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({
            modgroupname: nextProps.modGroupData.mod_group_name,
            modgroupnamehint: nextProps.modGroupData.mod_group_name_hint ? nextProps.modGroupData.mod_group_name_hint : "",
            poscode: nextProps.modGroupData.pos_code,
            erpid: nextProps.modGroupData.erp_id,
            modgroupdesc: nextProps.modGroupData.mod_group_description,
            itemsJson: nextProps.modGroupData.items_json && JSON.parse(nextProps.modGroupData.items_json),
            type: nextProps.modGroupData.modifiers_type,
            level: nextProps.modGroupData.type,
            priority: nextProps.modGroupData.priority,
            min_quantity: nextProps.modGroupData.min_quantity,
            max_quantity: nextProps.modGroupData.max_quantity,
            is_crust: nextProps.modGroupData.is_crust,
            is_toping: nextProps.modGroupData.is_toping,
            is_flavour: nextProps.modGroupData.is_flavour,
            is_condiment: nextProps.modGroupData.is_condiment,
            is_addon: nextProps.modGroupData.is_addon,
        })
        if (nextProps.modGroupData.type == "group") {
            this.setState({
                groupJson: nextProps.modGroupData.group_json && JSON.parse(nextProps.modGroupData.group_json),
            })
        } else if (nextProps.modGroupData.type == "subgroup") {
            this.setState({
                subgroupJson: nextProps.modGroupData.group_json && JSON.parse(nextProps.modGroupData.group_json)
            })
        }
    }
    handleCheckBoxes = (event: { target: { name: any; value: any; }; }) => {
        const { is_crust, is_addon, is_toping, is_condiment } = this.state;
        if (event.target.name == "is_crust") {
            this.setState({ is_crust: true, is_addon: false, is_flavour: false, is_toping: false, is_condiment: false })
        } else if (event.target.name == "is_addon") {
            this.setState({ is_addon: true, is_crust: false, is_flavour: false, is_toping: false, is_condiment: false })
        } else if (event.target.name == "is_toping") {
            this.setState({ is_toping: true, is_addon: false, is_flavour: false, is_crust: false, is_condiment: false })
        } else if (event.target.name == "is_flavour") {
            this.setState({ is_flavour: true, is_addon: false, is_crust: false, is_toping: false, is_condiment: false })
        } else if (event.target.name == "is_condiment") {
            this.setState({ is_condiment: true, is_flavour: false, is_addon: false, is_crust: false, is_toping: false  })
        }
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleModifiersLevel(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    isModGroupReady = () => {
        const { modgroupname, type, groupJson, subgroupJson, level, itemsJson, min_quantity, max_quantity } = this.state
        return (modgroupname !== "" && type !== "" && (level == "group" ? groupJson && groupJson.length > 0 : level == "subgroup" ? subgroupJson && subgroupJson.length > 0 : itemsJson && itemsJson.length > 0) && (min_quantity !== "" && (min_quantity == 0 || min_quantity > 0)) && (max_quantity !== "" && max_quantity > 0));
    }
    handleGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ itemsJson: [], groupJson: e });
        }
    };
    handleSubGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ itemsJson: [], groupJson: [], subgroupJson: e });
        }
    };
    handleItemsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ groupJson: [], itemsJson: e });
        }
    }
    handleSubmit = (event: any) => {
        let { modgroupname, modgroupnamehint, modgroupdesc, type, poscode, erpid, groupJson, subgroupJson, level, itemsJson, priority, min_quantity, max_quantity, is_addon, is_crust, is_flavour, is_toping, is_condiment } = this.state;
        let id = this.props.match.params.id;
        let data: any = {
            mod_group_name: modgroupname,
            mod_group_description: modgroupdesc,
            priority: priority,
            modifiers_type: type,
            type: level,
            min_quantity: min_quantity,
            max_quantity: max_quantity,
            is_crust: is_crust ? 1 : 0,
            is_addon: is_addon ? 1 : 0,
            is_toping: is_toping ? 1 : 0,
            is_flavour: is_flavour ? 1 : 0,
            is_condiment: is_condiment ? 1 : 0
        }
        if (modgroupnamehint !== "") {
            data.mod_group_name_hint = modgroupnamehint
        }
        if (level == 'group') {
            data["group_json"] = JSON.stringify(groupJson);
            data["items_json"] = null;
        } else if (level == 'item') {
            data["group_json"] = null;
            data["items_json"] = JSON.stringify(itemsJson);
        } else if (level == "subgroup") {
            data["group_json"] = JSON.stringify(subgroupJson);
        }
        if (poscode !== null && poscode !== "") {
            data['pos_code'] = poscode;
        } else {
            data['pos_code'] = null;
        }
        if (erpid !== null && erpid !== "") {
            data['erp_id'] = erpid
        } else {
            data['erp_id'] = null;
        }
        this.props.editModGroup(id, data);
        event.preventDefault()
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let { groupJson, subgroupJson, itemsJson } = this.state;
        const { modGroupData, groups, subgroups, items } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/edit-modgroup" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Modifier Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/mod-groups" className="text-primary">Modifier Groups</Link></li>
                                <li className="breadcrumb-item active">Edit Modifier Group</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <form method="post" className="form-validate">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Modifier Group Name <span className="text-danger">*</span></label>
                                                                <input id="modgroupname" type="text" name="modgroupname" defaultValue={modGroupData.mod_group_name} required data-msg="Please enter Modifier Name" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Modifier Group Name Hint </label>
                                                                <input id="modgroupnamehint" type="text" name="modgroupnamehint" defaultValue={modGroupData.mod_group_name_hint} data-msg="Please enter Modifier Name Hint" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">POS Code</label>
                                                                <input id="poscode" type="text" name="poscode" defaultValue={modGroupData.pos_code} className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">ERP ID</label>
                                                                <input id="erpid" type="text" name="erpid" defaultValue={modGroupData.erp_id} className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Modifiers Level <span className="text-danger">*</span></label>
                                                                <select name="level" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleModifiersLevel}>
                                                                    <option value='group' {...modGroupData.type == 'group' && { selected: true }}>Group</option>
                                                                    <option value='subgroup' {...modGroupData.type == 'subgroup' && { selected: true }}>SubGroup</option>
                                                                    <option value='item' {...modGroupData.type == 'item' && { selected: true }}>Menu Item</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        {this.state.level === "group" &&
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Group <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        isMulti
                                                                        name="groups"
                                                                        value={groupJson}
                                                                        options={groups}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.level === "subgroup" &&
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Sub Groups <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        isMulti
                                                                        name="subgroup"
                                                                        options={subgroups}
                                                                        value={subgroupJson}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleSubGroupsInputChange(e, i)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.level === "item" &&
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Select Items <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        isMulti
                                                                        name="items"
                                                                        options={items}
                                                                        value={itemsJson}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleItemsInputChange(e, i)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-12">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Min Quantity <span className="text-danger">*</span></label>
                                                                <input id="min_quantity" type="number" onWheel={(e: any) => e.target.blur()} name="min_quantity" defaultValue={modGroupData.min_quantity} min={0} required data-msg="Please enter Min Quantity" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-12">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Max Quantity <span className="text-danger">*</span></label>
                                                                <input id="max_quantity" type="number" onWheel={(e: any) => e.target.blur()} name="max_quantity" defaultValue={modGroupData.max_quantity} min={1} required data-msg="Please enter Max Quantity" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {/* <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Group <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="groups"
                                                                    value={groupJson}
                                                                    options={groups}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div> */}
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Description</label>
                                                                <input id="modgroupdesc" type="text" name="modgroupdesc" defaultValue={modGroupData.mod_group_description} className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Modifier Type <span className="text-danger">*</span></label>
                                                                <select name="type" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleInputChange}>
                                                                    <option {...modGroupData.modifiers_type === 'single' && { selected: true }} value='single'>Single Select</option>
                                                                    <option {...modGroupData.modifiers_type === 'multiple' && { selected: true }} value='multiple' >Multi Select</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Priority</label>
                                                                <input id="priority" type="number" onWheel={(e: any) => e.target.blur()} name="priority" defaultValue={modGroupData.priority} required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-2 col-sm-4 col-4 mt-5" >
                                                            <div>
                                                                <input id="checkboxCustom1" type="checkbox" name="is_addon" checked={this.state.is_addon} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                                <label htmlFor="checkboxCustom1">Addon</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-sm-4 col-4 mt-5" >
                                                            <div>
                                                                <input id="checkboxCustom2" type="checkbox" name="is_crust" checked={this.state.is_crust} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                                <label htmlFor="checkboxCustom2">Crust</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-sm-4 col-4 mt-5" >
                                                            <div>
                                                                <input id="checkboxCustom3" type="checkbox" name="is_toping" checked={this.state.is_toping} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                                <label htmlFor="checkboxCustom3">Toping</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-sm-4 col-4 mt-5" >
                                                            <div>
                                                                <input id="checkboxCustom4" type="checkbox" name="is_flavour" checked={this.state.is_flavour} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                                <label htmlFor="checkboxCustom4">Flavour</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-sm-4 col-4 mt-5" >
                                                            <div>
                                                                <input id="checkboxCustom5" type="checkbox" name="is_condiment" checked={this.state.is_condiment} onChange={this.handleCheckBoxes} className="checkbox-template" />
                                                                <label htmlFor="checkboxCustom5">Condiment</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group d-flex justify-content-end mt-4">
                                                        <button onClick={this.handleSubmit} disabled={!this.isModGroupReady()} className="btn btn-primary">Update Modifier Group</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        modGroupData: state.menu.modGroupData,
        groups: state.menu.groupsptions,
        subgroups: state.menu.subgroupsptions,
        items: state.menu.allActiveItems
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        editModGroup: function (id: any, data: any) {
            dispatch(editModGroup(id, data));
        },
        getModGroup: function (id: number) {
            dispatch(getModGroup(id));
        },
        groupsList: function () {
            dispatch(groupsListForMultiSelect())
        },
        subgroupsList: function () {
            dispatch(subgroupsListForMultiSelect())
        },
        itemsListForMultiSelect: function () {
            dispatch(itemsListForMultiSelect())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMod);