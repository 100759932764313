import { QUESTIONS_LIST, GET_QUESTION, ACTIVE_QUESTION } from '../actions/surveyType'
const initialState: any = {
    questions: [] || "",
    questionData: {},
    activeQuestions:[]||""
}

const surveyReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case QUESTIONS_LIST: return {
            ...state,
            questions: action.payload
        }
        case GET_QUESTION: return {
            ...state,
            questionData: action.payload
        }
        case ACTIVE_QUESTION: return{
            ...state,
            activeQuestions:action.payload
        }
        default: return state;
    }
}
export default surveyReducer;