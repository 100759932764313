export const MENUS_LIST = "MENUS_LIST"
export const MENU_STORE_OPTIONS="MENU_STORE_OPTIONS"
export const GET_MENU = "GET_MENU"
export const GROUPS_LIST = "GROUPS_LIST"
export const SUBGROUPS_LIST = "SUBGROUPS_LIST"
export const GROUP_OPTIONS="GROUP_OPTIONS"
export const SUB_GROUP_OPTIONS="SUB_GROUP_OPTIONS"
export const ADD_GROUP = "ADD_GROUP"
export const EDIT_GROUP = "EDIT_GROUP"
export const GET_GROUP = "GET_GROUP"
export const GET_SUBGROUP="GET_SUBGROUP"
export const ITEMS_LIST = "ITEMS_LIST"
export const ACTIVE_ITEMS_OPTIONS="ACTIVE_ITEMS_OPTIONS"
export const ACTIVE_SUGG_COMBOS = "ACTIVE_SUGG_COMBOS"
export const GET_SUGGESTIVE = "GET_SUGGESTIVE"
export const EDIT_ITEM = "EDIT_ITEM"
export const GET_ITEM = "GET_ITEM"
export const BS_LIMIT = "BS_LIMIT"
export const COMBOS_LIST = "COMBOS_LIST"
export const ADD_COMBO = "ADD_COMBO"
export const SUGGESTIVE_COMBOS_LIST = "SUGGESTIVE_COMBOS_LIST"
export const GET_COMBO = "GET_COMBO"
export const COMBO_LOAD="COMBO_LOAD"
export const ITEM_LOAD="ITEM_LOAD"
export const COMBO_OPTIONS = "COMBO_OPTIONS"
export const MOD_GROUP_LIST = "MOD_GROUP_LIST"
export const ACTIVE_MOD_GROUP_LIST = "ACTIVE_MOD_GROUP_LIST"
export const ADD_MOD_GROUP = "ADD_MOD_GROUP"
export const EDIT_MOD_GROUP = "EDIT_MOD_GROUP"
export const GET_MOD_GROUP = "GET_MOD_GROUP"
export const MOD_LIST = "MOD_LIST"
export const FP_MOD_LIST = "FP_MOD_LIST"
export const ADD_MOD = "ADD_MOD"
export const EDIT_MOD = "EDIT_MOD"
export const GET_MOD = "GET_MOD"
export const STORES_OPTIONS="STORES_OPTIONS"
export const CHECK_DUP_POS="CHECK_DUP_POS"
export const CHECK_DUP_ERP="CHECK_DUP_ERP"
export const COMBO_CHOICE_ITEMS="COMBO_CHOICE_ITEMS"
export const GET_BRANCH_MENU="GET_BRANCH_MENU"
export const VARIANTS_LIST = "VARIANTS_LIST"
export const STATUS_CHANGE_REASONS="STATUS_CHANGE_REASONS"
export const SEARCH_GROUPS="SEARCH_GROUPS"
export const SEARCH_SUBGROUPS="SEARCH_SUBGROUPS"
export const SEARCH_MENU_ITEM="SEARCH_MENU_ITEM"
export const SEARCH_COMBOS="SEARCH_COMBOS"
export const SEARCH_MODIFIERS="SEARCH_MODIFIERS"
export const SEARCH_VARIANTS="SEARCH_VARIANTS"
export const ORDER_MODES="ORDER_MODES"
export const MAKE_A_COMBO_LIST = "MAKE_A_COMBO_LIST"
export const GET_MAKE_A_COMBO = "GET_MAKE_A_COMBO"
export const ITEMS = 'ITEMS'
export const VARIANTS = 'VARIANTS'
export const BANNERS_LIST = "BANNERS_LIST"
export const GET_BANNER = "GET_BANNER"
export const ACTIVE_COMBOS_FOR_BANNERS="ACTIVE_COMBOS_FOR_BANNERS"
export const ACTIVE_ITEMS_FOR_BANNERS="ACTIVE_ITEMS_FOR_BANNERS"

//Nutritional Info
export const NUT_CAT_LIST="NUT_CAT_LIST"
export const GET_NUT_CAT= "GET_NUT_CAT"

export const NUT_ITEM_LIST="NUT_ITEM_LIST"
export const GET_NUT_ITEM= "GET_NUT_ITEM"
export const NUT_CATEGORIES="NUT_CATEGORIES"

export const FP_COMBO ='FP_COMBO'
export const FP_ITEM ='FP_ITEM'
export const FP_SCHEDULE ='FP_SCHEDULE'

