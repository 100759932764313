/* eslint-disable react/require-render-return */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { customersReport, dailySalesReport, logoutUser, pmixReport, salesmixReport } from '../../redux'
import { Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Select from 'react-select'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import ReactPaginate from 'react-paginate';
import { currency } from '../../client-config'
import { ReportsProps, ReportsState } from '../../interfaces/reports';
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
// import JSZip from 'jszip';

import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-buttons/js/buttons.print';
import { activityLogsReport, cancellationDtetailsReport, cancellationReport, channelmixReport, deviceinfoReports, couponredemptionReport, discountReport, orderDetailsReport, orderItemDetailsReport, orderSystemIntgReport, profitReport, riderDetailsReport, ridersReport, sosReport, storesList, storesReport, paymentLogsReport, voucherOrderDetailsReport, aggrFailedOrdersReport, aggrDataExportReport } from '../../redux/actions/reportAction';
import moment from 'moment';
import { toast } from 'react-toastify';
import './reports.css'
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
const $ = require("jquery");
$.DataTable = require("datatables.net");

class Reports extends Component<ReportsProps, ReportsState> {
  storesDropDown: any;
  constructor(props: any) {
    super(props);
    this.state = {
      reportType: "",
      storeType: "",
      allSales: false,
      showDate: false,
      validTimeFlag: false,
      startDate: "",
      validTimeFlagFrom: false,
      validTimeFlagTo: false,
      endDate: "",
      settings: {
        dom: 'Bfrtip',
        // "paginate": false,  //to disable the pagination
        // "bInfo": false,     //to disable the showing entries text
        "pageLength": 50,
        buttons: [
          'copy',
          'excel',
          'print',
          {
            extend: 'pdfHtml5',
            orientation: 'landscape',
            pageSize: 'LEGAL',
            text: 'PDF',
            footer: true,
            customize: function (doc: any) {
              doc.content[1].table.widths =
                Array(doc.content[1].table.body[0].length + 2).join('*').split('');
            },
          }
        ],
        "footerCallback": function (tfoot: any, data: any, start: any, end: any, display: any) {
          var api = this.api(), data;
          // Total over all pages
          $(api.column(5).footer()).html(
            `${currency} ` +
            api.column(5).data().reduce(function (a: any, b: any) {
              return parseInt(a) + parseInt(b);
            }, 0)
          );
        }
      }
    }
    this.handleValidTime = this.handleValidTime.bind(this)
    this.reportGenerator = this.reportGenerator.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    document.title = "Crust Culture | Reports"
    this.props.storesList();
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    let { reportType, settings } = this.state;
    if (nextProps.isLoad == false && this.props.isLoad == true) {
      if (reportType === "sales") {
        setTimeout(
          function () {
            $('#example').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      } else if (reportType === "customer") {
        setTimeout(
          function () {
            $('#customerreports').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      } else if (reportType === "product") {
        setTimeout(
          function () {
            $('#pmix').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      }
      else if (reportType === "salesmix") {
        setTimeout(
          function () {
            $('#smix').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      }
      else if (reportType === "channelmix") {
        setTimeout(
          function () {
            $('#cmix').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      }
      // else if(reportType === "devicemix"){
      //   setTimeout(
      //     function () {
      //       $('#dmix').DataTable(settings, { responsive: true, "deferRender": true });
      //     }.bind(this), 1500);
      // }
      else if (reportType === "couponredemption") {
        setTimeout(
          function () {
            $('#couponred').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      }
      else if (reportType === "profit") {
        setTimeout(
          function () {
            $('#profitreports').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      }
      else if (reportType === "discount") {
        setTimeout(
          function () {
            $('#discountreports').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      }
      else if (reportType === "sosReport") {
        setTimeout(
          function () {
            $('#sosreports').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      }
      else if (reportType === "riders") {
        setTimeout(
          function () {
            $('#ridersReport').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      }
      else if (reportType === "store") {
        setTimeout(
          function () {
            $('#storeReport').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      }
      else if (reportType === "cancel_smry") {
        setTimeout(
          function () {
            $('#cancel_smryReport').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      }
      else if (reportType === "cancel_details") {
        setTimeout(
          function () {
            $('#cancel_detailsReport').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      }
      else if (reportType === "order_details") {
        setTimeout(
          function () {
            $('#order_detailsReport').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      }
      else if (reportType === "voucher_orders_details") {
        setTimeout(
          function () {
            $('#voucher_logs_Report').DataTable({
              responsive: true, "deferRender": true,
              order: [[0, 'desc']],
              dom: 'Bfrtip',
              "pageLength": 50,
              buttons: [
                'copy',
                'excel',
                'print',
                {
                  extend: 'pdfHtml5',
                  orientation: 'landscape',
                  pageSize: 'LEGAL',
                  text: 'PDF',
                  footer: true,
                  customize: function (doc: any) {
                    doc.content[1].table.widths =
                      Array(doc.content[1].table.body[0].length + 2).join('*').split('');
                  },
                }
              ],
            });
          }.bind(this), 1500);
      }
      else if (reportType === "rider_details") {
        setTimeout(
          function () {
            $('#rider_detailsReport').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      }
      else if (reportType === "activity_logs") {
        setTimeout(
          function () {
            $('#activity_logsReport').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      }
      else if (reportType === "payment_logs") {
        setTimeout(
          function () {
            $('#payment_logsReport').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      } else if (reportType === "system_intrgrated_orders") {
        setTimeout(
          function () {
            $('#system_IntegratedOrdersReport').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      } else if (reportType === "order_item_details") {
        setTimeout(
          function () {
            $('#order_item_detailsReport').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      } else if (reportType === "aggregator_failed_orders") {
        setTimeout(
          function () {
            $('#aggr_failedOrdersReport').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      }
      else if (reportType === "aggr_data_export") {
        setTimeout(
          function () {
            $('#aaggr_DataExportReport').DataTable(settings, { responsive: true, "deferRender": true });
          }.bind(this), 1500);
      }
    }
  }
  handleStoresInputChange = (e: any, index: any) => {
    if (e && e.value > 0) {
      this.setState({ storeType: e.value });
    } else {
      this.setState({ storeType: " " });
    }
    //console.log("E ", e.value);
  };

  handleInputChange(event: { target: { name: any; value: any; }; }) {
    this.setState({
      [event.target.name]: event.target.value
    });
    if (this.state.storeType === 404) {
      this.setState({ storeType: "" })
      this.storesDropDown.select.clearValue();
    }
    if (event.target.value === "sales") {
      this.setState({ showDate: true })
    } else if (event.target.value === "customer") {
      this.setState({ showDate: true })
    } else if (event.target.value === "product") {
      this.setState({ showDate: true })
    } else if (event.target.value === "salesmix") {
      this.setState({ showDate: true })
    }
    else if (event.target.value === "channelmix") {
      this.setState({ showDate: true })
    }
    // else if(event.target.value === "devicemix"){
    //   this.setState({ showDate: true })
    // }
    else if (event.target.value === "couponredemption") {
      this.setState({ showDate: true })
    }
    else if (event.target.value === "profit") {
      this.setState({ showDate: true })
    }
    else if (event.target.value === "discount") {
      this.setState({ showDate: true, storeType: "" })
    }
    else if (event.target.value === "sosReport") {
      this.setState({ showDate: true })
    }
    else if (event.target.value === "riders") {
      this.setState({ showDate: true })
    }
    else if (event.target.value === "store") {
      this.setState({ showDate: true })
    }
    else if (event.target.value === "cancel_smry") {
      this.setState({ showDate: true })
    }
    else if (event.target.value === "cancel_details") {
      this.setState({ showDate: true })
    }
    else if (event.target.value === "order_details") {
      this.setState({ showDate: true })
    }
    else if (event.target.value === "voucher_orders_details") {
      this.setState({ showDate: true })
    }
    else if (event.target.value === "rider_details") {
      this.setState({ showDate: true })
    }
    else if (event.target.value === "activity_logs") {
      this.setState({ showDate: true })
    } else if (event.target.value === 'payment_logs') {
      this.setState({ showDate: true })
    } else if (event.target.value === "system_intrgrated_orders") {
      this.setState({ showDate: true })
    } else if (event.target.value === "order_item_details") {
      this.setState({ showDate: true })
    } else if (event.target.value === "aggregator_failed_orders") {
      this.setState({ showDate: true })
    }
    else if (event.target.value === "aggr_data_export") {
      this.setState({ showDate: false })
    }
  }

  handleValidTime(event: { target: { name: any; value: any } }) {
    let selectedDateTime = new Date(event.target.value)
    let validMinDateTime = new Date();
    if (selectedDateTime <= validMinDateTime) {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name == 'startDate') {
        this.setState({ validTimeFlagFrom: true })
      }
      else {
        this.setState({ validTimeFlagTo: true })
      }
    } else {
      toast.error("Invalid date and time selected");
      if (event.target.name == 'startDate') {
        this.setState({ validTimeFlagFrom: false })
      }
      else {
        this.setState({ validTimeFlagTo: false })
      }
    }
  }

  isReportReady = () => {
    let { reportType, startDate, endDate, validTimeFlagFrom, validTimeFlagTo } = this.state;
    if (reportType === "sales" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo) {
      return (reportType !== "");
    } else if (reportType === "customer") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    } else if (reportType === "product") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    } else if (reportType === "salesmix") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    }
    else if (reportType === "channelmix") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    }
    // else if (reportType === "devicemix") {
    //   return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    // }
    else if (reportType === "couponredemption") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    }
    else if (reportType === "profit") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    }
    else if (reportType === "discount") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    }
    else if (reportType === "sosReport") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    }
    else if (reportType === "riders") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    }
    else if (reportType === "store") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    }
    else if (reportType === "cancel_smry") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    }
    else if (reportType === "cancel_details") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    }
    else if (reportType === "order_details") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    }
    else if (reportType === "voucher_orders_details") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    }
    else if (reportType === "rider_details") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    }
    else if (reportType === "activity_logs") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    }
    else if (reportType === "payment_logs") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    } else if (reportType === "system_intrgrated_orders") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    } else if (reportType === "order_item_details") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    } else if (reportType === "aggregator_failed_orders") {
      return (reportType !== "" && startDate !== "" && endDate !== "" && validTimeFlagFrom && validTimeFlagTo);
    }
    else if (reportType === "aggr_data_export") {
      return (reportType !== "");
    }
  }

  validateTimeFromTo = () => {
    let { startDate, endDate, reportType } = this.state;
    if (reportType == 'payment_logs' || reportType == "aggr_data_export") {
      this.reportGenerator();
    } else {
      var start = moment(startDate, 'YYYY-MM-DD HH:mm:ss');
      var end = moment(endDate, 'YYYY-MM-DD HH:mm:ss');
      //Difference in number of days
      let days = moment.duration(end.diff(start)).asDays();

      let days_round = Math.round(days);

      if (startDate >= endDate) {
        toast.error("End date cannot be less than start date")
      } else if (days_round >= 31) {
        toast.error("Maximum 31 days can be selected")
      }
      else {
        this.reportGenerator();
      }
    }

    // this.setState({ isLoad: true })
  }
  reportGenerator() {

    let { reportType, startDate, endDate, settings } = this.state;
    let { dailyReports, sosReports, payment_logs, rider_details_report, activity_logs, order_details_report, voucher_logs_report, cancel_detail_report, storesReports, ridersReports, cancel_smryReports, customerReports, pmixReports, profitReports, smixReports, channelmixReports, deviceInfoReports, couponredemptionReports, discountReports, system_intrgrated_orders, order_item_details_report, aggregator_failed_orders, aggr_data_export } = this.props;
    dailyReports.length = 0;
    sosReports.length = 0;
    rider_details_report.length = 0;
    activity_logs.length = 0;
    payment_logs.length = 0;
    order_details_report.length = 0;
    voucher_logs_report.length = 0;
    cancel_smryReports.length = 0;
    cancel_detail_report.length = 0;
    storesReports.length = 0;
    ridersReports.length = 0;
    customerReports.length = 0;
    pmixReports.length = 0;
    smixReports.length = 0;
    channelmixReports.length = 0;
    deviceInfoReports.length = 0;
    couponredemptionReports.length = 0;
    profitReports.length = 0;
    discountReports.length = 0;
    system_intrgrated_orders.length = 0;
    order_item_details_report.length = 0;
    aggregator_failed_orders.length = 0;
    aggr_data_export.length = 0;
    let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
    let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
    let data: any = {
      start: startDateUtc,
      end: endDateUtc,
      store_id: (this.state.storeType == '' ? 0 : this.state.storeType)
    }
    if (reportType === "sales") {
      this.props.dailySalesReport(data);
      var table = $('#example').DataTable();
      table.destroy();
    } else if (reportType === "customer") {
      let customerData = {
        start: startDateUtc,
        end: endDateUtc
      }
      this.props.customersReport(customerData);
      var table = $('#customerreports').DataTable();
      table.destroy();
    } else if (reportType === "product") {
      this.props.pmixReport(data);
      var table = $('#pmix').DataTable();
      table.destroy();
    }
    else if (reportType === "salesmix") {
      this.props.salesmixReport(data);
      var table = $('#smix').DataTable();
      table.destroy();
    }
    else if (reportType === "channelmix") {
      this.props.channelmixReport(data);
      var table = $('#cmix').DataTable();
      table.destroy();
    }
    // else if (reportType === "devicemix") {
    //   this.props.deviceinfoReports(data);
    //   var table = $('#dmix').DataTable();
    //   table.destroy();
    // }
    else if (reportType === "couponredemption") {
      this.props.couponredemptionReport(data);
      var table = $('#couponred').DataTable();
      table.destroy()
    }
    else if (reportType === "profit") {
      this.props.profitReport(data);
      var table = $('#profitreports').DataTable();
      table.destroy()
    }
    else if (reportType === "discount") {
      this.props.discountReport(data);
      var table = $('#discountreports').DataTable();
      table.destroy()
    }
    else if (reportType === "sosReport") {
      this.props.sosReport(data);
      var table = $('#sosreports').DataTable();
      table.destroy()
    }
    else if (reportType === "riders") {
      this.props.ridersReport(data);
      var table = $('#ridersReport').DataTable();
      table.destroy()
    }
    else if (reportType === "store") {
      this.props.storesReport(data);
      var table = $('#storeReport').DataTable();
      table.destroy()
    }
    else if (reportType === "cancel_smry") {
      this.props.cancellationReport(data);
      var table = $('#cancel_smryReport').DataTable();
      table.destroy()
    }
    else if (reportType === "cancel_details") {
      this.props.cancellationDtetailsReport(data);
      var table = $('#cancel_detailsReport').DataTable();
      table.destroy()
    }
    else if (reportType === "order_details") {
      this.props.orderDetailsReport(data);
      var table = $('#order_detailsReport').DataTable();
      table.destroy();
    }
    else if (reportType === "voucher_orders_details") {
      this.props.voucherOrderDetailsReport(data);
      var table = $('#voucher_logs_Report').DataTable();
      table.destroy();
    }
    else if (reportType === "rider_details") {
      this.props.riderDetailsReport(data);
      var table = $('#rider_detailsReport').DataTable();
      table.destroy();
    }
    else if (reportType === "activity_logs") {
      this.props.activityLogsReport(data);
      var table = $('#activity_logsReport').DataTable();
      table.destroy();
    }
    else if (reportType === "payment_logs") {
      this.props.paymentLogsReport(data);
      var table = $('#payment_logsReport').DataTable();
      table.destroy();
    }
    else if (reportType === "system_intrgrated_orders") {
      this.props.orderSystemIntgReport(data);
      var table = $('#system_IntegratedOrdersReport').DataTable();
      table.destroy();
    } else if (reportType === "order_item_details") {
      this.props.orderItemDetailsReport(data);
      var table = $('#order_item_detailsReport').DataTable();
      table.destroy();
    } else if (reportType === "aggregator_failed_orders") {
      this.props.aggrFailedOrdersReport(data);
      var table = $('#aggr_failedOrdersReport').DataTable();
      table.destroy();
    } else if (reportType === "aggr_data_export") {
      this.props.aggrDataExportReport();
      var table = $('#aaggr_DataExportReport').DataTable();
      table.destroy();
    }
  }

  render() {
    let { dailyReports, sosReports, payment_logs, activity_logs, rider_details_report, order_details_report, voucher_logs_report, cancel_detail_report, cancel_smryReports, storesReports, ridersReports, stores, customerReports, pmixReports, smixReports, channelmixReports, deviceInfoReports, couponredemptionReports, profitReports, discountReports, msg, system_intrgrated_orders, order_item_details_report,aggregator_failed_orders, aggr_data_export, menuItems_extract, combos_extract, } = this.props;
    let { reportType } = this.state;
    let tokendata: any = jwt.decode(localStorage.token)
    let roleId = tokendata.role_id;
    let options = stores;
    let maxDate = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS')
    let newObj = {
      value: 404,
      label: "All Store"
    }
    if (options.length > 0) {
      if (reportType == "sales" && options[0].value != 404) {
        options = stores
        options.unshift(newObj)

      } else if (reportType != 'sales') {
        options = options.filter((opt: any) => {
          return opt.label != 'All Store'
        })
      }
    }


    if (localStorage.token) {
      jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />
    }
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header pb-1">
              <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between">
                  <h4>Reports Management</h4>
                </div>
              </div>
            </header>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <strong>
                          <p className="text-center font-weight-light text-danger mb-4">
                            {msg}
                          </p>
                        </strong>

                        <div className="form-horizontal">
                          <div className="row form-group">
                            <label
                              className="col-md-3 col-sm-3 form-control-label font-weight-bold"
                              style={{ paddingTop: "1em" }}
                            >
                              Report Type
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9 col-sm-9">
                              <select
                                name="reportType"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select brand"
                                onChange={this.handleInputChange}
                              >
                                <option value="">Select Report Type</option>
                                <optgroup label="Sales">
                                  <option value="sales">Total Sales</option>
                                  <option value="salesmix">
                                    Sales Mix
                                  </option>
                                  <option value="customer">Customer</option>
                                </optgroup>
                                <optgroup label="Product">
                                  <option value="product">
                                    Product Mix
                                  </option>
                                </optgroup>
                                <optgroup label="SOS">
                                  <option value="sosReport">
                                    Speed Of Services
                                  </option>
                                </optgroup>
                                <optgroup label="Channel">
                                  <option value="channelmix">
                                    Channel Mix
                                  </option>
                                  {/* <option value="devicemix">
                                    Device Mix
                                  </option> */}
                                </optgroup>
                                <optgroup label="Coupon">
                                  <option value="couponredemption">
                                    Coupon Redemption
                                  </option>
                                </optgroup>
                                <optgroup label="Profit">
                                  <option value="profit">
                                    Profit Margin
                                  </option>
                                </optgroup>
                                <optgroup label="Discount">
                                  <option value="discount">
                                    Discount
                                  </option>
                                </optgroup>
                                <optgroup label="Riders Efficiency">
                                  <option value="riders">
                                    Riders Efficiency Summary
                                  </option>
                                  <option value="rider_details">
                                    Riders Efficiency Details
                                  </option>
                                </optgroup>
                                <optgroup label="Stores">
                                  <option value="store">
                                    Stores Efficiency
                                  </option>
                                </optgroup>
                                <optgroup label="Order Details">
                                  <option value="order_details">
                                    Order Details
                                  </option>
                                  <option value="order_item_details">
                                    Order Item Details
                                  </option>
                                  <option value="voucher_orders_details">
                                    Voucher Logs
                                  </option>
                                </optgroup>
                                <optgroup label="Cancellation Report ">
                                  <option value="cancel_smry">
                                    Order Cancellation Summary
                                  </option>
                                  <option value="cancel_details">
                                    Order Cancellation Details
                                  </option>
                                </optgroup>
                                {(roleId == 1 || roleId == 2) &&
                                  <optgroup label="Activity Logs">
                                    <option value="activity_logs">
                                      Activity Logs
                                    </option>
                                  </optgroup>
                                }
                                <optgroup label="Aggregator Orders">
                                  <option value="aggregator_failed_orders">
                                    FoodPanda
                                  </option>
                                </optgroup>
                                {(roleId == 1 || roleId == 2) &&
                                  <optgroup label="Aggregator Data Extraction">
                                    <option value="aggr_data_export">
                                      FoodPanda
                                    </option>
                                  </optgroup>
                                }
                                {(roleId == 1 || roleId == 2) &&
                                  <optgroup label="Payment Logs">
                                    <option value="payment_logs">
                                      Payment Logs
                                    </option>
                                  </optgroup>
                                }
                                {(roleId == 1 || roleId == 2) &&
                                  <optgroup label="Integration Orders">
                                    <option value="system_intrgrated_orders">
                                      Failed Orders
                                    </option>
                                  </optgroup>
                                }
                              </select>
                            </div>
                          </div>
                          {(this.state.reportType !== "customer" && this.state.reportType !== "payment_logs" && this.state.reportType !== "discount" && this.state.reportType !== "store" && this.state.reportType !== "activity_logs" && this.state.reportType !== "aggregator_failed_orders" && this.state.reportType !== "aggr_data_export" && this.state.reportType !== "system_intrgrated_orders" && this.state.reportType !== "order_item_details" && this.state.reportType !== "aggregator_failed_orders" && this.state.reportType !== "aggr_data_export") &&
                            <div className="row form-group">
                              <label
                                className="col-md-3 col-sm-3 form-control-label font-weight-bold"
                                style={{ paddingTop: "1em" }}
                              >
                                Report By Stores
                              </label>
                              <div className="col-md-9 col-sm-9">
                                <Select
                                  //isMulti
                                  ref={el => this.storesDropDown = el}
                                  name="storeType"
                                  isClearable
                                  options={options}
                                  className="text-capitalize select mt-2"
                                  classNamePrefix="select"
                                  onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                />
                              </div>
                            </div>
                          }
                          {this.state.showDate == true && (
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label font-weight-bold">
                                    Start Date
                                    <small className="text-danger">*</small>
                                  </label>
                                  <input
                                    id="start"
                                    type="datetime-local"
                                    name="startDate"
                                    max={maxDate}
                                    required
                                    data-msg="Please enter Start Date"
                                    className="input-material"
                                    onChange={this.handleValidTime}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label font-weight-bold">
                                    End Date
                                    <small className="text-danger">*</small>
                                  </label>
                                  <input
                                    id="end"
                                    type="datetime-local"
                                    name="endDate"
                                    max={maxDate}
                                    required
                                    data-msg="Please enter End Date"
                                    className="input-material"
                                    onChange={this.handleValidTime}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="form-group float-right mt-3">
                          <button
                            className="btn btn-primary"
                            disabled={!this.isReportReady()}
                            onClick={this.validateTimeFromTo}
                          >
                            Generate Report
                          </button>
                        </div>
                      </div>
                      {(this.props.isLoad == true) ?
                        <div className="d-flex justify-content-center mb-4">
                          <Loader
                            type="TailSpin"
                            color="#007bff"
                            height={50}
                            width={50}
                          />
                        </div> :
                        <div className="row">

                          {(dailyReports && dailyReports.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="example"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th {...(this.state.storeType == '' && { style: { display: "none" } })}> {this.state.storeType != '' ? "Store" : null}</th>
                                        <th>Payment Method</th>
                                        <th>Void</th>
                                        <th>Discount</th>
                                        <th style={{ display: "none" }}></th>
                                        <th>total Sales ({currency})</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {dailyReports.map((data: any, index: any) => (
                                        <tr key={index}>
                                          <td {...(this.state.storeType == '' && { style: { display: "none" } })}>{this.state.storeType != '' ? data.store_name : null}</td>
                                          <td>{data.payment_method}</td>
                                          <td>{Math.round(data.Void)}</td>
                                          <td>{data.Discount}</td>
                                          <td style={{ display: "none" }}></td>
                                          <td>{Math.round(data.total)}</td>
                                        </tr>
                                      ))}
                                      {/* <span>Grand Total</span><span id="val">{sumVal.toFixed(2)}</span> */}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Grand Total</th>
                                        <th></th>
                                        <th></th>
                                        <th {...(this.state.storeType == '' && { style: { display: "none" } })}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>

                                </div>
                              </div>
                            </div>
                          ) : (pmixReports && pmixReports.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="pmix"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Item Name</th>
                                        <th>Group Name</th>
                                        <th>Channel</th>
                                        <th>Quantity Sold</th>
                                        <th>Quantity Percent</th>
                                        <th>Total Sales ({currency})</th>
                                        <th>Sales Percent</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {pmixReports.map((data: any, index: any) => (
                                        <tr key={index}>
                                          <td>{data.product_name} {data.variant_name ? `(${data.variant_name})` : ""} </td>
                                          <td>{data.group_name}</td>
                                          <td>{data.order_channel}</td>
                                          <td>{data.quantitySold}</td>
                                          <td>{data.quantitymixpercent ? Math.round(data.quantitymixpercent) : 0} %</td>
                                          <td>{Math.round(data.totalSales)}</td>
                                          <td>{data.salesmixpercent ? Math.round(data.salesmixpercent) : 0} %</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Grand Total</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (customerReports && customerReports.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="customerreports"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone Number</th>
                                        <th>Coupon Redeemed {currency}</th>
                                        <th style={{ display: "none" }}></th>
                                        <th>Order Amount ({currency})</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {customerReports.map((data: any, index: any) => (
                                        <tr key={index}>
                                          <td>{data.login_name}</td>
                                          <td>{data.email_address}</td>
                                          <td>{data.phone_number}</td>
                                          <td>{data.couponTotal}</td>
                                          <td style={{ display: "none" }}></td>
                                          <td>{Math.round(data.OrderAmount)}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Grand Total</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th style={{ display: "none" }}></th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>

                                </div>
                              </div>
                            </div>
                          ) : (smixReports && smixReports.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="smix"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Item Name</th>
                                        <th>Group Name</th>
                                        <th>Quantity Sold</th>
                                        <th>Quantity Mix Percentage</th>
                                        <th>Sales Mix Percentage</th>
                                        <th>Total Sales ({currency})</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {smixReports.map((data: any, index: any) => (
                                        <tr key={index}>
                                          <td>{data.product_name}</td>
                                          <td>{data.group_name}</td>
                                          <td>{data.quantitySold}</td>
                                          <td>{data.quantitymixpercent ? Math.round(data.quantitymixpercent) : 0} %</td>
                                          <td>{data.salesmixpercent ? Math.round(data.salesmixpercent) : 0} %</td>
                                          <td>{Math.round(data.totalSales)}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Grand Total</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (channelmixReports && channelmixReports.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="cmix"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Ticket Average</th>
                                        <th>Order Channel</th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th>Total Sales ({currency})</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {channelmixReports.map((data: any, index: any) => (
                                        <tr key={index}>
                                          <td>{data.avgTicket}</td>
                                          <td>{data.order_channel}</td>
                                          <td style={{ display: "none" }}></td>
                                          <td style={{ display: "none" }}></td>
                                          <td style={{ display: "none" }}></td>
                                          <td>{Math.round(data.total)}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Grand Total</th>
                                        <th></th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                            // ) : (deviceInfoReports && deviceInfoReports.length > 0) ? (
                            //   <div className="col-lg-12 col-12">
                            //     <div className="card">
                            //       <div className="card-body text-capitalize">
                            //         <table
                            //           className="table-custom"
                            //           id="dmix"
                            //           style={{ width: "100%" }}
                            //         >
                            //           <thead>
                            //             <tr>
                            //               <th>Order Device</th>
                            //               <th style={{ display: "none" }}></th>
                            //               <th style={{ display: "none" }}></th>
                            //               <th style={{ display: "none" }}></th>
                            //               <th style={{ display: "none" }}></th>
                            //               <th>Total Sales ({currency})</th>
                            //             </tr>
                            //           </thead>
                            //           <tbody>
                            //             {deviceInfoReports.map((data: any, index: any) => (
                            //               <tr key={index}>
                            //                 <td>{data.device_info}</td>
                            //                 <td style={{ display: "none" }}></td>
                            //                 <td style={{ display: "none" }}></td>
                            //                 <td style={{ display: "none" }}></td>
                            //                 <th style={{ display: "none" }}></th>
                            //                 <td>{Math.round(data.total)}</td>
                            //               </tr>
                            //             ))}
                            //           </tbody>
                            //           <tfoot>
                            //             <tr>
                            //               <th>Grand Total</th>
                            //               <th style={{ display: "none" }}></th>
                            //               <th style={{ display: "none" }}></th>
                            //               <th style={{ display: "none" }}></th>
                            //               <th style={{ display: "none" }}></th>
                            //               <th></th>
                            //             </tr>
                            //           </tfoot>
                            //         </table>
                            //       </div>
                            //     </div>
                            //   </div>
                          ) : (couponredemptionReports && couponredemptionReports.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="couponred"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Store Name</th>
                                        <th>Order Channel</th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th>Total Coupon Redeemed ({currency})</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {couponredemptionReports.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.store_name}</td>
                                            <td>{data.order_channel}</td>
                                            <td style={{ display: "none" }}></td>
                                            <td style={{ display: "none" }}></td>
                                            <td style={{ display: "none" }}></td>
                                            <td>{data.couponTotal ? Math.round(data.couponTotal) : 0}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Grand Total</th>
                                        <th></th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (profitReports && profitReports.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="profitreports"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Product Name</th>
                                        <th>Quantity (Sold Unit)</th>
                                        <th>Profit Margin</th>
                                        <th>Profit %</th>
                                        <th style={{ display: "none" }}></th>
                                        <th>Total Sales ({currency})</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {profitReports.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>
                                              {data.combo_name ? data.combo_name : data.item_name}
                                            </td>
                                            {/* {data.item_name &&
                                              <td>{data.item_name}</td>
                                            } */}
                                            <td>{data.quantitySold}</td>
                                            <td>{data.profitMargin == "NaN" ? 0 : data.profitMargin}</td>
                                            <td>{data.profitPercent == "NaN" ? 0 : Math.round(data.profitPercent)} %</td>
                                            <td style={{ display: "none" }}></td>
                                            <td>{data.totalSales ? Math.round(data.totalSales) : 0}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Grand Total</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th style={{ display: "none" }}></th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (discountReports && discountReports.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="discountreports"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Customer Name</th>
                                        <th>Total Discount ({currency})</th>
                                        <th style={{ display: "none" }}></th>
                                        <th>Discount %</th>
                                        <th style={{ display: "none" }}></th>
                                        <th>Total Sales ({currency})</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {discountReports.map(
                                        (data: any, index: any) => (
                                          <tr>
                                            <td>{data.customer}</td>
                                            <td>{data.totalDiscount}</td>
                                            <td style={{ display: "none" }}></td>
                                            <td>{data.discountPercent == "NaN" ? 0 : Math.round(data.discountPercent)} %</td>
                                            <td style={{ display: "none" }}></td>
                                            <td>{data.totalSales ? Math.round(data.totalSales) : ""}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Grand Total</th>
                                        <th></th>
                                        <th style={{ display: "none" }}></th>
                                        <th></th>
                                        <th style={{ display: "none" }}></th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (sosReports && sosReports.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="sosreports"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Order Id</th>
                                        <th>Order Type</th>
                                        <th>Order Placed Time</th>
                                        <th>Prep Time</th>
                                        <th>Ready Time</th>
                                        <th>Dispatch Time</th>
                                        <th>Total Delivery Time</th>
                                        <th>Channel</th>
                                        <th>Payment Method</th>
                                        <th>Order Recieved</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {sosReports.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.order_id}</td>
                                            <td>{data.delivery_status}</td>
                                            <td>{data.order_placed}</td>
                                            <td>{data.prep}</td>
                                            <td>{data.ready}</td>
                                            <td>{data.dispatch}</td>
                                            <td>{Math.round(data.total)}</td>
                                            <td>{data.order_channel}</td>
                                            <td>{data.payment_method}</td>
                                            <td>{data.date_created}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (ridersReports && ridersReports.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="ridersReport"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Riders</th>
                                        <th>Total Orders</th>
                                        <th>Avg Delivery Time</th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th>Order Amount</th>

                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ridersReports.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.Rider}</td>
                                            <td>{data.totalOrders}</td>
                                            <td>{data.avgDeliveryTime}</td>
                                            <td style={{ display: "none" }}></td>
                                            <td style={{ display: "none" }}></td>
                                            <td>{data.totalSale ? Math.round(data.totalSale) : ""}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (rider_details_report && rider_details_report.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="rider_detailsReport"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Riders</th>
                                        <th>Trade Zone</th>
                                        <th>Dispatched Time</th>
                                        <th>Delivered Time</th>
                                        <th>Order Time</th>
                                        <th>Order Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {rider_details_report.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.Rider}</td>
                                            <td>{data.trade_zone_name}</td>
                                            <td>{data.quality_check}</td>
                                            <td>{data.delivered}</td>
                                            <td>{moment(data.date_created).local().format('DD MMM YYYY HH:mm')}</td>
                                            <td>{data.order_amount ? Math.round(data.order_amount) : ""}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (storesReports && storesReports.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="storeReport"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Stores</th>
                                        <th>Avg OrderPlaced Time</th>
                                        <th>Avg Prep Time</th>
                                        <th>Avg Bake Time</th>
                                        <th>Avg QaulityCheck Time</th>
                                        <th>Total Sales</th>
                                        <th>Total Orders</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {storesReports.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.Store}</td>
                                            <td>{data.avgOrderPlacedTime}</td>
                                            <td>{data.avgPrepTime}</td>
                                            <td>{data.avgBakeTime}</td>
                                            <td>{data.avgQualityCheckTime}</td>
                                            <td>{data.totalSale ? Math.round(data.totalSale) : 0}</td>
                                            <td>{data.totalOrders ? data.totalOrders : 0}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Grand Total</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (cancel_smryReports && cancel_smryReports.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="cancel_smryReport"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Stores</th>
                                        <th>Reason</th>
                                        <th>Cancel Count</th>
                                        <th>Cancel Count Percentage</th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {cancel_smryReports.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.Store}</td>
                                            <td>{data.reason}</td>
                                            <td>{data.cancelCount}</td>
                                            <td>{data.cancelCountPer}</td>
                                            <td style={{ display: "none" }}></td>
                                            <td style={{ display: "none" }}></td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (cancel_detail_report && cancel_detail_report.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="cancel_detailsReport"
                                    style={{ display: 'block', overflowX: 'auto', whiteSpace: 'nowrap' }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Order Id</th>
                                        <th>Stores</th>
                                        <th>Reason</th>
                                        <th style={{ width: '300px !important' }}>Description</th>
                                        <th>Driver</th>
                                        <th>User</th>
                                        <th>Customer Name</th>
                                        <th>Contact Number</th>
                                        <th>Delivery Address</th>
                                        <th>Order Time</th>
                                        <th>Cancellation Time</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {cancel_detail_report.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.order_id}</td>
                                            <td>{data.Store}</td>
                                            <td>{data.reason}</td>
                                            {/* <td title={`${data.cancel_reason_description}`}>{(data.cancel_reason_description && data.cancel_reason_description.length > 20) ? `${data.cancel_reason_description.slice(0, 20)}` : data.cancel_reason_description}</td> */}
                                            <td style={{ width: '300px !important', whiteSpace: 'nowrap' }} title={`${data.cancel_reason_description}`}>{data.cancel_reason_description && data.cancel_reason_description}</td>
                                            <td>{data.driver}</td>
                                            <td>{data.user}</td>
                                            <td>{data.login_name}</td>
                                            <td>{data.phone_number}</td>
                                            <td style={{ whiteSpace: 'normal' }} >{data.delivery_address}</td>
                                            <td>{moment(data.order_time).local().format('DD MMM YYYY HH:mm')}</td>
                                            <td>{moment(data.cancel_time).local().format('DD MMM YYYY HH:mm')}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (order_details_report && order_details_report.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="order_detailsReport"
                                    style={{ display: 'block', overflowX: 'auto', whiteSpace: 'nowrap', width: '100%' }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>OrderID</th>
                                        <th>CustomerID</th>
                                        <th>CustomerName</th>
                                        <th>CustomerMobile</th>
                                        <th>OrderAmount</th>
                                        <th>Store</th>
                                        <th>Status</th>
                                        <th>OrderType</th>
                                        <th>Delivery Address</th>
                                        <th>Area</th>
                                        <th>Discount</th>
                                        <th>TaxAmount</th>
                                        <th>OrderTime</th>
                                        <th>PaymentType</th>
                                        <th>DeliveryInstructions</th>
                                        <th>Platform</th>
                                        <th>PulseOrderID</th>
                                        <th>WhenToOrder</th>
                                        <th>PulseStoreID</th>
                                        <th>PulseOrderStatus</th>
                                        <th>DeviceInfo</th>
                                        {/* <th>Trade Zone</th>
                                        <th>Delivery Fee</th>
                                        <th>Rider</th>
                                        <th>Order Placed Time</th>
                                        <th>Prep Time</th>
                                        <th>Bake Time</th>
                                        <th>Quality Check Time</th>
                                        <th>Delivered Time</th>
                                        <th>Confirme Date Time</th>
                                        <th>Pending Date Time</th>
                                        <th>Kitchen Date Time</th>
                                        <th>Ready Date Time</th>
                                        <th>Dispatched Date Time</th>
                                        <th>Delivered Date Time</th>
                                        <th>Order Transfer</th>
                                        <th>Order Transfer Caller</th>
                                        <th>Order Transfer User</th>
                                        <th>Order Transfer User Role</th>
                                        <th>Order Transfer Status</th>
                                        <th>Order Transfer Date</th>
                                        <th>Order Transfer Reason</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {order_details_report.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.order_id}</td>
                                            <td>{data.customer_id}</td>
                                            <td>{data.login_name}</td>
                                            <td>{data.phone_number}</td>
                                            <td>{Math.round(data.order_grossprice)}</td>
                                            <td>{data.store_name}</td>
                                            <td>{(data.check_number && data.check_number !== "") ? "PUSHED TO PULSE" : ""}</td>
                                            <td>{data.delivery_status}</td>
                                            <td style={{ whiteSpace: 'normal' }} >{data.delivery_address}</td>
                                            <td>{data.area_name}</td>
                                            <td>{data.discount}</td>
                                            <td>{data.tax_amount}</td>
                                            <td>{moment(data.order_time).local().format('DD MMM YYYY HH:mm')}</td>
                                            <td>{data.payment_method}</td>
                                            <td>{data.special_inst}</td>
                                            <td>{data.order_channel}</td>
                                            <td>{data.check_number}</td>
                                            <td>{data.future_status == 0 ? "now" : "future"}</td>
                                            <td>{data.branch_code}</td>
                                            <td>{data.order_status_description}</td>
                                            <td>{data.device_info}</td>
                                            {/* <td>{data.order_placed}</td>
                                            <td>{data.prep}</td>
                                            <td>{data.bake}</td>
                                            <td>{data.quality_check}</td>
                                            <td>{data.delivered}</td>
                                            <td>{data.confirmed_datetime}</td>
                                            <td>{data.pending_datetime}</td>
                                            <td>{data.kitchen_datetime}</td>
                                            <td>{data.ready_datetime}</td>
                                            <td>{data.dispatched_datetime}</td>
                                            <td>{data.delivered_datetime}</td>
                                            <td>{data.is_order_transfer == 0 ? "No" : "Yes"}</td>
                                            <td>{data.order_transfer_caller}</td>
                                            <td>{data.order_transfer_user}</td>
                                            <td>{data.order_transfer_userRole}</td>
                                            <td>{data.order_transfer_status}</td>
                                            <td>{data.order_transfer_date && moment(data.order_transfer_date).local().format('DD MMM YYYY HH:mm')}</td>
                                            <td>{data.order_transfer_reason}</td> */}
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (voucher_logs_report && voucher_logs_report.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="voucher_logs_Report"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>OrderID</th>
                                        <th>VoucherCode</th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th>ApplyTime</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {voucher_logs_report.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.order_id}</td>
                                            <td>{data.coupon_code}</td>
                                            <td style={{ display: "none" }}></td>
                                            <td style={{ display: "none" }}></td>
                                            <td style={{ display: "none" }}></td>
                                            <td>{moment(data.order_time).local().format('DD MMM YYYY HH:mm')}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (activity_logs && activity_logs.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="activity_logsReport"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>ID</th>
                                        <th>User Name</th>
                                        <th>Role</th>
                                        <th>Activity Type</th>
                                        <th>Activity Name</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {activity_logs.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.id}</td>
                                            <td>{data.user_name}</td>
                                            <td>{data.role}</td>
                                            <td>{data.activity_type}</td>
                                            <td title={data.activity_name}>{(data.activity_name && data.activity_name.length > 15) ? `${data.activity_name.slice(0, 15)}....` : data.activity_name}</td>
                                            <td>{data.status}</td>
                                            <td>{moment(data.date_created).local().format('DD MMM YYYY HH:mm')}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (payment_logs && payment_logs.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="payment_logsReport"
                                    style={{ display: 'block', overflowX: 'auto', whiteSpace: 'nowrap', width: '100%' }}
                                  >
                                    <thead>
                                      <tr>
                                        <th style={{ marginRight: '45px' }}>ID</th>
                                        <th>Cms Id</th>
                                        <th>Phone</th>
                                        <th>Bank OrderId</th>
                                        <th>Api Called</th>
                                        <th>Api Success</th>
                                        <th>Payment Status</th>
                                        {/* <th>Payment Response</th> */}
                                        <th>Bank Api Call Time</th>
                                        <th>Bank Api Response Time</th>
                                        {/* <th>Order Json</th> */}
                                        <th>Status</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {payment_logs.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.id}</td>
                                            <td>{data.cms_order_id}</td>
                                            <td>{data.phone_number}</td>
                                            <td>{data.bank_order_id}</td>
                                            <td>{data.api_called == 1 ? 'True' : 'False'}</td>
                                            <td>{data.api_success == 1 ? 'True' : 'False'}</td>
                                            <td>{data.payment_status}</td>
                                            {/* <td>{data.api_response}</td> */}
                                            <td>{data.api_call_time}</td>
                                            <td>{data.payment_response_time}</td>
                                            {/* <td>{data.order_json}</td> */}
                                            <td>{data.status}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (system_intrgrated_orders && system_intrgrated_orders.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="system_IntegratedOrdersReport"
                                    style={{ display: 'block', overflowX: 'scroll', whiteSpace: 'nowrap', width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>OrderId</th>
                                        <th>API Called</th>
                                        <th>Description</th>
                                        <th>Sync</th>
                                        <th>Response</th>
                                        <th style={{ display: 'none' }}></th>
                                        <th>Integration Time</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {system_intrgrated_orders.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.order_id}</td>
                                            <td><span className={data.api_called == 1 ? 'reports badge-success' : 'reports badge-fail'} style={{ textAlign: 'center' }}>{data.api_called == 1 ? 'True' : 'False'}</span></td>
                                            <td>{data.description}</td>
                                            <td><span className={data.manual_sync == 0 ? 'reports badge-success' : 'reports badge-fail'} style={{ textAlign: 'center' }}>{data.manual_sync == 1 ? 'Manual' : 'System'}</span></td>
                                            <td>{data.response}</td>
                                            <td style={{ display: 'none' }}></td>
                                            <td>{moment(data.date_created).local().format('DD MMM YYYY HH:mm')}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (order_item_details_report && order_item_details_report.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="order_item_detailsReport"
                                    style={{ display: 'block', overflowX: 'auto', whiteSpace: 'nowrap', width: '100%' }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>OrderId</th>
                                        <th>OrderDateTime</th>
                                        <th>OrderStatus</th>
                                        <th>OrderChannel</th>
                                        <th>Payment Method</th>
                                        <th>Agent</th>
                                        <th>BranchCode</th>
                                        <th>OutletName</th>
                                        <th>CustomerName</th>
                                        <th>CustomerPhone</th>
                                        <th>Items</th>
                                        <th>Combos</th>
                                        <th>Category</th>
                                        <th>Qauntity</th>
                                        <th>OrderAmount({currency})</th>
                                        {/* <th>Make A Combo Included</th>
                                        <th>Make A Combo Item Name</th>
                                        <th>Make A Combo Item Quantity</th>
                                        <th>Make A Combo Item Price</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {order_item_details_report.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.OrderId}</td>
                                            <td>{data.OrderDateTime}</td>
                                            <td>{data.OrderStatus}</td>
                                            <td>{data.OrderChannel}</td>
                                            <td>{data.payment_method}</td>
                                            <td>{data.Agent}</td>
                                            <td>{data.BranchCode}</td>
                                            <td>{data.OutletName}</td>
                                            <td>{data.CustomerName}</td>
                                            <td>{data.CustomerPhone}</td>
                                            <td>{data.item_name} {data.quick_combo && "(Make A Combo Items)"} {data.quick_combo && `Name: ${data.quick_combo.item_name} Quantity: ${data.quick_combo.quantity} Price: ${data.quick_combo.mrp}`}</td>
                                            <td>{data.combo_name}</td>
                                            <td>{data.Category}</td>
                                            <td>{data.Qauntity}</td>
                                            <td>{data.OrderAmount}</td>

                                            {/* <td>{data.quick_combo ? 'Yes' : 'No'}</td>
                                            <td>{data.quick_combo && data.quick_combo.item_name}</td>
                                            <td>{data.quick_combo && data.quick_combo.quantity}</td>
                                            <td>{data.quick_combo && data.quick_combo.mrp}</td> */}
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (aggregator_failed_orders && aggregator_failed_orders.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="aggr_failedOrdersReport"
                                    style={{ display: 'block', overflowX: 'auto', whiteSpace: 'nowrap', width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Aggregator OrderId</th>
                                        {/* <th>Aggregator Name</th> */}
                                        <th>Store Name</th>
                                        <th>CMS OrderId</th>
                                        <th>Status</th>
                                        <th>Order Time</th>
                                        <th>Exception</th>
                                        <th style={{ display: 'none' }}></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {aggregator_failed_orders.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.aggregator_orderId}</td>
                                            <td>{data.store_name}</td>
                                            <td>{data.order_id}</td>
                                            {/* <td>{data.aggregator_name}</td> */}
                                            <td><span className={data.is_success == 1 ? 'reports badge-success' : 'reports badge-fail'}>{data.is_success == 1 ? 'Success' : 'Fail'}</span></td>
                                            <td>{moment(data.date_created).local().format('DD MMM YYYY HH:mm')}</td>
                                            <td>{data.exception_description}</td>
                                            <td style={{ display: 'none' }}></td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (aggr_data_export && aggr_data_export.length > 0) ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="aaggr_DataExportReport"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Category Name</th>
                                        <th>Name</th>
                                        <th>Combo Code</th>
                                        <th>Size</th>
                                        <th>Code</th>
                                        <th>Price</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {aggr_data_export.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.group_name}</td>
                                            <td>{data.item_name}</td>
                                            <td>{data.combo_pos_code ? data.combo_pos_code : ''}</td>
                                            <td>{data.item_size}</td>
                                            <td>{data.variant_poscode}</td>
                                            <td>{data.price}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Menu Items:  {menuItems_extract}</th>
                                        <th></th>
                                        <th>Combos:  {combos_extract} </th>
                                        <th></th>
                                        <th>Total:  {menuItems_extract + combos_extract}</th>
                                        <th style={{ display: 'none' }}></th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : ""
                          }

                        </div>
                      }
                      {/* Pagination */}
                      {/* {this.props.pageCount > 1 &&
                        <div className="row">
                          <div className="col-12 d-flex flex-row-reverse">
                            <div className="mr-4">
                              <ReactPaginate
                                //initialPage={this.props.currentPage}
                                breakLinkClassName={'page-link'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                                previousLabel={"prev"}
                                nextLabel={"next"}
                                breakLabel={"...."}
                                breakClassName={"page-item"}
                                pageCount={this.props.pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={this.handlePageClick}
                                disableInitialCallback={true}  //Disable onPageChange callback with initial page. Default: false
                                containerClassName={"pagination"}
                                activeClassName={"active"} />
                            </div>
                          </div>
                        </div>
                      } */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
;
const mapStateToProps = (state: any) => {
  return {
    dailyReports: state.report.dailyReports,
    sosReports: state.report.sosReports,
    stores: state.report.stores,
    activity_logs: state.report.activity_logs,
    rider_details_report: state.report.rider_details_report,
    cancel_detail_report: state.report.cancel_detail_report,
    order_details_report: state.report.order_details_report,
    voucher_logs_report: state.report.voucher_logs_report,
    cancel_smryReports: state.report.cancel_smryReports,
    ridersReports: state.report.ridersReports,
    storesReports: state.report.storesReports,
    allstores: state.report.all_stores,
    customerReports: state.report.customerReports,
    pmixReports: state.report.pmixReports,
    smixReports: state.report.smixReports,
    channelmixReports: state.report.channelmixReports,
    deviceInfoReports: state.report.deviceInfoReports,
    couponredemptionReports: state.report.couponredemptionReports,
    profitReports: state.report.profitReports,
    aloha_orders: state.report.aloha_orders,
    discountReports: state.report.discountReports,
    // currentPage: state.report.currentPage,
    // pageCount: state.report.pageCount,
    // numOfRows: state.report.numOfRows,
    isLoad: state.report.isLoad,
    system_intrgrated_orders: state.report.system_intrgrated_orders,
    order_item_details_report: state.report.order_item_details_report,
    payment_logs: state.report.payment_logs,
    aggregator_failed_orders: state.report.aggregator_failed_orders,
    aggr_data_export: state.report.aggr_data_export,
    total_data_extract: state.report.total_data_extract,
    combos_extract: state.report.combos_extract,
    menuItems_extract: state.report.menuItems_extract,
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    dailySalesReport: function (data: any) {
      dispatch(dailySalesReport(data))
    },
    customersReport: function (data: any) {
      dispatch(customersReport(data))
    },
    pmixReport: function (data: any) {
      dispatch(pmixReport(data))
    },
    cancellationDtetailsReport: function (data: any) {
      dispatch(cancellationDtetailsReport(data))
    },
    riderDetailsReport: function (data: any) {
      dispatch(riderDetailsReport(data))
    },
    activityLogsReport: function (data: any) {
      dispatch(activityLogsReport(data))
    },
    cancellationReport: function (data: any) {
      dispatch(cancellationReport(data))
    },
    salesmixReport: function (data: any) {
      dispatch(salesmixReport(data))
    },
    ridersReport: function (data: any) {
      dispatch(ridersReport(data))
    },
    orderDetailsReport: function (data: any) {
      dispatch(orderDetailsReport(data))
    },
    voucherOrderDetailsReport: function (data: any) {
      dispatch(voucherOrderDetailsReport(data))
    },
    storesReport: function (data: any) {
      dispatch(storesReport(data))
    },
    channelmixReport: function (data: any) {
      dispatch(channelmixReport(data))
    },
    deviceinfoReports: function (data: any) {
      dispatch(deviceinfoReports(data))
    },
    couponredemptionReport: function (data: any) {
      dispatch(couponredemptionReport(data))
    },
    profitReport: function (data: any) {
      dispatch(profitReport(data))
    },
    discountReport: function (data: any) {
      dispatch(discountReport(data))
    },
    sosReport: function (data: any) {
      dispatch(sosReport(data))
    },
    storesList: function () {
      dispatch(storesList())
    },
    orderSystemIntgReport: function (data: any) {
      dispatch(orderSystemIntgReport(data))
    },
    orderItemDetailsReport: function (data: any) {
      dispatch(orderItemDetailsReport(data))
    },
    paymentLogsReport: (data: any) => {
      dispatch(paymentLogsReport(data))
    },
    aggrFailedOrdersReport: function (data: any) {
      dispatch(aggrFailedOrdersReport(data))
    },
    aggrDataExportReport: function () {
      dispatch(aggrDataExportReport())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Reports);