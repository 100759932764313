import Api from '../../components/Api';
import { EMP_LIST, GET_EMP, ACTIVE_EMP_COUPONS, EMP_TYPE_LIST } from './empTypes';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
export const employeeList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/employees/employeesList', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    response.data.successResponse.forEach((obj: any) => {
                        obj.label = obj.name;
                        obj.value = obj.id;
                    })
                    dispatch({
                        type: EMP_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addEmp = (data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post('/employees/add_emp', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/employees"
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    if (error.includes("Duplicate entry")) {
                        toast.error("Employee on this phone number already exist, kindly use another phone number", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 });
                    }
                    else {
                        toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    }
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editEmp = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.put(`/employees/edit_emp/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/employees"
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    if (error.includes("Duplicate entry")) {
                        toast.error("Employee on this phone number already exist, kindly use another phone number", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 });
                    }
                    else {
                        toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    }

                } else {
                    alert(err.message)
                }
            });
    }
}
export const getEmp = (id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/employees/employee/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_EMP,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockEmp = (id: any, isActive: any, email_address: any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1;
        } else if (isActive === 1) {
            is_active = 0;
        }
        let token: any = localStorage.getItem('token');
        let data = {
            is_active: is_active,
            email_address: email_address
        }
        Api.put(`/employees/block_unblock_emp/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/employees"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const empCoupons = (pageType?: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/active_emp_coupons', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ACTIVE_EMP_COUPONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const empTypeList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/employees/getAllEmpTypes', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let data = response.data.successResponse;
                    for (let i = 0; i < data.length; i++) {
                        data[i].label = data[i].name;
                        data[i].value = data[i].id;
                    }
                    dispatch({
                        type: EMP_TYPE_LIST,
                        payload: data
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}