import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { EditModProps, EditModState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { logoutUser, editModifier, getModifier, modGroupsList, groupsList } from '../../../redux'
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';
import { getItemsByGroup, getVariantsByItem, variantsList } from '../../../redux/actions/menuAction';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { IMAGE_URL } from '../../../client-config';

class EditMod extends Component<EditModProps, EditModState> {
    constructor(props: any) {
        super(props);
        this.state = {
            modGroupJson: [],
            modifiername: "",
            erpid: "",
            poscode: "",
            priority: "",
            modifiertype: "",
            nutritional_info:"",
            modifiercost: "",
            modifiersale: "",
            images: null,
            for_specific_variations: '0',
            min_quantity: "",
            variant_id: [],
            max_quantity: "",
            // sizejson: [
            //     {
            //         nutritional_info: "",
            //         variant_id: "",
            //     }
            // ]
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.groupsList();
        this.props.getModifier(id);
        this.props.modGroupsList();
        this.props.variantsList()
        document.title = "Crust Culture | Modifiers"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        let optionsObj: any = []
        if (nextProps.modData.for_specific_variations == "1") {
            this.setState({
                modGroupJson: nextProps.modData.mod_groups_json && JSON.parse(nextProps.modData.mod_groups_json),
                variant_id: nextProps.modData.variants && JSON.parse(nextProps.modData.variants),
                erpid: nextProps.modData.erp_id,
                poscode: nextProps.modData.pos_code,
                priority: nextProps.modData.priority,
                modifiername: nextProps.modData.modifier_name,
                modifiertype: nextProps.modData.modifier_type,
                nutritional_info: nextProps.modData.total_nutrition,
                modifiercost: nextProps.modData.modifier_cost_price,
                modifiersale: nextProps.modData.modifier_sale_price,
                for_specific_variations: nextProps.modData.for_specific_variations
                // min_quantity:nextProps.modData.min_quantity,
                // max_quantity:nextProps.modData.max_quantity,
            })
        } else if (nextProps.modData.for_specific_variations == "0") {
            this.setState({
                modGroupJson: nextProps.modData.mod_groups_json && JSON.parse(nextProps.modData.mod_groups_json),
                erpid: nextProps.modData.erp_id,
                poscode: nextProps.modData.pos_code,
                priority: nextProps.modData.priority,
                modifiername: nextProps.modData.modifier_name,
                modifiertype: nextProps.modData.modifier_type,
                nutritional_info: nextProps.modData.total_nutrition,
                modifiercost: nextProps.modData.modifier_cost_price,
                modifiersale: nextProps.modData.modifier_sale_price,
                for_specific_variations: nextProps.modData.for_specific_variations,
                // min_quantity:nextProps.modData.min_quantity,
                // max_quantity:nextProps.modData.max_quantity,
            })
        }
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    fileSelectedHandler = (e: any) => {
            this.setState({ images: e.target.files })
    }
    isModifierReady = () => {
        const { modifiername, modifiercost, modGroupJson, modifiersale } = this.state;
        return (modifiername !== "" && (modGroupJson && modGroupJson.length > 0) && modifiercost !== "" && modifiersale !== "");
    }
    handleModGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ modGroupJson: e });
        }
    };
    // handle click event of the Add button
    handleVariantChange = async (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ variant_id: e });
        }else{
            this.setState({ variant_id: [] });
        }
    }
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    // handleVariantInputChange = (e: any, index: any) => {
    //     const { name, value } = e.target;
    //     const list: any = this.state.sizejson;
    //     list[index][name] = value;
    //     this.setState({ sizejson: list });
    // };
    // handleRemoveClick = (index: any) => {
    //     const list = this.state.sizejson;
    //     list.splice(index, 1);
    //     this.setState({ sizejson: list });
    // };
    // handleAddClick = (i: any) => {
    //     const list: any = this.state.sizejson;
    //     if (list[i]["variant_id"] == "" || list[i]["nutritional_info"] == "") {
    //         toast.error("Please fill all mandatory nutrition fields", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: false, autoClose: 3000 });
          
    //     } else {
    //         const newlist: any = { variant_id: "", nutritional_info: "" }

    //         const nutrition: any = this.state.sizejson.concat(newlist)
    //         this.setState({ sizejson: nutrition });
    //     }

    // };
    handleSubmit = (event: any) => {
        let { for_specific_variations, modifiername, erpid, poscode, priority, modifiertype, modifiercost, modGroupJson, modifiersale, variant_id ,nutritional_info} = this.state;
        let id = this.props.match.params.id;
        const data: any = new FormData()
        // const SizeJsonString = JSON.stringify(sizejson)
        if (this.state.images) {
            data.append('file', this.state.images[0])
        }
        data.append('modifier_name', modifiername)
        if (erpid !== null && erpid !== "") {
            data.append('erp_id', erpid)
        } else {
            data.append('erp_id', null)
        }
        if (poscode !== null && poscode !== "") {
            data.append('pos_code', poscode)
        } else {
            data.append('pos_code', null)
        }
        data.append('modifier_type', modifiertype)
        data.append('total_nutrition', nutritional_info)
        data.append('modifier_cost_price', modifiercost)
        data.append('modifier_sale_price', modifiersale)
        data.append('priority', priority)
        data.append('mod_groups_json', JSON.stringify(modGroupJson))
//         if (sizejson[sizejson.length - 1]["variant_id"] !== "" && sizejson[sizejson.length - 1]["nutritional_info"] !== "") {
//             data.append('itemSizes', SizeJsonString)
// }
        // data.append('min_quantity', min_quantity)
        // data.append('max_quantity', max_quantity)
        if (for_specific_variations == '0') {
            data.append('for_specific_variations', 0)
            this.props.editModifier(id, data);
        } else if (for_specific_variations == '1') {
            data.append('for_specific_variations', 1)
            data.append('variants', JSON.stringify(variant_id))
            if (variant_id.length > 0) {
                this.props.editModifier(id, data);
            } else {
                alert("Please fill all mandatory variant fields")
            }
        }

    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const { modGroupJson, for_specific_variations, variant_id } = this.state;
        const { groups, items, variants, modData, modgroups } = this.props;
        let variants_arr: any = []
        variants.map((varr: any) => {
            variants_arr.push({ label: varr.name, value: varr.id })
        });
        return (
            <div className="page">
                <CheckChanges path="/edit-modifier" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">FoodPanda Modifier Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/fp-modifiers" className="text-primary">FoodPanda Modifiers</Link></li>
                                <li className="breadcrumb-item active">View Modifier</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifier Name <span className="text-danger">*</span></label>
                                                            <input id="modifiername" type="text" name="modifiername" defaultValue={modData.modifier_name} required data-msg="Please enter Modifier Name" className="input-material" readOnly/>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">ERP ID</label>
                                                            <input id="erpid" type="text" name="erpid" defaultValue={modData.erp_id} className="input-material" readOnly/>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">POS Code</label>
                                                            <input id="poscode" type="text" name="poscode" defaultValue={modData.pos_code} className="input-material" readOnly/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-8 col-sm-8 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifier Group <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="groups"
                                                                value={modGroupJson}
                                                                options={modgroups}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleModGroupsInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority</label>
                                                            <input id="priority" type="text" name="priority" defaultValue={modData.priority} required data-msg="Please enter Priority" className="input-material"  readOnly/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-3 col-sm-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifier Type</label>
                                                            <input id="modifiertype" type="text" name="modifiertype" defaultValue={modData.modifier_type} className="input-material"  readOnly/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Modifier Image</label>
                                                            <input id="file" type="file" name="file" className="form-control-file" readOnly/>
                                                            <small className="form-text">Image size should be less than 1 mb</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-2 col-2">
                                                        <img style={{ width: "100px",margin:"auto" }} alt="No Image" src={`${IMAGE_URL}${modData && modData.image_url && modData.image_url}`} />
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Nutritional Info</label>
                                                            <input id="nutritional_info" defaultValue={modData && modData.total_nutrition} type="text" required data-msg="Please enter Nutritional Info" name="nutritional_info" className="input-material" readOnly/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Cost Price <span className="text-danger">*</span></label>
                                                            <input id="modifiercost" type="number" onWheel={(e:any) => e.target.blur()} name="modifiercost" defaultValue={modData.modifier_cost_price} required data-msg="Please enter Cost Price" className="input-material" readOnly/>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Sale Price <span className="text-danger">*</span></label>
                                                            <input id="modifiersale" type="number" onWheel={(e:any) => e.target.blur()} name="modifiersale" defaultValue={modData.modifier_sale_price} required data-msg="Please enter Sale Price" className="input-material" readOnly/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Min Quantity <span className="text-danger">*</span></label>
                                                            <input id="min_quantity" type="number" onWheel={(e:any) => e.target.blur()} name="min_quantity" defaultValue={modData.min_quantity} min={0} required data-msg="Please enter Min Quantity" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Max Quantity <span className="text-danger">*</span></label>
                                                            <input id="max_quantity" type="number" onWheel={(e:any) => e.target.blur()} name="max_quantity" defaultValue={modData.max_quantity} min={1} required data-msg="Please enter Max Quantity" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                        <div >
                                                            <input readOnly id="radioCustom3" type="radio" name="for_specific_variations" value="0" checked={for_specific_variations == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Available for all variants</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                        <div >
                                                            <input readOnly id="radioCustom4" type="radio" name="for_specific_variations" value="1" checked={for_specific_variations == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Available for specific variants</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {for_specific_variations == "1" &&
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <div className="row">


                                                                    <div className="col-lg-6 col-sm-6 col-12 pt-3">
                                                                        <div className="form-group">
                                                                            <label className="form-control-label">Select Variant
                                                                                {/* <span className="text-danger">*</span> */}
                                                                            </label>
                                                                            <Select
                                                                                isMulti
                                                                                defaultValue={variant_id}
                                                                                options={variants_arr}
                                                                                className="text-capitalize basic-multi-select mt-2"
                                                                                classNamePrefix="select"
                                                                                onChange={(e, i) => this.handleVariantChange(e, i)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                }
                                                  {/* {sizejson.map((x: any, ind: any) => {
                                                    return (
                                                        <div key={ind} className="row">


                                                            <div className="col-lg-4 col-sm-4 col-6 mt-5">
                                                                <div className="form-group">
                                                                    <label className="form-control-label mb-3">Nutritional Info Variant  <span className="text-danger">*</span></label>

                                                                    <select
                                                                        onChange={(e: any) => this.handleVariantInputChange(e, ind)}
                                                                        name="variant_id" className="form-control text-capitalize mt-2">
                                                                        <option value="Select variant" selected disabled>Select variant</option>
                                                                        {variants &&
                                                                            variants.map((variant: any, index: any) => (
                                                                                <option value={variant.id}>{variant.name}</option>
                                                                            ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-sm-4 col-6 mt-5">
                                                                <div className="form-group">
                                                                    <label className="form-control-label mb-3">Nutritional Info <span className="text-danger">*</span></label>
                                                                    <input id="nutritional_info" value={x.nutritional_info} type="number" onWheel={(e:any) => e.target.blur()} name="nutritional_info" className="input-material" onChange={(e) => this.handleVariantInputChange(e, ind)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-sm-3 col-6 d-flex align-items-center">
                                                                <div className="form-group">
                                                                    <label className="form-control-label"> </label>
                                                                    {sizejson.length !== 1 &&
                                                                        <button className="btn btn-sm btn-outline-danger" onClick={() => this.handleRemoveClick(ind)}><i className="fa fa-trash"></i></button>}
                                                                    {sizejson.length - 1 === ind && <button className="btn btn-sm btn-primary ml-2 float-right" onClick={() => this.handleAddClick(ind)}><i className="fa fa-plus"></i></button>}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )
                                                })} */}
                                                {/* <div className="form-group d-flex justify-content-end mt-4">
                                                    <button onClick={this.handleSubmit} disabled={!this.isModifierReady()} className="btn btn-primary">Update Modifier</button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>

                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        modData: state.menu.modData,
        modgroups: state.menu.activeModgroups,
        groups: state.menu.groups,
        items: state.menu.itemsByGroup,
        variants: state.menu.variants,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        editModifier: function (id: any, data: any) {
            dispatch(editModifier(id, data));
        },
        getModifier: function (id: number) {
            dispatch(getModifier(id));
        },
        modGroupsList: function () {
            dispatch(modGroupsList())
        },
        groupsList: function () {
            dispatch(groupsList())
        },
        variantsList: function () {
            dispatch(variantsList())
        },
        getItemsByGroup: (groupid: number) => {
            dispatch(getItemsByGroup(groupid))
        },
        getVariantsByItem: (itemid: number) => {
            dispatch(getVariantsByItem(itemid))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMod);