import { CANCEL_ORDER_REASONS, DELIVERY_BOYS, FILTERED_ORDERS, ORDERS_LIST, ORDER_ITEMS_LIST, ORDER_STATUS_LIST, OUTBOUND_CONTACTS, TIMER_STATUS, UPDATE_ORDER, UPDATE_TIMER, MANUAL_SYNC_ORDER, ORDERS_BY_STORE, CARD_ORDERS_LIST, FILTERED_CARD_ORDERS, CARD_ORDER_ITEMS_LIST, UPDATE_SELECTED_ROW, UPDATE_LOADER, XML_BY_ORDER_ID } from '../actions/orderType'
const initialState: any = {
    data: [] || "",
    filteredOrders: [] || "",
    orderItems: [] || "",
    order: {},
    orderStatus: [] || "",
    deliveryBoys: [] || "",
    outboundContacts: [] || "",
    buttonDisable: false,
    timerStatus: true,
    prepCount: 0,
    recievedOrderCount: 0,
    readyCount: 0,
    dispatchCount: 0,
    completedOrderCount: 0,
    failedIntegOrderCount: 0,
    avg: 0,
    cancelReasons: [] || "",
    dayVal: {},
    emptyFilterOrder: false,
    isTimerUpdate: false,
    clickmenuSync: false,
    heatMapUpdate: false,
    cardOrders: [] || "",
    cardOrderItems: [] || "",
    filteredCardOrders: [] || "",
    emptyFilterCardOrder: false,
    verifyCount: 0,
    unverifyCount: 0,
    selectedRow: null,
    currentPage: 0,
    pageNumber: 1,
    total_orders_count: 0,
    pageSize: 50,
    pageCount: 0,
    numOfRows: 0,
    agg_orders: "" || [],
    orderTransferReasons: [] || "",
    isLoading: false,
}

const orderReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case "CLEAR_ORDERS": return {
            ...state,
            data: action.payload
        }
        case ORDERS_LIST: return {
            ...state,
            data: action.payload,
            avg: action.avg ? action.avg : 0,
            dayVal: action.dayVal,
            recievedOrderCount: action.recievedOrderCount,
            prepCount: action.prepCount,
            readyCount: action.readyCount,
            dispatchCount: action.dispatchCount,
            completedOrderCount: action.completedOrderCount,
            failedIntegOrderCount: action.failedIntegOrderCount,
            isLoading: action.isLoading,
            isTimerUpdate: action.isTimer,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows
        }
        case ORDER_ITEMS_LIST: return {
            ...state,
            orderItems: action.payload,
            order: action.orderDetail
        }
        case ORDER_STATUS_LIST: return {
            ...state,
            orderStatus: action.payload
        }
        case DELIVERY_BOYS: return {
            ...state,
            deliveryBoys: action.payload
        }
        case UPDATE_ORDER: return {
            ...state,
            buttonDisable: action.payload
        }
        case OUTBOUND_CONTACTS: return {
            ...state,
            outboundContacts: action.payload
        }
        case CANCEL_ORDER_REASONS: return {
            ...state,
            cancelReasons: action.payload
        }
        case XML_BY_ORDER_ID: return {
            ...state,
            orderXML: action.payload
        }
        case "SET_PAGINATION": return {
            ...state,
            pageNumber: action.payload.pageNum,
            pageSize: action.payload.pSize
        }
        case "TOTAL_ORDERS_COUNT": return {
            ...state,
            total_orders_count: action.payload

        }
        case FILTERED_ORDERS: return {
            ...state,
            filteredOrders: action.payload,
            emptyFilterOrder: action.emptyFilterOrder,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows
        }
        case TIMER_STATUS: return {
            ...state,
            timerStatus: action.payload == false ? false : true
        }
        case UPDATE_TIMER: return {
            ...state,
            isTimerUpdate: action.isTimerUpdate
        }
        case MANUAL_SYNC_ORDER: return {
            ...state,
            clickmenuSync: action.payload
        }
        case ORDERS_BY_STORE: return {
            ...state,
            orderByStores: action.payload,
            heatmapLat: action.heatmapLat,
            heatmapLng: action.heatmapLng,
            store_name: action.store_name,
            store_id: action.store_id,
            heatMapUpdate: action.heatMapUpdate
        }
        case CARD_ORDERS_LIST: return {
            ...state,
            cardOrders: action.payload,
            verifyCount: action.verifyCount,
            unverifyCount: action.unverifyCount,
            isLoading: action.isLoading
        }
        case FILTERED_CARD_ORDERS: return {
            ...state,
            filteredCardOrders: action.payload,
            emptyFilterCardOrder: action.emptyFilterCardOrder
        }
        case CARD_ORDER_ITEMS_LIST: return {
            ...state,
            cardOrderItems: action.payload,
            order: action.orderDetail
        }
        case UPDATE_SELECTED_ROW: return {
            ...state,
            selectedRow: action.payload
        }
        case "AGG_ORDERS_LIST": return {
            ...state,
            agg_orders: action.payload,
            failedIntegOrderCount: action.failedIntegOrderCount,
        }
        case "ORDER_TRANSFER_REASONS": return {
            ...state,
            orderTransferReasons: action.payload
        }
        case UPDATE_LOADER: return {
            ...state,
            isLoading: action.isLoading
        }
        default: return state;
    }
}
export default orderReducer;